import React, { FC } from 'react';
import { ActivityPreDemoStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { AssessmentOrderedListItem } from '../AssessmentOrderedListItem/AssessmentOrderedListItem';
import { ActivityTextDisplay } from '../ActivityTextDisplay/ActivityTextDisplay';
import { Row } from '../../Row/Row';
import { PillButton } from '../../PillButton/PillButton';
import { Column } from '../../Column/Column';

export const ActivityPreDemoStep: FC<ActivityPreDemoStepProps> = ({
  activityStep,
  onNext,
  ...rest
}) => {
  const { items } = activityStep;
  return (
    <AssessmentStepPage {...rest} showBack audio={null}>
      <Column className="flex-grow w-full h-full overflow-y-auto">
        {items.map((item, idx) => (
          <AssessmentOrderedListItem
            className="items-baseline pb-9 short:pb-4"
            order={idx + 1}
          >
            <ActivityTextDisplay activityText={item} />
          </AssessmentOrderedListItem>
        ))}
        <Row className="w-full justify-center short:mt-0 mt-20 pt-4">
          <PillButton buttonVariation="primary" onClick={onNext}>
            Continue
          </PillButton>
        </Row>
      </Column>
    </AssessmentStepPage>
  );
};
