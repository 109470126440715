import { SchoolOrg } from 'shared/lib/types/Org';
import { getSchool } from '../../api/school/getSchool';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { getStudentGroup } from '../../api/student-group/getStudentGroup';
import { EditableStudentGroup } from '../../types/student-group/EditableStudentGroup';
import { useHistory } from 'react-router-dom';
import { FormEvent, useCallback, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { formatSchoolDetailsRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { updateStudentGroup } from '../../api/student-group/updateStudentGroup';
import { createStudentGroup } from '../../api/student-group/createStudentGroup';
import { showOkAlert } from '../../components/Alert/Alert';

interface UseStudentGroupEditorParams {
  school: SchoolOrg | number | null;
  studentGroupId: number | null;
}

function loadCreateMode(
  school: SchoolOrg | number,
): Promise<[SchoolOrg, null]> {
  if (typeof school === 'number') {
    return Promise.all([getSchool(school), null]);
  }
  return Promise.resolve([school, null]);
}

async function loadEditMode(
  studentGroupId: number,
): Promise<[SchoolOrg, StudentGroup]> {
  const studentGroup = await getStudentGroup(studentGroupId);
  return [studentGroup.school, studentGroup];
}

const defaultEditableGroup: EditableStudentGroup = {
  name: '',
  location: '',
};

export function useStudentGroupEditor({
  studentGroupId,
  school,
}: UseStudentGroupEditorParams) {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [schoolOrg, setSchoolOrg] = useState<SchoolOrg | null>(null);
  const [studentGroup, setStudentGroup] = useState<StudentGroup | null>(null);
  const [editableGroup, setEditableGroup] = useState<EditableStudentGroup>(
    defaultEditableGroup,
  );
  const [error, setError] = useState('');

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setSchoolOrg(null);
        setStudentGroup(null);
      }

      if (!school && !studentGroupId) {
        await showOkAlert({
          title: 'Error',
          text: 'Unable to create or edit class.',
          theme: 'error',
        });
        history.goBack();
        return;
      }

      try {
        const [schoolOrg, studentClass] = !studentGroupId
          ? await loadCreateMode(school!)
          : await loadEditMode(studentGroupId!);

        if (!isCancelled()) {
          setStudentGroup(studentClass);
          setSchoolOrg(schoolOrg);
          setEditableGroup(
            studentClass
              ? { ...studentClass }
              : { ...defaultEditableGroup, location: schoolOrg.location },
          );
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare for class ${
            studentGroupId ? 'editing' : 'creation'
          }: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [school, studentGroupId, history],
  );

  const onNameChanged = useCallback((name: string) => {
    setEditableGroup((prev) => ({ ...prev, name }));
  }, []);

  const onLocationChanged = useCallback((location: string) => {
    setEditableGroup((prev) => ({ ...prev, location }));
  }, []);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading || !schoolOrg) {
        return;
      }
      setError('');
      const { name, location } = editableGroup;
      if (!name.trim()) {
        setError('Please enter a class name.');
        return;
      }

      if (!location.trim()) {
        setError("Please enter class's location");
        return;
      }

      setLoading(true);
      try {
        if (studentGroup) {
          setStudentGroup(
            await updateStudentGroup(studentGroup.id, editableGroup),
          );
          await showOkAlert({
            title: 'Class Updated',
            text: 'This class has been successfully updated.',
          });
        } else {
          const createdGroup = await createStudentGroup(
            schoolOrg.id,
            editableGroup,
          );
          await showOkAlert({
            title: 'Class Created',
            text: 'This class has been successfully created.',
          });
          history.replace(
            `${formatSchoolDetailsRoute(schoolOrg)}?selectedClass=${
              createdGroup.id
            }`,
          );
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `An error has occurred when ${
            studentGroup ? 'updating' : 'creating'
          } class: ${e.message}`,
          theme: 'error',
        });
        return;
      } finally {
        setLoading(false);
      }
    },
    [studentGroup, editableGroup, loading, history, schoolOrg],
  );

  return {
    loading,
    school: schoolOrg,
    editableGroup,
    studentGroup,
    onSubmit,
    onNameChanged,
    onLocationChanged,
    error,
  };
}
