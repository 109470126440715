import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { apiAxios } from '../apiAxios';

export async function goToPreviousStep(
  assessmentStepResultId: number,
): Promise<AssessmentTakeState> {
  const response = await apiAxios.get(
    `/take/step/${assessmentStepResultId}/previous`,
  );
  return response.data;
}
