import React, {
  ComponentPropsWithoutRef,
  FC,
  FormEvent,
  useCallback,
} from 'react';
import { EditableAccount } from '../../types/user/EditableAccount';
import { Row } from '../Row/Row';
import { Column } from '../Column/Column';
import { TextInput } from '../TextInput/TextInput';
import { PillButton } from '../PillButton/PillButton';
import { FormLabel } from '../FormLabel/FormLabel';

interface EditAccountFormProps extends ComponentPropsWithoutRef<'form'> {
  account: EditableAccount;
  onNameChanged(name: string): void;
  onEmailChanged(email: string): void;
  onCancelled(): void;
  error?: string;
}

export const EditAccountForm: FC<EditAccountFormProps> = ({
  account,
  className = '',
  onNameChanged,
  onEmailChanged,
  error,
  onCancelled,
  ...rest
}) => {
  const { name, email } = account;

  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );

  return (
    <form className={`flex flex-col edit-account-form ${className}`} {...rest}>
      <Column className="max-w-2.75xl">
        <Row className="justify-between flex-wrap">
          <Column className="flex-grow">
            <FormLabel htmlFor="name">Name</FormLabel>
            <TextInput
              id="first-name"
              value={name}
              onChange={(e) => onNameChanged(e.target.value)}
              placeholder="Name"
              className="edit-account-field"
              required
            />
          </Column>
          <Column className="flex-grow">
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextInput
              id="email"
              value={email}
              onChange={(e) => onEmailChanged(e.target.value)}
              placeholder="Email"
              required
            />
          </Column>
        </Row>
      </Column>
      <Row className="items-center flex-wrap pt-6">
        <PillButton
          type="submit"
          buttonVariation="primary"
          className="mt-9 mb-9"
        >
          Save Changes
        </PillButton>
        <PillButton
          onClick={handleCancel}
          buttonVariation="cancel"
          className="m-9"
        >
          Cancel
        </PillButton>
      </Row>
      <Row className="pt-3 text-red-600">{error}</Row>
    </form>
  );
};
