import React, { ComponentPropsWithRef, FC } from 'react';
import { AssessorDetail } from 'shared/lib/types/AssessorDetail';
import { RemoveConfirm } from '../RemoveConfirm/RemoveConfirm';
import { formatFirstLast } from 'shared/lib/utils/formatName';

interface RemoveAssessorFromClassConfirmProps
  extends ComponentPropsWithRef<'form'> {
  teacher: AssessorDetail;
  onCancelled(): void;
}

export const RemoveAssessorFromClassConfirm: FC<RemoveAssessorFromClassConfirmProps> = ({
  teacher,
  ...rest
}) => {
  const { id, currentClass } = teacher;
  return (
    <RemoveConfirm
      recordId={id}
      removalType="Teacher"
      confirmButtonText="Remove Teacher"
      recordName={formatFirstLast(teacher)}
      warningText={`You are about to Remove Teacher - ${formatFirstLast(
        teacher,
      )} - from ${
        currentClass?.name ?? 'this class'
      }. Do you wish to continue?`}
      {...rest}
    />
  );
};
