import React, { FC } from 'react';
import { Student } from 'shared/lib/types/Student';
import { AssessmentPage } from '../AssessmentPage/AssessmentPage';
import { Row } from '../../Row/Row';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import {
  blueSpeechBubble,
  blueSpeechBubbleFilled,
  blueSpeechBubbleGreyed,
  greenSpeechBubble,
  greenSpeechBubbleFilled,
  greenSpeechBubbleGreyed,
  headBlue,
} from '../../../images';
import { Column } from '../../Column/Column';
import { PillButton } from '../../PillButton/PillButton';
import { Language } from 'shared/lib/constants/Language';
import { Button } from '../../Button/Button';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';

interface ChooseAssessmentLanguageProps {
  student: Pick<Student, 'firstName' | 'lastName'>;
  language: Language | null;
  onLanguageSelected(language: Language): void;
  onBeginClicked(): void;
  onCancelClicked(): void;
}

export const ChooseAssessmentLanguage: FC<ChooseAssessmentLanguageProps> = ({
  student,
  onCancelClicked,
  language,
  onLanguageSelected,
  onBeginClicked,
  ...rest
}) => (
  <AssessmentPage
    onExitClicked={onCancelClicked}
    headingContents={<HeadingContents student={student} />}
    exitButtonText="Cancel"
    maintainHiddenElementLayout={false}
    {...rest}
  >
    <div className="flex flex-col lg:flex-row justify-between w-full">
      <div className="w-full lg:w-58">{/* For Layout */}</div>
      <Column className="w-full items-center">
        <h1 className="font-mplus text-light-blue-500 text-4xl">
          Please Select a BAPS version
        </h1>
        <Row className="w-full mt-2 justify-between lg:justify-center">
          <LanguageButton
            language={Language.ENGLISH}
            onClick={() => onLanguageSelected(Language.ENGLISH)}
            selected={language}
          />
          <LanguageButton
            language={Language.SPANISH}
            onClick={() => onLanguageSelected(Language.SPANISH)}
            selected={language}
          />
        </Row>
      </Column>
      <Column className="w-full pr-4 lg:w-58 items-center lg:items-start">
        <PillButton
          className={`transition-colors duration-200 ${
            language === null
              ? 'bg-disabled text-white'
              : 'bg-light-blue-500 text-white border border-light-blue-500'
          }`}
          disabled={language === null}
          onClick={onBeginClicked}
        >
          Begin BAPS
        </PillButton>
      </Column>
    </div>
  </AssessmentPage>
);

const HeadingContents: FC<Pick<ChooseAssessmentLanguageProps, 'student'>> = ({
  student,
}) => (
  <Row className="text-sm font-bold items-center">
    <img src={headBlue} alt="Student:" className="h-6 w-6 mr-2" />
    {formatFirstLast(student)}
  </Row>
);

interface LanguageButtonProps {
  language: Language;
  selected: Language | null;
  onClick(): void;
}

function getImage(language: Language, selected: Language | null): string {
  switch (language) {
    case Language.ENGLISH:
      if (selected === null) {
        return greenSpeechBubble;
      }
      return selected === language
        ? greenSpeechBubbleFilled
        : greenSpeechBubbleGreyed;
    case Language.SPANISH:
      if (selected === null) {
        return blueSpeechBubble;
      }
      return selected === language
        ? blueSpeechBubbleFilled
        : blueSpeechBubbleGreyed;
  }
}

function getFontColor(language: Language, selected: Language | null): string {
  if (selected === language) {
    return 'text-white';
  }
  if (selected !== null && selected !== language) {
    return 'text-gray-450';
  }
  return language === Language.SPANISH ? 'text-spanish' : 'text-english';
}

const LanguageButton: FC<LanguageButtonProps> = ({
  language,
  selected,
  ...rest
}) => (
  <Button
    className={`font-mplus font-extrabold text-4xl justify-center items-center w-72 h-72 m-6 lg:m-12 ${getFontColor(
      language,
      selected,
    )}`}
    {...rest}
  >
    <img
      src={getImage(language, selected)}
      alt={`${language} ${selected ? 'selected' : 'unselected'}`}
    />
    <div className="absolute">{capitalizeFirst(language)}</div>
  </Button>
);
