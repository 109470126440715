import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { add, edit, remove } from '../../images';

type LinkVariant = 'add' | 'edit' | 'remove';

function getIcon(variant: LinkVariant) {
  switch (variant) {
    case 'add':
      return add;
    case 'edit':
      return edit;
    case 'remove':
      return remove;
  }
}

interface ActionLinkProps extends LinkProps {
  variant: LinkVariant;
}

export const ActionLink: FC<ActionLinkProps> = ({
  variant,
  className = '',
  children,
  ...rest
}) => (
  <Link
    className={`flex flex-row text-light-blue-500 font-roboto font-medium text-sm items-center hover:underline ${className}`}
    {...rest}
  >
    <img src={getIcon(variant)} alt={variant} className="h-6 w-6 mr-2" />
    {children}
  </Link>
);
