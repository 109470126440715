import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Language } from 'shared/lib/constants/Language';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { BAPWithFullData } from 'shared/lib/types/BAP';
import {
  SortOrder,
  StudentSort,
  StudentSortField,
} from '../../types/student/StudentSort';
import { Row } from '../Row/Row';
import { SearchField } from '../SearchField/SearchField';
import { StudentList } from '../StudentList/StudentList';
import { BapsCompletedStats } from '../BapsCompletedStats/BapsCompletedStats';
import { BapsInProgress } from '../BapsInProgress/BapsInProgress';
import { MainPanel } from '../MainPanel/MainPanel';
import { Column } from '../Column/Column';
import './StudentsDashboard.css';
import { StudentSortDropdown } from '../StudentSortDropdown/StudentSortDropdown';

interface StudentsDashboardProps extends ComponentPropsWithoutRef<'div'> {
  students: ReadonlyArray<DashboardStudent>;
  inProgressBAPs: ReadonlyArray<BAPWithFullData>;
  totalCompletedBAPs: number;
  search: string;
  onSearchChanged(search: string): void;
  sortField: StudentSortField;
  sortOrder: SortOrder;
  onSortChanged(sort: StudentSort): void;
  onResumeClicked(studentId: number, languages: ReadonlyArray<Language>): void;
  onStartClicked(studentId: number): void;
  onTeachingRecommendationClicked(student: DashboardStudent): void;
}

export const StudentsDashboard: FC<StudentsDashboardProps> = ({
  students,
  inProgressBAPs,
  totalCompletedBAPs,
  search,
  onSearchChanged,
  sortField,
  sortOrder,
  onSortChanged,
  onResumeClicked,
  onStartClicked,
  className,
  onTeachingRecommendationClicked,
  ...rest
}) => {
  return (
    <MainPanel
      className={twMerge('overflow-hidden h-full pl-4 print:block', className)}
      leftPad={false}
      {...rest}
    >
      <Row className="w-full justify-end pb-6 pl-8 lg:pl-15 pr-5 print:hidden">
        <SearchField
          search={search}
          onSearchChanged={onSearchChanged}
          placeholder="Search for Student"
        />
      </Row>
      <Column className="overflow-y-auto h-full flex-grow flex-shrink-0 pl-4 pr-5 pb-20 student-dashboard-scroll print:block">
        <Row className="w-full justify-between flex-wrap pb-2">
          <div className="flex-grow flex-shrink-0 max-w-xs mr-6">
            <BapsCompletedStats
              totalCompletedBAPS={totalCompletedBAPs}
              totalStudents={students.length}
            />
          </div>
          <div className="flex-grow flex-shrink-0 max-w-97">
            <BapsInProgress
              inProgressBAPS={inProgressBAPs}
              onResumeClicked={onResumeClicked}
            />
          </div>
        </Row>
        <Column className="mt-5 print:block">
          <StudentSortDropdown
            sortField={sortField}
            sortOrder={sortOrder}
            onSelected={onSortChanged}
            className="print:hidden"
          />
          <StudentList
            students={students}
            sortField={sortField}
            onResumeClicked={onResumeClicked}
            onStartClicked={onStartClicked}
            onTeachingRecommendationClicked={onTeachingRecommendationClicked}
          />
        </Column>
      </Column>
    </MainPanel>
  );
};
