import React, { FC, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { Redirect, useHistory } from 'react-router-dom';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { InProgressAssessment } from 'shared/lib/types/InProgressAssessment';
import { getInProgressAssessment } from '../../api/assessment-take/getInProgressAssessment';
import {
  AssessmentTakeStatus,
  useAssessmentTake,
} from '../../hooks/assessment/useAssessmentTake';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import { ActivityErrorStep } from '../../components/Assessment/ActivityErrorStep/ActivityErrorStep';
import { ActivityStep } from '../../components/Assessment/ActivityStep/ActivityStep';
import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { Column } from '../../components/Column/Column';
import { Row } from '../../components/Row/Row';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { showOkAlert } from '../../components/Alert/Alert';
import { useSettingsContext } from '../../contexts/settingsContext';

export const TakeAssessmentPage: FC = (props) => {
  const [
    inProgressAssessment,
    setInProgressAssessment,
  ] = useState<InProgressAssessment | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      setLoading(true);
      setInProgressAssessment(null);
    }

    try {
      const inProgress = await getInProgressAssessment();
      if (!isCancelled()) {
        setInProgressAssessment(inProgress);
        setLoading(false);
      }
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        text: `Failed to initialize assessment: ${e.message}`,
        theme: 'error',
      });
      history.goBack();
    }
  }, []);

  if (loading || !inProgressAssessment) {
    return <SpinnerOverlay />;
  }

  return (
    <TakeAssessmentRunner
      bapAssessmentId={inProgressAssessment.bapAssessmentId}
    />
  );
};

const TakeAssessmentRunner: FC<{ bapAssessmentId: number }> = ({
  bapAssessmentId,
}) => {
  const { researchMode } = useSettingsContext();

  const {
    status,
    activityStep,
    assessmentTakeState,
    title,
    ...assessmentTakeProps
  } = useAssessmentTake({
    bapAssessmentId,
    showStudentResponseModal: researchMode,
  });
  const history = useHistory();

  if (
    status === AssessmentTakeStatus.INITIALIZING ||
    status === AssessmentTakeStatus.TIMED_OUT
  ) {
    return <SpinnerOverlay />;
  }

  // If the initialization fails, is hard to recover, so redirect to the dashboard.
  if (status === AssessmentTakeStatus.INITIALIZATION_FAILURE) {
    return <Redirect to={home} />;
  }

  if (activityStep === null) {
    return (
      <ActivityErrorStep onNext={() => history.replace(home)}>
        <h1 className="text-center">
          Failed to determine next item in this activity.
        </h1>
      </ActivityErrorStep>
    );
  }

  return (
    <>
      <PageTitle pageTitle={title.join(' ')} />
      <ActivityStep
        activityStep={activityStep}
        title={title}
        {...assessmentTakeProps}
      />
      <AssessmentTakeStateDebugDisplay
        assessmentTakeState={assessmentTakeState}
      />
    </>
  );
};

/**
 * Purely for debugging.
 * To make visible: `document.querySelector('#assessmentTakeState').style.display = 'flex'`
 */
const AssessmentTakeStateDebugDisplay: FC<{
  assessmentTakeState: AssessmentTakeState | null;
}> = ({ assessmentTakeState }) => {
  if (!assessmentTakeState) {
    return <>N/A</>;
  }
  const { currentItem, skill, totalSteps } = assessmentTakeState;
  const {
    isPrimaryStep,
    isFinalStep,
    activityStep,
    assessmentStepResult,
    itemAsset,
  } = currentItem;
  const { stepType, stepId } = activityStep;
  return (
    <Column className="hidden absolute" id="assessmentTakeState">
      <Row>Skill: {skill.name}</Row>
      <Row>Total Steps: {totalSteps}</Row>
      <Row>
        First?: {isPrimaryStep ? 'Yes' : 'No'} Last?:{' '}
        {isFinalStep ? 'Yes' : 'No'}
      </Row>
      <Row>
        Activity Step: {stepId}, type: {stepType}
      </Row>
      <Row>
        Assessment Step Result: {assessmentStepResult.id}, Order:{' '}
        {assessmentStepResult.stepOrder}. Selected Answer ID:{' '}
        {assessmentStepResult.answerId ?? 'None'}
      </Row>
      <Row>Item Asset: {itemAsset ? itemAsset.id : 'none'}</Row>
    </Column>
  );
};
