import React, { FC } from 'react';
import { ChooseAssessmentLanguage } from '../../components/Assessment/ChooseAssessmentLanguage/ChooseAssessmentLanguage';
import { useBeginAssessment } from '../../hooks/assessment/useBeginAssessment';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle/PageTitle';

export const BeginAssessmentPage: FC = (props) => {
  const {
    student,
    loading,
    onLanguageSelected,
    language,
    onBeginClicked,
  } = useBeginAssessment();

  const history = useHistory();
  if (loading || !student) {
    return <SpinnerOverlay />;
  }
  return (
    <>
      <PageTitle pageTitle="Choose Language" />
      <ChooseAssessmentLanguage
        language={language}
        onLanguageSelected={onLanguageSelected}
        onCancelClicked={() => history.goBack()}
        onBeginClicked={onBeginClicked}
        student={student}
      />
    </>
  );
};
