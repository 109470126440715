import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { useMemo } from 'react';
import { getActivityTitle } from 'shared/lib/utils/assessment/getActivityTitle';

export function useActivityTitle(
  takeState: Pick<
    AssessmentTakeState,
    'skill' | 'currentItem' | 'assessmentResult'
  > | null,
): [string, string] {
  return useMemo(() => {
    if (!takeState) {
      return ['', ''];
    }
    const { skill, currentItem, assessmentResult } = takeState;
    const { language } = assessmentResult;
    const { activityStep } = currentItem;
    return getActivityTitle(skill, activityStep, language);
  }, [takeState]);
}
