import { FC, useState } from 'react';
import { AssessorRoutes } from '../routes/AssessorRoutes';
import { SchoolOrg } from 'shared/lib/types/Org';
import { AssignedOrganizationContext } from '../contexts/assignedOrganizationContext';
import { useLoggedInUserContext } from '../contexts/userContext';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getAssessorSchool } from '../api/assessor/getAssessorSchool';
import { SpinnerOverlay } from '../components/SpinnerOverlay/SpinnerOverlay';
import { showOkAlert } from '../components/Alert/Alert';

export const AssessorApp: FC = () => {
  const [
    assignedOrganization,
    setAssignedOrganization,
  ] = useState<SchoolOrg | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useLoggedInUserContext();
  const { id } = user;

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setAssignedOrganization(null);
      }
      const assignedSchool = await getAssessorSchool();
      if (!assignedSchool) {
        await showOkAlert({
          title: 'Error',
          text:
            'You are not assigned a school. Please contact an administrator.',
          theme: 'error',
        });
      }
      if (!isCancelled()) {
        setAssignedOrganization(assignedSchool);
        setLoading(false);
      }
    },
    [id],
  );

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <AssignedOrganizationContext.Provider value={{ assignedOrganization }}>
      <AssessorRoutes />
    </AssignedOrganizationContext.Provider>
  );
};
