import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { SchoolDashboardData } from 'shared/lib/types/dashboard/school/SchoolDashboardData';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSchoolDashboardStudents } from './useSchoolDashboardStudents';
import { useSchoolDashboardAssessors } from './useSchoolDashboardAssessors';
import { UserWithGroupEnrollments } from 'shared/lib/types/User';
import { useSearchParamPatch, useSearchParams } from '../useSearchParams';
import { Admin } from 'shared/lib/types/Admin';
import { useNameSearch } from '../useNameSearch';
import { DashboardAssessor } from 'shared/lib/types/teacher/DashboardAssessor';
import { BAP, BAPWithFullData } from 'shared/lib/types/BAP';
import { showTeachingRecommendationsModal } from '../../components/TeachingRecommendationsModal/TeachingRecommendationsModal';
import { useStudentListSort } from '../student/useStudentListSort';
import {
  SortOrder,
  StudentSort,
  StudentSortField,
} from '../../types/student/StudentSort';

interface UseSchoolDashboardParams {
  students: ReadonlyArray<DashboardStudent>;
  classes: ReadonlyArray<StudentGroup>;
  assessors: ReadonlyArray<UserWithGroupEnrollments>;
  schoolAdmins: ReadonlyArray<Admin>;
  inProgressBAPs: ReadonlyArray<BAPWithFullData>;
  completedBAPs: ReadonlyArray<BAP>;
}

interface SchoolDetailsDashboardProps {
  data: SchoolDashboardData;
  classes: ReadonlyArray<StudentGroup>;
  selectedClass: StudentGroup | null;
  onClassSelected(group: StudentGroup | null): void;
  students: ReadonlyArray<DashboardStudent>;
  teachers: ReadonlyArray<DashboardAssessor>;
  schoolAdmins: ReadonlyArray<Admin>;
  search: string;
  onSearchChanged(search: string): void;
  sortField: StudentSortField;
  sortOrder: SortOrder;
  onSortChanged(sort: StudentSort): void;
  onTeachingRecommendationClicked(student: DashboardStudent): void;
}

export function useSchoolDashboard({
  students,
  assessors,
  classes,
  schoolAdmins,
  completedBAPs,
  inProgressBAPs,
}: UseSchoolDashboardParams): SchoolDetailsDashboardProps {
  const [selectedClass, setSelectedClass] = useState<StudentGroup | null>(null);
  const [search, setSearch] = useState('');
  const params = useSearchParams();
  const patchSearchParams = useSearchParamPatch();

  const classStudents = useSchoolDashboardStudents({
    students,
    selectedClass,
    search,
  });
  const {
    sortedStudents,
    sortField,
    sortOrder,
    onSortChanged,
  } = useStudentListSort(classStudents);
  const data = useMemo((): SchoolDashboardData => {
    const classStudentIds = new Set(sortedStudents.map(({ id }) => id));
    return {
      inProgressBAPS: inProgressBAPs.filter((inProgress: BAPWithFullData) =>
        classStudentIds.has(inProgress.student.id),
      ),
      totalCompletedBAPS: completedBAPs.filter((bap) =>
        classStudentIds.has(bap.studentId),
      ).length,
      students: sortedStudents,
    };
  }, [sortedStudents, inProgressBAPs, completedBAPs]);

  const onClassSelected = useCallback(
    (group: StudentGroup | null) => {
      patchSearchParams({ selectedClass: group?.id });
      setSelectedClass(group);
    },
    [patchSearchParams],
  );

  useEffect(() => {
    if (!selectedClass) {
      const selectedClassId = +(params.get('selectedClass') ?? '');
      if (!Number.isNaN(selectedClassId)) {
        setSelectedClass(
          classes.find((cls) => cls.id === selectedClassId) ?? null,
        );
      }
    }
  }, [params, selectedClass, classes]);

  const onTeachingRecommendationClicked = useCallback(
    async ({ teachingRecommendation, ...student }: DashboardStudent) => {
      if (!teachingRecommendation) {
        return;
      }
      const { strugglingSkills } = teachingRecommendation;
      await showTeachingRecommendationsModal({
        student,
        struggledSkills: strugglingSkills,
      });
    },
    [],
  );

  return {
    selectedClass,
    classes,
    onClassSelected,
    students: sortedStudents,
    teachers: useSchoolDashboardAssessors({
      assessors,
      selectedClass,
      search,
      students,
    }),
    schoolAdmins: useNameSearch({ list: schoolAdmins, search }),
    data,
    search,
    onSearchChanged: setSearch,
    sortField,
    sortOrder,
    onSortChanged,
    onTeachingRecommendationClicked,
  };
}
