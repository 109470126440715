import React, { ComponentPropsWithoutRef, FC } from 'react';
import { DashboardDistrict } from 'shared/lib/types/district/DashboardDistrict';
import { Admin } from 'shared/lib/types/Admin';
import { Column } from '../Column/Column';
import {
  createDistrict,
  createSuperAdmin,
} from 'shared/lib/constants/routes/administratorRoutes';
import { Row } from '../Row/Row';
import { SearchField } from '../SearchField/SearchField';
import { ListToggleButton } from '../ListToggleButton/ListToggleButton';
import { DistrictList } from '../DistrictList/DistrictList';
import { AdminList } from '../AdminList/AdminList';
import { MainPanel } from '../MainPanel/MainPanel';
import { ActionLink } from '../ActionLink/ActionLink';
import { ListSwitchable } from '../../types/ListSwitchable';
import { Redirect, Route, Switch } from 'react-router-dom';

const configureListRoutes = (basePath: string) => ({
  districtList: `${basePath}/list`,
  admins: `${basePath}/admins`,
});

interface DistrictsDashboardProps
  extends ComponentPropsWithoutRef<'div'>,
    ListSwitchable {
  districts: ReadonlyArray<DashboardDistrict>;
  superAdmins: ReadonlyArray<Admin>;
  search: string;
  onSearchChanged(search: string): void;
  onReinviteClicked(admin: Admin): void;
}

export const DistrictsDashboard: FC<DistrictsDashboardProps> = ({
  districts,
  superAdmins,
  className = '',
  search,
  onSearchChanged,
  basePath,
  activePath,
  onReinviteClicked,
  ...rest
}) => {
  const { admins, districtList } = configureListRoutes(basePath);
  const isDistrictMode = activePath === districtList || activePath === basePath;
  return (
    <Column className={`flex-grow flex-shrink-0 h-full ${className}`} {...rest}>
      <Row className="w-full justify-between items-center pl-17 pr-5 pb-8">
        <h1 className="font-roboto text-trueGray-750 text-2xl">
          All Districts
        </h1>
        <Row className="items-center">
          <ActionLink variant="add" className="mr-6" to={createDistrict}>
            Add District
          </ActionLink>

          <ActionLink variant="add" className="ml-6 mr-6" to={createSuperAdmin}>
            Add Super Admin
          </ActionLink>
        </Row>
      </Row>
      <MainPanel className="mt-2.5">
        <Row className="justify-between pb-7 pl-8 lg:pl-15 pr-6">
          <div>&nbsp;{/* for layout */}</div>
          <Row className="flex-wrap">
            <ListToggleButton
              to={districtList}
              active={isDistrictMode}
              buttonText="Districts"
            />
            <ListToggleButton
              to={admins}
              active={activePath === admins}
              buttonText="Super Admins"
            />
          </Row>
          <SearchField
            search={search}
            onSearchChanged={onSearchChanged}
            placeholder={`Search For ${
              isDistrictMode ? 'District' : 'Super Admin'
            }`}
          />
        </Row>
        <Switch>
          <Route exact path={[basePath, districtList]}>
            <DistrictList districts={districts} />
          </Route>
          <Route exact path={admins}>
            <AdminList
              admins={superAdmins}
              onReinviteClicked={onReinviteClicked}
            />
          </Route>
          <Redirect to={basePath} />
        </Switch>
      </MainPanel>
    </Column>
  );
};
