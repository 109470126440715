import React, { FC } from 'react';
import { Column } from '../Column/Column';

export const MainPanel: FC<{
  className?: string;
  leftPad?: boolean;
  topPad?: boolean;
}> = ({ className = '', leftPad = true, topPad = true, ...rest }) => (
  <Column
    className={`bg-blue-75 h-full  flex-grow flex-shrink-0 ${
      topPad ? 'pt-15' : ''
    } rounded-tl-6xl print:rounded-none ${
      leftPad ? 'pl-8 lg:pl-15' : ''
    } ${className}`}
    {...rest}
  />
);
