import { Student } from 'shared/lib/types/Student';
import { apiAxios } from '../apiAxios';

/**
 * Get a student
 * @param studentId the student to get
 */
export async function getStudent(studentId: number): Promise<Student> {
  const response = await apiAxios.get(`/student/${studentId}`);
  return response.data;
}
