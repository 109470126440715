import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { DistrictOrg } from 'shared/lib/types/Org';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getDistrict } from '../../api/district/getDistrict';
import { districts } from 'shared/lib/constants/routes/administratorRoutes';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { BackButton } from '../../components/BackButton/BackButton';
import { RemoveDistrictConfirm } from '../../components/RemoveDistrictConfirm/RemoveDistrictConfirm';
import { useHistory } from 'react-router-dom';
import { deleteDistrict } from '../../api/district/deleteDistrict';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { Row } from '../../components/Row/Row';
import { showOkAlert } from '../../components/Alert/Alert';

interface RemoveDistrictProps {
  districtId: number;
}

export const RemoveDistrict: FC<RemoveDistrictProps> = ({
  districtId,
  ...rest
}) => {
  const [district, setDistrict] = useState<DistrictOrg | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setDistrict(null);
      }
      try {
        const districtOrg = await getDistrict(districtId);
        if (!isCancelled()) {
          setDistrict(districtOrg);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to load district details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [districtId],
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!district) {
        return;
      }
      setLoading(true);
      try {
        await deleteDistrict(district.id);
        history.replace(districts);
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to remove district: ${e.message}`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [district, history],
  );

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || !district ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <BackButton onClick={() => history.goBack()} className="mb-6" />
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {district?.name ?? ''}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    Remove District
                  </Row>
                </Column>
              </Row>
            </Column>
          </Column>
          <MainPanel className="pr-8 lg:pr-15">
            <RemoveDistrictConfirm
              district={district}
              onSubmit={onSubmit}
              onCancelled={() => history.goBack()}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
