import { apiAxios } from '../apiAxios';

export async function removeAssessorFromGroup(
  studentGroupId: number,
  assessorId: number,
) {
  return apiAxios.delete(
    `/student-group/${studentGroupId}/assessor/${assessorId}`,
  );
}
