import React, { ComponentPropsWithoutRef, FC } from 'react';
import { DashboardData } from 'shared/lib/types/dashboard/DashboardData';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import './Dashboard.css';
import { StudentPerformancePercentileChart } from '../StudentPerformancePercentileChart/StudentPerformancePercentileChart';
import { RiskStatusChart } from '../RiskStatusChart/RiskStatusChart';
import { MainPanel } from '../MainPanel/MainPanel';
import { BapsInProgress } from '../BapsInProgress/BapsInProgress';
import { BapsCompletedStats } from '../BapsCompletedStats/BapsCompletedStats';
import { Language } from 'shared/lib/constants/Language';
import { PillButton } from '../PillButton/PillButton';

interface DashboardProps extends ComponentPropsWithoutRef<'div'> {
  data: DashboardData;
  onResumeClicked(studentId: number, languages: ReadonlyArray<Language>): void;
  onExportCSVClicked(): void;
}

export const Dashboard: FC<DashboardProps> = ({
  data,
  className = '',
  onResumeClicked,
  onExportCSVClicked,
  ...rest
}) => {
  const {
    totalStudents,
    totalCompletedBAPS,
    inProgressBAPs,
    studentPerformance,
    riskStatus,
    scaledScores,
  } = data;
  return (
    <MainPanel
      className={`flex-grow flex-shrink-0 overflow-y-auto pb-24 pr-5 pt-6 ${className}`}
      topPad={false}
      {...rest}
    >
      <Column>
        <Row className="w-full justify-end">
          <PillButton buttonVariation="primary" onClick={onExportCSVClicked}>
            Export CSV
          </PillButton>
        </Row>
        <Row className="w-full justify-between flex-wrap">
          <div className="flex-grow flex-shrink-0 max-w-xs mr-6 pt-6">
            <BapsCompletedStats
              totalCompletedBAPS={totalCompletedBAPS}
              totalStudents={totalStudents}
            />
          </div>
          <div className="flex-grow flex-shrink-0 pt-6 max-w-97">
            <BapsInProgress
              inProgressBAPS={inProgressBAPs}
              onResumeClicked={onResumeClicked}
            />
          </div>
        </Row>
        <Row className="w-full mt-6 justify-between flex-wrap">
          <Column className="bg-white shadow-dashboardShadow rounded-xl student-performance-panel">
            <StudentPerformancePercentileChart
              studentPerformance={studentPerformance}
              scaledScores={scaledScores}
            />
          </Column>
          <Column className="bg-white shadow-dashboardShadow rounded-xl student-risk-panel items-end">
            <RiskStatusChart riskStatus={riskStatus} className="w-full" />
          </Column>
        </Row>
      </Column>
    </MainPanel>
  );
};
