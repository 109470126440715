import { EditableUser } from '../../types/user/EditableUser';
import { Admin } from 'shared/lib/types/Admin';
import { apiAxios } from '../apiAxios';

export async function createDistrictAdmin(
  districtId: number,
  body: EditableUser,
): Promise<Admin> {
  const response = await apiAxios.put(`/district/${districtId}/admin`, body);
  return response.data;
}
