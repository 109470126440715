import React, { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { DashboardRiskStatus } from 'shared/lib/types/dashboard/DashboardData';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { blueGauge } from '../../images';

interface RiskStatusChartProps extends ComponentPropsWithoutRef<'div'> {
  riskStatus: DashboardRiskStatus;
}

const dimensions = [
  // 0 - 10% max
  {
    height: 'h-11',
    width: 'w-11',
  },
  // 11 - 20% max
  {
    height: 'h-12',
    width: 'w-12',
  },
  // 21 - 30% max
  {
    height: 'h-14',
    width: 'w-14',
  },
  // 31 - 40% max
  {
    height: 'h-16',
    width: 'w-16',
  },
  // 41 - 50% max
  {
    height: 'h-20',
    width: 'w-20',
  },
  // 51 - 60% max
  {
    height: 'h-24',
    width: 'w-24',
  },
  // 61 - 70% max
  {
    height: 'h-28',
    width: 'w-28',
  },
  // 71 - 80% max
  {
    height: 'h-32',
    width: 'w-32',
  },
  // 81 - 80% max
  {
    height: 'h-36',
    width: 'w-36',
  },
  // 90%+
  {
    height: 'h-40',
    width: 'w-40',
  },
];

function getRelativeDimension(value: number, max: number) {
  const index = Math.floor((value / Math.max(max, 1)) * 9);
  return dimensions[index];
}

export const RiskStatusChart: FC<RiskStatusChartProps> = ({
  riskStatus,
  className = '',
  ...rest
}) => {
  const { atRisk, notAtRisk } = riskStatus;
  const max = useMemo(() => Math.max(atRisk, notAtRisk), [atRisk, notAtRisk]);
  const dimensionAtRisk = useMemo(() => getRelativeDimension(atRisk, max), [
    atRisk,
    max,
  ]);
  const dimensionNotAtRisk = useMemo(
    () => getRelativeDimension(notAtRisk, max),
    [notAtRisk, max],
  );

  return (
    <Column className={`items-center ${className}`} {...rest}>
      <Row className="w-full items-center pb-12">
        <img src={blueGauge} alt="Status" className="w-6 h-6 absolute" />
        <Row className="w-full justify-center text-center font-roboto text-light-blue-500 font-bold">
          Risk Status
        </Row>
      </Row>
      <Row className={`items-center mb-12 h-40 flex-shrink-0`}>
        <Column
          className={`bg-white text-not-at-risk text-2xl border border-not-at-risk rounded-full justify-center items-center -right-4 ${
            dimensionNotAtRisk.height
          } ${dimensionNotAtRisk.width} ${atRisk >= notAtRisk ? 'z-10' : ''}`}
        >
          {notAtRisk}
        </Column>
        <Column
          className={`bg-white text-at-risk text-2xl border border-at-risk rounded-full justify-center items-center -left-4 ${
            dimensionAtRisk.height
          } ${dimensionAtRisk.width} ${notAtRisk >= atRisk ? 'z-10' : ''}`}
        >
          {atRisk}
        </Column>
      </Row>
      <Row className="w-full justify-between h-full font-roboto text-sm text-trueGray-750 font-light">
        <Row className="items-center">
          <div className="rounded-full bg-not-at-risk h-3 w-3 mr-2.5" />
          Not At Risk
        </Row>
        <Row className="items-center">
          <div className="rounded-full bg-at-risk h-3 w-3 mr-2.5" />
          At Risk
        </Row>
      </Row>
    </Column>
  );
};
