import React, { FC, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { BackButton } from '../../components/BackButton/BackButton';
import {
  formatRemoveSchoolRoute,
  formatSchoolDetailsRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { DistrictOrg, SchoolOrg } from 'shared/lib/types/Org';
import { useHistory } from 'react-router-dom';
import { useSchoolForm } from '../../hooks/school/useSchoolForm';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getSchool } from '../../api/school/getSchool';
import { getDistrict } from '../../api/district/getDistrict';
import { OrgForm } from '../../components/OrgForm/OrgForm';
import { OrgType } from 'shared/lib/constants/org/OrgType';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { Row } from '../../components/Row/Row';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { showOkAlert } from '../../components/Alert/Alert';
import { noop } from 'shared/lib/utils/noop';

interface SchoolEditorProps {
  schoolId: number | null;
  districtId: number | null;
}

export const SchoolEditor: FC<SchoolEditorProps> = ({
  schoolId,
  districtId,
  ...rest
}) => {
  const history = useHistory();
  const [district, setDistrict] = useState<DistrictOrg | null>(null);
  const [school, setSchool] = useState<SchoolOrg | null>(null);
  const [initializing, setInitializing] = useState(true);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setSchool(null);
        setInitializing(true);
      }
      try {
        let schoolDetail: SchoolOrg | null = null;
        let parentId: number | null = null;
        if (schoolId) {
          schoolDetail = await getSchool(schoolId);
          parentId = schoolDetail.parent;
        }
        if (!parentId && districtId) {
          parentId = districtId;
        }
        const parent = parentId ? await getDistrict(parentId) : null;
        if (!isCancelled()) {
          setDistrict(parent);
          setSchool(schoolDetail);
          setInitializing(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to load school details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [schoolId, history],
  );

  const onSubmitted = useCallback(
    (updated: SchoolOrg) => {
      history.replace(formatSchoolDetailsRoute(updated));
    },
    [history],
  );

  const {
    editableSchool,
    onNameChanged,
    onSubmit,
    loading,
    error,
    onLocationChanged,
  } = useSchoolForm({ school, onSubmitted, district });

  const editMode = !!school;

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || initializing ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle
            pageTitle={school ? `Edit ${school.name}` : 'Create School'}
          />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
                <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
                  {district?.name}
                </Row>
                <div>&nbsp;</div>
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {school?.name ?? district?.name ?? ''}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    {school ? 'Edit School' : 'Add School'}
                  </Row>
                </Column>
                {school && (
                  <ActionLink
                    variant="remove"
                    to={formatRemoveSchoolRoute(school)}
                    replace
                  >
                    Remove School
                  </ActionLink>
                )}
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <OrgForm
              editMode={editMode}
              org={{
                ...editableSchool,
                researchOrg:
                  district?.researchOrg ??
                  false /* not edited; just here to match common type */,
              }}
              onCancelled={() => history.goBack()}
              onNameChanged={onNameChanged}
              onLocationChanged={onLocationChanged}
              onSubmit={onSubmit}
              error={error}
              orgName={school?.name || ''}
              orgType={OrgType.SCHOOL}
              onResearchOrgChanged={noop /* District Level */}
              className="pl-9"
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
