import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { useState } from 'react';
import { PresignedURL } from 'shared/lib/types/asset/PresignedURL';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getPresignedUrl } from '../../api/presign/getPresignedUrl';

const DEFAULT_PLAYED = process.env.NODE_ENV === 'development';

export function useActivityStepAudio(
  takeState: Pick<AssessmentTakeState, 'currentItem'> | null,
) {
  const [
    presignedAudioUrl,
    setPresignedAudioUrl,
  ] = useState<PresignedURL | null>(null);
  const soundFile = takeState?.currentItem?.itemAsset?.soundFile ?? null;
  const [audioPlayed, setAudioPlayed] = useState(false);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setPresignedAudioUrl(null);
        setAudioPlayed(false);
      }
      if (soundFile) {
        const presignedUrl = await getPresignedUrl(soundFile);
        if (!isCancelled()) {
          setPresignedAudioUrl(presignedUrl);
        }
      }
    },
    [soundFile],
  );

  return {
    presignedAudioUrl,
    onAudioPlayed: () => setAudioPlayed(true),
    audioPlayed: DEFAULT_PLAYED || (soundFile !== null && audioPlayed),
  };
}
