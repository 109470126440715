import { FC, useState } from 'react';
import { useLoggedInAdminContext } from '../contexts/userContext';
import { AdminRoutes } from '../routes/AdminRoutes';
import { AssignedOrganizationContext } from '../contexts/assignedOrganizationContext';
import { Org } from 'shared/lib/types/Org';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { SpinnerOverlay } from '../components/SpinnerOverlay/SpinnerOverlay';
import { getCurrentAdminAssignedOrganization } from '../api/admin/getCurrentAdminAssignedOrganization';

export const AdminApp: FC = () => {
  const { adminLevel, user } = useLoggedInAdminContext();
  const { id } = user;
  const [assignedOrganization, setAssignedOrganization] = useState<Org | null>(
    null,
  );
  const [loading, setLoading] = useState(true);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setAssignedOrganization(null);
      }

      const org =
        adminLevel === AdminLevel.SUPER
          ? null
          : await getCurrentAdminAssignedOrganization();
      if (!isCancelled()) {
        setAssignedOrganization(org);
        setLoading(false);
      }
    },
    [adminLevel, id],
  );

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <AssignedOrganizationContext.Provider value={{ assignedOrganization }}>
      <AdminRoutes />
    </AssignedOrganizationContext.Provider>
  );
};
