import { useCallback, useState } from 'react';
import { User } from 'shared/lib/types/User';
import { resendInvite } from '../../api/user/resendInvite';
import { showOkAlert } from '../../components/Alert/Alert';

export function useResendInvite() {
  const [sendPending, setSendPending] = useState(false);

  const reinviteUser = useCallback(
    async (user: User) => {
      if (sendPending || !user.pending) {
        return;
      }
      setSendPending(true);
      try {
        await resendInvite(user.id);
        setSendPending(false);
        await showOkAlert({
          title: 'Success',
          text: 'Invite resent successfully.',
        });
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to reinvite user: ${e.message}`,
          theme: 'error',
        });
        setSendPending(false);
      }
    },
    [sendPending],
  );

  return {
    sendPending,
    reinviteUser,
  };
}
