import React, { FC } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { useUserContext } from '../../contexts/userContext';
import { useHistory } from 'react-router-dom';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';

export const Logout: FC = (props) => {
  const history = useHistory();
  const { logout } = useUserContext();
  useAsyncEffect(async () => {
    await logout();
  }, [logout, history]);
  return <SpinnerOverlay />;
};
