import { apiAxios } from '../apiAxios';

interface Request {
  email: string;
  code: string;
  password: string;
}
export function resetPassword(request: Request) {
  return apiAxios.post('/auth/reset-password', request);
}
