import { StudentGroupAndSchool } from 'shared/lib/types/StudentGroup';
import { apiAxios } from '../apiAxios';

export async function getPrimaryStudentGroupForAssessor(
  assessorId: number,
): Promise<StudentGroupAndSchool | null> {
  const response = await apiAxios.get(
    `/assessor/${assessorId}/student-group/primary`,
  );
  return response.data.studentGroup;
}
