import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';

export const Overlay: FC<ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...rest
}) => (
  <div
    className={twMerge(
      'flex flex-column fixed left-0 top-0 w-100 h-screen w-screen justify-center items-center bg-white z-50',
      className,
    )}
    {...rest}
  />
);
