import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ButtonVariation } from '../../types/ButtonVariation';
import { getStylesForButtonVariation } from '../../utils/getStylesForButtonVariation';

interface PillButtonLinkProps extends LinkProps {
  /**
   * Optional, utility to style a button to appear like a primary (blue background, white text)
   * or secondary (transparent background, blue text) button.
   * Defaults to no standard style other than making it look like a Pill button
   */
  buttonVariation?: ButtonVariation;
}

/**
 * A React Router Link styled like a pill button
 */
export const PillButtonLink: FC<PillButtonLinkProps> = ({
  className = '',
  buttonVariation,
  ...rest
}) => (
  <Link
    className={`font-roboto text-sm font-medium rounded-full flex flex-row justify-center items-center text-center h-12 w-39 ${getStylesForButtonVariation(
      buttonVariation,
    )} ${className}`}
    {...rest}
  />
);
