import React, { FC, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { DashboardDistrict } from 'shared/lib/types/district/DashboardDistrict';
import { Admin } from 'shared/lib/types/Admin';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { DistrictsDashboard } from '../../components/DistrictsDashboard/DistrictsDashboard';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getDistricts } from '../../api/district/getDistricts';
import { getSuperAdmins } from '../../api/admin/getSuperAdmins';
import { useDistrictsDashboard } from '../../hooks/district/useDistrictsDashboard';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { districts as districtsRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { useLocation } from 'react-router-dom';
import { useResendInvite } from '../../hooks/user/useResendInvite';

export const Districts: FC = (props) => {
  const [loading, setLoading] = useState(true);
  const [districts, setDistricts] = useState<DashboardDistrict[]>([]);
  const [superAdmins, setSuperAdmins] = useState<Admin[]>([]);
  const { pathname } = useLocation();

  useAsyncEffect(async (isCancelled) => {
    if (!isCancelled()) {
      setLoading(true);
      setDistricts([]);
      setSuperAdmins([]);
    }
    const [districtList, admins] = await Promise.all([
      getDistricts(),
      getSuperAdmins(),
    ]);
    if (!isCancelled()) {
      setDistricts(districtList);
      setSuperAdmins(admins);
      setLoading(false);
    }
  }, []);

  const {
    districts: districtList,
    superAdmins: superAdminList,
    search,
    onSearchChanged,
  } = useDistrictsDashboard({ superAdmins, districts });

  const { reinviteUser, sendPending } = useResendInvite();

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden h-full" {...props}>
      <PageTitle pageTitle="Districts" />
      {loading ? (
        <SpinnerOverlay />
      ) : (
        <DistrictsDashboard
          districts={districtList}
          superAdmins={superAdminList}
          search={search}
          onSearchChanged={onSearchChanged}
          className="mt-2"
          basePath={districtsRoute}
          activePath={pathname}
          onReinviteClicked={reinviteUser}
        />
      )}
      {sendPending && <SpinnerOverlay opaque />}
    </Column>
  );
};
