import React, { FC } from 'react';
import { CorrectIncorrect } from 'shared/lib/constants/assessment/CorrectIncorrect';
import { Row } from '../../Row/Row';
import { AssessmentCorrectIncorrectAnswerButton } from '../AssessmentCorrectIncorrectAnswerButton/AssessmentCorrectIncorrectAnswerButton';

interface AssessmentCorrectIncorrectButtonsProps {
  selected: CorrectIncorrect | null;
  onClick(value: CorrectIncorrect): void;
  className?: string;
}

export const AssessmentCorrectIncorrectButtons: FC<AssessmentCorrectIncorrectButtonsProps> = ({
  className = '',
  selected,
  onClick,
  ...rest
}) => (
  <Row className={`w-full justify-center items-center ${className}`} {...rest}>
    <Row className="w-full justify-between items-center flex-wrap max-w-2xl">
      <AssessmentCorrectIncorrectAnswerButton
        value={CorrectIncorrect.CORRECT}
        selected={selected === CorrectIncorrect.CORRECT}
        onClick={() => onClick(CorrectIncorrect.CORRECT)}
      />

      <AssessmentCorrectIncorrectAnswerButton
        value={CorrectIncorrect.INCORRECT}
        selected={selected === CorrectIncorrect.INCORRECT}
        onClick={() => onClick(CorrectIncorrect.INCORRECT)}
      />
    </Row>
  </Row>
);
