import { FC, useCallback, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import { Option } from '../../types/Option';
import {
  SortOrder,
  sortOrderDeserializer,
  StudentSort,
  StudentSortField,
  studentSortFieldDeserializer,
} from '../../types/student/StudentSort';
import { Dropdown } from '../Dropdown/Dropdown';
import { DirectionArrowIcon } from '../DirectionArrowIcon/DirectionArrowIcon';

interface StudentSortDropdownProps {
  className?: string;
  sortField: StudentSortField;
  sortOrder: SortOrder;
  onSelected(sort: StudentSort): void;
}

function formatOptionId(field: StudentSortField, order: SortOrder): string {
  return `${field}-${order}`;
}

function parseOptionId(optionId: Option['id']): StudentSort {
  if (typeof optionId !== 'string') {
    throw new Error('Invalid sort option'); // Should never happen
  }
  const [field, order] = optionId.split('-');
  return {
    field: studentSortFieldDeserializer(field),
    order: sortOrderDeserializer(order),
  };
}

const sortOrders: { order: SortOrder }[] = [
  { order: SortOrder.ASCENDING },
  { order: SortOrder.DESCENDING },
];

const optionFieldLabels: { field: StudentSortField; label: string }[] = [
  {
    field: StudentSortField.NAME,
    label: 'Alphabetical, Student Name',
  },
  {
    field: StudentSortField.RISK_STATUS,
    label: 'Individual Risk Status',
  },
  {
    field: StudentSortField.INSTRUCTION_LEVEL_ENGLISH,
    label: 'Instructional Level (E)',
  },
  {
    field: StudentSortField.INSTRUCTION_LEVEL_SPANISH,
    label: 'Instructional Level (S)',
  },
  {
    field: StudentSortField.PERCENTILE_ENGLISH,
    label: 'Age-Adjusted Percentile (E)',
  },
  {
    field: StudentSortField.PERCENTILE_SPANISH,
    label: 'Age-Adjusted Percentile (S)',
  },
];

const options: Option[] = optionFieldLabels.flatMap(({ field, label }) =>
  sortOrders.map(({ order }) => ({
    id: formatOptionId(field, order),
    value: (
      <div className="flex items-center">
        {label}
        <DirectionArrowIcon
          className={twMerge(
            'ml-2',
            order === SortOrder.ASCENDING && 'transform rotate-180',
          )}
        />
      </div>
    ),
  })),
);

export const StudentSortDropdown: FC<StudentSortDropdownProps> = ({
  sortField,
  sortOrder,
  onSelected,
  className,
  ...rest
}) => {
  const selectedOption = useMemo(
    () => options.find(({ id }) => id === formatOptionId(sortField, sortOrder)),
    [sortField, sortOrder],
  );

  const handleSelected = useCallback(
    (option: Option) => onSelected(parseOptionId(option.id)),
    [onSelected],
  );

  return (
    <Dropdown
      className={twMerge('rounded-lg', className)}
      options={options}
      selected={selectedOption}
      onSelected={handleSelected}
      withCheckIcon
      {...rest}
    />
  );
};
