import { useCallback, useState } from 'react';
import { User } from 'shared/lib/types/User';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getUser } from '../api/auth/getUser';
import { login } from '../api/auth/login';
import { logout } from '../api/auth/logout';
import { UserContextValue } from '../contexts/userContext';

/**
 * Hook to manage the currently logged in user and log them in or out.
 */
export function useUser(): UserContextValue & { loading: boolean } {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const refreshUser = useCallback(async (isCancelled?: () => boolean) => {
    const userFromSession = await getUser();
    if (!isCancelled || !isCancelled()) {
      setUser(userFromSession);
    }
  }, []);

  const doLogin = useCallback(
    async (username: string, password: string) => {
      await login(username, password);
      try {
        setLoading(true);
        await refreshUser();
      } finally {
        setLoading(false);
      }
    },
    [refreshUser],
  );

  const doLogout = useCallback(async () => {
    setLoading(true);
    try {
      await logout();
      setUser(null);
    } finally {
      setLoading(false);
    }
  }, []);

  useAsyncEffect(async (isCancelled) => {
    setLoading(true);
    try {
      await refreshUser(isCancelled);
    } finally {
      if (!isCancelled()) {
        setLoading(false);
      }
    }
  }, []);

  return {
    user,
    refreshUser,
    login: doLogin,
    logout: doLogout,
    loading,
  };
}
