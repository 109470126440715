import { useEffect } from 'react';
import { MINUTE } from 'shared/lib/constants/time';
import { refreshTakeAccess } from '../../api/assessment-take/refreshTakeAccess';

interface UseTakeAccessRefreshParams {
  onRefreshFailed(): void;
  pause?: boolean;
}

export function useTakeAccessRefresh({
  onRefreshFailed,
  pause,
}: UseTakeAccessRefreshParams) {
  useEffect(() => {
    let refreshFailed = false;
    const interval = window.setInterval(async () => {
      if (!pause && !refreshFailed) {
        await refreshTakeAccess().catch(() => {
          refreshFailed = true;
          onRefreshFailed();
        });
      }
    }, 5 * MINUTE);
    return () => {
      clearInterval(interval);
    };
  }, [pause, onRefreshFailed]);
}
