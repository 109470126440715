import { AxiosResponse } from 'axios';
import { downloadBlob } from './downloadBlob';

export async function downloadCSVFile(response: AxiosResponse) {
  const { data, headers } = response;
  const contentDisposition = headers['content-disposition'];
  let downloadFileName = 'report.csv';
  if (contentDisposition) {
    const [, fileName] = contentDisposition.split('filename=');
    if (fileName) {
      downloadFileName = fileName.replace(/"/g, '');
    }
  }
  const blob = new Blob([data], { type: 'text/csv' });
  return downloadBlob(blob, downloadFileName);
}
