import { User } from 'shared/lib/types/User';
import { useCallback, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getResearchModeSetting } from '../api/settings/getResearchModeSetting';

interface UseSettingsValues {
  researchMode: boolean;
  refreshResearchMode(): Promise<void>;
}

export function useSettings(user: User): UseSettingsValues {
  const [researchMode, setResearchMode] = useState(false);

  useAsyncEffect(
    async (isCancelled) => {
      try {
        const [researchModeSetting] = await Promise.all([
          getResearchModeSetting(),
        ]);
        if (!isCancelled || !isCancelled()) {
          setResearchMode(researchModeSetting);
        }
      } catch (e) {
        // Leave values as default; app will still function
        console.error(e.message);
      }
    },
    [user],
  );

  const refreshResearchMode = useCallback(async () => {
    try {
      setResearchMode(await getResearchModeSetting());
    } catch (e) {
      // Leave as is.
      console.error(e);
    }
  }, []);

  return {
    researchMode,
    refreshResearchMode,
  };
}
