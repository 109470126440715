import { EditableUser } from '../../types/user/EditableUser';
import { Admin } from 'shared/lib/types/Admin';
import { apiAxios } from '../apiAxios';

export async function createSchoolAdmin(
  schoolId: number,
  body: EditableUser,
): Promise<Admin> {
  const response = await apiAxios.put(`/school/${schoolId}/admin`, body);
  return response.data;
}
