import React, { ComponentPropsWithoutRef, FC, useEffect, useRef } from 'react';
import { Button } from '../Button/Button';
import { Row } from '../Row/Row';
import { close, search as searchIcon } from '../../images';
import useToggleState from '@emberex/react-utils/lib/useToggleState';

interface SearchFieldProps extends ComponentPropsWithoutRef<'div'> {
  search: string;
  onSearchChanged(search: string): void;
  placeholder?: string;
}

export const SearchField: FC<SearchFieldProps> = ({
  search,
  onSearchChanged,
  placeholder,
  className = '',
  ...rest
}) => {
  const [open, toggleOpen, setOpen] = useToggleState(false);
  const focusRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (open && focusRef.current) {
      focusRef.current.focus();
    }
  }, [open, focusRef]);

  return (
    <Row
      className={`transition-width duration-500 ${
        open ? 'w-72' : 'w-12'
      } ${className}`}
      {...rest}
    >
      <Row
        className={`rounded-full bg-white h-12 items-center ${
          open ? 'w-full pr-3' : 'w-12'
        } `}
      >
        <Button onClick={toggleOpen}>
          <img src={searchIcon} alt="search" className="w-6 h-6 ml-3" />
        </Button>
        {open && (
          <>
            <input
              type="text"
              value={search}
              onChange={(e) => onSearchChanged(e.target.value)}
              ref={focusRef}
              className={`font-roboto text-sm font-medium ml-3 placeholder-trueGray-750 w-11/12`}
              placeholder={placeholder}
            />
            <Button onClick={() => setOpen(false)}>
              <img src={close} alt="Close" className="w-6 h-6" />
            </Button>
          </>
        )}
      </Row>
    </Row>
  );
};
