import { useEffect, useMemo, useState } from 'react';
import {
  validateDateValue,
  validateFormat,
} from 'shared/lib/utils/validateDateFormat';
import { zeroPad } from 'shared/lib/utils/zeroPad';
import { DateInputValue } from '../types/DateInputValue';

interface ReturnValues {
  value: DateInputValue;
  onDateChange(value: DateInputValue): void;
  invalidDate: boolean;
}

interface UseDateInputParams {
  /**
   * The current date.
   * Must be in YYYY-MM-DD format.
   */
  date: string | null;

  /**
   * A function to call when a valid date is entered.
   * Will be in YYY-MM-DD format.
   */
  onDateChanged(date: string): void;
}

export function useDateInput({
  date,
  onDateChanged,
}: UseDateInputParams): ReturnValues {
  const [value, setValue] = useState<DateInputValue>({
    day: null,
    year: null,
    month: null,
  });

  const { day, month, year } = value;

  const dateString = useMemo(() => {
    const paddedMonth = month ? zeroPad(month) : '';
    const paddedDay = day ? zeroPad(day) : '';
    return `${year ?? ''}-${paddedMonth}-${paddedDay}`;
  }, [month, day, year]);

  const validDate = useMemo(() => {
    return validateFormat(dateString) && validateDateValue(dateString);
  }, [dateString]);

  useEffect(() => {
    if (validDate && date !== dateString) {
      onDateChanged(dateString);
    }
  }, [date, dateString, onDateChanged, validDate]);

  useEffect(() => {
    if (!date) {
      return;
    }
    // In case its `YYYY-MM-DDThh:mm:ss` (i.e. from the db)
    const [datePart] = date.split('T');
    if (!validateFormat(datePart) || !validateDateValue(datePart)) {
      return;
    }
    const [yearValue, monthValue, dayValue] = datePart
      .split('-')
      .map((value) => parseInt(value, 10));
    setValue((prev) => {
      let updates = { ...prev };
      if (!Number.isNaN(yearValue)) {
        updates.year = yearValue;
      }
      if (!Number.isNaN(monthValue)) {
        updates.month = monthValue;
      }
      if (!Number.isNaN(dayValue)) {
        updates.day = dayValue;
      }
      return updates;
    });
  }, [date]);

  return {
    value: {
      month,
      day,
      year,
    },
    onDateChange: setValue,
    invalidDate: (date?.length ?? 0) > 0 && !validDate,
  };
}
