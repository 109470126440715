import { EditableUser } from '../../types/user/EditableUser';
import { Admin } from 'shared/lib/types/Admin';
import { apiAxios } from '../apiAxios';

export async function updateAdmin(
  adminId: number,
  body: EditableUser,
): Promise<Admin> {
  const response = await apiAxios.patch(`/admin/${adminId}`, body);
  return response.data;
}
