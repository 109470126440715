import { ComponentPropsWithoutRef, FC, Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import { Modal } from '../Modal/Modal';

interface InstructionLevelInfoModalProps
  extends ComponentPropsWithoutRef<'div'> {
  onClickOutside(): void;
  overlayClassName?: string;
}

const LEVEL_DEFINITIONS: { term: string; definitions: string[] }[] = [
  {
    term: 'English - Level A',
    definitions: [
      'Blending syllables 2-3 syllable words',
      'Segmenting Syllables 2-3 syllable words',
    ],
  },
  {
    term: 'English - Level B',
    definitions: [
      'Blending onset-rime',
      'Rhyming - matching first, then production',
    ],
  },
  {
    term: 'English - Level C',
    definitions: ['Initial Sounds', 'Final Sounds'],
  },
  {
    term: 'English - Level D',
    definitions: ['Blending 2-4 phonemes', 'Segmenting 2-4 phonemes'],
  },
  {
    term: 'Spanish - Level A',
    definitions: [
      'Blending syllables 2-3 syllable words',
      'Segmenting Syllables 2-3 syllable words',
    ],
  },
  {
    term: 'Spanish - Level B',
    definitions: ['Initial Sounds', 'Final Sounds'],
  },
  {
    term: 'Spanish - Level C',
    definitions: ['Blending 2-3 phonemes', 'Segmenting 2-3 phonemes'],
  },
  {
    term: 'Spanish - Level D',
    definitions: ['Blending 3-5 phonemes', 'Segmenting 3-5 phonemes'],
  },
];

export const InstructionLevelInfoModal: FC<InstructionLevelInfoModalProps> = ({
  className,
  ...rest
}) => {
  return (
    <Modal
      className={twMerge(
        'h-96 max-w-full max-h-full',
        // Center in the viewport
        'top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
        className,
      )}
      {...rest}
    >
      <section className="flex flex-col h-full pt-12 pb-7 pl-10 pr-5 text-left">
        <h1 className="font-extrabold text-2xl text-light-blue-500">
          BAPS Instructional Levels
        </h1>
        <dl className="overflow-y-auto overscroll-y-contain mt-6 font-normal">
          {LEVEL_DEFINITIONS.map(({ term, definitions }, idx) => (
            <Fragment key={term}>
              <dt className={twMerge('font-bold', idx > 0 && 'mt-5')}>
                {term}
              </dt>
              <dd>
                <ul className="pl-5 list-disc">
                  {definitions.map((definition) => (
                    <li key={definition}>{definition}</li>
                  ))}
                </ul>
              </dd>
            </Fragment>
          ))}
        </dl>
      </section>
    </Modal>
  );
};
