import React, { FC, useMemo } from 'react';
import { User } from 'shared/lib/types/User';
import { Page, PageProps } from '../Page/Page';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import {
  useAdministratorActiveRoutes,
  useAssessorActiveRoutes,
} from '../../hooks/useActiveRoute';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import {
  assignedSchool,
  districts,
  schools,
} from 'shared/lib/constants/routes/administratorRoutes';
import {
  AuthenticatedSidePanel,
  PrimaryRoute,
} from '../AuthenticatedSidePanel/AuthenticatedSidePanel';
import { students } from 'shared/lib/constants/routes/assessorRoutes';
import { useLocation } from 'react-router-dom';

interface AuthenticatedPageProps extends PageProps {
  user: User;
}

export const AuthenticatedPage: FC<AuthenticatedPageProps> = ({
  user,
  children,
  ...rest
}) => {
  const { pathname } = useLocation();
  const shouldHideSidePanel = useMemo(() => {
    return pathname.startsWith('/assessment');
  }, [pathname]);

  return (
    <Page
      sidePanel={
        shouldHideSidePanel ? undefined : isAdmin(user) ? (
          <AdministratorSidePanelComponent adminLevel={user.role.adminLevel} />
        ) : (
          <AssessorSidePanelComponent />
        )
      }
      {...rest}
    >
      {children}
    </Page>
  );
};

interface AdministratorSidePanelComponentProps {
  adminLevel: AdminLevel;
}

const PRIMARY_SUPER_ADMIN_ROUTE: PrimaryRoute = {
  route: districts,
  activeRouteName: 'districts',
  name: 'My Districts',
};

const PRIMARY_DISTRICT_ADMIN_ROUTE: PrimaryRoute = {
  route: schools,
  activeRouteName: 'schools',
  name: 'My Schools',
};

const PRIMARY_SCHOOL_ADMIN_ROUTE: PrimaryRoute = {
  route: assignedSchool,
  activeRouteName: 'school',
  name: 'My School',
};

function getPrimaryRoute(adminLevel: AdminLevel): PrimaryRoute {
  switch (adminLevel) {
    case AdminLevel.SUPER:
      return PRIMARY_SUPER_ADMIN_ROUTE;
    case AdminLevel.DISTRICT:
      return PRIMARY_DISTRICT_ADMIN_ROUTE;
    default:
      return PRIMARY_SCHOOL_ADMIN_ROUTE;
  }
}

const AdministratorSidePanelComponent: FC<AdministratorSidePanelComponentProps> = ({
  adminLevel,
}) => {
  const activeRoute = useAdministratorActiveRoutes();
  return (
    <AuthenticatedSidePanel
      activeRoute={activeRoute}
      primaryRoute={getPrimaryRoute(adminLevel)}
    />
  );
};

const AssessorSidePanelComponent: FC = () => {
  const activeRoute = useAssessorActiveRoutes();
  return (
    <AuthenticatedSidePanel
      activeRoute={activeRoute}
      primaryRoute={{
        route: students,
        activeRouteName: 'students',
        name: 'My Students',
      }}
    />
  );
};
