import React, { FC, useCallback } from 'react';
import { Column } from '../../components/Column/Column';
import { SchoolOrg } from 'shared/lib/types/Org';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { BackButton } from '../../components/BackButton/BackButton';
import {
  formatCreateClassRoute,
  formatSchoolDetailsRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { Row } from '../../components/Row/Row';
import { useSchoolDashboard } from '../../hooks/school/useSchoolDashboard';
import { SchoolDetailDashboard } from '../../components/SchoolDetailDashboard/SchoolDetailDashboard';
import { useAssignedSchool } from '../../contexts/assignedOrganizationContext';
import { useBeginAssessmentLauncher } from '../../hooks/assessment/useBeginAssessmentLaunch';
import { Language } from 'shared/lib/constants/Language';
import { useSchoolData } from '../../hooks/school/useSchoolData';
import { useHistory, useLocation } from 'react-router-dom';
import { useResendInvite } from '../../hooks/user/useResendInvite';

interface SchoolDetailsProps {
  school: number | SchoolOrg;
}

export const SchoolDetails: FC<SchoolDetailsProps> = ({ school, ...rest }) => {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const {
    schoolAdmins,
    schoolOrg,
    completedBAPs,
    inProgressBAPs,
    loading,
    students,
    assessors,
    classes,
  } = useSchoolData({
    school,
  });

  const { selectedClass, ...dashboardProps } = useSchoolDashboard({
    students,
    assessors,
    classes,
    schoolAdmins,
    completedBAPs,
    inProgressBAPs,
  });

  const {
    launchForResume,
    launchFromSelectAssessment,
  } = useBeginAssessmentLauncher();

  const onResumeClicked = useCallback(
    async (studentId: number, languages: ReadonlyArray<Language>) => {
      if (languages.length !== 1) {
        launchFromSelectAssessment({ id: studentId });
        return;
      }
      const student = students.find((student) => student.id === studentId);
      if (student) {
        await launchForResume(student, languages[0]);
      }
    },
    [students, launchForResume, launchFromSelectAssessment],
  );

  const { sendPending, reinviteUser } = useResendInvite();

  return (
    <Column
      className="flex-grow h-full pt-3.5 overflow-hidden print:block"
      {...rest}
    >
      <PageTitle pageTitle={schoolOrg?.name ?? 'School Details'} />
      {loading || schoolOrg === null ? (
        <SpinnerOverlay />
      ) : (
        <Column className="flex-grow flex-shrink-0 h-full print:block">
          <PageTitle pageTitle={schoolOrg.name} />
          <Row className="justify-between pl-17 mb-6 print:pl-0">
            <BackButton
              onClick={() => history.goBack()}
              className="print:hidden"
            />
            <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
              {schoolOrg.name}
            </Row>
            <div>&nbsp;</div>
          </Row>
          <SchoolDetailDashboard
            {...dashboardProps}
            schoolId={schoolOrg.id}
            onResumeClicked={onResumeClicked}
            onStartClicked={(studentId) =>
              launchFromSelectAssessment({ id: studentId })
            }
            createClassRoute={formatCreateClassRoute(schoolOrg.id)}
            className="overflow-hidden h-full"
            canCreateSchoolAdmin
            showSchoolAdminList
            basePath={formatSchoolDetailsRoute(schoolOrg)}
            activePath={pathname}
            selectedClass={selectedClass}
            searchParams={search}
            onReinviteClicked={reinviteUser}
            allStudents={students}
          />
        </Column>
      )}
      {sendPending && <SpinnerOverlay opaque />}
    </Column>
  );
};

export const AssignedSchoolDetails: FC = () => {
  const { assignedSchool } = useAssignedSchool();
  return <SchoolDetails school={assignedSchool} />;
};
