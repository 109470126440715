import React, {
  ComponentPropsWithoutRef,
  FC,
  FormEvent,
  useCallback,
} from 'react';
import { DateInputValue } from '../../types/DateInputValue';
import { Gender } from 'shared/lib/constants/user/Gender';
import { EditableStudent } from '../../types/student/EditableStudent';
import { Row } from '../Row/Row';
import { Column } from '../Column/Column';
import { TextInput } from '../TextInput/TextInput';
import { DateInput } from '../DateInput/DateInput';
import { GenderSelector } from '../GenderSelector/GenderSelector';
import { FamilyLanguageUse } from 'shared/lib/constants/student/FamilyLanguageUse';
import { FamilyLanguageUseDropdown } from '../FamilyLanguageUseDropdown/FamilyLanguageUseDropdown';
import { PillButton } from '../PillButton/PillButton';
import { FormLabel } from '../FormLabel/FormLabel';

interface StudentFormProps extends ComponentPropsWithoutRef<'form'> {
  student: EditableStudent;
  birthDate: DateInputValue;
  onBirthDateChanged(value: DateInputValue): void;
  invalidBirthDate?: boolean;
  onFirstNameChanged(firstName: string): void;
  onLastNameChanged(lastName: string): void;
  onGenderChanged(gender: Gender): void;
  onFamilyLanguageUseChanged(language: FamilyLanguageUse): void;
  editMode: boolean;
  onCancelled(): void;
  error?: string;
}

export const StudentForm: FC<StudentFormProps> = ({
  birthDate,
  onBirthDateChanged,
  invalidBirthDate,
  onCancelled,
  className = '',
  onFirstNameChanged,
  onLastNameChanged,
  editMode,
  onGenderChanged,
  error,
  student,
  onFamilyLanguageUseChanged,
  ...rest
}) => {
  const { gender, lastName, firstName, familyLanguageUse } = student;

  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );

  return (
    <form className={`flex flex-col ${className}`} {...rest}>
      <Column className="w-full pr-4 max-w-2.75xl">
        <Row className="w-full flex-grow flex-shrink-0 justify-between flex-wrap">
          <Column>
            <FormLabel htmlFor="first-name">First Name</FormLabel>
            <TextInput
              id="first-name"
              value={firstName}
              onChange={(e) => onFirstNameChanged(e.target.value)}
              placeholder="First Name"
              required={!editMode}
            />
          </Column>
          <Column>
            <FormLabel htmlFor="last-name">Last Name</FormLabel>
            <TextInput
              id="last-name"
              value={lastName}
              onChange={(e) => onLastNameChanged(e.target.value)}
              placeholder="Last Name"
              required={!editMode}
            />
          </Column>
        </Row>
        <Row className="w-full flex-grow flex-shrink-0 justify-between flex-wrap  mt-4">
          <Column>
            <FormLabel>D.O.B.</FormLabel>
            <DateInput
              onDateChange={onBirthDateChanged}
              value={birthDate}
              invalidDate={invalidBirthDate}
            />
          </Column>
          <Column>
            <FormLabel>Gender</FormLabel>
            <GenderSelector
              gender={gender}
              onGenderChanged={onGenderChanged}
              placeholder="Select Gender"
              className="w-80"
            />
          </Column>
        </Row>
        <Row className="w-full flex-grow flex-shrink-0 justify-between flex-wrap mt-4">
          <Column>
            <FormLabel>Family's Language Use at Home</FormLabel>
            <FamilyLanguageUseDropdown
              onSelected={onFamilyLanguageUseChanged}
              selected={familyLanguageUse}
            />
          </Column>
        </Row>
      </Column>
      <Row className="items-center flex-wrap pt-6">
        <PillButton
          type="submit"
          buttonVariation="primary"
          className="mt-9 mb-9"
        >
          {editMode ? 'Save Changes' : 'Add Student'}
        </PillButton>
        <PillButton
          onClick={handleCancel}
          buttonVariation="cancel"
          className="m-9"
        >
          Cancel
        </PillButton>
      </Row>
      <Row className="pt-3 text-red-600">{error}</Row>
    </form>
  );
};
