import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Admin } from 'shared/lib/types/Admin';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { Link } from 'react-router-dom';
import {
  formatAdminDetailsRoute,
  formatEditAdminRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { headBlue } from '../../images';
import { PillButton } from '../PillButton/PillButton';
import { PillButtonLink } from '../PillButtonLink/PillButtonLink';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';

interface AdminListProps extends ComponentPropsWithoutRef<'div'> {
  admins: ReadonlyArray<Admin>;
  disableInternalScroll?: boolean;
  onReinviteClicked(admin: Admin): void;
}

export const AdminList: FC<AdminListProps> = ({
  admins,
  className = '',
  disableInternalScroll,
  onReinviteClicked,
  ...rest
}) => (
  <Column className={`overflow-hidden h-full ${className}`} {...rest}>
    <div className="font-roboto text-trueGray-750 font-light w-full items-center pr-3 pl-3 pb-1">
      <Row>Name</Row>
    </div>
    {admins.map((admin) => {
      const { id, name, role, pending } = admin;
      const { adminLevel } = role;
      return (
        <div
          className="grid grid-cols-2 h-25 max-h-25 bg-white font-bold font-roboto text-sm shadow-dashboardShadow rounded-xl items-center mb-3 pr-3 pl-3"
          key={id}
        >
          <div className="grid grid-cols-2">
            <Link
              to={formatAdminDetailsRoute(id)}
              className="flex flex-row items-center"
            >
              <img
                src={headBlue}
                alt={`${role.adminLevel} admin`}
                className="w-6 h-6 mr-2"
              />
              <Column>
                {name}
                {pending && (
                  <span className="font-roboto text-sm font-medium text-light-blue-500">
                    Pending
                  </span>
                )}
              </Column>
            </Link>
            <Column>
              {pending && (
                <PillButton
                  buttonVariation="primary"
                  onClick={() => onReinviteClicked(admin)}
                >
                  Resend Invite
                </PillButton>
              )}
            </Column>
          </div>
          <Row className="justify-end">
            <PillButtonLink
              className="bg-lime-350 "
              to={formatEditAdminRoute(id, adminLevel)}
            >
              Edit {capitalizeFirst(adminLevel)} Admin
            </PillButtonLink>
          </Row>
        </div>
      );
    })}
  </Column>
);
