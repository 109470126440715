import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Column } from '../Column/Column';
import './DashboardList.css';
import { Row } from '../Row/Row';

interface DashboardListProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * Disable the custom scrolling on the list.
   */
  disableInternalScroll?: boolean;
  useDefaultMinWidth?: boolean;
}

export const DashboardList: FC<DashboardListProps> = ({
  className,
  disableInternalScroll = false,
  useDefaultMinWidth = true,
  ...rest
}) => (
  <Column
    className={twMerge(
      'pt-0 pb-8',
      disableInternalScroll
        ? 'overflow-hidden'
        : 'overflow-y-auto dashboard-list  pl-7.5 pr-10',
      useDefaultMinWidth && 'dashboard-list-min-width',
      className,
    )}
    {...rest}
  />
);

interface DashboardListHeadProps extends ComponentPropsWithoutRef<'div'> {
  useDefaultMinWidth?: boolean;
}

export const DashboardListHead: FC<DashboardListHeadProps> = ({
  className,
  useDefaultMinWidth = true,
  ...rest
}) => (
  <Row
    className={twMerge(
      'font-roboto text-trueGray-750 font-light w-full items-center pb-1 mr-16 lg:mr-0 lg:pr-6 pl-14 dashboard-list-head',
      useDefaultMinWidth && 'dashboard-list-min-width',
      className,
    )}
    {...rest}
  />
);

export const DashboardListItem: FC<ComponentPropsWithoutRef<'div'>> = ({
  className,
  ...rest
}) => (
  <Row
    className={twMerge(
      'w-full h-25 max-h-25 bg-white flex-grow flex-shrink-0 font-bold font-roboto text-sm shadow-dashboardShadow rounded-xl items-center px-3 mb-3',
      className,
    )}
    {...rest}
  />
);
