import { DistrictOrg } from 'shared/lib/types/Org';
import { useCallback, useEffect, useState } from 'react';
import { EditableDistrict } from '../../types/district/EditableDistrict';
import { State } from 'shared/lib/constants/state';

const defaultEditableDistrict: EditableDistrict = {
  name: '',
  location: '',
  researchOrg: false,
};

export function useDistrictForm(district: DistrictOrg | null) {
  const [editableDistrict, setEditableDistrict] = useState({
    ...defaultEditableDistrict,
  });

  useEffect(() => {
    setEditableDistrict({ ...(district ?? defaultEditableDistrict) });
  }, [district]);

  const onNameChanged = useCallback((name: string) => {
    setEditableDistrict((prev) => ({ ...prev, name }));
  }, []);

  const onLocationChanged = useCallback((location: State) => {
    setEditableDistrict((prev) => ({ ...prev, location }));
  }, []);

  const onResearchOrgChanged = useCallback((researchOrg: boolean) => {
    setEditableDistrict((prev) => ({ ...prev, researchOrg }));
  }, []);

  return {
    editableDistrict,
    districtName: district?.name ?? '',
    onNameChanged,
    onLocationChanged,
    onResearchOrgChanged,
  };
}
