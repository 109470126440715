import React, { FC, useCallback } from 'react';
import { Column } from '../../components/Column/Column';
import { Row } from '../../components/Row/Row';
import { useAcceptInvite } from '../../hooks/accept-invite/useAcceptInvite';
import { useHistory } from 'react-router-dom';
import { login } from 'shared/lib/constants/routes/unauthenticatedRoutes';
import { UnauthenticatedPage } from '../../components/UnauthenticatedPage/UnauthenticatedPage';
import { TextInput } from '../../components/TextInput/TextInput';
import { showOkAlert } from '../../components/Alert/Alert';
import { PillButton } from '../../components/PillButton/PillButton';

export const AcceptInvite: FC = (props) => {
  const history = useHistory();
  const handleSubmitSuccess = useCallback(async () => {
    await showOkAlert({
      title: 'Success',
      text:
        'Your invite has been accepted. You will now be redirected to login.',
    });
    history.replace(login);
  }, [history]);
  const {
    onSubmit,
    setPasswordConfirm,
    passwordConfirm,
    password,
    setPassword,
    error,
    loading,
  } = useAcceptInvite({ onSuccess: handleSubmitSuccess });

  return (
    <UnauthenticatedPage>
      <Column className="flex-grow h-full pt-12 mt-2 w-full items-center justify-center">
        <div className="flex flex-col md:flex-row flex-grow h-full justify-between items-center md:items-start w-full max-w-3xl">
          <Column className="w-72 h-52 lg:mb-20">
            <h2 className="mb-5 text-trueGray-900 font-roboto font-medium">
              Welcome
            </h2>
            <h1 className="text-4xl font-extrabold">
              Complete
              <br />
              Registration
            </h1>
          </Column>
          <form
            className="flex flex-col h-full pt-18 items-center"
            onSubmit={onSubmit}
          >
            <TextInput
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading}
            />
            <TextInput
              type="password"
              placeholder="Confirm Password"
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              className="mt-4"
              disabled={loading}
            />
            <Row className="w-full justify-between mt-9 items-center flex-wrap">
              <PillButton type="submit" buttonVariation="primary">
                Register
              </PillButton>
            </Row>
            <Row className="h-8 text-red-600">{error}</Row>
          </form>
        </div>
      </Column>
    </UnauthenticatedPage>
  );
};
