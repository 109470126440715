import { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import showModal from '@emberex/react-utils/lib/showModal';
import { Language } from 'shared/lib/constants/Language';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { noop } from 'shared/lib/utils/noop';
import { Modal } from '../Modal/Modal';
import { Row } from '../Row/Row';
import { Button } from '../Button/Button';
import { headBlue } from '../../images';
import { Column } from '../Column/Column';
import { PercentileButton } from '../PercentileButton/PercentileButton';
import './AgeAdjustedPercentileModal.css';

export interface AgeAdjustedPercentileModalProps
  extends ComponentPropsWithoutRef<'div'> {
  onCloseClicked(): void;
  onResumeClicked(language: Language): void;
  student: Pick<
    DashboardStudent,
    'firstName' | 'lastName' | 'ageAdjustedPercentileReport'
  >;
}

export const AgeAdjustedPercentileModal: FC<AgeAdjustedPercentileModalProps> = ({
  className = '',
  onResumeClicked,
  onCloseClicked,
  student,
  ...rest
}) => {
  const { ageAdjustedPercentileReport } = student;
  const isInProgress = useMemo(() => {
    if (!ageAdjustedPercentileReport) {
      return false;
    }
    const {
      englishPercentile,
      spanishPercentile,
    } = ageAdjustedPercentileReport;
    return englishPercentile?.inProgress || spanishPercentile?.inProgress;
  }, [ageAdjustedPercentileReport]);

  return (
    <Modal
      className={`border border-black pb-10 age-adjusted-percentile-modal ${className}`}
      onClickOutside={onCloseClicked}
      animate
      {...rest}
    >
      <Row className="justify-between items-center">
        <Row className="ml-9">{isInProgress ? 'In Progress' : <>&nbsp;</>}</Row>
        <Button
          onClick={onCloseClicked}
          className="bg-black text-white text-4xl p1 justify-center items-center"
        >
          <Row className="h-20 w-20 justify-center items-center">
            {'\u2715'}
          </Row>
        </Button>
      </Row>
      <h3 className="font-bold text-center text-xl mt-9 mb-px">
        Recent age-adjusted percentile
      </h3>
      <Row className="justify-center items-center text-xl font-bold mt-1">
        <img src={headBlue} alt="" className="h-5 w-5 mr-4" />
        <Row>{formatFirstLast(student)}</Row>
      </Row>
      <Row className="justify-between pl-24 pr-24 mt-12 flex-wrap">
        <Column className="items-center">
          <Row className="w-40 h-10 mb-6 justify-center items-center bg-gray-700 text-white font-semibold">
            English
          </Row>
          <PercentileButton
            percentile={ageAdjustedPercentileReport?.englishPercentile ?? null}
            onResumeClicked={() => onResumeClicked(Language.ENGLISH)}
            onScoreClicked={noop}
            disabled={
              !ageAdjustedPercentileReport?.englishPercentile?.inProgress
            }
          />
        </Column>
        <Column className="items-center">
          <Row className="w-40 h-10 mb-6 justify-center items-center bg-gray-700 text-white font-semibold">
            Spanish
          </Row>
          <PercentileButton
            percentile={ageAdjustedPercentileReport?.spanishPercentile ?? null}
            onResumeClicked={() => onResumeClicked(Language.SPANISH)}
            onScoreClicked={noop}
            disabled={
              !ageAdjustedPercentileReport?.spanishPercentile?.inProgress
            }
          />
        </Column>
      </Row>
    </Modal>
  );
};

export async function showAgeAdjustedPercentileModal(
  props: Pick<AgeAdjustedPercentileModalProps, 'student' | 'onResumeClicked'>,
) {
  return showModal((resolve) => (
    <AgeAdjustedPercentileModal onCloseClicked={resolve} {...props} />
  ));
}
