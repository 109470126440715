import { User } from 'shared/lib/types/User';
import { useCallback, useState } from 'react';
import { EditableAccount } from '../../types/user/EditableAccount';

export function useEditAccountForm(user: User) {
  const [account, setAccount] = useState<EditableAccount>({
    ...user,
  });

  const onNameChanged = useCallback((name: string) => {
    setAccount((prev) => ({ ...prev, name }));
  }, []);

  const onEmailChanged = useCallback((email: string) => {
    setAccount((prev) => ({ ...prev, email }));
  }, []);

  return {
    account,
    onNameChanged,
    onEmailChanged,
  };
}
