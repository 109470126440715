import React, { FC } from 'react';
import { Modal } from '../Modal/Modal';
import { noop } from 'shared/lib/utils/noop';
import { Row } from '../Row/Row';
import { Button } from '../Button/Button';
import { closeWhite } from '../../images';
import { Column } from '../Column/Column';
import { PillButton } from '../PillButton/PillButton';

export interface AlertModalProps {
  /**
   * Show primary confirm button.
   * Defaults to true.
   */
  showConfirm?: boolean;

  /**
   * Text to show on the confirm button.
   * Default: Confirm
   */
  confirmText?: string;

  /**
   * Show secondary cancel button.
   * Defaults to false.
   */
  showCancel?: boolean;

  /**
   * Text to show on the cancel button.
   * Default: Cancel
   */
  cancelText?: string;

  /**
   * Show an exit button.
   * Defaults to false.
   * Calls the onCancelClicked function.
   */
  showExit?: boolean;

  /**
   * Function to call when the confirm button is clicked.
   */
  onConfirmClicked?(): void;

  /**
   * Function to call when the cancel button is clicked.
   */
  onCancelClicked?(): void;

  /**
   * Function to call when the exit button is clicked.
   * Defaults to the cancel function
   */
  onExitClicked?(): void;

  /**
   * Only applicable when both confirm and cancel buttons are present.
   * If true, the confirm button will appear on the left.
   * Default: false
   */
  reverseButtonOrder?: boolean;
}

export const AlertModal: FC<AlertModalProps> = ({
  showConfirm = true,
  showCancel = false,
  showExit = false,
  onCancelClicked = noop,
  onConfirmClicked = noop,
  children,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  onExitClicked,
  reverseButtonOrder = false,
  ...rest
}) => (
  <Modal animate {...rest}>
    <Row className="w-full justify-end h-15">
      {showExit && (
        <Button
          className="bg-light-blue-500 rounded-full justify-center items-center h-12 w-12 absolute bottom-6 left-6"
          onClick={onExitClicked ? onExitClicked : onCancelClicked}
        >
          <img src={closeWhite} alt="close" className="h-10 w-10" />
        </Button>
      )}
    </Row>
    <Column className="w-full pb-15 pr-24 pl-24">
      {children}
      {showConfirm || showCancel ? (
        <div
          className={`relative flex ${
            reverseButtonOrder ? 'flex-row-reverse' : 'flex-row'
          } w-full h-12 items-center ${
            showCancel ? 'justify-between' : 'justify-center'
          } font-roboto mt-14 pt-1 }`}
        >
          {showConfirm && (
            <PillButton buttonVariation="primary" onClick={onConfirmClicked}>
              {confirmText}
            </PillButton>
          )}
          {showCancel && (
            <Button
              className="text-sm text-light-blue-500"
              onClick={onCancelClicked}
            >
              {cancelText}
            </Button>
          )}
        </div>
      ) : null}
    </Column>
  </Modal>
);
