import { User } from 'shared/lib/types/User';
import { EditableUser } from '../../types/user/EditableUser';
import { useCallback, useEffect, useState } from 'react';
import { formatFirstLast } from 'shared/lib/utils/formatName';

const defaultEditableUser: EditableUser = {
  name: '',
  email: '',
};

interface ReturnValues {
  userName: string;
  editableUser: EditableUser;
  onNameChanged(name: string): void;
  onEmailChanged(email: string): void;
}

export function useUserForm(user: User | null): ReturnValues {
  const [editableUser, setEditableUser] = useState<EditableUser>({
    ...defaultEditableUser,
  });

  const onEmailChanged = useCallback((email: string) => {
    setEditableUser((prev) => ({ ...prev, email }));
  }, []);

  const onNameChanged = useCallback((name: string) => {
    setEditableUser((prev) => ({ ...prev, name }));
  }, []);

  useEffect(() => {
    setEditableUser({ ...(user ?? defaultEditableUser) });
  }, [user]);

  return {
    editableUser,
    userName: user ? formatFirstLast(user) : '',
    onEmailChanged,
    onNameChanged,
  };
}
