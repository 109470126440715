import React, { FC } from 'react';
import { AssessmentPage } from '../Assessment/AssessmentPage/AssessmentPage';
import { Student } from 'shared/lib/types/Student';
import { Language } from 'shared/lib/constants/Language';
import { Row } from '../Row/Row';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { headBlue } from '../../images';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { BAPWithFullData } from 'shared/lib/types/BAP';
import { BapDetails } from '../BapDetails/BapDetails';

interface BAPAssessmentResultsProps {
  bap: BAPWithFullData | null;
  classListRoute: string;
  showScaledScore: boolean;
  includeLanguage?: boolean;
}

export const BAPAssessmentResults: FC<BAPAssessmentResultsProps> = ({
  bap,
  classListRoute,
  showScaledScore,
  includeLanguage = true,
  ...rest
}) => {
  const completedLanguage = bap?.bapAssessments[0]?.language ?? null;
  return (
    <AssessmentPage
      showExit={false}
      headingContents={
        <HeadingContents
          language={completedLanguage}
          student={bap?.student ?? null}
          includeLanguage={includeLanguage}
        />
      }
      maintainHiddenElementLayout={false}
      {...rest}
    >
      <BapDetails
        bap={bap}
        classListRoute={classListRoute}
        showScaledScore={showScaledScore}
      />
    </AssessmentPage>
  );
};

const HeadingContents: FC<{
  student: Pick<Student, 'firstName' | 'lastName'> | null;
  language: Language | null;
  includeLanguage: boolean;
}> = ({ student, language, includeLanguage }) => (
  <Row
    className={`w-full ${
      includeLanguage ? 'justify-between' : 'justify-center'
    } items-center font-roboto`}
  >
    {includeLanguage && language && (
      <Row className="w-48 text-light-blue-500 font-bold flex-grow flex-shrink-0">
        BAPS {capitalizeFirst(language)} Complete
      </Row>
    )}
    {student && (
      <Row className="w-full justify-center items-center">
        <img src={headBlue} alt="Student:" className="w-6 h-6 mr-2" />
        <Row className="text-trueGray-900 font-bold text-sm">
          {formatFirstLast(student)}
        </Row>
      </Row>
    )}
  </Row>
);
