import { EditableSchool } from '../../types/school/EditableSchool';
import { SchoolOrg } from 'shared/lib/types/Org';
import { apiAxios } from '../apiAxios';

export async function createSchoolInDistrict(
  districtId: number,
  body: EditableSchool,
): Promise<SchoolOrg> {
  const response = await apiAxios.put(`/district/${districtId}/school`, body);
  return response.data.school;
}
