import { EnrollableUser } from 'shared/lib/types/student-group/EnrollableUser';
import { useSearch } from '../useSearch';
import { useCallback, useState } from 'react';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { useNameSearch } from '../useNameSearch';

export function useGroupEnrollmentEditor(users: readonly EnrollableUser[]) {
  const { search, onSearchChanged } = useSearch();
  const [selectedUser, setSelectedUser] = useState<EnrollableUser | null>(null);
  const filteredUsers = useNameSearch({ list: users, search });

  const handleSearchChanged = useCallback(
    (searchText: string) => {
      // Invalidate the selected user when search text changes
      setSelectedUser(null);
      onSearchChanged(searchText);
    },
    [onSearchChanged],
  );

  const onUserSelected = useCallback(
    (user: EnrollableUser) => {
      // Use the 'unwrapped' version
      onSearchChanged(formatFirstLast(user));
      setSelectedUser(user);
    },
    [onSearchChanged],
  );

  return {
    search,
    onSearchChanged: handleSearchChanged,
    users: filteredUsers,
    onUserSelected,
    selectedUser,
  };
}
