import { SchoolWithEnrollmentInfo } from 'shared/lib/types/school/SchoolWithEnrollmentInfo';
import { Admin } from 'shared/lib/types/Admin';
import { useMemo } from 'react';
import { useSearch } from '../useSearch';
import { startsWithIgnoreCase } from 'shared/lib/utils/startsWithIgnoreCase';
import { useNameSearch } from '../useNameSearch';

interface UseDistrictDashboardParams {
  schools: ReadonlyArray<SchoolWithEnrollmentInfo>;
  districtAdmins: ReadonlyArray<Admin>;
}

export function useDistrictDashboard({
  schools,
  districtAdmins,
}: UseDistrictDashboardParams) {
  const { search, onSearchChanged } = useSearch();
  return {
    search,
    onSearchChanged,
    schoolList: useMemo(() => {
      if (!search.trim()) {
        return schools;
      }
      return schools.filter(({ school }) =>
        startsWithIgnoreCase(school.name, search),
      );
    }, [schools, search]),
    districtAdminList: useNameSearch({ list: districtAdmins, search }),
  };
}
