import React, { FC, useCallback } from 'react';
import { useLoggedInUserContext } from '../../contexts/userContext';
import { Dashboard } from '../../components/Dashboard/Dashboard';
import { Row } from '../../components/Row/Row';
import { Column } from '../../components/Column/Column';
import { isAdmin, isSchoolAdmin, isSuperAdmin } from 'shared/lib/utils/isAdmin';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { useDashboard } from '../../hooks/dashboard/useDashboard';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { DashboardDropdown } from '../../components/DashboardDropdown/DashboardDropdown';
import { useBeginAssessmentLauncher } from '../../hooks/assessment/useBeginAssessmentLaunch';
import { Language } from 'shared/lib/constants/Language';
import { getStudent } from '../../api/student/getStudent';
import { showOkAlert } from '../../components/Alert/Alert';

export const DashboardPage: FC = (props) => {
  const { user } = useLoggedInUserContext();
  const {
    loading,
    schoolOptions,
    classOptions,
    selectedClassOption,
    selectedSchoolOption,
    onClassSelect,
    onSchoolSelect,
    dashboardData,
    dashboardRefreshing,
    districtOptions,
    onDistrictSelect,
    selectedDistrictOption,
    onExportCSVClicked,
  } = useDashboard();

  const {
    launchForResume,
    launchFromSelectAssessment,
  } = useBeginAssessmentLauncher();

  const onResumeClicked = useCallback(
    async (studentId: number, languages: ReadonlyArray<Language>) => {
      if (languages.length !== 1) {
        launchFromSelectAssessment({ id: studentId });
        return;
      }
      try {
        const student = await getStudent(studentId);
        return launchForResume(student, languages[0]);
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare assessment launch: ${e.message}`,
        });
      }
    },
    [launchForResume, launchFromSelectAssessment],
  );

  const includeDistrictDropdown = isAdmin(user) && isSuperAdmin(user);
  const includeSchoolDropdown = isAdmin(user) && !isSchoolAdmin(user);
  const totalDropdowns =
    (includeDistrictDropdown ? 1 : 0) + (includeSchoolDropdown ? 1 : 0) + 1;

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden">
      <PageTitle pageTitle="Dashboard" />
      {loading || !dashboardData ? (
        <SpinnerOverlay />
      ) : (
        <Column className="w-full h-full">
          <Row
            className={`flex-grow flex-shrink-0 ${
              totalDropdowns <= 2 ? 'max-w-lg' : 'max-w-3xl'
            } justify-between mt-2 ml-16 pb-6 z-10 pr-24`}
          >
            {includeDistrictDropdown && (
              <DashboardDropdown
                options={districtOptions}
                onSelected={onDistrictSelect}
                selected={selectedDistrictOption}
                placeholder="All Districts"
                className="z-20"
              />
            )}
            {includeSchoolDropdown && (
              <DashboardDropdown
                options={schoolOptions}
                onSelected={onSchoolSelect}
                placeholder="All Schools"
                className="z-20"
                selected={selectedSchoolOption}
              />
            )}
            <DashboardDropdown
              options={classOptions}
              onSelected={onClassSelect}
              placeholder="All Classes"
              selected={selectedClassOption}
              className="z-20"
            />
          </Row>
          <Dashboard
            data={dashboardData}
            onResumeClicked={onResumeClicked}
            onExportCSVClicked={onExportCSVClicked}
          />
          {dashboardRefreshing ? <SpinnerOverlay opaque /> : null}
        </Column>
      )}
    </Column>
  );
};
