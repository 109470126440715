import React, { FC } from 'react';
import { Row } from '../../Row/Row';
import { PillButton } from '../../PillButton/PillButton';

interface AssessmentNavigationButtonsProps {
  onNext(): void;
  onBack(): void;
  canProgress: boolean;
  className?: string;
}

export const AssessmentNavigationButtons: FC<AssessmentNavigationButtonsProps> = ({
  onNext,
  onBack,
  className = '',
  canProgress,
  ...rest
}) => (
  <Row
    className={`w-full justify-between pt-4 pl-28 pr-28 ${className}`}
    {...rest}
  >
    <PillButton
      buttonVariation="secondary"
      onClick={onBack}
      onMouseDown={(e) => e.preventDefault()}
    >
      Back
    </PillButton>
    <PillButton
      buttonVariation={canProgress ? 'primary' : 'disabled'}
      disabled={!canProgress}
      onClick={onNext}
      onMouseDown={(e) => e.preventDefault()}
    >
      Next
    </PillButton>
  </Row>
);
