import React, { ComponentPropsWithoutRef, FC, ReactNode, useMemo } from 'react';
import {
  DashboardStudentPerformance,
  DashboardStudentScaledScores,
} from 'shared/lib/types/dashboard/DashboardData';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { barChartBlue } from '../../images';
import { ScoreDistribution } from 'shared/lib/types/dashboard/ScoreDistribution';

interface ChartHeading {
  id: number;
  heading: ReactNode;
}

const CHART_OPACITIES = [
  // 0 to 1/6 of max
  'bg-opacity-10',
  // 1/6 to 2/6 of max
  'bg-opacity-20',
  // 2/6 to 3/6 of max
  'bg-opacity-30',
  // 3/6 to 4/6 of max
  'bg-opacity-40',
  // 4/6 to 5/6 of max
  'bg-opacity-50',
  // 5/6 to 6/6 of max
  'bg-opacity-60',
];

const ADJUSTED_PERCENTILE_HEADINGS: ChartHeading[] = [
  {
    id: 1,
    heading: (
      <div>
        <span className="font-bold">&lt;</span>15
      </div>
    ),
  },
  {
    id: 2,
    heading: '15-30',
  },
  {
    id: 3,
    heading: '30-50',
  },
  {
    id: 4,
    heading: '50-70',
  },
  { id: 5, heading: '70-85' },
  {
    id: 6,
    heading: (
      <div>
        85<span className="font-bold">+</span>
      </div>
    ),
  },
];

const SCALED_SCORE_HEADINGS: ChartHeading[] = [
  {
    id: 1,
    heading: '0-15',
  },
  {
    id: 2,
    heading: '16-18',
  },
  {
    id: 3,
    heading: '19-22',
  },
  {
    id: 4,
    heading: '23-25',
  },
  {
    id: 5,
    heading: '26-28',
  },
  {
    id: 6,
    heading: (
      <div>
        29<span className="font-bold">+</span>
      </div>
    ),
  },
];

function getRelativeOpacity(value: number, max: number) {
  if (value <= 0) {
    return CHART_OPACITIES[0];
  }
  const index = Math.floor((value / Math.max(max, 1)) * 5);
  return CHART_OPACITIES[index];
}

interface StudentPerformancePercentileChartProps
  extends ComponentPropsWithoutRef<'div'> {
  studentPerformance: DashboardStudentPerformance;
  scaledScores?: DashboardStudentScaledScores;
}

export const StudentPerformancePercentileChart: FC<StudentPerformancePercentileChartProps> = ({
  studentPerformance,
  className = '',
  scaledScores,
  ...rest
}) => {
  const { english, spanish } = studentPerformance;
  // Get the maximum value out of all the english and spanish percentiles to find the "max color"
  const maxPercentile = useMemo(() => {
    return Math.max(1, ...Object.values(english), ...Object.values(spanish));
  }, [english, spanish]);

  const maxScaledScore = useMemo(() => {
    if (!scaledScores) {
      return 0;
    }
    return Math.max(
      1,
      ...Object.values(scaledScores.english),
      ...Object.values(scaledScores.spanish),
    );
  }, [scaledScores]);

  return (
    <Column className={`flex-grow items-center p-6 ${className}`} {...rest}>
      <Row className="w-full items-center">
        <img src={barChartBlue} alt="Chart" className="w-6 h-6 absolute" />
        <Row className="w-full justify-center text-center font-roboto text-light-blue-500 font-bold">
          Student Performance
        </Row>
      </Row>

      <Column className="justify-between items-center pl-2.5">
        <Row className="text-sm font-roboto font-medium text-trueGray-750 pl-20">
          Age-Adjusted Percentile
        </Row>
        <Chart
          headings={ADJUSTED_PERCENTILE_HEADINGS}
          english={english}
          spanish={spanish}
          max={maxPercentile}
        />
      </Column>

      {scaledScores && (
        <>
          <Column className="pt-4">
            <Column className="justify-between items-center">
              <Row className="text-sm font-roboto font-medium text-trueGray-750 pl-20">
                Scaled Score
              </Row>
            </Column>
          </Column>
          <Chart
            headings={SCALED_SCORE_HEADINGS}
            english={scaledScores.english}
            spanish={scaledScores.spanish}
            max={maxScaledScore}
          />
        </>
      )}
    </Column>
  );
};

interface ChartEntryProps extends ComponentPropsWithoutRef<'div'> {
  value: number;
  maxValue: number;
}

const ChartEntry: FC<ChartEntryProps> = ({
  value,
  maxValue,
  className = '',
  ...rest
}) => (
  <Row
    className={`h-12 w-14 justify-center items-center bg-light-blue-500 text-2xl ${getRelativeOpacity(
      value,
      maxValue,
    )} ${className}`}
  >
    {value}
  </Row>
);

interface ChartProps {
  headings: ChartHeading[];
  english: ScoreDistribution;
  spanish: ScoreDistribution;
  max: number;
}

const Chart: FC<ChartProps> = ({ headings, spanish, english, max }) => (
  <Row className="mt-3">
    <Column className="font-roboto mr-4">
      <div className="w-20">&nbsp;</div>
      <Row className="items-center justify-end font-light h-12 w-20">
        English
      </Row>
      <Row className="items-center justify-end font-light h-12 w-20">
        Spanish
      </Row>
    </Column>
    <Column>
      <Row className="pb-2">
        {headings.map(({ id, heading }) => (
          <Row
            key={id}
            className="text-light-blue-500 font-light items-end justify-center w-14"
          >
            {heading}
          </Row>
        ))}
      </Row>
      <Row>
        {Object.entries(english).map(([key, { count }]) => (
          <ChartEntry key={key} value={count} maxValue={max} />
        ))}
      </Row>
      <Row>
        {Object.entries(english).map(([key, { count }]) => (
          <ChartEntry key={key} value={count} maxValue={max} />
        ))}
      </Row>
    </Column>
  </Row>
);
