import React, { FC } from 'react';
import { AssessmentPage } from '../AssessmentPage/AssessmentPage';
import { Column } from '../../Column/Column';
import { BaseActivityStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepHeading } from '../../AssessmentStepHeading/AssessmentStepHeading';

interface AssessmentStepPageProps
  extends Omit<
    BaseActivityStepProps<any>,
    'activityStep' | 'canProgress' | 'onNext'
  > {
  /**
   * If a button is hidden, maintain the container its in.
   * Defaults to true.
   */
  maintainHiddenElementLayout?: boolean;
}

export const AssessmentStepPage: FC<AssessmentStepPageProps> = ({
  onPauseClicked,
  title,
  activityProgress,
  audio,
  onAudioPlayed,
  children,
  maintainHiddenElementLayout = true,
  onShowAudioCompleteDismissed,
  showAudioIncomplete,
  ...rest
}) => {
  return (
    <AssessmentPage
      headingContents={
        <AssessmentStepHeading
          title={title}
          activityProgress={activityProgress}
          audio={audio}
          onAudioPlayed={onAudioPlayed}
          showAudioIncomplete={showAudioIncomplete}
          onShowAudioCompleteDismissed={onShowAudioCompleteDismissed}
        />
      }
      showExit
      exitButtonText="Pause Test"
      onExitClicked={onPauseClicked}
      maintainHiddenElementLayout={maintainHiddenElementLayout}
      {...rest}
    >
      <Column className="w-full h-full overflow-hidden pt-2">{children}</Column>
      {maintainHiddenElementLayout && (
        <Column className="w-58 flex-grow flex-shrink-0">
          {/* For Layout*/}
        </Column>
      )}
    </AssessmentPage>
  );
};
