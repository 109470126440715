import { apiAxios } from '../apiAxios';

export async function addStudentToGroup(
  studentGroupId: number,
  studentId: number,
): Promise<void> {
  await apiAxios.post(
    `/student-group/${studentGroupId}/enroll/student/${studentId}`,
  );
}
