import React, { FC, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudentGroups } from '../../api/student-group/getStudentGroups';
import {
  formatEnrollStudentInGroupRoute,
  home,
} from 'shared/lib/constants/routes/commonRoutes';
import { getStudents } from '../../api/student/getStudents';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { StudentsDashboard } from '../../components/StudentsDashboard/StudentsDashboard';
import { useStudentsDashboard } from '../../hooks/student/useStudentsDashboard';
import { DashboardDropdown } from '../../components/DashboardDropdown/DashboardDropdown';
import { Row } from '../../components/Row/Row';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { StudentGroupEnrollmentCounts } from 'shared/lib/types/student-group/StudentGroupEnrollmentCounts';
import { getStudentGroupEnrollmentCounts } from '../../api/student-group/getStudentGroupEnrollmentCounts';
import { showOkAlert } from '../../components/Alert/Alert';
import { useBeginAssessmentLauncher } from '../../hooks/assessment/useBeginAssessmentLaunch';
import { Language } from 'shared/lib/constants/Language';
import { getStudent } from '../../api/student/getStudent';
import { getInProgressBAPs } from '../../api/baps/getInProgressBAPs';
import { BAPWithAssessments, BAPWithFullData } from 'shared/lib/types/BAP';
import { getCompletedBAPs } from '../../api/baps/getCompletedBAPs';

export const Students: FC = (props) => {
  const [students, setStudents] = useState<DashboardStudent[]>([]);
  const [classes, setClasses] = useState<StudentGroup[]>([]);
  const [
    studentGroupEnrollmentCounts,
    setStudentGroupEnrollmentCounts,
  ] = useState<StudentGroupEnrollmentCounts[]>([]);
  const [inProgressBAPs, setInProgressBAPs] = useState<BAPWithFullData[]>([]);
  const [completedBAPs, setCompletedBAPs] = useState<BAPWithAssessments[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setStudents([]);
        setClasses([]);
        setLoading(true);
      }
      try {
        const [
          groups,
          studentList,
          enrollmentCounts,
          bapsInProgress,
          bapsCompleted,
        ] = await Promise.all([
          getStudentGroups(),
          getStudents(),
          getStudentGroupEnrollmentCounts(),
          getInProgressBAPs(),
          getCompletedBAPs(),
        ]);
        if (!isCancelled()) {
          setClasses(groups);
          setStudents(studentList);
          setStudentGroupEnrollmentCounts(enrollmentCounts);
          setInProgressBAPs(bapsInProgress);
          setCompletedBAPs(bapsCompleted);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to get students: ${e.message}`,
          theme: 'error',
        });
        history.replace(home);
      }
    },
    [history],
  );

  const {
    launchFromSelectAssessment,
    launchForResume,
  } = useBeginAssessmentLauncher();

  const onResumeClicked = useCallback(
    async (studentId: number, languages: ReadonlyArray<Language>) => {
      if (languages.length !== 1) {
        launchFromSelectAssessment({ id: studentId });
        return;
      }
      try {
        const student = await getStudent(studentId);
        return launchForResume(student, languages[0]);
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare assessment launch: ${e.message}`,
        });
      }
    },
    [launchForResume, launchFromSelectAssessment],
  );

  const {
    students: studentList,
    onClassSelect,
    classOptions,
    selectedClassOption,
    sortField,
    sortOrder,
    onSortChanged,
    search,
    onSearchChanged,
    selectedClass,
    inProgressBAPs: bapsInProgress,
    completedBAPs: bapsCompleted,
    onTeachingRecommendationClicked,
  } = useStudentsDashboard({
    students,
    classes,
    studentGroupEnrollmentCounts,
    inProgressBAPs,
    completedBAPs,
  });

  return (
    <Column
      className="flex-grow pt-3.5 overflow-hidden print:overflow-visible print:block"
      {...props}
    >
      <PageTitle
        pageTitle={`My Students ${
          selectedClass ? ` - ${selectedClass.name}` : ''
        }`}
      />
      {loading ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full overflow-hidden print:block">
          <Row className="justify-between items-center lg:ml-24 pb-6 pr-10  z-10 print:hidden">
            <DashboardDropdown
              options={classOptions}
              onSelected={onClassSelect}
              selected={selectedClassOption}
              placeholder="All Classes"
            />
            {selectedClass && (
              <ActionLink
                variant="add"
                to={formatEnrollStudentInGroupRoute(selectedClass.id)}
              >
                Add Student
              </ActionLink>
            )}
          </Row>
          <Column className="pt-3 overflow-hidden print:overflow-visible print:block">
            <StudentsDashboard
              students={studentList}
              inProgressBAPs={bapsInProgress}
              totalCompletedBAPs={bapsCompleted.length}
              search={search}
              onSearchChanged={onSearchChanged}
              sortField={sortField}
              sortOrder={sortOrder}
              onSortChanged={onSortChanged}
              onResumeClicked={onResumeClicked}
              onStartClicked={(id) => launchFromSelectAssessment({ id })}
              onTeachingRecommendationClicked={onTeachingRecommendationClicked}
            />
          </Column>
        </Column>
      )}
    </Column>
  );
};
