import { createContext, useContext } from 'react';
import { asyncNoop } from 'shared/lib/utils/noop';

export interface SettingsContextValues {
  researchMode: boolean;
  refreshResearchMode(): Promise<void>;
}

export const SettingsContext = createContext<SettingsContextValues>({
  researchMode: false,
  refreshResearchMode: asyncNoop,
});

export function useSettingsContext() {
  return useContext(SettingsContext);
}
