import React, { FC, useCallback, useState } from 'react';
import { Language } from 'shared/lib/constants/Language';
import { BeginAssessmentNotes } from '../../components/Assessment/BeginAssessmentNotes/BeginAssessmentNotes';
import { useHistory, useLocation } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { beginBAPAssessment } from '../../api/baps/beginBAPAssessment';
import { takeAssessment } from 'shared/lib/constants/routes/assessmentRoutes';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { showOkAlert } from '../../components/Alert/Alert';

interface AssessmentTakeInstructionsPageProps {
  language: Language;
}

export const AssessmentTakeInstructionsPage: FC<AssessmentTakeInstructionsPageProps> = ({
  language,
  ...rest
}) => {
  const history = useHistory();
  const { state } = useLocation<{ studentId?: number }>();
  const { studentId } = state;
  const [loading, setLoading] = useState(true);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
      }
      if (!studentId) {
        await showOkAlert({
          title: 'Error',
          text: 'A student must be selected prior to visiting this page.',
          theme: 'error',
        });
      }
      setLoading(false);
    },
    [studentId, state],
  );

  const onStartClicked = useCallback(async () => {
    if (!studentId) {
      return;
    }
    setLoading(true);
    try {
      await beginBAPAssessment({ studentId, language, unsafeLaunch: true });
      history.replace(takeAssessment);
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        text: `Failed to launch assessment: ${e.message}`,
        theme: 'error',
      });
      setLoading(false);
      return;
    }
  }, [studentId, language, history]);

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <>
      <PageTitle pageTitle="Assessment Instructions" />
      <BeginAssessmentNotes
        language={language}
        onBackClicked={() => history.goBack()}
        onStartClicked={onStartClicked}
        {...rest}
      />
    </>
  );
};
