import { Student } from 'shared/lib/types/Student';
import { useCallback, useEffect, useState } from 'react';
import { EditableStudent } from '../../types/student/EditableStudent';
import { useDateInput } from '../useDateInput';
import { Gender } from 'shared/lib/constants/user/Gender';
import { FamilyLanguageUse } from 'shared/lib/constants/student/FamilyLanguageUse';

const defaultEditableStudent: EditableStudent = {
  firstName: '',
  lastName: '',
  gender: null,
  birthDate: '',
  familyLanguageUse: null,
};

export function useStudentForm(student: Student | null) {
  const [editableStudent, setEditableStudent] = useState<EditableStudent>({
    ...defaultEditableStudent,
  });
  useEffect(() => {
    setEditableStudent({
      ...(student ?? defaultEditableStudent),
      firstName: '',
      lastName: '',
    });
  }, [student]);

  const handleBirthDateChanged = useCallback((date: string) => {
    setEditableStudent((prev) => ({ ...prev, birthDate: date }));
  }, []);

  const onFirstNameChanged = useCallback((firstName: string) => {
    setEditableStudent((prev) => ({ ...prev, firstName }));
  }, []);

  const onLastNameChanged = useCallback((lastName: string) => {
    setEditableStudent((prev) => ({ ...prev, lastName }));
  }, []);

  const onGenderChanged = useCallback((gender: Gender) => {
    setEditableStudent((prev) => ({ ...prev, gender }));
  }, []);

  const onFamilyLanguageUseChanged = useCallback(
    (familyLanguageUse: FamilyLanguageUse) => {
      setEditableStudent((prev) => ({ ...prev, familyLanguageUse }));
    },
    [],
  );

  const {
    value: birthDate,
    onDateChange: onBirthDateChanged,
    invalidDate: invalidBirthDate,
  } = useDateInput({
    date: editableStudent.birthDate,
    onDateChanged: handleBirthDateChanged,
  });

  return {
    editableStudent,
    onFirstNameChanged,
    onLastNameChanged,
    onGenderChanged,
    birthDate,
    onBirthDateChanged,
    invalidBirthDate,
    onFamilyLanguageUseChanged,
  };
}
