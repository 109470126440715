import { apiAxios } from '../apiAxios';

interface Params {
  studentGroupId: number;
  studentId: number;
}

export async function removeStudentGroupEnrollment({
  studentGroupId,
  studentId,
}: Params) {
  return apiAxios.delete(
    `/student-group/${studentGroupId}/student/${studentId}`,
  );
}
