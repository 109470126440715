import React, { FC, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { StudentGroupAndSchool } from 'shared/lib/types/StudentGroup';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudentGroup } from '../../api/student-group/getStudentGroup';
import { useHistory } from 'react-router-dom';
import { formatSchoolDetailsRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { BackButton } from '../../components/BackButton/BackButton';
import { RemoveClassConfirm } from '../../components/RemoveClassConfirm/RemoveClassConfirm';
import { deleteStudentGroup } from '../../api/student-group/deleteStudentGroup';
import { useLoggedInUserContext } from '../../contexts/userContext';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import { students } from 'shared/lib/constants/routes/assessorRoutes';
import { Row } from '../../components/Row/Row';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { showOkAlert } from '../../components/Alert/Alert';

interface RemoveClassConfirmProps {
  studentGroupId: number;
}

export const RemoveClass: FC<RemoveClassConfirmProps> = ({
  studentGroupId,
  ...rest
}) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [
    studentGroup,
    setStudentGroup,
  ] = useState<StudentGroupAndSchool | null>(null);
  const { user } = useLoggedInUserContext();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudentGroup(null);
      }
      try {
        const group = await getStudentGroup(studentGroupId);
        if (!isCancelled()) {
          setStudentGroup(group);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare class for removal: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [studentGroupId, history],
  );

  const onSubmit = useCallback(async () => {
    if (!studentGroup) {
      return;
    }
    setLoading(true);
    try {
      await deleteStudentGroup(studentGroup.id);
      history.replace(
        isAdmin(user)
          ? formatSchoolDetailsRoute(studentGroup.school)
          : students,
      );
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        text: `Failed to delete class: ${e.message}`,
        theme: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [studentGroup, history, user]);

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || !studentGroup ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
                <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
                  {studentGroup?.school?.name}
                </Row>
                <div>&nbsp;</div>
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {studentGroup?.name ?? ''}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    Remove Class
                  </Row>
                </Column>
              </Row>
            </Column>
          </Column>
          <MainPanel className="pr-8 lg:pr-15">
            <RemoveClassConfirm
              studentGroup={studentGroup}
              onCancelled={() => history.goBack()}
              onSubmit={onSubmit}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
