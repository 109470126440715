import React, { FC } from 'react';
import { Row } from '../../Row/Row';
import {
  ActivityProgress,
  ActivitySectionProgress,
} from 'shared/lib/types/activity/ActivityProgress';
import { ActivityProgressStatus } from 'shared/lib/constants/activity/ActivityProgressStatus';
import {
  close,
  progressUnvisited,
  progressVisited,
  progressVisiting,
} from '../../../images';

interface ActivityProgressBarProps {
  activityProgress: ActivityProgress;
  className?: string;
}

export const ActivityProgressBar: FC<ActivityProgressBarProps> = ({
  activityProgress,
  className = '',
  ...rest
}) => (
  <Row {...rest}>
    {activityProgress.sections.map((section, idx) => (
      <ActivityProgressBarSection
        activitySectionProgress={section}
        key={`section_${idx}`}
      />
    ))}
  </Row>
);

const ActivityProgressBarSection: FC<{
  activitySectionProgress: ActivitySectionProgress;
}> = ({ activitySectionProgress, ...rest }) => (
  <Row className="mr-6" {...rest}>
    {activitySectionProgress.progress.map(({ status, progressItemId }) => (
      <ProgressDot status={status} key={progressItemId} />
    ))}
  </Row>
);

function getStatusDot(status: ActivityProgressStatus) {
  switch (status) {
    case ActivityProgressStatus.UNVISITED:
      return progressUnvisited;
    case ActivityProgressStatus.VISITING:
      return progressVisiting;
    case ActivityProgressStatus.VISITED:
      return progressVisited;
    case ActivityProgressStatus.MISSING:
      return close;
  }
}

const ProgressDot: FC<{ status: ActivityProgressStatus }> = ({
  status,
  ...rest
}) => (
  <img
    src={getStatusDot(status)}
    alt={status}
    className={`h-2 w-2 mr-2 ${
      status === ActivityProgressStatus.VISITING ? 'animate-pulse' : ''
    }`}
    {...rest}
  />
);
