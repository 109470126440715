import React from 'react';
import { UserContext } from './contexts/userContext';
import { useUser } from './hooks/useUser';
import { UnauthenticatedRoutes } from './routes/UnauthenticatedRoutes';
import { SpinnerOverlay } from './components/SpinnerOverlay/SpinnerOverlay';
import { LoggedInApp } from './apps/LoggedInApp';

function App() {
  const { user, loading, ...restOfContextValues } = useUser();

  return (
    <UserContext.Provider value={{ user, ...restOfContextValues }}>
      {loading && <SpinnerOverlay />}
      {!loading && user === null && <UnauthenticatedRoutes />}
      {user !== null && <LoggedInApp />}
    </UserContext.Provider>
  );
}

export default App;
