import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getAssessmentTakeState } from '../../api/assessment-take/getAssessmentTakeState';
import { ActivityStepProps } from '../../types/assessment/ActivityStepProps';
import { ActivityStep } from 'shared/lib/types/activity/ActivityStep';
import { useActivityTitle } from './useActivityTitle';
import { useActivityStepAnswers } from './useActivityStepAnswers';
import { useActivityStepAudio } from './useActivityStepAudio';
import { ActivityAnswer } from 'shared/lib/types/assessment/ActivityAnswer';
import { progressToNextStep } from '../../api/assessment-take/progressToNextStep';
import { goToPreviousStep } from '../../api/assessment-take/goToPreviousStep';
import {
  showAlert,
  showOkAlert,
  showYesNoAlert,
} from '../../components/Alert/Alert';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import { useHistory } from 'react-router-dom';
import { endAssessmentTake } from '../../api/assessment-take/endAssessmentTake';
import { useTakeAccessRefresh } from './useTakeAccessRefresh';
import { AssessmentStepCompletionParams } from '../../types/assessment-take/SubmitAssessmentStepAnswerParams';
import { saveAssessmentStepAnswer } from '../../api/assessment-take/saveAssessmentStepAnswer';
import { finishAssessmentTake } from '../../api/assessment-take/finishAssessmentTake';
import { formatAssessmentResultsRoute } from 'shared/lib/constants/routes/assessmentRoutes';
import { useTimeout } from '../useTimeout';
import { SECOND } from 'shared/lib/constants/time';
import { NO_ANSWER_ID } from 'shared/lib/constants/assessment/NoAnswer';
import { isAtBeginningOfActivity } from 'shared/lib/utils/assessment/calculateActivityProgress';

export enum AssessmentTakeStatus {
  INITIALIZING,
  INITIALIZATION_FAILURE,
  GOOD,
  PROGRESSING,
  TIMED_OUT,
}

const ALLOW_PROGRESS_DELAY = 3 * SECOND;

interface ReturnValues extends Omit<ActivityStepProps, 'activityStep'> {
  activityStep: ActivityStep | null;
  assessmentTakeState: AssessmentTakeState | null;
  status: AssessmentTakeStatus;
}

interface UseAssessmentTakeParams {
  bapAssessmentId: number;
  showStudentResponseModal: boolean;
}

export function useAssessmentTake({
  bapAssessmentId,
  showStudentResponseModal,
}: UseAssessmentTakeParams): ReturnValues {
  const [
    assessmentTakeState,
    setAssessmentTakeState,
  ] = useState<AssessmentTakeState | null>(null);
  const [status, setStatus] = useState<AssessmentTakeStatus>(
    AssessmentTakeStatus.INITIALIZING,
  );
  const history = useHistory();
  const [showAudioIncomplete, setShowAudioIncomplete] = useState(false);
  const [canProgress, setCanProgress] = useState(false);

  const refreshAssessmentTakeState = useCallback(
    async (isCancelled?: () => boolean) => {
      const takeState = await getAssessmentTakeState(bapAssessmentId);
      if (!isCancelled || !isCancelled()) {
        setAssessmentTakeState(takeState);
      }
    },
    [bapAssessmentId],
  );

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setStatus(AssessmentTakeStatus.INITIALIZING);
        setAssessmentTakeState(null);
      }
      try {
        await refreshAssessmentTakeState(isCancelled);
        if (!isCancelled()) {
          setStatus(AssessmentTakeStatus.GOOD);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to get assessment take state: ${e.message}`,
          theme: 'error',
        });
        setStatus(AssessmentTakeStatus.INITIALIZATION_FAILURE);
      }
    },
    [refreshAssessmentTakeState],
  );

  const saveAndProgressToNextStep = useCallback(
    async (answerInfo: AssessmentStepCompletionParams) => {
      if (!assessmentTakeState || status !== AssessmentTakeStatus.GOOD) {
        return;
      }
      setStatus(AssessmentTakeStatus.PROGRESSING);
      const {
        currentItem,
        isFinalSkill,
        assessmentResult,
        bap,
      } = assessmentTakeState;
      const { language } = assessmentResult;
      const { isFinalStep } = currentItem;
      try {
        if (isFinalSkill && isFinalStep) {
          await saveAssessmentStepAnswer(answerInfo);
          await finishAssessmentTake();
          history.replace(formatAssessmentResultsRoute(bap.id), {
            language,
          });
        } else {
          setAssessmentTakeState(await progressToNextStep(answerInfo));
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to go back to the save the current step: ${e.message}`,
          theme: 'error',
        });
      } finally {
        setStatus(AssessmentTakeStatus.GOOD);
      }
    },
    [assessmentTakeState, history, status],
  );

  const onStudentResponseSaved = useCallback(
    async (answer: ActivityAnswer, response: string | null) => {
      if (!assessmentTakeState) {
        return null;
      }
      const { currentItem } = assessmentTakeState;
      const { assessmentStepResult } = currentItem;
      const { correct, id } = answer;
      const saveParams: AssessmentStepCompletionParams = {
        correct,
        answerId: id,
        studentResponse: response,
        assessmentStepId: assessmentStepResult.id,
      };
      await saveAndProgressToNextStep(saveParams);
    },
    [assessmentTakeState, saveAndProgressToNextStep],
  );

  const {
    selectedAnswer,
    answers,
    onAnswerSelected,
    studentResponse,
    selectedAnswerHistory,
  } = useActivityStepAnswers({
    takeState: assessmentTakeState,
    showStudentResponseModal,
    onStudentResponseSaved,
  });

  const {
    presignedAudioUrl,
    onAudioPlayed,
    audioPlayed,
  } = useActivityStepAudio(assessmentTakeState);

  const handleAnswerSelected = useCallback(
    async (answer: ActivityAnswer) => {
      // If the audio is not played and there is no "history" for this item, require that the audio be played
      if (!audioPlayed && !selectedAnswerHistory.length) {
        setShowAudioIncomplete(true);
        return;
      }
      await onAnswerSelected(answer);
    },
    [audioPlayed, onAnswerSelected, selectedAnswerHistory],
  );

  const stepSubmissionParams = useMemo(() => {
    if (!assessmentTakeState) {
      return null;
    }
    const { currentItem } = assessmentTakeState;
    const { assessmentStepResult } = currentItem;
    let saveParams: AssessmentStepCompletionParams = {
      assessmentStepId: assessmentStepResult.id,
      correct: null,
      studentResponse: null,
      answerId: null,
    };

    if (selectedAnswer) {
      const { correct, id } = selectedAnswer;
      saveParams = {
        ...saveParams,
        correct,
        answerId: id,
        studentResponse,
      };
    } else {
      saveParams = {
        ...saveParams,
        answerId: NO_ANSWER_ID,
        correct: null,
        studentResponse,
      };
    }
    return saveParams;
  }, [assessmentTakeState, studentResponse, selectedAnswer]);

  const onNext = useCallback(async () => {
    if (status !== AssessmentTakeStatus.GOOD) {
      return;
    }
    if (!stepSubmissionParams) {
      return;
    }
    setShowAudioIncomplete(false);
    await saveAndProgressToNextStep(stepSubmissionParams);
  }, [status, stepSubmissionParams, saveAndProgressToNextStep]);

  const onPauseClicked = useCallback(async () => {
    if (
      !(await showAlert({
        title: 'BAPS Session Paused',
        text:
          'Would you like to resume this assessment or leave and return later?',
        showConfirm: true,
        confirmText: 'Resume',
        showCancel: true,
        cancelText: 'Return Later',
        showExit: true,
        exitAsCancel: false,
      }))
    ) {
      try {
        await endAssessmentTake();
      } catch (e) {
        // Not severe enough to worry.
        console.error(e);
      }
      history.replace(home);
    }
  }, [history]);

  const onError = useCallback(() => {
    history.replace(home);
  }, [history]);

  const onBackClicked = useCallback(async () => {
    if (!assessmentTakeState || status !== AssessmentTakeStatus.GOOD) {
      return;
    }
    const { currentItem } = assessmentTakeState;
    const { assessmentStepResult, activityStep, isPrimaryStep } = currentItem;
    if (isAtBeginningOfActivity(activityStep.stepId)) {
      await showOkAlert({
        title: 'Cannot Progress',
        text: 'Cannot progress backward because this is the first step.',
        theme: 'error',
      });
      return;
    }

    // isPrimaryStep is true at the starting end of a step sequence, which means its a transition point between skills
    if (
      isPrimaryStep &&
      !(await showYesNoAlert({
        title: 'Go Back?',
        text:
          'Going back to a previous subtest may change the outcome of the branch decision and cause responses to be discarded. This cannot be undone. Do you wish to continue?',
      }))
    ) {
      return;
    }
    try {
      setStatus(AssessmentTakeStatus.PROGRESSING);
      setShowAudioIncomplete(false);
      if (stepSubmissionParams) {
        await saveAssessmentStepAnswer(stepSubmissionParams);
      }
      setAssessmentTakeState(await goToPreviousStep(assessmentStepResult.id));
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        text: `Failed to go back to the previous step: ${e.message}`,
        theme: 'error',
      });
    } finally {
      setStatus(AssessmentTakeStatus.GOOD);
    }
  }, [assessmentTakeState, status, stepSubmissionParams]);

  const onRefreshFailed = useCallback(async () => {
    setStatus(AssessmentTakeStatus.TIMED_OUT);
    await showOkAlert({
      title: 'Timed Out',
      text: 'The In-Progress Assessment has timed out.',
    });
    history.replace(home);
  }, [history]);

  useTakeAccessRefresh({
    onRefreshFailed,
    pause:
      status === AssessmentTakeStatus.PROGRESSING ||
      status === AssessmentTakeStatus.INITIALIZING,
  });

  const onShowAudioCompleteDismissed = useCallback(() => {
    setShowAudioIncomplete(false);
  }, []);

  const allowProgress = useCallback(() => {
    setCanProgress(true);
  }, []);

  const canProgressWithoutDelay = selectedAnswerHistory.length > 0;
  useTimeout(
    allowProgress,
    ALLOW_PROGRESS_DELAY,
    !canProgress && !canProgressWithoutDelay,
  );

  useEffect(() => {
    setCanProgress(false);
  }, [assessmentTakeState]);

  const progressAllowed = useMemo(() => {
    if (selectedAnswerHistory.length > 0) {
      return true;
    }
    return canProgress && audioPlayed;
  }, [canProgress, audioPlayed, selectedAnswerHistory]);

  const showBack = useMemo(() => {
    if (!assessmentTakeState) {
      return false;
    }
    const { currentItem } = assessmentTakeState;
    return !isAtBeginningOfActivity(currentItem.activityStep.stepId);
  }, [assessmentTakeState]);

  return {
    assessmentTakeState,
    status,
    activityStep: assessmentTakeState?.currentItem.activityStep ?? null,
    onNext,
    onBackClicked,
    title: useActivityTitle(assessmentTakeState),
    activityProgress: assessmentTakeState?.activityProgress ?? { sections: [] },
    canProgress: progressAllowed,
    audio: presignedAudioUrl?.url ?? null,
    onAudioPlayed,
    onError,
    onPauseClicked,
    selectedAnswer,
    answers,
    onAnswerSelected: handleAnswerSelected,
    showAudioIncomplete,
    onShowAudioCompleteDismissed,
    showBack,
  };
}
