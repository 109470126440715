import React, { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { formatMonthDayYear } from 'shared/lib/utils/formatDate';
import { Language } from 'shared/lib/constants/Language';
import { Student } from 'shared/lib/types/Student';
import { StudentPercentileHistoryItem } from 'shared/lib/types/assessment/StudentPercentileHistoryItem';
import { PercentileHistory } from '../PercentileHistory/PercentileHistory';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { formatEditStudentRoute } from 'shared/lib/constants/routes/userRoutes';
import { getTitle } from 'shared/lib/constants/student/FamilyLanguageUse';
import { ActionLink } from '../ActionLink/ActionLink';
import { TeachingRecommendation } from 'shared/lib/types/assessment/TeachingRecommendation';

interface StudentDetailsProps extends ComponentPropsWithoutRef<'div'> {
  student: Student;
  studentGroups: ReadonlyArray<StudentGroup>;
  /**
   * The Student's BAPS percentile history.
   * Should be in descending order.
   */
  percentileHistory: ReadonlyArray<StudentPercentileHistoryItem>;

  showScaledScore: boolean;
  onResumeClicked(language: Language): void;
  onStartNewClicked(): void;
  onScoreClicked(): void;
  from?: string;
  onTeachingRecommendationClicked(
    teachingReccomendation: TeachingRecommendation,
  ): void;
  sourceClassId?: number;
}

export const StudentDetails: FC<StudentDetailsProps> = ({
  student,
  percentileHistory,
  showScaledScore,
  onResumeClicked,
  onStartNewClicked,
  studentGroups,
  from,
  onScoreClicked,
  onTeachingRecommendationClicked,
  sourceClassId,
  ...rest
}) => {
  const { birthDate, createdBy, familyLanguageUse } = student;
  const recentResultStatus = useMemo(() => {
    const [mostRecentResult] = percentileHistory;
    if (!mostRecentResult) {
      return 'No Results';
    }
    const { completedDate, ageAdjustedPercentile } = mostRecentResult;
    if (!completedDate) {
      return 'In Progress';
    }
    if (!ageAdjustedPercentile) {
      return 'No Results';
    }
    const { atRisk, expired } = ageAdjustedPercentile;
    if (expired) {
      return 'N/a';
    }

    return atRisk ? 'At Risk' : 'Not At Risk';
  }, [percentileHistory]);

  return (
    <Column {...rest}>
      <Row className="justify-between">
        <Column className="font-roboto">
          <Row className="h-9 items-center">
            <Row className="font-bold text-trueGray-750 w-48">D.O.B.</Row>
            <Row className="text-trueGray-450 font-medium">
              {formatMonthDayYear(birthDate)}
            </Row>
          </Row>
          <Row className="h-9 items-center">
            <Row className="font-bold text-trueGray-750 w-48">Created by</Row>
            <Row className="text-trueGray-450 font-medium">
              {createdBy ? formatFirstLast(createdBy) : 'N/A'}
            </Row>
          </Row>
          <Row className="w-full">
            <Row className="font-bold text-trueGray-750 w-48 flex-shrink-0">
              Current Classes
            </Row>
            <ul className="text-trueGray-450 font-medium">
              {studentGroups.length
                ? studentGroups.map((group) => (
                    <li key={group.id}>
                      {group.name}
                      {group.school ? ` | ${group.school.name}` : ''}
                    </li>
                  ))
                : 'N/A'}
            </ul>
          </Row>
          <Row className="h-9 items-center mb-3">
            <Row className="font-bold text-trueGray-750 w-48">
              Family Language Use
            </Row>
            <Row className="text-trueGray-450 font-medium">
              {getTitle(familyLanguageUse)}
            </Row>
          </Row>
        </Column>
        <Row className="items-start">
          <ActionLink
            variant="edit"
            to={{
              pathname: formatEditStudentRoute(student.id),
              state: { from, sourceClassId },
            }}
          >
            Edit Student
          </ActionLink>
        </Row>
      </Row>
      <Row className="mt-12 mb-6 font-bold font-roboto">
        <Row className="mr-3">Recent Test Results:</Row>
        <Row>{recentResultStatus}</Row>
      </Row>
      <PercentileHistory
        percentileHistory={percentileHistory}
        showScaledScore={showScaledScore}
        onResumeClicked={onResumeClicked}
        onScoreClicked={onScoreClicked}
        student={student}
        onTeachingRecommendationClicked={onTeachingRecommendationClicked}
      />
    </Column>
  );
};
