import React, { FC } from 'react';
import { arrowLeft, arrowLeftCircle } from '../../images';
import { Button } from '../Button/Button';

interface BackButtonProps {
  onClick(): void;
  disabled?: boolean;
  className?: string;
  variation?: 'default' | 'circled';
}

export const BackButton: FC<BackButtonProps> = ({
  className = '',
  children = 'Back',
  variation = 'default',
  ...rest
}) => (
  <Button className={`items-center hover:underline ${className}`} {...rest}>
    <img
      src={variation === 'default' ? arrowLeft : arrowLeftCircle}
      alt="Go Back"
      className="mr-2 w-6 h-6"
    />
    <span className="font-roboto font-bold text-trueGray-900">{children}</span>
  </Button>
);
