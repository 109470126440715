import React, { FC } from 'react';
import { Column } from '../../components/Column/Column';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { useHistory } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import { StudentGroupForm } from '../../components/StudentGroupForm/StudentGroupForm';
import { useStudentGroupEditor } from '../../hooks/student-group/useStudentGroupEditor';
import { useAssignedSchool } from '../../contexts/assignedOrganizationContext';
import { SchoolOrg } from 'shared/lib/types/Org';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { formatRemoveClassRoute } from 'shared/lib/constants/routes/administratorRoutes';

interface StudentGroupEditorProps {
  school: SchoolOrg | number | null;
  studentGroupId: number | null;
}

export const StudentGroupEditor: FC<StudentGroupEditorProps> = ({
  school,
  studentGroupId,
  ...rest
}) => {
  const history = useHistory();
  const {
    studentGroup,
    editableGroup,
    school: schoolOrg,
    loading,
    onSubmit,
    onNameChanged,
    onLocationChanged,
    error,
  } = useStudentGroupEditor({ studentGroupId, school });

  return (
    <Column className="flex-grow  pt-3.5 overflow-hidden" {...rest}>
      <PageTitle
        pageTitle={`Class - ${
          studentGroup ? `${studentGroup.name}` : 'Add New'
        }`}
      />
      {loading || !schoolOrg ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
                <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
                  {schoolOrg?.name}
                </Row>
                <div>&nbsp;</div>
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {studentGroup?.name ?? schoolOrg?.name ?? ''}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    {studentGroup ? 'Edit Class' : 'Add Class'}
                  </Row>
                </Column>
                {studentGroup && (
                  <ActionLink
                    variant="remove"
                    replace
                    to={formatRemoveClassRoute(studentGroup.id)}
                  >
                    Remove Class
                  </ActionLink>
                )}
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <StudentGroupForm
              groupName={studentGroup?.name ?? ''}
              school={schoolOrg}
              studentGroup={editableGroup}
              editMode={studentGroup !== null}
              onCancelled={() => history.goBack()}
              onNameChanged={onNameChanged}
              onLocationChanged={onLocationChanged}
              onSubmit={onSubmit}
              error={error}
              className="pl-9"
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};

export const AssignedSchoolStudentGroupEditor: FC = () => {
  const { assignedSchool } = useAssignedSchool();
  return <StudentGroupEditor school={assignedSchool} studentGroupId={null} />;
};
