import { useCallback, useEffect, useMemo, useState } from 'react';
import { DashboardDropdownOption } from '../../components/DashboardDropdown/DashboardDropdown';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { useSearchParamPatch, useSearchParams } from '../useSearchParams';
import { StudentGroupEnrollmentCounts } from 'shared/lib/types/student-group/StudentGroupEnrollmentCounts';

interface UseStudentGroupSelectParams {
  classes: ReadonlyArray<StudentGroup>;
  studentGroupEnrollmentCounts: ReadonlyArray<StudentGroupEnrollmentCounts>;
  loading?: boolean;
}

export function useStudentGroupSelect({
  classes,
  studentGroupEnrollmentCounts,
  loading,
}: UseStudentGroupSelectParams) {
  const [selectedClass, setSelectedClass] = useState<StudentGroup | null>(null);
  const patchParams = useSearchParamPatch();
  const params = useSearchParams();

  const handleClassSelect = useCallback(
    ({ id }: DashboardDropdownOption) => {
      const group = classes.find((cls) => cls.id === id) ?? null;
      setSelectedClass(group);
      patchParams({ classId: group?.id });
    },
    [classes, patchParams],
  );

  const classOptions = useMemo(
    (): DashboardDropdownOption[] => [
      { id: -1, name: 'All Classes', studentCount: null, categoryName: '' },
      ...classes.map((cls) => ({
        id: cls.id,
        name: cls.name,
        studentCount:
          studentGroupEnrollmentCounts.find((e) => e.studentGroupId === cls.id)
            ?.studentCount ?? 0,
        categoryName: cls.school?.name ?? '',
      })),
    ],
    [classes, studentGroupEnrollmentCounts],
  );

  useEffect(() => {
    if (selectedClass && !classes.some((cls) => cls.id === selectedClass.id)) {
      setSelectedClass(null);
    }
  }, [selectedClass, classes]);

  const selectedClassOption = useMemo(
    () => classOptions.find((cls) => cls.id === selectedClass?.id) ?? null,
    [classOptions, selectedClass],
  );

  useEffect(() => {
    if (!loading) {
      const classId = +(params.get('classId') ?? '');
      if (!Number.isNaN(classId)) {
        setSelectedClass(classes.find((cls) => cls.id === classId) ?? null);
      }
    }
  }, [params, loading, classes]);

  return {
    onClassSelect: handleClassSelect,
    selectedClass,
    classOptions,
    selectedClassOption,
  };
}
