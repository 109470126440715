import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { useMemo } from 'react';
import { useNameSearch } from '../useNameSearch';
import { UserWithGroupEnrollments } from 'shared/lib/types/User';
import { DashboardAssessor } from 'shared/lib/types/teacher/DashboardAssessor';
import { StudentWithGroupEnrollments } from 'shared/lib/types/Student';
import { intersectLists } from 'shared/lib/utils/intersectSets';

interface UseSchoolDashboardAssessorsParams {
  assessors: ReadonlyArray<UserWithGroupEnrollments>;
  students: ReadonlyArray<Pick<StudentWithGroupEnrollments, 'studentGroups'>>;
  search: string;
  selectedClass: StudentGroup | null;
}

export function useSchoolDashboardAssessors({
  assessors,
  search,
  selectedClass,
  students,
}: UseSchoolDashboardAssessorsParams): ReadonlyArray<DashboardAssessor> {
  const searchedTeachers = useNameSearch({ list: assessors, search });

  const assessorList = useMemo(() => {
    if (!selectedClass) {
      return searchedTeachers;
    }
    return searchedTeachers.filter(({ studentGroups }) =>
      studentGroups.some((g) => g.id === selectedClass.id),
    );
  }, [searchedTeachers, selectedClass]);

  return useMemo(() => {
    return assessorList.map((assessor) => {
      const { studentGroups } = assessor;
      const assessorGroupIds = studentGroups.map((g) => g.id);
      return {
        ...assessor,
        studentCount: students.filter(
          ({ studentGroups: studentGroupEnrollment }) => {
            const studentGroupIds = studentGroupEnrollment.map((g) => g.id);
            return intersectLists(assessorGroupIds, studentGroupIds).length;
          },
        ).length,
      };
    });
  }, [assessorList, students]);
}
