import React, { ComponentPropsWithoutRef, FC } from 'react';
import { StudentPercentileHistoryItem } from 'shared/lib/types/assessment/StudentPercentileHistoryItem';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { PercentileButton } from '../PercentileButton/PercentileButton';
import { Language } from 'shared/lib/constants/Language';
import { getAtRiskColor, getAtRiskLabel } from '../../constants/AtRiskStatus';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Student } from 'shared/lib/types/Student';
import { headBlue } from '../../images';
import './PercentileHistory.css';
import { TeachingRecommendation } from 'shared/lib/types/assessment/TeachingRecommendation';
import { TeachingRecommendationsButton } from '../TeachingRecommendationsButton/TeachingRecommendationsButton';

export interface PercentileHistoryProps
  extends ComponentPropsWithoutRef<'div'> {
  student: Pick<Student, 'firstName' | 'lastName'>;
  percentileHistory: ReadonlyArray<StudentPercentileHistoryItem>;
  showScaledScore: boolean;
  onResumeClicked(language: Language): void;
  onScoreClicked(): void;
  onTeachingRecommendationClicked(
    teachingRecommendation: TeachingRecommendation,
  ): void;
}

export const PercentileHistory: FC<PercentileHistoryProps> = ({
  percentileHistory,
  showScaledScore,
  onResumeClicked,
  className = '',
  onScoreClicked,
  student,
  onTeachingRecommendationClicked,
  ...rest
}) => {
  return (
    <Column
      className={`overflow-hidden max-h-full min-w-600px ${className}`}
      {...rest}
    >
      <Row className="font-roboto text-trueGray-750 font-light w-full min-w-max justify-between items-end pb-1 pr-6 pl-5">
        <Row className="w-1/3 items-end ">
          <Row className="w-1/2 flex-grow flex-shrink-0 ">Name</Row>
          <Row className="w-1/2 justify-center items-end flex-grow flex-shrink-0 ">
            Risk Status
          </Row>
        </Row>
        <Row className="w-2/3  flex-grow flex-shrink-0">
          <Column className="justify-center items-center w-1/2 ">
            <Row className="mb-3 text-center font-bold  whitespace-nowrap">
              Recent Age-Adjusted Percentile
            </Row>
            <Row className="w-32 justify-between ">
              <div>English</div>
              <div>Spanish</div>
            </Row>
          </Column>
          <Column className="justify-center items-center w-1/2 ">
            {showScaledScore && (
              <>
                <Row className="mb-3 text-center font-bold">Scaled Score</Row>
                <Row className="w-32 justify-between">
                  <div>English</div>
                  <div>Spanish</div>
                </Row>
              </>
            )}
          </Column>
        </Row>
      </Row>
      <Column className="overflow-y-auto h-full pb-36 flex-grow percentile-history-list">
        {percentileHistory.map((item, idx) => {
          const {
            ageAdjustedPercentile,
            scaledScore,
            teachingRecommendation,
          } = item;

          return (
            <Row
              key={idx}
              className="w-full h-25 max-h-25 bg-white flex-grow flex-shrink-0 font-bold font-roboto text-sm shadow-dashboardShadow rounded-xl items-center pl-6 pr-6 mb-3 justify-between"
            >
              <Row className="w-1/3 items-center ">
                <Column className="w-full h-full justify-center">
                  <Row className="items-center flex-grow flex-shrink-0">
                    <img
                      src={headBlue}
                      alt="Student"
                      className="w-6 h-6 mr-2"
                    />
                    {formatFirstLast(student)}
                  </Row>
                  <div className="absolute transform translate-y-8 whitespace-nowrap">
                    {teachingRecommendation &&
                    teachingRecommendation.strugglingSkills.length ? (
                      <TeachingRecommendationsButton
                        onClick={() =>
                          onTeachingRecommendationClicked(
                            teachingRecommendation,
                          )
                        }
                      />
                    ) : (
                      <>&nbsp;</>
                    )}
                  </div>
                </Column>
                <Row
                  className={`text-sm justify-center w-1/2  flex-grow flex-shrink-0   ${getAtRiskColor(
                    ageAdjustedPercentile,
                  )} `}
                >
                  {getAtRiskLabel(ageAdjustedPercentile)}
                </Row>
              </Row>
              <Row className="w-2/3  justify-between">
                <Row className="items-center justify-center w-1/2  pr-8">
                  <Row className="w-32 ">
                    <Column>
                      <PercentileButton
                        percentile={
                          ageAdjustedPercentile?.englishPercentile ?? null
                        }
                        onResumeClicked={() =>
                          onResumeClicked(Language.ENGLISH)
                        }
                      />
                    </Column>
                    <Column>
                      <PercentileButton
                        percentile={
                          ageAdjustedPercentile?.spanishPercentile ?? null
                        }
                        onResumeClicked={() =>
                          onResumeClicked(Language.SPANISH)
                        }
                      />
                    </Column>
                  </Row>
                </Row>
                <Row className="items-center justify-center w-1/2  pr-8">
                  {showScaledScore && (
                    <Row className="w-32 ">
                      <Column>
                        <PercentileButton
                          percentile={scaledScore?.englishPercentile ?? null}
                          onResumeClicked={() =>
                            onResumeClicked(Language.ENGLISH)
                          }
                          fontSize="text-base"
                        />
                      </Column>
                      <Column>
                        <PercentileButton
                          percentile={scaledScore?.spanishPercentile ?? null}
                          onResumeClicked={() =>
                            onResumeClicked(Language.SPANISH)
                          }
                          fontSize="text-base"
                        />
                      </Column>
                    </Row>
                  )}
                </Row>
              </Row>
            </Row>
          );
        })}
      </Column>
    </Column>
  );
};
