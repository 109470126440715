import { deserializer } from 'shared/lib/utils/enumHelper';

export enum StudentSortField {
  NAME = 'name',
  RISK_STATUS = 'riskStatus',
  INSTRUCTION_LEVEL_ENGLISH = 'instructionLevelEnglish',
  INSTRUCTION_LEVEL_SPANISH = 'instructionLevelSpanish',
  PERCENTILE_ENGLISH = 'percentileEnglish',
  PERCENTILE_SPANISH = 'percentileSpanish',
}
export const studentSortFieldDeserializer = deserializer(StudentSortField);

export enum SortOrder {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}
export const sortOrderDeserializer = deserializer(SortOrder);

export interface StudentSort {
  field: StudentSortField;
  order: SortOrder;
}
