import React, {
  ComponentPropsWithRef,
  FC,
  FormEvent,
  useCallback,
} from 'react';
import { Row } from '../Row/Row';
import { Column } from '../Column/Column';
import { TextInput } from '../TextInput/TextInput';
import { SchoolOrg } from 'shared/lib/types/Org';
import { EditableStudentGroup } from '../../types/student-group/EditableStudentGroup';
import { PillButton } from '../PillButton/PillButton';
import { FormLabel } from '../FormLabel/FormLabel';

export interface StudentGroupFormProps extends ComponentPropsWithRef<'form'> {
  groupName: string;
  school: Pick<SchoolOrg, 'name'>;
  studentGroup: EditableStudentGroup;
  editMode: boolean;
  onCancelled(): void;
  onNameChanged(name: string): void;
  onLocationChanged(location: string): void;
  error?: string;
}

export const StudentGroupForm: FC<StudentGroupFormProps> = ({
  groupName,
  editMode,
  onCancelled,
  onNameChanged,
  className = '',
  school,
  studentGroup,
  onLocationChanged,
  error,
  ...rest
}) => {
  const { name, location } = studentGroup;

  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );
  return (
    <form className={`flex flex-col ${className}`} {...rest}>
      <Column className="max-w-2.75xl">
        <Row className="justify-between flex-wrap">
          <Column>
            <FormLabel htmlFor="class-name">Class Name</FormLabel>
            <TextInput
              type="text"
              onChange={(e) => onNameChanged(e.target.value)}
              id="class-name"
              value={name}
              placeholder="Class Name"
              className="mt-2 mb-2"
              required
            />
          </Column>
          <Column>
            <FormLabel htmlFor="location">Location</FormLabel>
            <TextInput
              type="text"
              id="location"
              placeholder="Location"
              value={location}
              onChange={(e) => onLocationChanged(e.target.value)}
              className="m-2"
              required
            />
          </Column>
        </Row>
        <Row className="items-center flex-wrap pt-6">
          <PillButton
            type="submit"
            buttonVariation="primary"
            className="mt-9 mb-9"
          >
            {editMode ? 'Save Changes' : `Add Class`}
          </PillButton>
          <PillButton
            onClick={handleCancel}
            buttonVariation="cancel"
            className="m-9"
          >
            Cancel
          </PillButton>
        </Row>
        <Row className="pt-3 text-red-600">{error}</Row>
      </Column>
    </form>
  );
};
