import React, { FC } from 'react';
import { AlertModal } from '../AlertModal/AlertModal';
import { Row } from '../Row/Row';
import { headBlue, lightbulb } from '../../images';
import { Student } from 'shared/lib/types/Student';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Skill } from 'shared/lib/types/Skill';
import showModal from '@emberex/react-utils/lib/showModal';

interface TeachingRecommendationsModalProps {
  onClose(): void;
  student: Pick<Student, 'firstName' | 'lastName'>;
  struggledSkills: ReadonlyArray<Skill>;
}

function formatSkillList(struggledSkills: ReadonlyArray<Skill>): string {
  // Should be avoided...
  if (!struggledSkills.length) {
    return 'some skills';
  }
  const skillNames = struggledSkills.map((skill) => skill.name);
  if (skillNames.length <= 2) {
    return skillNames.join(' and ');
  }
  const lastSkill = skillNames[struggledSkills.length - 1];
  return `${skillNames
    .slice(0, skillNames.length - 1)
    .join(', ')}, and ${lastSkill}`;
}

export const TeachingRecommendationsModal: FC<TeachingRecommendationsModalProps> = ({
  onClose,
  student,
  struggledSkills,
  ...rest
}) => (
  <AlertModal onExitClicked={onClose} {...rest} showExit showConfirm={false}>
    <div className=" absolute left-7 -top-9">
      <img src={lightbulb} alt="Recommendations" className="w-6 h-6" />
    </div>
    <h1 className="text-light-blue-500 font-mplus text-2xl font-extrabold">
      Teaching Recommendations
    </h1>
    <Row className="items-center font-roboto text-sm text-trueGray-750 font-bold mt-9">
      <img src={headBlue} alt="Student:" className="w-6 h-6 mr-2" />
      {formatFirstLast(student)}
    </Row>
    <p className="font-bold text-trueGray-750 mt-3">
      This student performed low on {formatSkillList(struggledSkills)}. Consider
      doing more activities in{' '}
      {struggledSkills.length <= 1 ? 'this subject' : 'these subjects'}.
    </p>
  </AlertModal>
);

export function showTeachingRecommendationsModal(
  props: Pick<TeachingRecommendationsModalProps, 'student' | 'struggledSkills'>,
) {
  return showModal((resolve) => (
    <TeachingRecommendationsModal onClose={() => resolve()} {...props} />
  ));
}
