import React, { ComponentPropsWithRef, FC } from 'react';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { Link } from 'react-router-dom';
import {
  formatAssessorDetailRoute,
  formatEditAssessorRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { DashboardAssessor } from 'shared/lib/types/teacher/DashboardAssessor';
import { headBlue } from '../../images';
import { PillButton } from '../PillButton/PillButton';
import { PillButtonLink } from '../PillButtonLink/PillButtonLink';

interface AssessorListProps extends ComponentPropsWithRef<'div'> {
  assessors: ReadonlyArray<DashboardAssessor>;
  disableInternalScroll?: boolean;
  onReinviteClicked(assessor: DashboardAssessor): void;
}

export const AssessorList: FC<AssessorListProps> = ({
  assessors,
  className = '',
  disableInternalScroll,
  onReinviteClicked,
  ...rest
}) => {
  return (
    <Column className={`overflow-hidden max-h-full ${className}`} {...rest}>
      <div className="grid grid-cols-4 font-roboto text-trueGray-750 font-light w-full items-center pr-3 pl-3 pb-1">
        <Row>Name</Row>
        <Row>{/* for layout */}</Row>
        <div className="grid grid-cols-2">
          <Row className="justify-center">Classes</Row>
          <Row className="justify-center">Students</Row>
        </div>
        <Row>{/* for layout */}</Row>
      </div>
      {assessors.map((teacher) => {
        const { id, name, pending, studentGroups, studentCount } = teacher;
        return (
          <div
            className="grid grid-cols-4 h-25 max-h-25 bg-white font-bold font-roboto text-sm shadow-dashboardShadow rounded-xl items-center mb-3 pr-3 pl-3"
            key={id}
          >
            <Link
              className={`flex flex-row items-center`}
              to={formatAssessorDetailRoute({ id })}
            >
              <img src={headBlue} alt="Teacher" className="w-6 h-6  mr-4" />
              <Column>
                {name}
                {pending && (
                  <span className="font-roboto text-sm font-medium text-light-blue-500">
                    Pending
                  </span>
                )}
              </Column>
            </Link>
            <Column>
              {pending && (
                <PillButton
                  buttonVariation="primary"
                  onClick={() => onReinviteClicked(teacher)}
                >
                  Resend Invite
                </PillButton>
              )}
            </Column>
            <div className="grid grid-cols-2">
              <Row className="justify-center flex-grow flex-shrink-0">
                {studentGroups.length}
              </Row>
              <Row className="justify-center flex-grow flex-shrink-0">
                {studentCount}
              </Row>
            </div>
            <Row className="justify-end">
              <PillButtonLink
                className="bg-lime-350 "
                to={formatEditAssessorRoute(teacher)}
              >
                Edit Teacher
              </PillButtonLink>
            </Row>
          </div>
        );
      })}
    </Column>
  );
};
