import React, { FC } from 'react';
import { BAPWithFullData } from 'shared/lib/types/BAP';
import { Language } from 'shared/lib/constants/Language';
import { Row } from '../Row/Row';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { Column } from '../Column/Column';
import { PillButtonLink } from '../PillButtonLink/PillButtonLink';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import { formatMonthDayYear } from 'shared/lib/utils/formatDate';

interface BapDetailsProps {
  bap: BAPWithFullData | null;
  classListRoute: string;
  showScaledScore: boolean;
}

export const BapDetails: FC<BapDetailsProps> = ({
  bap,
  classListRoute,
  showScaledScore,
  ...rest
}) => {
  return (
    <Column className="flex-grow flex-shrink-0 items-center" {...rest}>
      {!bap ||
      !bap.bapAssessments.filter(
        (assessment) => assessment.completedAt !== null,
      ).length ? (
        <NoResults />
      ) : (
        <WithResults bap={bap} showScaledScore={showScaledScore} />
      )}
      <Row className="w-full max-w-sm justify-between pr-px pl-px">
        <PillButtonLink to={home} buttonVariation="primary">
          Home
        </PillButtonLink>
        <PillButtonLink to={classListRoute} className="text-light-blue-500">
          Class List
        </PillButtonLink>
      </Row>
    </Column>
  );
};

const NoResults: FC = (props) => (
  <Column className="w-full items-center">
    <h1 className="font-mplus text-light-blue-500 text-4xl mb-4">
      There are no results for this BAP
    </h1>
    <Row className="w-full text-trueGray-750 font-bold mb-1">
      Please be sure to complete any in-progress assessments before returning to
      this page.
    </Row>
  </Column>
);

interface WithResultsProps
  extends Omit<BapDetailsProps, 'classListRoute' | 'bap'> {
  bap: BAPWithFullData;
}

const WithResults: FC<WithResultsProps> = ({ showScaledScore, bap }) => {
  const { bapAssessments } = bap;
  const complete =
    bapAssessments.filter((assessment) => assessment.completedAt !== null)
      .length === 2;
  const mostRecentLanguage = bapAssessments[0].language;
  const english = bapAssessments.find(
    (assessment) => assessment.language === Language.ENGLISH,
  );
  const spanish = bapAssessments.find(
    (assessment) => assessment.language === Language.SPANISH,
  );
  return (
    <Column className="w-full items-center">
      <h1 className="font-mplus text-light-blue-500 text-4xl mb-7">
        {complete
          ? 'BAPS Complete!'
          : `BAPS ${capitalizeFirst(mostRecentLanguage)} Score`}
      </h1>
      <Column className="w-full items-center pr-4 pl-4 pb-28 mb-2 short:pb-15">
        <Row
          className={`w-full max-w-screen-sm ${
            showScaledScore ? 'justify-between' : 'justify-center'
          } flex-wrap`}
        >
          <Column className="w-full max-w-58 items-center">
            {showScaledScore && (
              <h2 className="w-full text-center text-trueGray-750 font-bold mb-6 short:mb-3">
                Recent Age-Adjusted Percentile
              </h2>
            )}
            <Row className="w-full justify-between">
              <ResultScore
                language={Language.ENGLISH}
                score={english ? english.ageAdjustedPercentile : null}
                completedAt={english?.completedAt ?? null}
                maxDecimalDigits={0}
              />
              <ResultScore
                language={Language.SPANISH}
                score={spanish ? spanish.ageAdjustedPercentile : null}
                completedAt={spanish?.completedAt ?? null}
                maxDecimalDigits={0}
              />
            </Row>
          </Column>
          {showScaledScore && (
            <Column className="w-full max-w-58 items-center">
              <h2 className="w-full text-center text-trueGray-750 font-bold mb-6 short:mb-3">
                Scaled Score
              </h2>
              <Row className="w-full justify-between">
                <ResultScore
                  language={Language.ENGLISH}
                  score={english?.mScore ?? null}
                  completedAt={english?.completedAt ?? null}
                  maxDecimalDigits={1}
                />
                <ResultScore
                  language={Language.SPANISH}
                  score={spanish?.mScore ?? null}
                  completedAt={spanish?.completedAt ?? null}
                  maxDecimalDigits={1}
                />
              </Row>
            </Column>
          )}
        </Row>
      </Column>
      {!complete && (
        <h3 className="w-full text-center text-trueGray-750 font-bold mb-12 pb-3 short:mb-6">
          Remember to complete BAPS
          {mostRecentLanguage === Language.ENGLISH ? ' Spanish' : ' English'}
        </h3>
      )}
    </Column>
  );
};

interface ResultScoreProps {
  language: Language;
  score: number | string | null;
  completedAt: string | null;
  maxDecimalDigits?: number;
}

function formatScore(
  score: number | string | null,
  maxDecimalDigits: number,
): string {
  if (score === null) {
    return '-';
  }

  if (typeof score === 'string') {
    return formatScore(+score, maxDecimalDigits);
  }

  return score.toFixed(maxDecimalDigits);
}

const ResultScore: FC<ResultScoreProps> = ({
  language,
  score,
  completedAt,
  maxDecimalDigits = 0,
}) => {
  return (
    <Column className="items-center">
      <Row className="font-light text-trueGray-750 mb-6">
        {capitalizeFirst(language)}
      </Row>
      <Column className="h-21 w-21 justify-center items-center flex-shrink-0 rounded-full border border-light-blue-500 text-light-blue-500 font-mplus text-4xl">
        {formatScore(score, maxDecimalDigits)}
      </Column>
      <Row className="text-sm font-medium mt-2 text-trueGray-750">
        {completedAt
          ? formatMonthDayYear(completedAt, {
              zeroPad: false,
              spaceBetween: false,
              outputTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            })
          : 'Incomplete'}
      </Row>
    </Column>
  );
};
