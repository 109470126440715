import { useHistory, useLocation } from 'react-router-dom';
import { useCallback, useMemo } from 'react';

export function useSearchParams(): URLSearchParams {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
}

type FieldType = Record<string, string | number | boolean | null | undefined>;

type PatchSearchParams<T extends FieldType> = (
  fields: T,
  options?: { replace?: boolean },
) => void;

export function useSearchParamPatch<T extends FieldType>(): PatchSearchParams<
  T
> {
  const history = useHistory();
  return useCallback(
    (fields: T, { replace = false } = {}) => {
      const urlSearchParams = new URLSearchParams(history.location.search);
      for (const [key, value] of Object.entries(fields)) {
        if (value === null || value === undefined) {
          urlSearchParams.delete(key);
        } else {
          urlSearchParams.set(key, value.toString());
        }
      }
      if (replace) {
        history.replace({
          pathname: history.location.pathname,
          search: urlSearchParams.toString(),
        });
      } else {
        history.push({
          pathname: history.location.pathname,
          search: urlSearchParams.toString(),
        });
      }
    },
    [history],
  );
}
