import React, { ComponentPropsWithRef, FC } from 'react';
import { Button } from '../Button/Button';
import { lightbulb } from '../../images';

interface TeachingRecommendationsButtonProps
  extends ComponentPropsWithRef<'button'> {
  onClick(): void;
}

export const TeachingRecommendationsButton: FC<TeachingRecommendationsButtonProps> = ({
  className = '',
  ...rest
}) => (
  <Button
    className={`font-roboto text-light-blue-500 font-medium items-center ${className}`}
    {...rest}
  >
    <img src={lightbulb} alt="Recommendation" className="w-5 h-5 mr-3" />
    Teaching Recommendations
  </Button>
);
