import React, { FC } from 'react';
import { Column } from '../Column/Column';
import { checkBlue, headsBlue } from '../../images';

interface BapsCompletedStatsProps {
  totalStudents: number;
  totalCompletedBAPS: number;
  className?: string;
}

export const BapsCompletedStats: FC<BapsCompletedStatsProps> = ({
  totalCompletedBAPS,
  totalStudents,
  className = '',
  ...rest
}) => (
  <div
    className={`flex flex-row justify-between flex-wrap w-full ${className}`}
    {...rest}
  >
    <Column className="bg-white shadow-dashboardShadow rounded-xl w-36 h-full p-3 pb-4 justify-center">
      <img src={headsBlue} alt="Students" className="w-6 h-6 mb-2" />
      <Column>
        <div className="font-light text-sm font-roboto text-trueGray-750 mb-2">
          Total Students
        </div>
        <div className="font-light text-4.5xl text-light-blue-500">
          {totalStudents}
        </div>
      </Column>
    </Column>
    <Column className="bg-white shadow-dashboardShadow rounded-xl w-36 h-full p-3 pb-4">
      <img src={checkBlue} alt="Completed" className="w-6 h-6 mb-2" />
      <Column>
        <div className="font-light text-sm font-roboto text-trueGray-750 mb-2">
          BAPS Completed
        </div>
        <div className="font-light text-4.5xl text-light-blue-500">
          {totalCompletedBAPS}
        </div>
      </Column>
    </Column>
  </div>
);
