import { ButtonVariation } from '../types/ButtonVariation';

export function getStylesForButtonVariation(buttonVariation?: ButtonVariation) {
  if (buttonVariation) {
    if (buttonVariation === 'primary') {
      return 'bg-light-blue-500 text-white border border-light-blue-500';
    }
    if (buttonVariation === 'secondary') {
      return 'bg-transparent text-light-blue-500 border border-light-blue-500';
    }

    if (buttonVariation === 'disabled') {
      return 'bg-gray-450 text-white border border-gray-450';
    }

    if (buttonVariation === 'cancel') {
      return 'bg-transparent text-light-blue-500 border-none';
    }
  }
  return '';
}
