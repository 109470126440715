import React, { FC, useCallback, useState } from 'react';
import { StringOption } from '../../types/Option';
import { TextInput } from '../TextInput/TextInput';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';

interface SearchSelectorProps {
  className?: string;
  label: string;
  search: string;
  onSearchChanged(search: string): void;
  options: readonly StringOption[];
  onOptionSelected(option: StringOption): void;
  placeholder?: string;
}

export const SearchSelector: FC<SearchSelectorProps> = ({
  className = '',
  label,
  search,
  onSearchChanged,
  onOptionSelected,
  options,
  placeholder = 'Select',
  ...rest
}) => {
  const [open, setOpen] = useState(false);

  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleOptionSelected = useCallback(
    (option: StringOption) => {
      setOpen(false);
      onOptionSelected(option);
    },
    [onOptionSelected],
  );

  const ref = useOnClickOutside<HTMLDivElement>(onClickOutside);
  return (
    <div
      className={`flex flex-col w-82 font-roboto ${className}`}
      ref={ref}
      {...rest}
    >
      <label className="font-bold text-trueGray-900 mb-2">{label}</label>
      <TextInput
        value={search}
        onChange={(e) => onSearchChanged(e.target.value)}
        width="w-82"
        placeholder={placeholder}
        onFocus={() => setOpen(true)}
      />
      {open && (
        <div className="flex flex-col absolute bg-white w-82 h-56 top-32 shadow-dashboardShadow rounded-xl pt-4 pb-2 pl-3 pr-1.5 ">
          <ul className="divide-y divide-trueGray-150 w-full h-full overflow-y-auto">
            {options.map((option) => (
              <li key={option.id} className="w-46">
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    handleOptionSelected(option);
                  }}
                  className={`flex flex-row items-center w-full h-12 text-trueGray-750 hover:underline`}
                >
                  {option.value}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
