import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Column } from '../../Column/Column';
import { Button } from '../../Button/Button';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import { add } from '../../../images';

export const AssessmentInfoSection: FC<ComponentPropsWithoutRef<'div'>> = ({
  className,
  children,
  ...rest
}) => {
  const [open, toggleOpen] = useToggleState(true);
  return (
    <div
      className={`flex flex-col items-center fixed left-0 bottom-0 w-full flex-shrink-0 bg-white shadow-assessmentFooterShadow ${
        open ? 'h-auto' : 'h-15'
      }`}
      {...rest}
    >
      <Button
        onClick={toggleOpen}
        className="border border-light-blue-500 bg-white z-10 rounded-full h-12 w-12 justify-center items-center bottom-6"
      >
        <img
          src={add}
          alt={open ? 'Close' : 'Open'}
          className={`h-10 w-10 transition-transform transform ${
            open ? 'rotate-45' : 'rotate-0'
          }`}
        />
      </Button>
      {open && <Column className="pb-6">{children}</Column>}
    </div>
  );
};
