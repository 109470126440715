import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { AssessorDetail } from 'shared/lib/types/AssessorDetail';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getAssessor } from '../../api/assessor/getAssessor';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import {
  formatAssessorDetailRoute,
  formatSchoolDetailsRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { removeAssessorFromGroup } from '../../api/student-group/removeAssessorFromGroup';
import { RemoveAssessorFromClassConfirm } from '../../components/RemoveAssessorFromClassConfirm/RemoveAssessorFromClassConfirm';
import { showOkAlert } from '../../components/Alert/Alert';
import { RemoveConfirm } from '../../components/RemoveConfirm/RemoveConfirm';
import { deleteAssessor } from '../../api/assessor/deleteAssessor';

interface RemoveAssessorProps {
  assessorId: number;
}

export const RemoveAssessor: FC<RemoveAssessorProps> = ({
  assessorId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [assessor, setAssessor] = useState<AssessorDetail | null>(null);
  const history = useHistory();
  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setAssessor(null);
      }
      try {
        const teacher = await getAssessor(assessorId);
        if (!isCancelled()) {
          setAssessor(teacher);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to get teacher details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [assessorId],
  );

  const removeFromClass = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!assessor || loading) {
        return;
      }
      const { currentClass } = assessor;
      if (!currentClass) {
        await showOkAlert({
          title: 'Error',
          text: 'This teacher has no primary class.',
          theme: 'error',
        });
        return;
      }
      setLoading(true);
      try {
        await removeAssessorFromGroup(currentClass.id, assessor.id);
        history.replace(formatAssessorDetailRoute(assessor));
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to remove teacher from class: ${e.message}`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [assessor, loading, history],
  );

  const removeTeacher = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!assessor || loading) {
        return;
      }
      const { school } = assessor;
      if (!school) {
        await showOkAlert({
          title: 'Error',
          text: 'This teacher has no school assignments.',
          theme: 'error',
        });
        return;
      }
      try {
        setLoading(true);
        await deleteAssessor(assessor.id);
        history.replace(formatSchoolDetailsRoute(school));
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to remove teacher from school: ${e.message}`,
          theme: 'error',
        });
        setLoading(false);
        return;
      }
    },
    [history, loading, assessor],
  );

  return (
    <Column className="flex-grow pt-14 overflow-hidden" {...rest}>
      <PageTitle
        pageTitle={
          assessor ? `Remove ${formatFirstLast(assessor)}` : 'Remove Teacher'
        }
      />
      {loading || !assessor ? (
        <SpinnerOverlay />
      ) : (
        <Column className="pt-3">
          <Row className="mb-6">
            <BackButton onClick={() => history.goBack()} />
            <Row className="font-semibold text-xl self-center w-full justify-center pr-9">
              {assessor.school?.name}
            </Row>
          </Row>
          {assessor.currentClass ? (
            <RemoveAssessorFromClassConfirm
              teacher={assessor}
              onCancelled={() =>
                history.replace(formatAssessorDetailRoute(assessor))
              }
              onSubmit={removeFromClass}
            />
          ) : (
            <RemoveConfirm
              recordName={assessor.name}
              recordId={assessor.id}
              confirmButtonText="Remove Teacher"
              onCancelled={() => {
                history.replace(formatAssessorDetailRoute(assessor));
              }}
              removalType="Teacher"
              warningText={`You are about to Remove Teacher - ${
                assessor.name
              } - from ${
                assessor.school?.name ?? 'this school'
              }. Do you wish to continue?`}
              onSubmit={removeTeacher}
            />
          )}
        </Column>
      )}
    </Column>
  );
};
