import React, { FC, ReactNode } from 'react';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { noop } from 'shared/lib/utils/noop';
import { Row } from '../../Row/Row';
import { PillButton } from '../../PillButton/PillButton';
import { Column } from '../../Column/Column';

interface ActivityErrorStepProps {
  onNext(): void;
  errorTitle?: string;
  children: ReactNode;
}

export const ActivityErrorStep: FC<ActivityErrorStepProps> = ({
  onNext,
  errorTitle = 'An error occurred',
  children,
  ...rest
}) => (
  <AssessmentStepPage
    showBack={false}
    onBackClicked={noop}
    title={['Error', errorTitle]}
    activityProgress={{ sections: [] }}
    audio={null}
    onPauseClicked={noop}
    onAudioPlayed={noop}
    onShowAudioCompleteDismissed={noop}
    showAudioIncomplete={false}
    {...rest}
  >
    <Column className="w-full items-center">
      {children}
      <Row className="mt-24">
        <PillButton buttonVariation="primary">Continue</PillButton>
      </Row>
    </Column>
  </AssessmentStepPage>
);
