import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { useCallback, useEffect, useState } from 'react';
import {
  ActivityAnswer,
  buildAnswerId,
  ImageAnswerChoice,
  isImageAnswerChoice,
} from 'shared/lib/types/assessment/ActivityAnswer';
import { ActivityStepType } from 'shared/lib/constants/activity/ActivityStepType';
import {
  CorrectIncorrectAnswerChoices,
  INCORRECT,
} from 'shared/lib/constants/assessment/CorrectIncorrectAnswerChoices';
import { PresignedURLStatus } from 'shared/lib/constants/asset/PresignedURLStatus';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getPresignedUrl } from '../../api/presign/getPresignedUrl';
import { AssetItemResponse } from 'shared/lib/types/AssetItem';
import { ActivityStep } from 'shared/lib/types/activity/ActivityStep';
import { showStudentResponseAlert } from '../../components/Assessment/StudentResponseAlert/StudentResponseAlert';
import {
  NO_ACTIVITY_ANSWER,
  NO_ANSWER_ID,
} from 'shared/lib/constants/assessment/NoAnswer';

function getImageAnswers(
  activityStep: Pick<ActivityStep, 'stepId'>,
  assetItemResponses: AssetItemResponse[],
): ActivityAnswer[] {
  return assetItemResponses.map(({ picture, order, text, target }) => ({
    id: buildAnswerId(activityStep, { order }),
    image: {
      url: null,
      fileName: picture,
      alt: text,
      expiresAt: -1,
      status: PresignedURLStatus.LOADING,
      validFor: 0,
    },
    correct: target,
  }));
}

async function getSignedUrlsIfNeeded(answers: ReadonlyArray<ActivityAnswer>) {
  const imageAnswers = answers.filter(isImageAnswerChoice);
  const refreshed = await Promise.all(
    imageAnswers.map(
      async (imageAnswer): Promise<ImageAnswerChoice> => ({
        ...imageAnswer,
        image: {
          ...imageAnswer.image,
          ...(await getPresignedUrl(imageAnswer.image.fileName)),
        },
      }),
    ),
  );
  return answers.map((answer) => {
    const refreshedAnswer = refreshed.find(
      (refreshedAnswer) => refreshedAnswer.id === answer.id,
    );
    return refreshedAnswer ?? answer;
  });
}

interface UseActivityStepAnswersParams {
  takeState: Pick<AssessmentTakeState, 'currentItem'> | null;
  showStudentResponseModal: boolean;
  onStudentResponseSaved(answer: ActivityAnswer, response: string | null): void;
}

export function useActivityStepAnswers({
  takeState,
  onStudentResponseSaved,
  showStudentResponseModal,
}: UseActivityStepAnswersParams) {
  const [answers, setAnswers] = useState<ActivityAnswer[]>([]);
  const [selectedAnswer, setSelectedAnswer] = useState<ActivityAnswer | null>(
    null,
  );
  const [selectedAnswerHistory, setSelectedAnswerHistory] = useState<
    ActivityAnswer[]
  >([]);
  const [studentResponse, setStudentResponse] = useState<string | null>(null);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setAnswers([]);
        setSelectedAnswer(null);
      }

      if (!takeState) {
        return;
      }
      let activityAnswers: ActivityAnswer[] = [];
      const { currentItem } = takeState;
      const { activityStep, itemAsset, assessmentStepResult } = currentItem;
      const {
        answerId,
        studentResponse: existingStudentResponse,
      } = assessmentStepResult;
      const { stepType } = activityStep;
      if (
        stepType === ActivityStepType.TEST ||
        stepType === ActivityStepType.PRACTICE ||
        stepType === ActivityStepType.DEMO
      ) {
        if (itemAsset && itemAsset.responses) {
          activityAnswers = getImageAnswers(activityStep, itemAsset.responses);
        } else {
          activityAnswers = [...CorrectIncorrectAnswerChoices];
        }
      }
      const wiredAnswers = await getSignedUrlsIfNeeded(activityAnswers);
      if (!isCancelled()) {
        setSelectedAnswerHistory([]);
        setAnswers(wiredAnswers);
        // Select the previously selected response if it exists.
        const initialSelectedAnswer =
          answerId === NO_ANSWER_ID
            ? NO_ACTIVITY_ANSWER
            : wiredAnswers.find((answer) => answer.id === answerId) ?? null;
        setSelectedAnswer(initialSelectedAnswer);
        setSelectedAnswerHistory(
          initialSelectedAnswer ? [initialSelectedAnswer] : [],
        );
        // Set the existing student response.
        setStudentResponse(existingStudentResponse);
      }
    },
    [takeState],
  );

  const onAnswerSelected = useCallback(
    async (answer: ActivityAnswer) => {
      // If they re-click the same answer, clear it.
      if (selectedAnswer?.id === answer.id) {
        setSelectedAnswer(null);
        setStudentResponse(null);
        return;
      }

      /**
       * If the answer is incorrect, and the selected answer is the INCORRECT button, require
       * the student's response to be recorded.
       */
      if (
        showStudentResponseModal &&
        !answer.correct &&
        answer.id === INCORRECT.id
      ) {
        const { value, cancel } = await showStudentResponseAlert();
        if (cancel) {
          return;
        }
        onStudentResponseSaved(answer, value);
      }
      setSelectedAnswer(answer);
    },
    [selectedAnswer, showStudentResponseModal, onStudentResponseSaved],
  );

  useEffect(() => {
    if (selectedAnswer !== null) {
      setSelectedAnswerHistory((current) => [...current, selectedAnswer]);
    }
  }, [selectedAnswer]);

  return {
    answers,
    selectedAnswer,
    onAnswerSelected,
    studentResponse,
    selectedAnswerHistory,
  };
}
