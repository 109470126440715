import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useLoggedInUserContext } from '../contexts/userContext';
import { AuthenticatedPage } from '../components/AuthenticatedPage/AuthenticatedPage';
import { students } from 'shared/lib/constants/routes/assessorRoutes';
import { Students } from '../pages/Students/Students';
import { ClassRoutes } from './sub-routes/ClassRoutes';
import { StudentRoutes } from './sub-routes/StudentRoutes';
import {
  assessmentInstructions,
  assessmentResults,
  beginAssessment,
  takeAssessment,
} from 'shared/lib/constants/routes/assessmentRoutes';
import { BeginAssessmentPage } from '../pages/BeginAssessmentPage/BeginAssessmentPage';
import { TakeAssessmentPage } from '../pages/TakeAssessmentPage/TakeAssessmentPage';
import { languages } from 'shared/lib/constants/Language';
import { editAccount, home } from 'shared/lib/constants/routes/commonRoutes';
import { AssessmentTakeInstructionsPage } from '../pages/AssessmentTakeInstructionsPage/AssessmentTakeInstructionsPage';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { Account } from '../pages/Account/Account';
import { logout } from 'shared/lib/constants/routes/userRoutes';
import { Logout } from '../pages/Logout/Logout';
import { AssessmentResultsPage } from '../pages/AssessmentResultsPage/AssessmentResultsPage';

export function AssessorRoutes() {
  const { user } = useLoggedInUserContext();
  return (
    <BrowserRouter>
      <AuthenticatedPage user={user}>
        <Switch>
          <Route exact path={students}>
            <Students />
          </Route>
          <Route path="/class/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const classId = +match.params.id;
              if (Number.isNaN(classId)) {
                return <Redirect to={students} />;
              }
              return <ClassRoutes studentGroupId={classId} />;
            }}
          </Route>
          <Route path="/student/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const studentId = +match.params.id;
              if (Number.isNaN(studentId)) {
                return <Redirect to={students} />;
              }
              return <StudentRoutes studentId={studentId} />;
            }}
          </Route>
          <Route exact path={beginAssessment}>
            <BeginAssessmentPage />
          </Route>
          <Route exact path={takeAssessment}>
            <TakeAssessmentPage />
          </Route>
          <Route exact path={assessmentInstructions}>
            {({ match }) => {
              if (!match) {
                return null;
              }
              const language = match.params.language;
              if (!language || !languages.includes(language)) {
                return <Redirect to={home} />;
              }
              return <AssessmentTakeInstructionsPage language={language} />;
            }}
          </Route>
          <Route exact path={assessmentResults}>
            {({ match }) => {
              if (!match) {
                return null;
              }
              const bapId = +match.params.bapId;
              if (Number.isNaN(bapId)) {
                return <Redirect to={home} />;
              }
              return <AssessmentResultsPage bapId={bapId} />;
            }}
          </Route>
          <Route exact path={home}>
            <DashboardPage />
          </Route>
          <Route exact path={editAccount}>
            <Account />
          </Route>
          <Route exact path={logout}>
            <Logout />
          </Route>
          <Redirect to={home} />{' '}
        </Switch>
      </AuthenticatedPage>
    </BrowserRouter>
  );
}
