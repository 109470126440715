import { useSearchParams } from '../useSearchParams';
import { useHistory } from 'react-router-dom';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { login } from 'shared/lib/constants/routes/unauthenticatedRoutes';
import { acceptInvite } from '../../api/user/acceptInvite';

interface AcceptInviteValues {
  password: string;
  setPassword: (password: string) => void;
  passwordConfirm: string;
  setPasswordConfirm: (passwordConfirm: string) => void;

  /**
   * Whether or not the form is submitting
   */
  loading: boolean;

  /**
   * An error while validating or submitting. Defaults to an empty string.
   */
  error: string;

  /**
   * Attempt to submit.
   */
  onSubmit: (event: FormEvent) => Promise<void>;
}

interface AcceptInviteParams {
  onSuccess(): void;
}

export function useAcceptInvite({
  onSuccess,
}: AcceptInviteParams): AcceptInviteValues {
  const params = useSearchParams();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (!params.has('code') || !params.has('email')) {
      history.replace(login);
    }
    setPassword('');
    setPasswordConfirm('');
    setError('');
  }, [params, history]);

  const { email, code } = useMemo(
    () => ({
      code: params.get('code') ?? '',
      email: params.get('email') ?? '',
    }),
    [params],
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();

      if (loading) {
        return;
      }

      setError('');
      if (password.length < 6) {
        setError('Password must be at least 6 characters long.');
        return;
      }

      if (password !== passwordConfirm) {
        setError('Passwords dont match.');
        return;
      }

      setLoading(true);
      try {
        await acceptInvite({ email, password, token: code });
        onSuccess();
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [password, passwordConfirm, email, code, loading, onSuccess],
  );

  return {
    password,
    passwordConfirm,
    setPassword,
    setPasswordConfirm,
    onSubmit,
    error,
    loading,
  };
}
