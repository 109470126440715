import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { useMemo } from 'react';
import { useNameSearch } from '../useNameSearch';

interface UseSchoolDashboardStudentsParams {
  students: ReadonlyArray<DashboardStudent>;
  search: string;
  selectedClass: StudentGroup | null;
}

export function useSchoolDashboardStudents({
  students,
  search,
  selectedClass,
}: UseSchoolDashboardStudentsParams): ReadonlyArray<DashboardStudent> {
  const searchedStudents = useNameSearch({ list: students, search });

  return useMemo(() => {
    if (!selectedClass) {
      return searchedStudents;
    }
    return searchedStudents.filter(({ studentGroups }) =>
      studentGroups.some((g) => g.id === selectedClass.id),
    );
  }, [selectedClass, searchedStudents]);
}
