import React, { FC } from 'react';
import { ActivityPreTestStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { Column } from '../../Column/Column';
import { ActivityTextDisplay } from '../ActivityTextDisplay/ActivityTextDisplay';
import { AssessmentOrderedListItem } from '../AssessmentOrderedListItem/AssessmentOrderedListItem';
import { Row } from '../../Row/Row';
import { PillButton } from '../../PillButton/PillButton';

export const ActivityPreTestStep: FC<ActivityPreTestStepProps> = ({
  activityStep,
  onNext,
  ...rest
}) => {
  const { intro, items, outro } = activityStep;
  const [headingItem, ...individualItems] = items;
  return (
    <AssessmentStepPage {...rest} showBack audio={null}>
      <Column className="w-full h-full flex-grow overflow-y-auto pr-4 max-h-assessment-page">
        {intro && (
          <Column className="pb-9 short:pb-4">
            {intro.map((introItem, idx) => (
              <ActivityTextDisplay
                activityText={introItem}
                key={`intro_${idx}`}
                className="pb-3"
              />
            ))}
          </Column>
        )}
        <Column>
          <ActivityTextDisplay
            activityText={headingItem}
            className="pb-9 short:pb-4"
          />
          {individualItems.map((item, idx) => (
            <AssessmentOrderedListItem
              className="items-baseline pb-9 short:pb-4"
              order={idx + 1}
            >
              <ActivityTextDisplay activityText={item} />
            </AssessmentOrderedListItem>
          ))}
        </Column>
        {outro &&
          outro.map((outroItem, idx) => (
            <ActivityTextDisplay
              activityText={outroItem}
              key={`outro_${idx}`}
              className="pb-6"
            />
          ))}
        <Row
          className={`w-full justify-center short:mt-0 mt-20 ${
            outro === null ? 'pt-4' : ''
          }`}
        >
          <PillButton buttonVariation="primary" onClick={onNext}>
            Continue
          </PillButton>
        </Row>
      </Column>
    </AssessmentStepPage>
  );
};
