import React, { ComponentPropsWithoutRef, FC } from 'react';
import { AssessorDetail } from 'shared/lib/types/AssessorDetail';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';

interface TeacherDetailProps extends ComponentPropsWithoutRef<'div'> {
  teacher: AssessorDetail;
}

export const TeacherDetail: FC<TeacherDetailProps> = ({
  teacher,
  className = '',
  ...rest
}) => {
  const { createdBy, currentClass } = teacher;

  return (
    <Column {...rest}>
      <Row className="justify-between">
        <Column className="font-roboto">
          <Row className="h-9 items-center">
            <Row className="font-bold text-trueGray-750 w-48">Created by</Row>
            <Row className="text-trueGray-450">
              {createdBy ? formatFirstLast(createdBy) : 'N/A'}
            </Row>
          </Row>
          <Row className="h-9 items-center">
            <Row className="font-bold text-trueGray-750 w-48">
              Current Class
            </Row>
            <Row className="text-trueGray-450">
              {currentClass ? (
                <Row>
                  {currentClass.name}
                  {currentClass.school ? ` | ${currentClass.school.name}` : ''}
                </Row>
              ) : (
                <Row>N/A</Row>
              )}
            </Row>
          </Row>
        </Column>
      </Row>
    </Column>
  );
};
