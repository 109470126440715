import { ComponentPropsWithoutRef, FC } from 'react';

export const DirectionArrowIcon: FC<ComponentPropsWithoutRef<'svg'>> = (
  props,
) => (
  <svg width="8px" height="14px" viewBox="0 0 8 14" version="1.1" {...props}>
    <g
      id="Color_7_26_21"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Icon_InstructionalLevels"
        transform="translate(-426.000000, -437.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g id="Group" transform="translate(272.000000, 351.000000)">
          <g
            id="straight_black_24dp-copy-9"
            transform="translate(158.000000, 93.000000) rotate(-180.000000) translate(-158.000000, -93.000000) translate(154.000000, 86.000000)"
          >
            <path
              d="M3,3.83 L2.11722046,4.70722747 C1.72627302,5.09571612 1.09471998,5.09471998 0.705,4.705 C0.315639251,4.31563925 0.315639251,3.68436075 0.705,3.295 L3.29289322,0.707106781 C3.68341751,0.316582489 4.31658249,0.316582489 4.70710678,0.707106781 L7.295,3.295 C7.68436075,3.68436075 7.68436075,4.31563925 7.295,4.705 C6.90528002,5.09471998 6.27372698,5.09571612 5.88277954,4.70722747 L5,3.83 L5,3.83 L5,13 C5,13.5522847 4.55228475,14 4,14 C3.44771525,14 3,13.5522847 3,13 L3,3.83 L3,3.83 Z"
              id="Path"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
