import { FC, FormEvent, ReactNode, useCallback, MouseEvent } from 'react';
import { twMerge } from 'tailwind-merge';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import { Option } from '../../types/Option';
import { Button } from '../Button/Button';
import { blueTriangle } from '../../images';
import './Dropdown.css';
import { Column } from '../Column/Column';
import { CheckIcon } from '../CheckIcon/CheckIcon';

interface DropdownProps {
  options: ReadonlyArray<Option>;
  onSelected(option: Option): void;
  selected?: Option | null;
  placeholder?: ReactNode;
  disabled?: boolean;
  className?: string;
  withCheckIcon?: boolean;
}

export const Dropdown: FC<DropdownProps> = ({
  options,
  selected,
  onSelected,
  placeholder = <>&nbsp;</>,
  className,
  disabled,
  withCheckIcon,
  ...rest
}) => {
  const [open, toggleOpen, setOpen] = useToggleState(false);
  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const rootRef = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  const handleSelect = useCallback(
    (option: Option, e: MouseEvent<HTMLButtonElement>) => {
      onSelected(option);
      setOpen(false);
    },
    [onSelected, setOpen],
  );

  const handleToggle = useCallback(
    (e?: FormEvent) => {
      e?.preventDefault();
      toggleOpen();
    },
    [toggleOpen],
  );

  return (
    <div
      ref={rootRef}
      className={twMerge(
        'flex flex-col h-15 w-80 justify-center rounded border border-light-blue-500 bg-white font-roboto text-sm text-trueGray-900 font-medium',
        className,
      )}
      {...rest}
    >
      <Button
        className="w-full h-full justify-between items-center pl-6 pr-5"
        onClick={handleToggle}
        disabled={disabled}
      >
        <span className="whitespace-nowrap overflow-hidden overflow-ellipsis">
          {selected?.value ?? placeholder}
        </span>
        <img
          src={blueTriangle}
          alt={open ? 'Close' : 'Open'}
          className={twMerge(
            'relative h-6 w-6 transition-transform transform duration-500',
            open && 'rotate-180',
          )}
        />
      </Button>
      {open && options.length > 0 && (
        <Column>
          <ul className="absolute px-6 py-3 top-1.5 bg-white rounded-xl shadow-dashboardShadow overflow-y-auto w-full dropdown-menu z-10">
            {options.map(({ id, value }, idx) => {
              const itemSelected = selected?.id === id;
              return (
                <li
                  key={id}
                  className={twMerge(
                    'w-full pb-4 pt-4',
                    idx !== 0 && 'option-separator',
                  )}
                >
                  <Button
                    className={twMerge(
                      'font-roboto font-normal w-full pb-1.5 pt-1.5 hover:underline',
                      itemSelected &&
                        'underline font-extrabold text-light-blue-500',
                    )}
                    onClick={(e) => handleSelect({ id, value }, e)}
                  >
                    {itemSelected && withCheckIcon && (
                      <CheckIcon
                        className="mr-4"
                        polyLineClassName={twMerge(
                          itemSelected && 'stroke-current',
                        )}
                        strokeWidth={4}
                      />
                    )}
                    {value}
                  </Button>
                </li>
              );
            })}
          </ul>
        </Column>
      )}
    </div>
  );
};
