import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { DistrictOrg, SchoolOrg } from 'shared/lib/types/Org';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getSchool } from '../../api/school/getSchool';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { RemoveSchoolConfirm } from '../../components/RemoveSchoolConfirm/RemoveSchoolConfirm';
import { useHistory } from 'react-router-dom';
import { BackButton } from '../../components/BackButton/BackButton';
import {
  formatDistrictDetailsRoute,
  schools,
} from 'shared/lib/constants/routes/administratorRoutes';
import { deleteSchool } from '../../api/school/deleteSchool';
import { getDistrict } from '../../api/district/getDistrict';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { Row } from '../../components/Row/Row';
import { useLoggedInAdminContext } from '../../contexts/userContext';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { showOkAlert } from '../../components/Alert/Alert';

interface RemoveSchoolProps {
  schoolId: number;
}

export const RemoveSchool: FC<RemoveSchoolProps> = ({ schoolId, ...rest }) => {
  const history = useHistory();
  const [school, setSchool] = useState<SchoolOrg | null>(null);
  const [district, setDistrict] = useState<DistrictOrg | null>(null);
  const [loading, setLoading] = useState(true);
  const { adminLevel } = useLoggedInAdminContext();
  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setSchool(null);
        setLoading(true);
      }
      try {
        const schoolDetails = await getSchool(schoolId);
        const schoolDistrict = await getDistrict(schoolDetails.parent);
        if (!isCancelled()) {
          setSchool(schoolDetails);
          setDistrict(schoolDistrict);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to load school details: ${e.message}`,
          theme: 'error',
        });
        history.replace(schools);
      }
    },
    [schoolId, history],
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        await deleteSchool(schoolId);
        if (adminLevel === AdminLevel.SUPER && district) {
          history.replace(formatDistrictDetailsRoute(district.id));
        } else {
          history.replace(schools);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to delete school: ${e.message}`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [schoolId, history, adminLevel, district],
  );

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || !school || !district ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
                <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
                  {district?.name}
                </Row>
                <div>&nbsp;</div>
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {school?.name ?? ''}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    Remove School
                  </Row>
                </Column>
              </Row>
            </Column>
          </Column>
          <MainPanel className="pr-8 lg:pr-15">
            <RemoveSchoolConfirm
              school={school}
              district={district}
              onSubmit={onSubmit}
              onCancelled={() => history.goBack()}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
