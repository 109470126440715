import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import './Page.css';

export interface PageProps extends ComponentPropsWithoutRef<'div'> {
  sidePanel?: ReactNode;
}

export const Page: FC<PageProps> = ({
  className = '',
  children,
  sidePanel,
  ...rest
}) => (
  <Row
    className={`min-w-screen h-screen page ${
      !sidePanel /* Change to a column layout if there is not a side panel */
        ? 'flex-col'
        : ''
    } ${className}`}
    {...rest}
  >
    {sidePanel && (
      <div className="fixed flex-col h-screen bg-white overflow-hidden items-center flex-shrink page-side-panel">
        {sidePanel}
      </div>
    )}
    <Column
      className={`w-full min-h-supported overflow-hidden print:overflow-visible print:block ${
        sidePanel ? 'page-with-panel' : ''
      }`}
    >
      {children}
    </Column>
  </Row>
);
