import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { useEditAccountForm } from '../../hooks/user/useEditAccountForm';
import { useLoggedInUserContext } from '../../contexts/userContext';
import { EditAccountForm } from '../../components/EditAccountForm/EditAccountForm';
import isEmail from 'validator/lib/isEmail';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { updateAccount } from '../../api/account/updateAccount';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { Row } from '../../components/Row/Row';
import { useHistory } from 'react-router-dom';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { showOkAlert } from '../../components/Alert/Alert';

export const Account: FC = (props) => {
  const { user, refreshUser } = useLoggedInUserContext();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { account, ...restOfFormProps } = useEditAccountForm(user);
  const history = useHistory();

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading) {
        return;
      }
      setError('');
      const { name, email } = account;
      if (!name.trim()) {
        setError('Name is required.');
        return;
      }
      if (!email.trim() || !isEmail(email)) {
        setError('Invalid email.');
        return;
      }

      setLoading(true);
      try {
        await updateAccount({ name, email });
        await refreshUser();
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to update account: ${e.message}`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [account, refreshUser, loading],
  );

  return (
    <Column className="flex-grow pt-14 overflow-hidden" {...props}>
      <PageTitle pageTitle="My Account" />
      {loading && <SpinnerOverlay />}
      <Column className="h-full pt-4">
        <Column className="pl-8 lg:pl-15 pr-10 pb-8">
          <Row className="pl-9 pb-2.5 font-roboto text-2xl text-trueGray-750">
            Edit Account
          </Row>
        </Column>
        <MainPanel>
          <EditAccountForm
            account={account}
            error={error}
            onSubmit={onSubmit}
            className="pl-6 mt-6"
            onCancelled={() => history.goBack()}
            {...restOfFormProps}
          />
        </MainPanel>
      </Column>
    </Column>
  );
};
