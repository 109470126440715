import { createContext, useContext } from 'react';
import { User } from 'shared/lib/types/User';
import { Admin } from 'shared/lib/types/Admin';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';

export interface UserContextValue {
  user: User | null;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  refreshUser: () => Promise<void>;
}

export const UserContext = createContext<UserContextValue>({
  user: null,
  async login() {},
  async logout() {},
  async refreshUser() {},
});

export function useLoggedInUserContext(): Omit<
  UserContextValue,
  'user' | 'login'
> & {
  user: User;
} {
  const { user, login, ...restOfContext } = useContext(UserContext);

  if (!user) {
    throw new Error('useUser must be called after ensuring logged in');
  }

  return {
    user,
    ...restOfContext,
  };
}

export function useLoggedInAdminContext(): Omit<
  UserContextValue,
  'user' | 'login'
> & {
  user: Admin;
  adminLevel: AdminLevel;
} {
  const { user, ...rest } = useLoggedInUserContext();

  if (!isAdmin(user)) {
    throw new Error('useLoggedInAdminContext must be called only for admins');
  }

  const { role } = user;
  const { adminLevel } = role;
  return {
    user,
    adminLevel,
    ...rest,
  };
}

export function useUserContext() {
  return useContext(UserContext);
}
