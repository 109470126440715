import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Admin } from 'shared/lib/types/Admin';
import { Org } from 'shared/lib/types/Org';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';

interface AdminDetailProps extends ComponentPropsWithoutRef<'div'> {
  admin: Admin;
  assignedOrganization: Org | null;
}

export const AdminDetail: FC<AdminDetailProps> = ({
  admin,
  assignedOrganization,
  className = '',
  ...rest
}) => {
  const { createdBy, role } = admin;
  const { adminLevel } = role;

  return (
    <Column {...rest}>
      <Column className="font-roboto">
        <Row className="h-9 items-center">
          <Row className="font-bold text-trueGray-750 w-48">Created by</Row>
          <Row className="text-trueGray-450">
            {createdBy ? formatFirstLast(createdBy) : 'N/A'}
          </Row>
        </Row>
        {adminLevel !== AdminLevel.SUPER && (
          <Row className="h-9 items-center">
            <Row className="font-bold text-trueGray-750 w-48">
              Assigned
              {adminLevel === AdminLevel.DISTRICT ? ' District' : ' School'}
            </Row>
            {assignedOrganization && (
              <AssignedOrganizationInfo
                assignedOrganization={assignedOrganization}
              />
            )}
          </Row>
        )}
      </Column>
    </Column>
  );
};

const AssignedOrganizationInfo: FC<{ assignedOrganization: Org }> = ({
  assignedOrganization,
}) => {
  const { name, parentOrg } = assignedOrganization;
  return (
    <Row className="text-trueGray-450">
      {name}
      {parentOrg ? ` | ${parentOrg.name}` : ''}
    </Row>
  );
};
