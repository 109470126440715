import { ComponentPropsWithRef, FC, useState } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { formatStudentDetailsRoute } from 'shared/lib/constants/routes/userRoutes';
import { Language } from 'shared/lib/constants/Language';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { StudentSortField } from '../../types/student/StudentSort';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { headBlue, infoCircleFilled } from '../../images';
import { PercentileButton } from '../PercentileButton/PercentileButton';
import { DashboardListItem } from '../DashboardList/DashboardList';
import { PillButton } from '../PillButton/PillButton';
import { getAtRiskColor, getAtRiskLabel } from '../../constants/AtRiskStatus';
import { InstructionLevelInfoModal } from '../InstructionLevelInfoModal/InstructionLevelInfoModal';

const textCellPadding = 'pb-8';

interface StudentListProps extends ComponentPropsWithRef<'div'> {
  students: ReadonlyArray<DashboardStudent>;
  currentClass?: StudentGroup | null;
  sortField: StudentSortField;
  onResumeClicked(studentId: number, languages: ReadonlyArray<Language>): void;
  onStartClicked(studentId: number): void;
  onTeachingRecommendationClicked(student: DashboardStudent): void;
}

function getHeadingId(headingKey: StudentSortField) {
  return `studentList-${headingKey}`;
}

export const StudentList: FC<StudentListProps> = ({
  className,
  students,
  currentClass,
  sortField,
  onResumeClicked,
  onStartClicked,
  onTeachingRecommendationClicked,
  ...rest
}) => {
  const [showLevelModal, setShowLevelModal] = useState(false);

  const getHeadingProps = (
    headingKey: StudentSortField,
    headingClasses: string,
  ) => ({
    id: getHeadingId(headingKey),
    className: twMerge(
      headingClasses,
      sortField === headingKey && 'font-bold text-light-blue-500',
    ),
  });

  return (
    <Column
      className={twMerge('max-h-full pb-8 print:block', className)}
      {...rest}
    >
      <Row className="font-roboto text-trueGray-750 font-light w-full justify-between items-end pb-2 pr-4 pl-5">
        <div {...getHeadingProps(StudentSortField.NAME, 'w-56')}>Name</div>
        <div
          {...getHeadingProps(StudentSortField.RISK_STATUS, 'text-center w-24')}
        >
          Risk Status
        </div>
        <Column className="items-center w-64 text-center">
          <div className="relative flex items-center mb-1 font-bold">
            <button
              onClick={() => setShowLevelModal(true)}
              className="absolute w-2.5 h-2.5 right-full mr-1.5 print:hidden"
            >
              <img src={infoCircleFilled} alt="Show Instructional Level info" />
            </button>
            Instructional Level
          </div>
          <Row className="w-full justify-center gap-x-3">
            <div
              {...getHeadingProps(
                StudentSortField.INSTRUCTION_LEVEL_ENGLISH,
                'w-14',
              )}
            >
              English
            </div>
            <div
              {...getHeadingProps(
                StudentSortField.INSTRUCTION_LEVEL_SPANISH,
                'w-14',
              )}
            >
              Spanish
            </div>
          </Row>
        </Column>
        <Column className="items-center w-64 text-center">
          <div className="mb-1 font-bold">Recent Age-Adjusted Percentile</div>
          <Row className="w-full justify-center gap-x-3">
            <div
              {...getHeadingProps(StudentSortField.PERCENTILE_ENGLISH, 'w-14')}
            >
              English
            </div>
            <div
              {...getHeadingProps(StudentSortField.PERCENTILE_SPANISH, 'w-14')}
            >
              Spanish
            </div>
          </Row>
        </Column>
      </Row>
      {students.map((student) => {
        const { ageAdjustedPercentileReport } = student;
        return (
          <DashboardListItem
            key={student.id}
            className="justify-between break-inside-avoid"
          >
            <Link
              to={{
                pathname: formatStudentDetailsRoute(student),
                state: { sourceClassId: currentClass?.id },
              }}
              aria-labelledby={getHeadingId(StudentSortField.NAME)}
              className={`flex items-center w-56 ${textCellPadding}`}
            >
              <img src={headBlue} alt="Student" className="w-6 h-6 mr-2" />
              <span
                className="whitespace-nowrap overflow-hidden overflow-ellipsis"
                title={formatFirstLast(student)}
              >
                {formatFirstLast(student)}
              </span>
            </Link>
            <Row
              aria-labelledby={getHeadingId(StudentSortField.RISK_STATUS)}
              className={twMerge(
                `text-sm justify-center w-24 ${textCellPadding}`,
                getAtRiskColor(ageAdjustedPercentileReport, true),
              )}
            >
              {getAtRiskLabel(ageAdjustedPercentileReport, true)}
            </Row>
            <Row
              className={`items-center justify-center w-64 gap-x-3 text-center text-trueGray-750 font-normal ${textCellPadding}`}
            >
              <Column
                aria-labelledby={getHeadingId(
                  StudentSortField.INSTRUCTION_LEVEL_ENGLISH,
                )}
                className="w-14"
              >
                {ageAdjustedPercentileReport?.englishPercentile
                  ?.instructionLevel ?? 'N/a'}
              </Column>
              <Column
                aria-labelledby={getHeadingId(
                  StudentSortField.INSTRUCTION_LEVEL_SPANISH,
                )}
                className="w-14"
              >
                {ageAdjustedPercentileReport?.spanishPercentile
                  ?.instructionLevel ?? 'N/a'}
              </Column>
            </Row>
            <Row className="items-center justify-center w-64 gap-x-3">
              <PercentileButton
                aria-labelledby={getHeadingId(
                  StudentSortField.PERCENTILE_ENGLISH,
                )}
                percentile={
                  ageAdjustedPercentileReport?.englishPercentile ?? null
                }
                onResumeClicked={() =>
                  onResumeClicked(student.id, [Language.ENGLISH])
                }
                className="w-14"
              />
              <PercentileButton
                aria-labelledby={getHeadingId(
                  StudentSortField.PERCENTILE_SPANISH,
                )}
                percentile={
                  ageAdjustedPercentileReport?.spanishPercentile ?? null
                }
                onResumeClicked={() =>
                  onResumeClicked(student.id, [Language.SPANISH])
                }
                className="w-14"
              />
            </Row>
            <PillButton
              onClick={() => onStartClicked(student.id)}
              className="absolute left-0 bottom-0 bg-lime-350 rounded-tl-none rounded-tr-xl rounded-bl-xl rounded-br-none h-8 print:hidden"
            >
              Start New BAPS
            </PillButton>
          </DashboardListItem>
        );
      })}
      {showLevelModal && (
        <InstructionLevelInfoModal
          onClickOutside={() => setShowLevelModal(false)}
          overlayClassName="print:hidden"
        />
      )}
    </Column>
  );
};
