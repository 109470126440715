import React, { FC } from 'react';
import { AlertModal } from '../../AlertModal/AlertModal';
import { Language } from 'shared/lib/constants/Language';
import showModal from '@emberex/react-utils/lib/showModal';

interface LanguageSelectedAlertProps {
  language: Language;
  resume: boolean;
  onConfirmClicked(): void;
  onCancelClicked(): void;
}

export const LanguageSelectedAlert: FC<LanguageSelectedAlertProps> = ({
  language,
  resume,
  ...rest
}) => (
  <AlertModal showConfirm showCancel showExit {...rest}>
    <h2 className="font-mplus text-light-blue-500 text-2xl font-extrabold">
      {resume ? 'Resume' : 'Start'} BAPS
    </h2>
    <p className="font-roboto font-bold mt-9">
      You are about to {resume ? 'resume' : 'start'} {language.toUpperCase()}.
      Do you wish to confirm?
    </p>
  </AlertModal>
);

export async function showLanguageSelectAlert(
  props: Omit<
    LanguageSelectedAlertProps,
    'onCancelClicked' | 'onConfirmClicked'
  >,
): Promise<boolean> {
  return showModal<boolean>((resolve) => (
    <LanguageSelectedAlert
      onConfirmClicked={() => resolve(true)}
      onCancelClicked={() => resolve(false)}
      {...props}
    />
  ));
}
