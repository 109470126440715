import { FC, useEffect } from 'react';

interface PageTitleProps {
  pageTitle: string;
}

export const PageTitle: FC<PageTitleProps> = ({ pageTitle }) => {
  useEffect(() => {
    const original = document.title;
    document.title = `BAPS${pageTitle ? ` - ${pageTitle}` : ''}`;
    return () => {
      document.title = original;
    };
  }, [pageTitle]);

  return null;
};
