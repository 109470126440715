import React, { FC, useMemo } from 'react';
import { Column } from '../../components/Column/Column';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { BackButton } from '../../components/BackButton/BackButton';
import { Row } from '../../components/Row/Row';
import { formatRemoveAdminRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { useAdminEditor } from '../../hooks/admin/useAdminEditor';
import { EditAdminForm } from '../../components/EditAdminForm/EditAdminForm';
import { useHistory } from 'react-router-dom';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { PillButton } from '../../components/PillButton/PillButton';
import { useResendInvite } from '../../hooks/user/useResendInvite';

interface AdminEditorProps {
  orgId: number | null;
  userId: number | null;
  adminLevel: AdminLevel;
}

export const AdminEditor: FC<AdminEditorProps> = ({
  orgId,
  userId,
  adminLevel,
  ...rest
}) => {
  const history = useHistory();
  const {
    editableAdmin,
    org,
    loading,
    admin,
    ...adminFormProps
  } = useAdminEditor({ userId, adminLevel, orgId });

  const mainHeading = useMemo(() => {
    if (admin) {
      return formatFirstLast(admin);
    }

    if (adminLevel === AdminLevel.SUPER) {
      return 'All Districts';
    }

    return org?.name ?? '';
  }, [adminLevel, admin, org]);

  const formattedLevel = useMemo(() => {
    return `${capitalizeFirst(adminLevel)} Admin`;
  }, [adminLevel]);

  const { reinviteUser, sendPending } = useResendInvite();

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle
            pageTitle={`${
              admin ? `Edit ${formatFirstLast(admin)}` : `Add ${formattedLevel}`
            }`}
          />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750 items-center">
                    {mainHeading}
                    {admin?.pending && (
                      <div className="text-light-blue-500 font-roboto font-normal text-base ml-3">
                        (Pending)
                      </div>
                    )}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    {admin ? `Edit ${formattedLevel}` : `Add ${formattedLevel}`}
                  </Row>
                </Column>
                {admin && (
                  <Row className="items-center">
                    {admin.pending && (
                      <PillButton
                        buttonVariation="primary"
                        className="mr-6"
                        onClick={() => reinviteUser(admin)}
                      >
                        Resend Invite
                      </PillButton>
                    )}
                    <ActionLink
                      variant="remove"
                      replace
                      to={formatRemoveAdminRoute(admin.id)}
                    >
                      Remove User
                    </ActionLink>
                  </Row>
                )}
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <EditAdminForm
              admin={editableAdmin}
              orgName={org?.name ?? ''}
              adminLevel={adminLevel}
              editMode={admin !== null}
              onCancelled={() => history.goBack()}
              className="pt-6 pl-9"
              {...adminFormProps}
            />
          </MainPanel>
          {sendPending && <SpinnerOverlay opaque />}
        </Column>
      )}
    </Column>
  );
};
