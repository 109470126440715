import { useCallback, useMemo } from 'react';
import { BAPWithAssessments, BAPWithFullData } from 'shared/lib/types/BAP';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { StudentGroupEnrollmentCounts } from 'shared/lib/types/student-group/StudentGroupEnrollmentCounts';
import { useSearch } from '../useSearch';
import { useSchoolDashboardStudents } from '../school/useSchoolDashboardStudents';
import { useStudentGroupSelect } from '../student-group/useStudentGroupSelect';
import { showTeachingRecommendationsModal } from '../../components/TeachingRecommendationsModal/TeachingRecommendationsModal';
import { useStudentListSort } from './useStudentListSort';

interface UseAssessorStudentsParams {
  students: ReadonlyArray<DashboardStudent>;
  classes: ReadonlyArray<StudentGroup>;
  studentGroupEnrollmentCounts: ReadonlyArray<StudentGroupEnrollmentCounts>;
  inProgressBAPs: ReadonlyArray<BAPWithFullData>;
  completedBAPs: ReadonlyArray<BAPWithAssessments>;
}

export function useStudentsDashboard({
  students,
  classes,
  studentGroupEnrollmentCounts,
  completedBAPs,
  inProgressBAPs,
}: UseAssessorStudentsParams) {
  const { onSearchChanged, search } = useSearch();

  const {
    selectedClass,
    selectedClassOption,
    classOptions,
    onClassSelect,
  } = useStudentGroupSelect({ classes, studentGroupEnrollmentCounts });

  const classStudents = useSchoolDashboardStudents({
    students,
    search,
    selectedClass,
  });

  const {
    sortedStudents,
    sortField,
    sortOrder,
    onSortChanged,
  } = useStudentListSort(classStudents);

  const filteredCompletedBAPs = useMemo(() => {
    return completedBAPs.filter((bap) =>
      sortedStudents.some((student) => student.id === bap.studentId),
    );
  }, [completedBAPs, sortedStudents]);

  const filteredInProgressBAPs = useMemo(() => {
    return inProgressBAPs.filter((inProgress) =>
      sortedStudents.some((student) => student.id === inProgress.student.id),
    );
  }, [inProgressBAPs, sortedStudents]);

  const onTeachingRecommendationClicked = useCallback(
    async ({ teachingRecommendation, ...student }: DashboardStudent) => {
      if (!teachingRecommendation) {
        return;
      }
      const { strugglingSkills } = teachingRecommendation;
      await showTeachingRecommendationsModal({
        student,
        struggledSkills: strugglingSkills,
      });
    },
    [],
  );

  return {
    students: sortedStudents,
    sortField,
    sortOrder,
    onSortChanged,
    search,
    onSearchChanged,
    onClassSelect,
    selectedClass,
    inProgressBAPs: filteredInProgressBAPs,
    completedBAPs: filteredCompletedBAPs,
    selectedClassOption,
    classOptions,
    onTeachingRecommendationClicked,
  };
}
