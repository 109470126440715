import React, { FC } from 'react';
import { CorrectIncorrect } from 'shared/lib/constants/assessment/CorrectIncorrect';
import { Button } from '../../Button/Button';
import { Column } from '../../Column/Column';

interface AssessmentCorrectIncorrectAnswerButtonProps {
  value: CorrectIncorrect;
  selected: boolean;
  onClick(): void;
  className?: string;
}

function getContainerColors(value: CorrectIncorrect, selected: boolean) {
  if (value === CorrectIncorrect.INCORRECT) {
    return selected
      ? 'bg-light-blue-500 border-light-blue-500'
      : 'bg-white border-white';
  }
  return selected
    ? 'bg-green-highlight border-green-highlight'
    : 'bg-white border-white';
}

export const AssessmentCorrectIncorrectAnswerButton: FC<AssessmentCorrectIncorrectAnswerButtonProps> = ({
  value,
  className = '',
  selected,
  ...rest
}) => (
  <Button
    className={`w-72 h-72 justify-center items-center rounded-3xl p-4.5 bg-opacity-20 border-4 ${getContainerColors(
      value,
      selected,
    )} ${className}`}
    {...rest}
  >
    <Column
      className={`flex-grow flex-shrink-0 h-full ${
        value === CorrectIncorrect.INCORRECT
          ? 'bg-light-blue-500'
          : 'bg-green-highlight'
      } rounded-3xl font-mplus justify-center items-center text-white text-100px font-extrabold`}
    >
      {value === CorrectIncorrect.CORRECT ? 'C' : 'I'}
    </Column>
  </Button>
);
