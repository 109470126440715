import {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  MouseEvent,
  useRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { noop } from 'shared/lib/utils/noop';
import { Overlay } from '../Overlay/Overlay';
import './Modal.css';

interface ModalProps extends ComponentPropsWithoutRef<'div'> {
  bordered?: boolean;

  /**
   * An optional function to call when the outside (the overlay) of the modal is clicked.
   */
  onClickOutside?(): void;

  /**
   * Do a "drop in" animation when rendered.
   * Defaults to false.
   */
  animate?: boolean;
  overlayClassName?: string;
}

export const Modal: FC<ModalProps> = ({
  className,
  overlayClassName,
  children,
  bordered,
  onClickOutside = noop,
  animate = false,
  ...rest
}) => {
  const modalRef = useRef<HTMLDivElement | null>(null);

  const onClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const current = modalRef.current;
      if (!current) {
        return;
      }
      const targetElement = event.target;
      // Check if the clicked element is within the modal.
      const isOnModal =
        targetElement &&
        targetElement instanceof Node &&
        current.contains(targetElement);
      // otherwise its on the overlay part.
      if (!isOnModal) {
        onClickOutside();
      }
    },
    [onClickOutside],
  );

  return (
    <Overlay
      className={twMerge('bg-gray-200 bg-opacity-25', overlayClassName)}
      onClick={onClick}
    >
      <div
        className={twMerge(
          'flex flex-col absolute bg-white top-32 border-4 rounded-3xl modal-container',
          bordered && 'border-black',
          animate && 'animate-dropIn',
          className,
        )}
        ref={modalRef}
        {...rest}
      >
        {children}
      </div>
    </Overlay>
  );
};
