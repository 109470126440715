import { StudentPercentileHistoryItem } from 'shared/lib/types/assessment/StudentPercentileHistoryItem';
import { apiAxios } from '../apiAxios';

export async function getStudentPercentileHistory(
  studentId: number,
): Promise<StudentPercentileHistoryItem[]> {
  const response = await apiAxios.get(
    `/student/${studentId}/percentile-history`,
  );
  return response.data.percentileHistory;
}
