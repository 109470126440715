import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  editStudent,
  studentDetails,
} from 'shared/lib/constants/routes/userRoutes';
import { StudentEditor } from '../../pages/StudentEditor/StudentEditor';
import { StudentDetailPage } from '../../pages/StudentDetail/StudentDetailPage';

interface StudentRoutesProps {
  studentId: number;
}

export const StudentRoutes: FC<StudentRoutesProps> = ({ studentId }) => {
  return (
    <Switch>
      <Route exact path={studentDetails}>
        <StudentDetailPage studentId={studentId} />
      </Route>
      <Route exact path={editStudent}>
        <StudentEditor studentId={studentId} studentGroupId={null} />
      </Route>
    </Switch>
  );
};
