import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from '../../Row/Row';

export const AssessmentListItem: FC<ComponentPropsWithoutRef<'div'>> = ({
  className = '',
  children,
  ...rest
}) => (
  <Row className={`items-center ${className}`} {...rest}>
    <div className="bg-light-blue-500 h-3 w-3 rounded-full mr-6 flex-shrink-0" />
    {children}
  </Row>
);
