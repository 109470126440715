import { Admin } from 'shared/lib/types/Admin';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { Org } from 'shared/lib/types/Org';
import { getDistrict } from '../../api/district/getDistrict';
import { getSchool } from '../../api/school/getSchool';
import { getAssignedOrganization } from '../../api/admin/getAssignedOrganization';
import { getAdmin } from '../../api/admin/getAdmin';
import { FormEvent, useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { useUserForm } from '../user/useUserForm';
import isEmail from 'validator/lib/isEmail';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { updateAdmin } from '../../api/admin/updateAdmin';
import { EditableUser } from '../../types/user/EditableUser';
import { createDistrictAdmin } from '../../api/district/createDistrictAdmin';
import { createSchoolAdmin } from '../../api/school/createSchoolAdmin';
import { createSuperAdmin } from '../../api/admin/createSuperAdmin';
import { useLoggedInAdminContext } from '../../contexts/userContext';
import { compareAdminLevel } from 'shared/lib/utils/admin/compareAdminLevel';
import {
  assignedSchool,
  formatAdminDetailsRoute,
  schools,
} from 'shared/lib/constants/routes/administratorRoutes';
import { showOkAlert } from '../../components/Alert/Alert';

interface UseAdminEditorParams {
  orgId: number | null;
  userId: number | null;
  adminLevel: AdminLevel;
}

async function loadForCreate(
  orgId: number,
  adminLevel: AdminLevel,
): Promise<[Org, null]> {
  const org =
    adminLevel === AdminLevel.DISTRICT
      ? await getDistrict(orgId)
      : await getSchool(orgId);
  return [org, null];
}

async function loadForEdit(userId: number): Promise<[Org | null, Admin]> {
  return Promise.all([getAssignedOrganization(userId), getAdmin(userId)]);
}

export function useAdminEditor({
  userId,
  adminLevel,
  orgId,
}: UseAdminEditorParams) {
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [loading, setLoading] = useState(true);
  const [org, setOrg] = useState<Org | null>(null);
  const history = useHistory();
  const [error, setError] = useState('');
  const { adminLevel: currentUserAdminLevel } = useLoggedInAdminContext();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setAdmin(null);
        setOrg(null);
      }

      if (adminLevel !== AdminLevel.SUPER) {
        if (!orgId && !userId) {
          await showOkAlert({
            title: 'Error',
            text: `Cannot load info to create or edit ${adminLevel}`,
            theme: 'error',
          });
          history.goBack();
          return;
        }
      }

      let loadedOrg: Org | null = null;
      let loadedUser: Admin | null = null;

      if (adminLevel !== AdminLevel.SUPER) {
        const [orgInfo, user] = userId
          ? await loadForEdit(userId)
          : await loadForCreate(orgId!, adminLevel);
        loadedOrg = orgInfo;
        loadedUser = user;
      } else {
        if (userId) {
          loadedUser = await getAdmin(userId);
        }
      }

      if (!isCancelled()) {
        setAdmin(loadedUser);
        setOrg(loadedOrg);
        setLoading(false);
      }
    },
    [orgId, userId, adminLevel],
  );

  const { editableUser: editableAdmin, ...restOfFormProps } = useUserForm(
    admin,
  );

  const onCreate = useCallback(
    async (createAdminProps: EditableUser) => {
      let created: Admin;
      if (adminLevel === AdminLevel.SUPER) {
        created = await createSuperAdmin(createAdminProps);
      } else {
        if (orgId) {
          created =
            adminLevel === AdminLevel.DISTRICT
              ? await createDistrictAdmin(orgId, createAdminProps)
              : await createSchoolAdmin(orgId, createAdminProps);
        } else {
          await showOkAlert({
            title: 'Error',
            text: `Failed to create ${adminLevel} admin because there was no organization`,
            theme: 'error',
          });
          return;
        }
      }

      // Admins can create admins at the same level as they are.
      // If that is the case for school/district admins, redirect to their organization's page
      if (
        adminLevel !== AdminLevel.SUPER &&
        compareAdminLevel(currentUserAdminLevel, adminLevel) === 0
      ) {
        history.replace(
          currentUserAdminLevel === AdminLevel.DISTRICT
            ? schools
            : assignedSchool,
        );
      } else {
        history.replace(formatAdminDetailsRoute(created.id));
      }
    },
    [history, adminLevel, orgId, currentUserAdminLevel],
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading) {
        return;
      }
      setError('');
      const { email, name } = editableAdmin;
      if (!email || !isEmail(email)) {
        setError('Email is invalid.');
        return;
      }

      if (!name) {
        setError('Name is required.');
        return;
      }

      setLoading(true);
      try {
        if (admin) {
          setAdmin(await updateAdmin(admin.id, editableAdmin));
        } else {
          await onCreate(editableAdmin);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to ${admin ? 'update' : 'create'} ${capitalizeFirst(
            adminLevel,
          )} Admin: ${e.message}`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [editableAdmin, loading, adminLevel, admin, onCreate],
  );

  return {
    editableAdmin,
    org,
    admin,
    loading,
    onSubmit,
    error,
    ...restOfFormProps,
  };
}
