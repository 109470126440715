import React, { ComponentPropsWithRef, FC, useCallback, useMemo } from 'react';
import { SchoolDashboardData } from 'shared/lib/types/dashboard/school/SchoolDashboardData';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { SearchField } from '../SearchField/SearchField';
import { StudentList } from '../StudentList/StudentList';
import { Language } from 'shared/lib/constants/Language';
import { AssessorList } from '../AssessorList/AssessorList';
import {
  formatAddSchoolAdminRoute,
  formatEditClassRoute,
  formatEditSchoolRoute,
  formatEnrollAssessorInGroupRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import {
  formatAddStudentToGroupRoute,
  formatEnrollStudentInGroupRoute,
} from 'shared/lib/constants/routes/commonRoutes';
import { RouteMenu, MenuOption } from '../RouteMenu/RouteMenu';
import { Admin } from 'shared/lib/types/Admin';
import { AdminList } from '../AdminList/AdminList';
import { ActionLink } from '../ActionLink/ActionLink';
import { ListToggleButton } from '../ListToggleButton/ListToggleButton';
import { MainPanel } from '../MainPanel/MainPanel';
import {
  DashboardDropdown,
  DashboardDropdownOption,
} from '../DashboardDropdown/DashboardDropdown';
import { DashboardAssessor } from 'shared/lib/types/teacher/DashboardAssessor';
import './SchoolDetailDashboard.css';
import { BapsInProgress } from '../BapsInProgress/BapsInProgress';
import { BapsCompletedStats } from '../BapsCompletedStats/BapsCompletedStats';
import { ListSwitchable } from '../../types/ListSwitchable';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { User } from 'shared/lib/types/User';
import {
  SortOrder,
  StudentSort,
  StudentSortField,
} from '../../types/student/StudentSort';
import { StudentSortDropdown } from '../StudentSortDropdown/StudentSortDropdown';

enum ListMode {
  STUDENT = 'Students',
  ASSESSOR = 'Teachers',
  SCHOOL_ADMIN = 'School Admins',
}

const listSwitchRoutes = (basePath: string) => ({
  studentList: `${basePath}/students`,
  teacherList: `${basePath}/teachers`,
  adminList: `${basePath}/admins`,
});

export interface SchoolDetailDashboardProps
  extends ComponentPropsWithRef<'div'>,
    ListSwitchable {
  schoolId: number;
  data: SchoolDashboardData;
  classes: ReadonlyArray<StudentGroup>;
  selectedClass: StudentGroup | null;
  onClassSelected(group: StudentGroup | null): void;
  createClassRoute: string;
  teachers: ReadonlyArray<DashboardAssessor>;
  schoolAdmins: ReadonlyArray<Admin>;
  search: string;
  onSearchChanged(search: string): void;
  sortField: StudentSortField;
  sortOrder: SortOrder;
  onSortChanged(sort: StudentSort): void;

  /**
   * Function to call when the resume button is clicked.
   * @param studentId the id of the student clicked on
   */
  onResumeClicked(studentId: number, languages: ReadonlyArray<Language>): void;
  onStartClicked(studentId: number): void;
  showSchoolAdminList: boolean;
  canCreateSchoolAdmin: boolean;
  searchParams?: string;
  onTeachingRecommendationClicked(student: DashboardStudent): void;
  onReinviteClicked(user: User): void;
  allStudents: readonly DashboardStudent[];
}

export const SchoolDetailDashboard: FC<SchoolDetailDashboardProps> = ({
  data,
  classes,
  onClassSelected,
  selectedClass,
  createClassRoute,
  search,
  onSearchChanged,
  sortField,
  sortOrder,
  onSortChanged,
  teachers,
  onStartClicked,
  onResumeClicked,
  showSchoolAdminList,
  canCreateSchoolAdmin,
  schoolAdmins,
  schoolId,
  basePath,
  activePath,
  searchParams,
  onTeachingRecommendationClicked,
  onReinviteClicked,
  allStudents,
  className,
  ...rest
}) => {
  const { totalCompletedBAPS, inProgressBAPS, students } = data;

  const classOptions = useMemo(
    (): DashboardDropdownOption[] => [
      { id: -1, name: 'All Classes', studentCount: null },
      ...classes.map((cls) => ({
        id: cls.id,
        name: cls.name,
        studentCount: allStudents.filter(({ studentGroups }) =>
          studentGroups.some((g) => g.id === cls.id),
        ).length,
      })),
    ],
    [classes, allStudents],
  );
  const handleClassSelected = useCallback(
    ({ id }: DashboardDropdownOption) => {
      const selected = classes.find((cls) => cls.id === id);
      onClassSelected(selected ?? null);
    },
    [classes, onClassSelected],
  );

  const selectedClassOption = useMemo(
    () => classOptions.find((cls) => cls.id === selectedClass?.id),
    [classOptions, selectedClass],
  );

  const addRoutes = useMemo((): MenuOption[] => {
    const options: MenuOption[] = [];
    if (!selectedClass) {
      options.push({ to: createClassRoute, label: 'Add New Class' });
    } else {
      options.push(
        {
          to: formatEnrollAssessorInGroupRoute(selectedClass.id),
          label: 'Add Teacher',
        },
        {
          to: formatEnrollStudentInGroupRoute(selectedClass.id),
          label: 'Add Student',
        },
        {
          to: formatAddStudentToGroupRoute(selectedClass.id),
          label: 'Add Existing Student',
        },
      );
    }
    if (canCreateSchoolAdmin) {
      options.push({
        to: formatAddSchoolAdminRoute(schoolId),
        label: 'Add School Admin',
      });
    }
    return options;
  }, [selectedClass, canCreateSchoolAdmin, schoolId, createClassRoute]);

  const { adminList, studentList, teacherList } = listSwitchRoutes(basePath);

  const mode = useMemo(() => {
    if (activePath === teacherList) {
      return ListMode.ASSESSOR;
    }
    if (activePath === adminList) {
      return ListMode.SCHOOL_ADMIN;
    }
    return ListMode.STUDENT;
  }, [activePath, teacherList, adminList]);

  return (
    <Column {...rest} className={twMerge('print:block', className)}>
      <Row className="justify-between items-center lg:ml-24 pb-6 pr-10 z-10 print:hidden">
        <DashboardDropdown
          options={classOptions}
          onSelected={handleClassSelected}
          selected={selectedClassOption}
          placeholder="All Classes"
        />
        {!selectedClass ? (
          <Row className="print:hidden">
            <ActionLink
              variant="edit"
              to={formatEditSchoolRoute({ id: schoolId })}
            >
              Edit School
            </ActionLink>
            {canCreateSchoolAdmin ? (
              <RouteMenu routes={addRoutes} />
            ) : (
              <Link
                className="bg-gray-600 text-white text-center pb-3 pt-3 pl-8 pr-8"
                to={createClassRoute}
              >
                Add New Class
              </Link>
            )}
          </Row>
        ) : (
          <Row>
            <ActionLink
              variant="edit"
              className="mr-6 ml-3"
              to={formatEditClassRoute(selectedClass.id)}
            >
              Edit Class
            </ActionLink>
            <RouteMenu routes={addRoutes} />
          </Row>
        )}
      </Row>
      <MainPanel
        className="overflow-hidden h-full pl-4 print:block"
        leftPad={false}
      >
        <Row className="w-full justify-between pb-7 pl-8 lg:pl-15 pr-5 lg:pr-10 print:hidden">
          <div>{/* for layout */}</div>
          <Row className="justify-center flex-wrap font-semibold">
            <ListToggleButton
              to={{ pathname: studentList, search: searchParams }}
              active={activePath === studentList || activePath === basePath}
              buttonText="Students"
            />
            <ListToggleButton
              to={{ pathname: teacherList, search: searchParams }}
              active={activePath === teacherList}
              buttonText="Teachers"
            />
            {showSchoolAdminList && (
              <ListToggleButton
                to={{ pathname: adminList, search: searchParams }}
                active={activePath === adminList}
                buttonText="School Admins"
              />
            )}
          </Row>
          <SearchField
            search={search}
            onSearchChanged={onSearchChanged}
            placeholder={`Search for ${mode}`}
          />
        </Row>
        <Column className="overflow-y-auto h-full flex-grow flex-shrink-0 pl-4 pr-5 pb-20 school-detail-scroll print:block">
          <Row className="w-full justify-between flex-wrap pb-2">
            <div className="flex-grow flex-shrink-0 max-w-xs mr-6">
              <BapsCompletedStats
                totalCompletedBAPS={totalCompletedBAPS}
                totalStudents={students.length}
              />
            </div>
            <div className="flex-grow flex-shrink-0 max-w-97">
              <BapsInProgress
                inProgressBAPS={inProgressBAPS}
                onResumeClicked={onResumeClicked}
              />
            </div>
          </Row>
          <Column className="mt-5 pb-20 print:block">
            <Switch>
              <Route exact path={[basePath, studentList]}>
                <StudentSortDropdown
                  sortField={sortField}
                  sortOrder={sortOrder}
                  onSelected={onSortChanged}
                  className="print:hidden"
                />
                <StudentList
                  students={students}
                  sortField={sortField}
                  onResumeClicked={onResumeClicked}
                  onStartClicked={onStartClicked}
                  onTeachingRecommendationClicked={
                    onTeachingRecommendationClicked
                  }
                  currentClass={selectedClass}
                />
              </Route>
              <Route exact path={teacherList}>
                <AssessorList
                  assessors={teachers}
                  disableInternalScroll
                  onReinviteClicked={onReinviteClicked}
                />
              </Route>
              <Route exact path={adminList}>
                <AdminList
                  admins={schoolAdmins}
                  disableInternalScroll
                  onReinviteClicked={onReinviteClicked}
                />
              </Route>
              <Redirect to={basePath} />
            </Switch>
          </Column>
        </Column>
      </MainPanel>
    </Column>
  );
};
