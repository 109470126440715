import React, { FC, useCallback, useMemo } from 'react';
import { Option } from '../../types/Option';
import {
  FamilyLanguageUse,
  getTitle,
} from 'shared/lib/constants/student/FamilyLanguageUse';
import { Dropdown } from '../Dropdown/Dropdown';

const FAMILY_LANGUAGE_USE_OPTIONS: Option[] = Object.values(
  FamilyLanguageUse,
).map((option) => ({ id: option, value: getTitle(option) }));

interface FamilyLanguageUseDropdownProps {
  selected?: FamilyLanguageUse | null;
  onSelected(selected: FamilyLanguageUse): void;
  placeholder?: string;
  className?: string;
}

export const FamilyLanguageUseDropdown: FC<FamilyLanguageUseDropdownProps> = ({
  selected,
  onSelected,
  placeholder = "Select Family's Language Use",
  ...rest
}) => {
  const handleSelected = useCallback(
    ({ id }: Option) => {
      const selectedOption = FAMILY_LANGUAGE_USE_OPTIONS.find(
        (option) => option.id === id,
      );
      if (selectedOption) {
        onSelected(selectedOption.id as FamilyLanguageUse);
      }
    },
    [onSelected],
  );

  const selectedOption = useMemo(
    () => FAMILY_LANGUAGE_USE_OPTIONS.find((option) => option.id === selected),
    [selected],
  );

  return (
    <Dropdown
      options={FAMILY_LANGUAGE_USE_OPTIONS}
      onSelected={handleSelected}
      selected={selectedOption}
      placeholder={placeholder}
      {...rest}
    />
  );
};
