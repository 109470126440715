import { downloadFile } from './downloadFile';

export function downloadBlob(blob: Blob, fileName: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    try {
      const objectUrl = URL.createObjectURL(blob);
      downloadFile(objectUrl, fileName);
      // This is a workaround for a Safari bug.
      setTimeout(() => {
        URL.revokeObjectURL(objectUrl);
        resolve();
      }, 500);
    } catch (e) {
      reject(e);
    }
  });
}
