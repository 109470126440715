import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  assessorDetail,
  editAssessor,
  removeAssessor,
} from 'shared/lib/constants/routes/administratorRoutes';
import { AssessorDetails } from '../../pages/AssessorDetails/AssessorDetails';
import { AssessorEditor } from '../../pages/AssessorEditor/AssessorEditor';
import { RemoveAssessor } from '../../pages/RemoveAssessor/RemoveAssessor';

interface AssessorRoutesProps {
  assessorId: number;
}

export const AssessorRoutes: FC<AssessorRoutesProps> = ({ assessorId }) => {
  return (
    <Switch>
      <Route exact path={assessorDetail}>
        <AssessorDetails assessorId={assessorId} />
      </Route>
      <Route exact path={editAssessor}>
        <AssessorEditor studentGroupId={null} assessorId={assessorId} />
      </Route>
      <Route exact path={removeAssessor}>
        <RemoveAssessor assessorId={assessorId} />
      </Route>
    </Switch>
  );
};
