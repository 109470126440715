import React, {
  ComponentPropsWithoutRef,
  FC,
  ReactNode,
  useEffect,
  useMemo,
} from 'react';
import { Row } from '../Row/Row';
import { Button } from '../Button/Button';
import {
  mute,
  play,
  restart,
  reset,
  unmute,
  pause as pauseIcon,
  close,
} from '../../images';
import { useAudioPlayer } from '../../hooks/useAudioPlayer';
import { noop } from 'shared/lib/utils/noop';
import { SECOND } from 'shared/lib/constants/time';

export interface AudioPlayerProps extends ComponentPropsWithoutRef<'div'> {
  /**
   * The source for the audio
   */
  source: string;

  /**
   * A callback for when the audio has been fully played at least once
   */
  onPlayedInFull?(): void;

  pause?: boolean;

  /**
   * Hide/show the mute button, which if shown, hides the replay
   */
  includeMute?: boolean;

  /**
   * The 'preload' attribute for the audio player.
   * From experience, it should be 'auto' for best experience with remote (i.e. S3) audio files
   */
  preload?: 'auto' | 'metadata' | 'none' | '';

  showAudioIncomplete: boolean;
  onShowAudioCompleteDismissed(): void;
  audioIncompleteMessage?: ReactNode;
  /**
   * The "grace period" between the current play time and end of the audio where its considered "played to completion".
   * Default: 0 milliseconds
   */
  graceCompletionMilliseconds?: number;
}

export const AudioPlayer: FC<AudioPlayerProps> = ({
  source,
  className = '',
  onPlayedInFull = noop,
  includeMute = false,
  preload,
  pause,
  showAudioIncomplete,
  onShowAudioCompleteDismissed,
  audioIncompleteMessage = 'Play full audio recording before selecting answer.',
  graceCompletionMilliseconds = 0,
  ...rest
}) => {
  const {
    togglePlay,
    reset: resetAudio,
    replay: replayAudio,
    playing,
    playedInFull,
    playerRef,
    percentProgress,
    toggleMute,
    muted,
    currentPlayIncomplete,
    elapsedTime,
    totalTime,
  } = useAudioPlayer({ source, pause: pause || showAudioIncomplete });

  const barWidth = useMemo(() => {
    if (playing) {
      return percentProgress;
    }
    return playedInFull && !currentPlayIncomplete ? 100 : percentProgress;
  }, [playing, percentProgress, playedInFull, currentPlayIncomplete]);

  const seekHandlePosition = useMemo(() => {
    // The min helps keep the dot within the bounds of the bar when finished.
    // The max allows it to be just on the edge of the color.
    return Math.min(97, Math.max(-1.3, barWidth - 1.5));
  }, [barWidth]);

  useEffect(() => {
    if (
      elapsedTime &&
      totalTime &&
      totalTime - elapsedTime <= graceCompletionMilliseconds / SECOND
    ) {
      onPlayedInFull();
    }
  }, [elapsedTime, totalTime, graceCompletionMilliseconds, onPlayedInFull]);

  return (
    <Row
      className={`${
        percentProgress < 100 ? 'justify-between' : 'justify-center'
      } items-center max-w-md ${className}`}
      {...rest}
    >
      <div
        className={`flex flex-row absolute h-full w-full justify-center items-center border border-blue-500 rounded-xl transition-all transform ${
          showAudioIncomplete
            ? 'bg-white z-20 translate-y-0 opacity-100'
            : 'opacity-0 select-none pointer-events-none translate-y-full'
        }`}
      >
        <div className="p-8 text-center text-xs">{audioIncompleteMessage}</div>
        <button
          className="w-6 h-6 absolute right-2 border border-light-blue-200 rounded-lg"
          onClick={onShowAudioCompleteDismissed}
          disabled={!showAudioIncomplete}
        >
          <img src={close} alt="Dismiss" />
        </button>
      </div>
      {percentProgress < 100 ? (
        <>
          <Button onClick={togglePlay} className="mr-9">
            <img
              src={playing ? pauseIcon : play}
              alt={`Click to ${playing ? 'Pause' : 'Play'}`}
              className="mr-1 h-9 w-8"
            />
          </Button>
          <Row className="flex-grow items-center rounded-xl bg-light-blue-500 bg-opacity-20 h-1.5">
            <div
              className="relative bg-light-blue-500 h-1.5 rounded-xl"
              style={{ width: `${barWidth}%` }}
            />
            <div
              className="absolute h-4 w-4 rounded-xl z-10 bg-white flex flex-col justify-center items-center"
              style={{ left: `${seekHandlePosition}%` }}
            >
              <div className="h-3 w-3 rounded-xl bg-light-blue-500" />
            </div>
          </Row>
          <Button
            onClick={includeMute ? toggleMute : resetAudio}
            className="ml-9"
          >
            {includeMute ? (
              <img
                src={muted ? unmute : mute}
                alt={muted ? 'Click to Unmute' : 'Click to Mute'}
                className="h-6 w-6"
              />
            ) : (
              <img src={reset} alt="Click to Replay" className="h-5 w-6" />
            )}
          </Button>
        </>
      ) : (
        <Button
          onClick={replayAudio}
          className="items-center text-light-blue-500 text-sm font-medium"
        >
          <img src={restart} alt="Restart" className="h-6 w-6 mr-3" /> Replay
          Audio
        </Button>
      )}
      <audio className="hidden" ref={playerRef} preload={preload} />
    </Row>
  );
};
