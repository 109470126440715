import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  addDistrictAdmin,
  addDistrictSchool,
  districtDetails,
  editDistrict,
  removeDistrict,
} from 'shared/lib/constants/routes/administratorRoutes';
import { DistrictEditor } from '../../pages/DistrictEditor/DistrictEditor';
import { RemoveDistrict } from '../../pages/RemoveDistrict/RemoveDistrict';
import { DistrictDetails } from '../../pages/DistrictDetails/DistrictDetails';
import { SchoolEditor } from '../../pages/SchoolEditor/SchoolEditor';
import { useLoggedInAdminContext } from '../../contexts/userContext';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { AdminEditor } from '../../pages/AdminEditor/AdminEditor';

interface DistrictRoutesProps {
  districtId: number;
}

export const DistrictRoutes: FC<DistrictRoutesProps> = ({ districtId }) => {
  const { adminLevel } = useLoggedInAdminContext();
  return (
    <Switch>
      <Route path={districtDetails}>
        <DistrictDetails district={districtId} />
      </Route>
      <Route exact path={editDistrict}>
        <DistrictEditor districtId={districtId} />
      </Route>
      <Route exact path={removeDistrict}>
        <RemoveDistrict districtId={districtId} />
      </Route>
      <Route exact path={addDistrictSchool}>
        <SchoolEditor schoolId={null} districtId={districtId} />
      </Route>
      {adminLevel !== AdminLevel.SCHOOL && (
        <Route exact path={addDistrictAdmin}>
          <AdminEditor
            userId={null}
            orgId={districtId}
            adminLevel={AdminLevel.DISTRICT}
          />
        </Route>
      )}
    </Switch>
  );
};
