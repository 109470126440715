import React, { ComponentPropsWithoutRef, FC } from 'react';
import { forgotPassword } from 'shared/lib/constants/routes/unauthenticatedRoutes';
import { TextInput } from '../TextInput/TextInput';
import { Row } from '../Row/Row';
import { Link } from 'react-router-dom';
import { PillButton } from '../PillButton/PillButton';

interface LoginFormProps extends ComponentPropsWithoutRef<'form'> {
  username: string;
  password: string;
  onUsernameChanged(username: string): void;
  onPasswordChanged(username: string): void;
  error?: string;
}

export const LoginForm: FC<LoginFormProps> = ({
  username,
  password,
  onUsernameChanged,
  onPasswordChanged,
  className = '',
  error,
  ...rest
}) => (
  <form
    className={`relative flex flex-col items-center max-w-xs w-full ${className}`}
    {...rest}
  >
    <TextInput
      type="text"
      placeholder="Username / Email"
      value={username}
      onChange={(e) => onUsernameChanged(e.target.value)}
      className="max-w-xs w-full bg-blue-75"
    />
    <TextInput
      type="password"
      placeholder="Password"
      value={password}
      onChange={(e) => onPasswordChanged(e.target.value)}
      className="max-w-xs w-full mt-4"
    />
    <Row className="w-full justify-between mt-9 items-center flex-wrap">
      <PillButton type="submit" buttonVariation="primary">
        Login
      </PillButton>
      <Link
        to={forgotPassword}
        className={'m-1 font-roboto text-sm font-medium trueGray-900'}
      >
        Forgot Password?
      </Link>
    </Row>
    <Row className="h-8 text-red-600">{error}</Row>
  </form>
);
