import React, { FC } from 'react';
import ReactMarkdown from 'react-markdown';
import { ActivityText } from 'shared/lib/types/activity/ActivityText';
import { PresentationStyle } from 'shared/lib/constants/activity/PresentationStyle';
import { Row } from '../../Row/Row';
import { Column } from '../../Column/Column';
import { AssessmentListItem } from '../AssessmentListItem/AssessmentListItem';
import { play } from '../../../images';
import { PillButton } from '../../PillButton/PillButton';

type HighlightColor = 'blue' | 'red' | 'green';

function getHighlightColor(color: HighlightColor): string {
  switch (color) {
    case 'blue':
      return 'text-light-blue-500';
    case 'red':
      return 'text-red-highlight';
    case 'green':
      return 'text-green-highlight';
  }
}

interface ActivityTextDisplayProps {
  activityText: ActivityText;
  className?: string;
  highlightColor?: HighlightColor;
}

export const ActivityTextDisplay: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { style } = activityText;

  switch (style) {
    case PresentationStyle.UNORDERED_LIST:
      return (
        <UnorderedListActivityText activityText={activityText} {...rest} />
      );
    case PresentationStyle.ORDERED_LIST:
      return <OrderedListActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.SAY:
      return <SayActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.PLAY:
      return <PlayActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.NEXT:
      return <NextActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.SAY_DO:
      return <SayDoActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.SAY_DO_SAY:
      return <SayDoSayActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.PLAY_THEN_SAY:
      return <PlayThenSayActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.NOTE:
      return <NoteActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.BOLD:
      return <BoldActivityText activityText={activityText} {...rest} />;
    case PresentationStyle.PLAIN:
    default:
      return <PlainActivityText activityText={activityText} {...rest} />;
  }
};

function activityTextToString(activityText: ActivityText | string): string {
  if (isString(activityText)) {
    return activityText;
  }
  const values: string[] = [];
  const { text } = activityText;
  for (const textElement of text) {
    if (isString(textElement)) {
      values.push(textElement);
    } else {
      values.push(activityTextToString(textElement));
    }
  }
  return values.join(' ');
}

const ActivityTextSubRenderer: FC<{
  activityText: ActivityText | string;
  highlightColor?: HighlightColor;
}> = ({ activityText, highlightColor }) =>
  isString(activityText) ? (
    <ReactMarkdown className="inline-block mx-0.5">
      {activityText}
    </ReactMarkdown>
  ) : (
    <ActivityTextDisplay
      activityText={activityText}
      highlightColor={highlightColor}
    />
  );

function isString(activityText: ActivityText | string): activityText is string {
  return typeof activityText === 'string';
}

const PlainActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { text } = activityText;
  return (
    <Row {...rest}>
      {text.map((value, idx) => {
        return <ActivityTextSubRenderer key={idx} activityText={value} />;
      })}
    </Row>
  );
};

const UnorderedListActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { text } = activityText;
  const [heading, ...items] = text;
  return (
    <Column {...rest}>
      <div className="pb-9">
        <ActivityTextSubRenderer activityText={heading} />
      </div>
      {items.map((item, idx) => (
        <AssessmentListItem key={idx} className="pb-6">
          <ActivityTextSubRenderer activityText={item} />
        </AssessmentListItem>
      ))}
    </Column>
  );
};

const CHAR_CODE_LOWER_A = 'a'.charCodeAt(0);

function getOrderedListItem(itemIndex: number) {
  // Probably should check the upper bounds, but the most items in an activity that uses the ordered list is 3.
  return `${String.fromCharCode(CHAR_CODE_LOWER_A + itemIndex)}.`;
}

const OrderedListActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { text } = activityText;
  const [heading, ...items] = text;
  return (
    <Column {...rest}>
      <div className="pb-9">
        <ActivityTextSubRenderer activityText={heading} />
      </div>
      {items.map((item, idx) => (
        <Row key={idx} className="pb-6">
          <span className="mr-6">{getOrderedListItem(idx)}</span>
          <ActivityTextSubRenderer activityText={item} />
        </Row>
      ))}
    </Column>
  );
};

const SayActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  highlightColor = 'blue',
  ...rest
}) => {
  const { text } = activityText;
  const [say, ...whatToSay] = text;
  return (
    <p {...rest}>
      {activityTextToString(say)}
      <span className={`${getHighlightColor(highlightColor)} font-bold ml-1`}>
        {whatToSay.map((item, idx) => (
          <ActivityTextSubRenderer key={idx} activityText={item} />
        ))}
      </span>
    </p>
  );
};

const PlayActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { text } = activityText;
  const [beforePlay, ...afterPlay] = text;
  return (
    <span {...rest}>
      {activityTextToString(beforePlay)}
      <img src={play} alt="Play" className="inline h-4.6 w-4.5 mr-3.5 ml-3.5" />
      {afterPlay.map((item, idx) => activityTextToString(item))}
    </span>
  );
};

const NextActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  className = '',
  ...rest
}) => {
  const { text } = activityText;
  return (
    <Row className={`items-center ${className}`} {...rest}>
      {text.map((item, idx) => (
        <ActivityTextSubRenderer activityText={item} key={idx} />
      ))}
      <PillButton
        className="w-13 h-6 ml-3.5 text-xss"
        disabled
        buttonVariation="primary"
      >
        Next
      </PillButton>
    </Row>
  );
};

const BoldActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  className = '',
  ...rest
}) => {
  const { text } = activityText;
  return (
    <Row className={`font-bold ${className}`} {...rest}>
      {text.map((item, idx) => (
        <ActivityTextSubRenderer activityText={item} key={idx} />
      ))}
    </Row>
  );
};

const NoteActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { text } = activityText;
  const [preNote, ...note] = text;
  return (
    <p {...rest}>
      <div className="font-bold inline">
        <ActivityTextSubRenderer activityText={preNote} />
      </div>
      {note.map((item, idx) => (
        <ActivityTextSubRenderer activityText={item} key={idx} />
      ))}
    </p>
  );
};

const SayDoActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  highlightColor,
  ...rest
}) => {
  const { text } = activityText;
  const [say, whatToSay, ...thenDoThis] = text;
  return (
    <Column {...rest}>
      <div>
        <ActivityTextSubRenderer activityText={say} />
        <div
          className={`${getHighlightColor(
            highlightColor ?? 'blue',
          )} font-bold inline`}
        >
          <ActivityTextSubRenderer activityText={whatToSay} />
        </div>
      </div>
      {thenDoThis.map((item, idx) => (
        <ActivityTextSubRenderer
          activityText={item}
          highlightColor={highlightColor}
          key={idx}
        />
      ))}
    </Column>
  );
};

const SayDoSayActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  ...rest
}) => {
  const { text } = activityText;
  const [say, whatToSay, doThis, sayAgain, ...moreStuffToSay] = text;
  return (
    <Column {...rest}>
      <p>
        <ActivityTextSubRenderer activityText={say} />
        <div className="text-light-blue-500 font-bold inline">
          <ActivityTextSubRenderer activityText={whatToSay} />
        </div>
      </p>
      <ActivityTextSubRenderer activityText={doThis} />
      <p>
        <Row className="text-light-blue-500 font-bold inline">
          <ActivityTextSubRenderer activityText={sayAgain} />
        </Row>
        <Row className="text-light-blue-500 font-bold">
          {moreStuffToSay.map((item, idx) => (
            <ActivityTextSubRenderer activityText={item} key={idx} />
          ))}
        </Row>
      </p>
    </Column>
  );
};

const PlayThenSayActivityText: FC<ActivityTextDisplayProps> = ({
  activityText,
  highlightColor = 'blue',
  ...rest
}) => {
  const { text } = activityText;
  const [beforePlay, afterPlay, thenSay, ...say] = text;
  return (
    <Column {...rest}>
      <p>
        <ActivityTextSubRenderer activityText={beforePlay} />
        <img
          src={play}
          alt="Play"
          className="inline h-4.6 w-4.5 mr-3.5 ml-3.5"
        />
        <ActivityTextSubRenderer activityText={afterPlay} />
      </p>
      <p>
        <div className="font-bold inline">
          <ActivityTextSubRenderer activityText={thenSay} />
        </div>
        <div
          className={`${getHighlightColor(highlightColor)} font-bold inline`}
        >
          {say.map((item, idx) => (
            <ActivityTextSubRenderer activityText={item} key={idx} />
          ))}
        </div>
      </p>
    </Column>
  );
};
