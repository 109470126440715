import React, { FC } from 'react';
import { Link } from 'react-router-dom';

interface SidePanelButtonProps {
  route: string;
  name: string;
  images: {
    active: string;
    inactive: string;
  };
  isActive?: boolean;
  hide?: boolean;
}

export const SidePanelButton: FC<SidePanelButtonProps> = ({
  route,
  name,
  images,
  isActive,
  hide,
}) => (
  <Link
    to={route}
    className={`${
      hide ? 'hidden' : 'flex flex-row'
    } items-center transition-colors duration-300 w-full p-3 ${
      isActive ? 'text-light-blue-500 font-extrabold' : 'text-trueGray-750'
    }`}
  >
    {isActive && (
      <div className="h-1 w-1 rounded-full bg-light-blue-500 mr-5">&nbsp;</div>
    )}
    <img
      src={isActive ? images.active : images.inactive}
      alt={name}
      className="mr-4 w-6 h-6"
    />
    {name}
  </Link>
);
