import { PercentileReport } from 'shared/lib/types/assessment/PercentileReport';
import { AtRiskStatus } from 'shared/lib/constants/assessment/AtRiskStatus';
import { getAtRiskStatus } from 'shared/lib/utils/assessment/getAtRiskStatus';

export function getAtRiskLabel(
  percentile?: PercentileReport | null,
  excludeExpired = false,
) {
  const status = getAtRiskStatus(excludeExpired, percentile);
  if (status === AtRiskStatus.NOT_AVAILABLE) {
    return 'N/a';
  }
  return status === AtRiskStatus.AT_RISK ? 'At Risk' : 'Not At Risk';
}

export function getAtRiskColor(
  percentile?: PercentileReport | null,
  excludeExpired = false,
) {
  const status = getAtRiskStatus(excludeExpired, percentile);
  if (status === AtRiskStatus.NOT_AVAILABLE) {
    return 'text-trueGray-750';
  }
  return status === AtRiskStatus.AT_RISK ? 'text-at-risk' : 'text-not-at-risk';
}
