import React, {
  FC,
  FormEvent,
  MouseEvent,
  ReactNode,
  useCallback,
  useMemo,
} from 'react';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import { Button } from '../Button/Button';
import { blueTriangle, headsBlueFilled } from '../../images';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import './DashboardDropdown.css';
import { unique } from 'shared/lib/utils/unique';
import { sort } from 'shared/lib/utils/sort';
import groupBy from 'lodash/groupBy';

export interface DashboardDropdownOption {
  id: number;
  name: string;
  studentCount: number | null;
  /**
   * The "parent category" for this option.
   * Defaults to an empty string, which will yield a "plain" dropdown with options listed one by one.
   * If more than 1 category is given, the dropdown will be separated by the name of the category, e.g.
   *
   * ```
   * Category Name 1
   *   Option 1
   *   Option 2
   * Category Name 2
   *   Option 3
   *   Option 4
   * ```
   * etc.
   */
  categoryName?: string;
}

interface DashboardDropdownProps {
  options: ReadonlyArray<DashboardDropdownOption>;
  onSelected(option: DashboardDropdownOption): void;
  selected?: DashboardDropdownOption | null;
  placeholder?: ReactNode;
  disabled?: boolean;
  className?: string;
}

export const DashboardDropdown: FC<DashboardDropdownProps> = ({
  options,
  selected,
  onSelected,
  placeholder = <>&nbsp;</>,
  className = '',
  disabled,
  ...rest
}) => {
  const [open, toggleOpen, setOpen] = useToggleState(false);
  const handleClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const rootRef = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  const handleSelect = useCallback(
    (option: DashboardDropdownOption, e: MouseEvent<HTMLButtonElement>) => {
      onSelected(option);
      setOpen(false);
    },
    [onSelected, setOpen],
  );

  const handleToggle = useCallback(
    (e?: FormEvent) => {
      if (e) {
        e.preventDefault();
      }
      toggleOpen();
    },
    [toggleOpen],
  );

  const { groupedOptions, categoryNames } = useMemo(() => {
    return {
      groupedOptions: groupBy(options, (option) => option.categoryName ?? ''),
      categoryNames: sort(
        unique(options.map((option) => option.categoryName ?? '')),
        (a, b) => a.localeCompare(b),
      ),
    };
  }, [options]);

  const hasMultipleCategories =
    categoryNames.filter((name) => name.trim()).length > 0;

  return (
    <div
      ref={rootRef}
      className={`flex flex-col box-border w-40 ${className}`}
      {...rest}
    >
      <Button
        className={`font-roboto text-2xl text-black pt-3 pb-3 w-full justify-between items-center`}
        onClick={handleToggle}
        disabled={disabled}
      >
        <span className={`whitespace-nowrap overflow-hidden overflow-ellipsis`}>
          {selected?.name ?? placeholder}
        </span>
        <img
          src={blueTriangle}
          alt={open ? 'Close' : 'Open'}
          className={`relative h-6 w-6 transition-transform transform duration-500 ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </Button>
      {open && options.length > 0 && (
        <Column>
          <ul
            className={`absolute p-6 pt-3 pb-3 bg-white rounded-xl shadow-dashboardShadow overflow-y-auto w-full dashboard-dropdown-menu z-10`}
          >
            {categoryNames.map((categoryName) => (
              <li key={categoryName}>
                {hasMultipleCategories && (
                  <div className="font-bold text-sm border-b-2">
                    {categoryName}
                  </div>
                )}
                <ul className={hasMultipleCategories ? 'pl-1' : ''}>
                  {groupedOptions[categoryName].map(
                    ({ id, name, studentCount, categoryName }, idx) => (
                      <li
                        key={id}
                        className={`w-full pb-1.5 pt-1.5 ${
                          idx !== 0 ? 'option-separator' : ''
                        }`}
                      >
                        <Button
                          className={`w-full pb-1.5 pt-1.5 hover:underline ${
                            selected?.id === id ? 'underline' : ''
                          }`}
                          onClick={(e) =>
                            handleSelect(
                              { id, name, studentCount, categoryName },
                              e,
                            )
                          }
                        >
                          <Row
                            className="items-center justify-between"
                            {...rest}
                          >
                            <span className="w-36">{name}</span>
                            {studentCount !== null ? (
                              <Row className="items-center">
                                <img
                                  src={headsBlueFilled}
                                  alt="Student Count"
                                  className="h-4 w-4 mr-2"
                                />
                                <span className="text-light-blue-500">
                                  {studentCount}
                                </span>
                              </Row>
                            ) : null}
                          </Row>
                        </Button>
                      </li>
                    ),
                  )}
                </ul>
              </li>
            ))}
          </ul>
        </Column>
      )}
    </div>
  );
};
