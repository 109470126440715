import { DistrictOrg, Org, SchoolOrg } from 'shared/lib/types/Org';
import { createContext, useContext } from 'react';
import { isDistrict } from 'shared/lib/utils/isDistrict';

export interface AssignedOrganizationContextValues {
  assignedOrganization: Org | null;
}

export const AssignedOrganizationContext = createContext<
  AssignedOrganizationContextValues
>({
  assignedOrganization: null,
});

export function useAssignedOrganizationContext() {
  return useContext(AssignedOrganizationContext);
}

export function useAssignedDistrict(): { assignedDistrict: DistrictOrg } {
  const { assignedOrganization } = useAssignedOrganizationContext();
  if (!assignedOrganization) {
    throw new Error('Error: no assigned district established.');
  }

  if (!isDistrict(assignedOrganization)) {
    throw new Error('Error: assigned organization is not a district.');
  }

  return {
    assignedDistrict: assignedOrganization as DistrictOrg,
  };
}

export function useAssignedSchool(): { assignedSchool: SchoolOrg } {
  const { assignedOrganization } = useAssignedOrganizationContext();
  if (!assignedOrganization) {
    throw new Error('Error: no assigned district established.');
  }

  if (isDistrict(assignedOrganization)) {
    throw new Error('Error: assigned organization is not a district.');
  }

  return {
    assignedSchool: assignedOrganization as SchoolOrg,
  };
}
