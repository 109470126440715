import React, { FC, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { Admin } from 'shared/lib/types/Admin';
import { Org } from 'shared/lib/types/Org';
import { useHistory } from 'react-router-dom';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getAdmin } from '../../api/admin/getAdmin';
import { getAssignedOrganization } from '../../api/admin/getAssignedOrganization';
import { AdminDetail } from '../../components/AdminDetail/AdminDetail';
import { showOkAlert } from '../../components/Alert/Alert';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { formatEditAdminRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';

interface AdminDetailsProps {
  adminId: number;
}

export const AdminDetailsPage: FC<AdminDetailsProps> = ({
  adminId,
  ...rest
}) => {
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [org, setOrg] = useState<Org | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setOrg(null);
        setAdmin(null);
      }

      try {
        const [user, assignedOrg] = await Promise.all([
          getAdmin(adminId),
          getAssignedOrganization(adminId),
        ]);
        if (!isCancelled()) {
          setAdmin(user);
          setOrg(assignedOrg);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed load admin details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [adminId],
  );

  return (
    <Column className="flex-grow pt-14 overflow-hidden" {...rest}>
      {loading || !admin ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle pageTitle={`${formatFirstLast(admin)}`} />
          <Column className="pl-8 lg:pl-15 pr-10 pb-8">
            <Column className="pl-9 pb-2.5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between pr-10">
                <Row className="font-roboto text-2xl text-trueGray-750 items-center">
                  {formatFirstLast(admin)}
                </Row>
                <ActionLink
                  variant="edit"
                  to={{
                    pathname: formatEditAdminRoute(
                      admin.id,
                      admin.role.adminLevel,
                    ),
                  }}
                >
                  Edit {capitalizeFirst(admin.role.adminLevel)} Admin
                </ActionLink>
              </Row>
            </Column>
          </Column>
          <MainPanel>
            <Column className="pl-9 pr-10 pt-6">
              <AdminDetail admin={admin} assignedOrganization={org} />
            </Column>
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
