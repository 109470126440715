import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { Student } from 'shared/lib/types/Student';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudent } from '../../api/student/getStudent';
import { getStudentGroupsForStudent } from '../../api/student/getStudentGroupsForStudent';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { useHistory } from 'react-router-dom';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import { RemoveConfirm } from '../../components/RemoveConfirm/RemoveConfirm';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { showOkAlert, showYesNoAlert } from '../../components/Alert/Alert';
import { deleteStudent } from '../../api/student/deleteStudent';
import { formatSchoolDetailsRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { removeStudentGroupEnrollment } from '../../api/student/removeStudentGroupEnrollment';

interface RemoveStudentFromGroupProps {
  studentGroupId: number;
  studentId: number;
}

export const RemoveStudent: FC<RemoveStudentFromGroupProps> = ({
  studentId,
  studentGroupId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [studentGroup, setStudentGroup] = useState<StudentGroup | null>(null);
  const [studentGroups, setStudentGroups] = useState<StudentGroup[]>([]);
  const [student, setStudent] = useState<Student | null>(null);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudent(null);
        setStudentGroup(null);
      }
      try {
        const groups = await getStudentGroupsForStudent(studentId);
        const group = groups.find((group) => group.id === studentGroupId);
        if (!group) {
          await showOkAlert({
            title: 'Error',
            text: `This student is not enrolled in this class`,
            theme: 'error',
          });
          history.goBack();
          return;
        }
        const studentInfo = await getStudent(studentId);

        if (!isCancelled()) {
          setStudent(studentInfo);
          setStudentGroup(group);
          setStudentGroups(groups);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare for removing student from class: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [studentId, studentGroupId],
  );

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!student || !studentGroup) {
        return;
      }
      let shouldDeleteStudent = false;
      if (studentGroups.length <= 1) {
        shouldDeleteStudent = await showYesNoAlert({
          title: 'Delete Student?',
          text:
            "This is this student's last class. Do you wish to delete the student? This operation cannot be undone.",
        });
      }
      try {
        if (shouldDeleteStudent) {
          await deleteStudent(student.id);
        } else {
          await removeStudentGroupEnrollment({
            studentGroupId,
            studentId: student.id,
          });
        }
        history.replace(
          `${formatSchoolDetailsRoute({
            id: studentGroup.schoolId,
          })}?selectedClass=${studentGroup.id}`,
        );
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to remove student from class: ${e.message}`,
          theme: 'error',
        });
      }
    },
    [student, studentGroup, studentGroups, history, studentGroupId],
  );
  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || !student ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle pageTitle={`Remove ${formatFirstLast(student)}`} />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {formatFirstLast(student)}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    Remove Student
                  </Row>
                </Column>
              </Row>
            </Column>
          </Column>
          <MainPanel className="pr-8 lg:pr-15">
            <RemoveConfirm
              recordId={student.id}
              removalType="Student"
              recordName={formatFirstLast(student)}
              onCancelled={() => history.goBack()}
              warningText={`You are about to remove Student - ${formatFirstLast(
                student,
              )} - from ${
                studentGroup?.name ?? 'this class'
              }. Once removed, this action cannot be undone.`}
              confirmButtonText="Remove Student"
              onSubmit={onSubmit}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
