import React, { FC } from 'react';
import { Column } from '../../components/Column/Column';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { BackButton } from '../../components/BackButton/BackButton';
import { useHistory } from 'react-router-dom';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Row } from '../../components/Row/Row';
import { AssessorForm } from '../../components/AssessorForm/AssessorForm';
import { useAssessorEditor } from '../../hooks/user/useAssessorEditor';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { formatRemoveAssessorFromGroupRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { PillButton } from '../../components/PillButton/PillButton';
import { useResendInvite } from '../../hooks/user/useResendInvite';

interface AssessorEditorProps {
  studentGroupId: number | null;
  assessorId: number | null;
}

export const AssessorEditor: FC<AssessorEditorProps> = ({
  studentGroupId,
  assessorId,
  ...rest
}) => {
  const history = useHistory();
  const {
    loading,
    studentGroup,
    editableAssessor,
    assessor,
    ...formProps
  } = useAssessorEditor({ studentGroupId, assessorId });
  const { reinviteUser, sendPending } = useResendInvite();

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      <PageTitle
        pageTitle={
          assessor ? `Edit ${formatFirstLast(assessor)}` : 'Create Teacher'
        }
      />
      {loading || (!studentGroup && !assessor) ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle
            pageTitle={`${assessor ? `Edit Teacher` : `Add New Teacher`}`}
          />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
                <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
                  {studentGroup?.school.name}
                </Row>
                <div>&nbsp;</div>
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750 items-center">
                    {assessor?.name ?? studentGroup?.name}
                    {assessor?.pending && (
                      <div className="text-light-blue-500 font-roboto font-normal text-base ml-3">
                        (Pending)
                      </div>
                    )}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    {assessor ? `Edit Teacher` : `Add New Teacher`}
                  </Row>
                </Column>
                {assessor && (
                  <Row className="items-center">
                    {assessor.pending && (
                      <PillButton
                        buttonVariation="primary"
                        className="mr-6"
                        onClick={() => reinviteUser(assessor)}
                      >
                        Resend Invite
                      </PillButton>
                    )}
                    <ActionLink
                      variant="remove"
                      replace
                      to={formatRemoveAssessorFromGroupRoute(assessor)}
                    >
                      Remove User
                    </ActionLink>
                  </Row>
                )}
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <AssessorForm
              editMode={assessor !== null}
              studentGroupName={studentGroup?.name ?? ''}
              onCancelled={() => history.goBack()}
              assessor={editableAssessor}
              className="pt-6 pl-9"
              {...formProps}
            />
          </MainPanel>
          {sendPending && <SpinnerOverlay opaque />}
        </Column>
      )}
    </Column>
  );
};
