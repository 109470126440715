import React, { FC, useMemo } from 'react';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { clockBlue } from '../../images';
import { InProgressStudentsDropdown } from '../InProgressStudentsDropdown/InProgressStudentsDropdown';
import { Language } from 'shared/lib/constants/Language';
import './BapsInProgress.css';
import { BAPWithFullData } from 'shared/lib/types/BAP';
import { unique } from 'shared/lib/utils/unique';

interface BapsInProgressProps {
  inProgressBAPS: ReadonlyArray<
    Pick<BAPWithFullData, 'student' | 'bapAssessments'>
  >;
  className?: string;
  onResumeClicked(studentId: number, languages: ReadonlyArray<Language>): void;
}

export const BapsInProgress: FC<BapsInProgressProps> = ({
  inProgressBAPS,
  className = '',
  onResumeClicked,
  ...rest
}) => {
  const { total, totalSpanish, totalEnglish } = useMemo(() => {
    const assessments = inProgressBAPS
      .flatMap((bap) => bap.bapAssessments)
      .filter(({ completedAt }) => completedAt === null);
    return {
      total: unique(assessments.map((assessment) => assessment.bapId)).length,
      totalSpanish: assessments.filter(
        ({ language }) => language === Language.SPANISH,
      ).length,
      totalEnglish: assessments.filter(
        ({ language }) => language === Language.ENGLISH,
      ).length,
    };
  }, [inProgressBAPS]);
  return (
    <Column
      className={`bg-white shadow-dashboardShadow pl-6 pr-6 pt-3 items-center justify-between h-32 rounded-xl ${className}`}
      {...rest}
    >
      <Row className="w-full mb-2 items-center">
        <img src={clockBlue} alt="In Progress" className="w-6 h-6 absolute" />
        <div className="relative justify-center w-full text-center text-light-blue-500 font-bold">
          BAPS In Progress
        </div>
      </Row>
      <Row className="w-full flex-wrap justify-between pr-6 pl-6">
        <Column className="items-center flex-grow flex-shrink-0">
          <div className="font-light text-sm font-roboto text-trueGray-750 mb-2">
            Total
          </div>
          <div className="font-light text-4.5xl text-light-blue-500">
            {total}
          </div>
        </Column>
        <Column className="items-center flex-grow flex-shrink-0">
          <div className="font-light text-sm font-roboto text-trueGray-750 mb-2">
            English
          </div>
          <div className="font-light text-4.5xl text-light-blue-500">
            {totalEnglish}
          </div>
        </Column>
        <Column className="items-center flex-grow flex-shrink-0">
          <div className="font-light text-sm font-roboto text-trueGray-750 mb-2">
            Spanish
          </div>
          <div className="font-light text-4.5xl text-light-blue-500">
            {totalSpanish}
          </div>
        </Column>
      </Row>
      <Row className="w-full justify-end left-6 baps-in-progress-student-dropdown">
        <InProgressStudentsDropdown
          inProgressBAPS={inProgressBAPS}
          onResumeClicked={onResumeClicked}
        />
      </Row>
    </Column>
  );
};
