import React, { FC, useCallback } from 'react';
import {
  ActivityAnswer,
  CorrectIncorrectAnswerChoice,
  getActivityAnswersOfSameType,
  ImageAnswerChoice,
  isImageAnswerChoice,
} from 'shared/lib/types/assessment/ActivityAnswer';
import { AssessmentImageAnswerButtons } from '../AssessmentImageAnswerButtons/AssessmentImageAnswerButtons';
import { AssessmentCorrectIncorrectButtons } from '../AssessmentCorrectIncorrectButtons/AssessmentCorrectIncorrectButtons';
import { CorrectIncorrect } from 'shared/lib/constants/assessment/CorrectIncorrect';

interface ActivityAnswerButtonsProps {
  answers: ReadonlyArray<ActivityAnswer>;
  selectedAnswer: ActivityAnswer | null;
  onAnswerSelected(answer: ActivityAnswer): void;
}

/**
 * The purpose of this component is to support mixed ActivityAnswers (which are either images or Correct/Incorrect buttons)
 */
export const ActivityAnswerButtons: FC<ActivityAnswerButtonsProps> = ({
  answers,
  ...rest
}) => {
  if (!answers.length) {
    return null;
  }
  const coercedAnswers = getActivityAnswersOfSameType(answers);
  const [answerChoice1, ...remainingChoices] = coercedAnswers;

  if (isImageAnswerChoice(answerChoice1)) {
    return (
      <ImageActivityAnswerButtons
        answers={[answerChoice1, ...(remainingChoices as ImageAnswerChoice[])]}
        {...rest}
      />
    );
  }

  return (
    <CorrectIncorrectActivityAnswerButtons
      answers={[
        answerChoice1,
        ...(remainingChoices as CorrectIncorrectAnswerChoice[]),
      ]}
      {...rest}
    />
  );
};

interface ImageActivityAnswerButtonsProps extends ActivityAnswerButtonsProps {
  answers: ReadonlyArray<ImageAnswerChoice>;
}

const ImageActivityAnswerButtons: FC<ImageActivityAnswerButtonsProps> = ({
  answers,
  selectedAnswer,
  onAnswerSelected,
}) => {
  const handleAnswerSelected = useCallback(
    ({ id }: ImageAnswerChoice) => {
      const answer = answers.find((answer) => answer.id === id);
      if (answer) {
        onAnswerSelected(answer);
      }
    },
    [onAnswerSelected, answers],
  );
  return (
    <AssessmentImageAnswerButtons
      choices={answers}
      selected={
        answers.find((answer) => answer.id === selectedAnswer?.id) ?? null
      }
      onClick={handleAnswerSelected}
    />
  );
};

interface CorrectIncorrectActivityAnswerButtonsProps
  extends ActivityAnswerButtonsProps {
  answers: ReadonlyArray<CorrectIncorrectAnswerChoice>;
}

const CorrectIncorrectActivityAnswerButtons: FC<CorrectIncorrectActivityAnswerButtonsProps> = ({
  answers,
  selectedAnswer,
  onAnswerSelected,
}) => {
  const handleAnswerSelected = useCallback(
    (value: CorrectIncorrect) => {
      const answer = answers.find((answer) => answer.value === value);
      if (answer) {
        onAnswerSelected(answer);
      }
    },
    [onAnswerSelected, answers],
  );
  return (
    <AssessmentCorrectIncorrectButtons
      selected={
        answers.find((answer) => answer.id === selectedAnswer?.id)?.value ??
        null
      }
      onClick={handleAnswerSelected}
    />
  );
};
