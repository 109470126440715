import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { capitalize, Language } from 'shared/lib/constants/Language';
import { Column } from '../Column/Column';
import { Button } from '../Button/Button';
import { headBlue, whiteTriangle } from '../../images';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import { Row } from '../Row/Row';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { InProgressBAP } from 'shared/lib/types/dashboard/InProgressBAP';
import { BAPWithFullData } from 'shared/lib/types/BAP';
import { BAPAssessment } from 'shared/lib/types/BAPAssessment';

interface InProgressStudentsDropdownProps
  extends ComponentPropsWithoutRef<'div'> {
  inProgressBAPS: ReadonlyArray<
    Pick<BAPWithFullData, 'student' | 'bapAssessments'>
  >;
  onResumeClicked(studentId: number, languages: ReadonlyArray<Language>): void;
}

function formatInProgressLanguages({
  languages,
}: Pick<InProgressBAP, 'languages'>) {
  if (languages.length) {
    if (languages.length > 1) {
      return 'Both';
    }
    return capitalize(languages[0]);
  }
  return '';
}

function getIncompleteLanguages(bapAssessments: BAPAssessment[]): Language[] {
  const incomplete = bapAssessments.filter(
    (bapAssessment) => bapAssessment.completedAt === null,
  );
  // If there are started but not completed assessments, return the languages for those.
  if (incomplete.length) {
    return incomplete.map((bapAssessment) => bapAssessment.language);
  }
  // Otherwise there is either 1 completed or both completed.
  // In the case of both being completed, return an empty list (this really should have been prevented by the API).
  // Otherwise return the unstarted language
  // The bold assumption is that there are, at most, 2 BAPAssessments per BAP
  if (
    bapAssessments.filter((bapAssessment) => bapAssessment.completedAt)
      .length >= 2
  ) {
    return [];
  }
  const [lastAssessment] = bapAssessments;

  return [
    lastAssessment.language === Language.ENGLISH
      ? Language.SPANISH
      : Language.ENGLISH,
  ];
}

export const InProgressStudentsDropdown: FC<InProgressStudentsDropdownProps> = ({
  inProgressBAPS,
  className = '',
  onResumeClicked,
  ...rest
}) => {
  const [open, toggleOpen, setOpen] = useToggleState(false);
  const onClickOutside = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const rootRef = useOnClickOutside<HTMLDivElement>(onClickOutside);

  return (
    <div
      className={`relative flex flex-col w-10 h-10 ${className}`}
      ref={rootRef}
      {...rest}
    >
      <Button
        className={`rounded-tl-xl rounded-br-xl justify-center items-center bg-light-blue-500 h-10 flex-grow flex-shrink-0 ${
          inProgressBAPS.length < 1 ? 'invisible' : 'visible'
        }`}
        onClick={toggleOpen}
        disabled={inProgressBAPS.length < 1}
      >
        <img
          src={whiteTriangle}
          alt={open ? 'Close' : 'Open'}
          className={`w-6 h-6 relative transition-transform transform duration-500 ${
            open ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </Button>
      {open && (
        <Column className="absolute right-76 top-3 flex-grow flex-shrink-0 rounded-xl shadow-dashboardShadow w-82 max-h-56 overflow-y-auto pl-3 pt-3 bg-white z-50">
          {inProgressBAPS.map(({ student, bapAssessments }) => {
            // Should not be the case if handled properly on the server
            if (!bapAssessments.length) {
              return null;
            }
            const languages = getIncompleteLanguages(bapAssessments);
            return (
              <Row
                className="items-center pt-3 pb-3 border-b border-gray-200"
                key={student.id}
              >
                <Row className="w-full max-w-76 justify-between pl-1">
                  <Row className="items-center">
                    <img
                      src={headBlue}
                      alt={formatFirstLast(student)}
                      className="w-6 h-6 mr-2"
                    />
                    <span
                      title={formatFirstLast(student)}
                      className="whitespace-nowrap overflow-hidden overflow-ellipsis max-w-45"
                    >
                      {formatFirstLast(student)}
                    </span>
                  </Row>
                  <Button
                    className="rounded-2.25xl bg-light-blue-500 text-white font-roboto text-sm font-medium w-28 justify-center pt-2 pb-2"
                    onClick={() => onResumeClicked(student.id, languages)}
                  >
                    {formatInProgressLanguages({ languages })}
                  </Button>
                </Row>
              </Row>
            );
          })}
        </Column>
      )}
    </div>
  );
};
