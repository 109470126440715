import React, { FC } from 'react';
import { Alert } from '../Alert/Alert';
import { Student } from 'shared/lib/types/Student';
import { Language } from 'shared/lib/constants/Language';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { headBlue } from '../../images';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import showModal from '@emberex/react-utils/lib/showModal';

interface ResumeBapAlertProps {
  student: Pick<Student, 'firstName' | 'lastName'>;
  language: Language;
  onConfirmClicked(): void;
  onCancelClicked(): void;
}

export const ResumeBapAlert: FC<ResumeBapAlertProps> = ({
  student,
  language,
  onCancelClicked,
  ...rest
}) => (
  <Alert
    title="Resume BAPS"
    text={<AlertText student={student} language={language} />}
    showExit
    showCancel
    onExitClicked={onCancelClicked}
    onCancelClicked={onCancelClicked}
    {...rest}
  />
);

const AlertText: FC<Pick<ResumeBapAlertProps, 'student' | 'language'>> = ({
  student,
  language,
}) => (
  <Column>
    <Row className="items-center">
      <img src={headBlue} alt="Student:" className="w-6 h-6 mr-2" />
      {formatFirstLast(student)}
    </Row>
    <Row className="mt-3">
      You are about to resume {language.toUpperCase()}. Do you wish to confirm?
    </Row>
  </Column>
);

export function showResumeBapAlert(
  props: Pick<ResumeBapAlertProps, 'student' | 'language'>,
): Promise<boolean> {
  return showModal((resolve) => (
    <ResumeBapAlert
      onConfirmClicked={() => resolve(true)}
      onCancelClicked={() => resolve(false)}
      {...props}
    />
  ));
}
