import React, { ComponentPropsWithoutRef, FC } from 'react';
import { SchoolWithEnrollmentInfo } from 'shared/lib/types/school/SchoolWithEnrollmentInfo';
import { formatSchoolDetailsRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { Link } from 'react-router-dom';
import {
  DashboardList,
  DashboardListHead,
  DashboardListItem,
} from '../DashboardList/DashboardList';
import { headBlue } from '../../images';

interface SchoolListProps extends ComponentPropsWithoutRef<'div'> {
  schools: ReadonlyArray<SchoolWithEnrollmentInfo>;
}

export const SchoolList: FC<SchoolListProps> = ({
  schools,
  className = '',
  ...rest
}) => {
  return (
    <Column className={`overflow-y-hidden h-full ${className}`} {...rest}>
      <DashboardListHead>
        <Row className="w-30 md:w-40 lg:w-60 flex-shrink-0">School Name</Row>
        <Row className="flex-grow flex-shrink-0 max-w-sm justify-between lg:pr-6 lg:pl-14  ">
          <Row className="justify-center flex-grow flex-shrink-0">Classes</Row>
          <Row className="justify-center flex-grow flex-shrink-0">Teachers</Row>
          <Row className="justify-center flex-grow flex-shrink-0">Students</Row>
        </Row>
      </DashboardListHead>
      <DashboardList className="max-h-full overflow-y-auto pb-32">
        {schools.map(({ school, students, classes, assessors }) => (
          <DashboardListItem key={school.id}>
            <Link
              to={formatSchoolDetailsRoute(school)}
              className="flex flex-row items-center w-40 lg:w-60 flex-shrink-0"
            >
              <img src={headBlue} alt="School" className="w-6 h-6 mr-2" />
              {school.name}
            </Link>
            <Row className="flex-grow flex-shrink-0 max-w-sm justify-between lg:pr-6 lg:pl-14">
              <Row className="justify-center flex-grow flex-shrink-0">
                {classes}
              </Row>
              <Row className="justify-center flex-grow flex-shrink-0">
                {assessors}
              </Row>
              <Row className="justify-center flex-grow flex-shrink-0">
                {students}
              </Row>
            </Row>
          </DashboardListItem>
        ))}
      </DashboardList>
    </Column>
  );
};
