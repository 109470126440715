import React, { FC } from 'react';
import { PresignedImageURL } from 'shared/lib/types/asset/PresignedURL';
import { Button } from '../../Button/Button';
import { PresignedURLStatus } from 'shared/lib/constants/asset/PresignedURLStatus';
import { Spinner } from '../../Spinner/Spinner';

interface AssessmentImageAnswerButtonProps {
  image: PresignedImageURL;
  selected: boolean;
  onClick(): void;
  className?: string;
}

function getBackgroundAndBorder(
  selected: boolean,
  { status }: Pick<PresignedImageURL, 'status'>,
) {
  if (status === PresignedURLStatus.ERRORED) {
    return 'bg-red-500 border-red-500';
  }
  return selected
    ? 'bg-light-blue-500 border-light-blue-500'
    : 'bg-white border-white';
}

export const AssessmentImageAnswerButton: FC<AssessmentImageAnswerButtonProps> = ({
  image,
  selected,
  className = '',
  ...rest
}) => {
  const { url, status } = image;
  return (
    <Button
      className={`flex-shrink-0 w-32 h-32 md:w-52 md:h-52 lg:w-60 lg:h-60 xl:w-72 xl:h-72 justify-center items-center rounded-3xl pr-5 pl-5 pt-8 pb-8 bg-opacity-20 border-4 ${getBackgroundAndBorder(
        selected,
        image,
      )} ${className}`}
      disabled={!url || status !== PresignedURLStatus.GOOD}
      {...rest}
    >
      <AssessmentImageAnswer image={image} />
    </Button>
  );
};

const AssessmentImageAnswer: FC<{ image: PresignedImageURL }> = ({ image }) => {
  const { status, url, alt } = image;

  if (status === PresignedURLStatus.LOADING) {
    return <Spinner />;
  }

  if (status === PresignedURLStatus.ERRORED || !url) {
    return (
      <p className="font-bold text-center">
        Failed to load image. Please notify an administrator.
      </p>
    );
  }

  return <img src={url} alt={alt} className="w-full h-full object-contain" />;
};
