import { AssessmentTakeState } from 'shared/lib/types/assessment-take/AssessmentTakeState';
import { apiAxios } from '../apiAxios';
import { AssessmentStepCompletionParams } from '../../types/assessment-take/SubmitAssessmentStepAnswerParams';

export async function progressToNextStep({
  assessmentStepId,
  ...body
}: AssessmentStepCompletionParams): Promise<AssessmentTakeState> {
  const response = await apiAxios.post(
    `/take/step/${assessmentStepId}/next`,
    body,
  );
  return response.data;
}
