import React, { FC, FormEvent, useCallback, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { UnauthenticatedPage } from '../../components/UnauthenticatedPage/UnauthenticatedPage';
import { Row } from '../../components/Row/Row';
import { useHistory } from 'react-router-dom';
import { login } from 'shared/lib/constants/routes/unauthenticatedRoutes';
import { Column } from '../../components/Column/Column';
import { TextInput } from '../../components/TextInput/TextInput';
import './ForgotPassword.css';
import { forgotPassword } from '../../api/auth/forgotPassword';
import { showOkAlert } from '../../components/Alert/Alert';
import { PillButton } from '../../components/PillButton/PillButton';

export const ForgotPassword: FC = (props) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (loading) {
        return;
      }
      setError('');
      if (!email.trim() || !isEmail(email)) {
        setError('Email is invalid.');
        return;
      }
      setLoading(true);
      try {
        await forgotPassword(email);
        await showOkAlert({
          title: 'Success',
          text: 'An email with a recovery link has been sent.',
        });
        history.push(login);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [email, loading, history],
  );

  return (
    <UnauthenticatedPage
      backButton={{
        backButtonText: 'Back to Login Screen',
        onClick: () => history.goBack(),
      }}
      {...props}
    >
      <Column className="flex-grow h-full pt-12 mt-2 w-full items-center justify-center">
        <div className="flex flex-col md:flex-row flex-grow h-full justify-between items-center md:items-start w-full max-w-3xl pl-4 pr-4">
          <Column className="w-72">
            <h1 className="text-4xl font-extrabold">
              Forgot
              <br /> Password
            </h1>
            <h2 className="mb-5 text-trueGray-900 font-roboto font-medium mt-2.5">
              Enter your email, and we will send
              <br /> you a recovery link with directions.
            </h2>
          </Column>
          <form className="flex flex-col h-full" onSubmit={handleSubmit}>
            <TextInput
              type="text"
              value={email}
              placeholder="Email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />

            <Row className="mt-9">
              <PillButton
                type="submit"
                buttonVariation="primary"
                disabled={loading}
              >
                Recover Password
              </PillButton>
            </Row>
            <Row className="h-8 text-red-600">{error}</Row>
          </form>
        </div>
      </Column>
    </UnauthenticatedPage>
  );
};
