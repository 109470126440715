import React, { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';

interface ListToggleButtonProps extends LinkProps {
  active: boolean;
  buttonText: string;
}

export const ListToggleButton: FC<ListToggleButtonProps> = ({
  active,
  buttonText,
  replace = true,
  ...rest
}) => (
  <Link
    className={`relative flex flex-row font-roboto text-sm font-medium border-2 border-solid border-light-blue-500 rounded h-12 w-39 justify-center items-center m-2 ${
      active ? 'text-white bg-light-blue-500' : 'text-light-blue-500'
    }`}
    replace={replace}
    {...rest}
  >
    {buttonText}
  </Link>
);
