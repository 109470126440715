import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { Student } from 'shared/lib/types/Student';
import { StudentGroupAndSchool } from 'shared/lib/types/StudentGroup';
import { useHistory, useLocation } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudentGroup } from '../../api/student-group/getStudentGroup';
import { getStudent } from '../../api/student/getStudent';
import { getPrimaryStudentGroup } from '../../api/student/getPrimaryStudentGroup';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { BackButton } from '../../components/BackButton/BackButton';
import { useStudentForm } from '../../hooks/student/useStudentForm';
import { StudentForm } from '../../components/StudentForm/StudentForm';
import { formatRemoveStudentFromGroupRoute } from 'shared/lib/constants/routes/commonRoutes';
import { updateStudent } from '../../api/student/updateStudent';
import { createAndEnrollStudentInGroup } from '../../api/student-group/createAndEnrollStudentInGroup';
import { formatStudentDetailsRoute } from 'shared/lib/constants/routes/userRoutes';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { Row } from '../../components/Row/Row';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { showOkAlert } from '../../components/Alert/Alert';

interface StudentEditorProps {
  studentId: number | null;
  studentGroupId: number | null;
}

async function loadForCreate(
  studentGroupId: number,
): Promise<[StudentGroupAndSchool, null]> {
  return Promise.all([getStudentGroup(studentGroupId), null]);
}

async function loadForEdit(
  studentId: number,
): Promise<[StudentGroupAndSchool | null, Student]> {
  return Promise.all([
    getPrimaryStudentGroup(studentId),
    getStudent(studentId),
  ]);
}

export const StudentEditor: FC<StudentEditorProps> = ({
  studentId,
  studentGroupId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<Student | null>(null);
  const [
    studentGroup,
    setStudentGroup,
  ] = useState<StudentGroupAndSchool | null>(null);
  const history = useHistory();
  const [error, setError] = useState('');
  const { state } = useLocation<{ sourceClassId?: number }>();
  const sourceClassId = state?.sourceClassId;

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudent(null);
        setStudentGroup(null);
      }
      if (!studentId && !studentGroupId) {
        await showOkAlert({
          title: 'Error',
          text: 'Could not prepare for student creation.',
          theme: 'error',
        });
        history.goBack();
        return;
      }
      try {
        const [group, studentData] = studentId
          ? await loadForEdit(studentId)
          : await loadForCreate(studentGroupId!);
        if (!isCancelled()) {
          setStudentGroup(group);
          setStudent(studentData);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare for student ${
            studentId ? 'creation' : 'update'
          }: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [studentId, studentGroupId],
  );

  const { editableStudent, ...studentFormProps } = useStudentForm(student);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading) {
        return;
      }
      setError('');
      const {
        firstName,
        lastName,
        gender,
        birthDate,
        familyLanguageUse,
      } = editableStudent;
      if (!student) {
        if (!firstName.trim()) {
          setError('Student first name is required.');
          return;
        }

        if (!lastName.trim()) {
          setError('Student last name is required.');
          return;
        }
      }

      if (!gender) {
        setError('Student gender is required.');
        return;
      }

      if (!birthDate) {
        setError('Student D.O.B. is required.');
        return;
      }

      if (!familyLanguageUse) {
        setError('Family Language Use is required');
        return;
      }

      setLoading(true);
      try {
        if (student) {
          setStudent(
            await updateStudent(student.id, {
              firstName: firstName.trim() ? firstName : undefined,
              lastName: lastName.trim() ? lastName : undefined,
              gender,
              birthDate,
              familyLanguageUse,
            }),
          );
          return;
        }
        if (studentGroupId) {
          const created = await createAndEnrollStudentInGroup(studentGroupId, {
            firstName,
            lastName,
            gender,
            birthDate,
            familyLanguageUse,
          });
          history.replace(formatStudentDetailsRoute(created));
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to ${student ? 'update' : 'create'} student: ${
            e.message
          }`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [student, editableStudent, loading, history, studentGroupId],
  );

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {student ? formatFirstLast(student) : ''}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    {student ? 'Edit Student' : 'Add Student'}
                  </Row>
                </Column>
                {(studentGroup || sourceClassId) && student && (
                  <ActionLink
                    variant="remove"
                    replace
                    to={formatRemoveStudentFromGroupRoute(
                      sourceClassId ?? studentGroup!.id,
                      student.id,
                    )}
                  >
                    Remove Student
                  </ActionLink>
                )}
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <StudentForm
              student={editableStudent}
              onCancelled={() => history.goBack()}
              editMode={student !== null}
              onSubmit={onSubmit}
              error={error}
              className="pt-6 pl-9"
              {...studentFormProps}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
