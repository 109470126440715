import { EditableSchool } from '../../types/school/EditableSchool';
import { apiAxios } from '../apiAxios';
import { SchoolOrg } from 'shared/lib/types/Org';

export async function updateSchool({
  id,
  ...updates
}: EditableSchool & { id: number }): Promise<SchoolOrg> {
  const response = await apiAxios.patch(`/school/${id}`, updates);
  return response.data.school;
}
