import React, { FC, ReactNode } from 'react';
import { Row } from '../../Row/Row';
import { Button } from '../../Button/Button';
import { Column } from '../../Column/Column';
import { noop } from 'shared/lib/utils/noop';
import { arrowLeft } from '../../../images';

interface AssessmentPageProps {
  headingContents?: ReactNode;
  /**
   * Show the exit button.
   * Defaults to true.
   */
  showExit?: boolean;
  onExitClicked?(): void;

  /**
   * The text for the exit button.
   * Defaults to Cancel
   */
  exitButtonText?: string;
  /**
   * Show the back button.
   * Defaults to false.
   */
  showBack?: boolean;
  onBackClicked?(): void;
  backDisabled?: boolean;

  /**
   * If a button is hidden, maintain the container its in.
   * Defaults to true.
   */
  maintainHiddenElementLayout?: boolean;
}

export const AssessmentPage: FC<AssessmentPageProps> = ({
  headingContents,
  exitButtonText = 'Cancel',
  onExitClicked = noop,
  children,
  showBack = false,
  onBackClicked = noop,
  backDisabled,
  showExit = true,
  maintainHiddenElementLayout = true,
  ...rest
}) => (
  <Column
    className={`w-full h-full flex-grow flex-shrink-0 font-roboto bg-white overflow-y-hidden`}
    {...rest}
  >
    <Row className="w-full items-center justify-between">
      <AssessmentPageHead>{headingContents}</AssessmentPageHead>
      <Row className="w-58 flex-grow flex-shrink-0 h-full justify-center items-center">
        {showExit && (
          <Button
            className="h-full w-full text-light-blue-500 font-medium justify-center items-center"
            onClick={onExitClicked}
          >
            {exitButtonText}
          </Button>
        )}
      </Row>
    </Row>
    <Row className="h-full pt-15 flex-grow flex-shrink-0 short:pt-2">
      <Column
        className={`w-58 pl-10 flex-grow flex-shrink-0 ${
          !showBack && !maintainHiddenElementLayout ? 'hidden' : ''
        }`}
      >
        {showBack && (
          <Button
            onClick={onBackClicked}
            className="items-center h-10 font-bold"
            disabled={backDisabled}
          >
            <img src={arrowLeft} alt="<-" className="mr-2 h-6 2-6" />
            Back
          </Button>
        )}
      </Column>
      {children}
    </Row>
  </Column>
);

const AssessmentPageHead: FC = (props) => (
  <Row
    className="w-full h-full min-h-21 bg-white pl-10 pr-10 pt-4 pb-4 items-center rounded-br-100px shadow-assessmentHeadShadow"
    {...props}
  />
);
