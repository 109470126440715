import React, {
  ComponentPropsWithoutRef,
  FC,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { Row } from '../Row/Row';
import './DateInput.css';
import { Option } from '../../types/Option';
import times from 'lodash.times';
import { Column } from '../Column/Column';
import { Button } from '../Button/Button';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import { isLeapYear } from 'shared/lib/utils/isLeapYear';
import { Month } from 'shared/lib/constants/Month';
import { DateInputValue } from '../../types/DateInputValue';
import { zeroPad } from 'shared/lib/utils/zeroPad';

const MONTH_OPTIONS: Option[] = times(12, (idx) => ({
  id: idx + 1,
  value: `${zeroPad(idx + 1)}`,
}));

const DAY_OPTIONS: Option[] = times(31, (idx) => ({
  id: idx + 1,
  value: `${zeroPad(idx + 1)}`,
}));

const CURRENT_YEAR = new Date().getFullYear();
const YEAR_OPTIONS = times(100, (idx) => ({
  id: CURRENT_YEAR - idx,
  value: `${CURRENT_YEAR - idx}`,
}));

interface DateInputProps extends ComponentPropsWithoutRef<'div'> {
  value: DateInputValue;
  onDateChange(value: DateInputValue): void;
  invalidDate?: boolean;
}

export const DateInput: FC<DateInputProps> = ({
  className = '',
  invalidDate,
  value,
  onDateChange,
  ...rest
}) => {
  const { day, month, year } = value;

  const dayOptions = useMemo(() => {
    if (month) {
      let maxDay = 31;
      if (month === Month.FEB) {
        maxDay = year && isLeapYear(year) ? 29 : 28;
      } else if (
        month === Month.APRIL ||
        month === Month.JUNE ||
        month === Month.SEP ||
        month === Month.NOV
      ) {
        maxDay = 30;
      }
      return DAY_OPTIONS.filter((option) => option.id <= maxDay);
    }
    return DAY_OPTIONS;
  }, [month, year]);

  const onYearChanged = useCallback(
    (yearValue: number | null) => {
      onDateChange({ ...value, year: yearValue });
    },
    [value, onDateChange],
  );

  const onMonthChanged = useCallback(
    (monthValue: number | null) => {
      onDateChange({ ...value, month: monthValue });
    },
    [value, onDateChange],
  );

  const onDayChanged = useCallback(
    (dayValue: number | null) => {
      onDateChange({ ...value, day: dayValue });
    },
    [onDateChange, value],
  );

  useEffect(() => {
    // If a day is selected and its not an available option, clear it out.
    if (day && !dayOptions.some((d) => d.id === day)) {
      onDayChanged(null);
    }
  }, [dayOptions, onDayChanged, day]);

  return (
    <Column>
      <Row
        className={`h-15 w-80 text-black bg-white items-center rounded border font-roboto font-sm font-medium pl-6 ${
          invalidDate ? 'border-red-500' : 'border-light-blue-500'
        } date-input-root ${className}`}
        {...rest}
      >
        <InputField
          placeholder="MM"
          value={month}
          onSelected={onMonthChanged}
          options={MONTH_OPTIONS}
        />
        <div className="bottom-1">/</div>
        <InputField
          placeholder="DD"
          value={day}
          onSelected={onDayChanged}
          options={dayOptions}
        />
        <div className="bottom-1">/</div>
        <InputField
          placeholder="YY"
          value={year}
          onSelected={onYearChanged}
          options={YEAR_OPTIONS}
          year
        />
      </Row>
    </Column>
  );
};

interface InputFieldProps {
  placeholder: string;
  value: number | null;
  onSelected(value: number): void;
  options: ReadonlyArray<Option>;
  year?: boolean;
}

function formatValue(
  value: number | string | null,
  placeholder: string,
  year?: boolean,
) {
  if (year && value) {
    return value.toString(10).slice(2);
  }
  if (value) {
    return zeroPad(+value);
  }
  return placeholder;
}

const InputField: FC<InputFieldProps> = ({
  placeholder,
  value,
  onSelected,
  options,
  year,
}) => {
  const [showOptions, toggleShowOptions, setShowOptions] = useToggleState(
    false,
  );
  const handleClickOutside = useCallback(() => {
    setShowOptions(false);
  }, [setShowOptions]);
  const root = useOnClickOutside<HTMLDivElement>(handleClickOutside);

  const selectedOption = useMemo(() => {
    return options.find((o) => o.id === value);
  }, [value, options]);

  return (
    <div className="flex flex-col relative" ref={root}>
      <Button
        onClick={(e) => {
          e.preventDefault();
          toggleShowOptions();
        }}
        className="w-8 bg-white text-center justify-center items-center"
      >
        {formatValue(selectedOption?.id || null, placeholder, year)}
      </Button>
      {showOptions && (
        <ul
          className={`absolute top-8 z-10 bg-white border border-black pt-1 w-20 date-selector`}
        >
          {options.map((option) => (
            <li key={option.id} className="pt-1 pb-1">
              <Button
                className={`w-full justify-center pt-1 pb-1 ${
                  value === option.id ? 'bg-blue-100' : ''
                } hover:bg-blue-100`}
                onClick={(e) => {
                  e.preventDefault();
                  onSelected(option.id as number);
                  setShowOptions(false);
                }}
              >
                {option.value}
              </Button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
