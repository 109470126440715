import React, { FC } from 'react';
import { ActivityDemoStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { AssessmentInfoSection } from '../AssessmentInfoSection/AssessmentInfoSection';
import { Row } from '../../Row/Row';
import { ActivityTextDisplay } from '../ActivityTextDisplay/ActivityTextDisplay';

import { ActivityAnswerButtons } from '../ActivityAnswerButtons/ActivityAnswerButtons';
import { AssessmentNavigationButtons } from '../AssessmentNavigationButtons/AssessmentNavigationButtons';
import { Column } from '../../Column/Column';

export const ActivityDemoStep: FC<ActivityDemoStepProps> = ({
  answers,
  selectedAnswer,
  activityStep,
  canProgress,
  onAnswerSelected,
  onBackClicked,
  onNext,
  ...rest
}) => {
  const { info } = activityStep;

  return (
    <AssessmentStepPage
      {...rest}
      showBack={false}
      maintainHiddenElementLayout={false}
    >
      <Row className="w-full items-center pt-6 short:pt-0 mt-2.5">
        <ActivityAnswerButtons
          answers={answers}
          selectedAnswer={selectedAnswer}
          onAnswerSelected={onAnswerSelected}
        />
      </Row>
      <Row className="w-full justify-center pt-15 short:pt-2">
        <AssessmentNavigationButtons
          onNext={onNext}
          onBack={onBackClicked}
          canProgress={canProgress}
        />
      </Row>
      <AssessmentInfoSection>
        <Column className="max-w-lg text-sm">
          {info.map((item, idx) => (
            <Row key={`demo-item-info-${idx}`} className="pb-2.5">
              <span className="mr-6">{idx + 1}.</span>
              <ActivityTextDisplay activityText={item} />
            </Row>
          ))}
        </Column>
      </AssessmentInfoSection>
    </AssessmentStepPage>
  );
};
