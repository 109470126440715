import { useCallback, useEffect, useRef } from 'react';

export function useTimeout(
  timeoutFunction: () => any,
  timeoutMillis = 1000,
  active = true,
) {
  const ready = useRef<boolean | null>(false);
  const timeout = useRef<number>();
  const callback = useRef(timeoutFunction);

  const isReady = useCallback(() => ready.current, []);

  const prepareTimeout = useCallback(() => {
    ready.current = false;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    timeout.current = window.setTimeout(() => {
      ready.current = true;
      callback.current();
    }, timeoutMillis);
  }, [timeoutMillis]);

  const cancel = useCallback(() => {
    ready.current = null;
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
  }, []);

  useEffect(() => {
    callback.current = timeoutFunction;
  }, [timeoutFunction]);

  useEffect(() => {
    if (active) {
      prepareTimeout();
    } else {
      cancel();
    }
    return cancel;
  }, [cancel, prepareTimeout, active]);

  return {
    isReady,
    cancel,
  };
}
