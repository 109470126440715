import { isFirstLast, Named } from 'shared/lib/types/Named';
import { useMemo } from 'react';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { startsWithIgnoreCase } from 'shared/lib/utils/startsWithIgnoreCase';

interface UseNameSearchParams<T extends Named> {
  list: ReadonlyArray<T>;
  search: string;
}

export function useNameSearch<T extends Named>({
  list,
  search,
}: UseNameSearchParams<T>): ReadonlyArray<T> {
  return useMemo(() => {
    return list.filter((name) => {
      if (isFirstLast(name)) {
        const { firstName, lastName } = name;
        return (
          startsWithIgnoreCase(firstName, search) ||
          startsWithIgnoreCase(lastName, search) ||
          startsWithIgnoreCase(formatFirstLast(name), search)
        );
      }
      return startsWithIgnoreCase(formatFirstLast(name), search);
    });
  }, [list, search]);
}
