import React, { ComponentPropsWithRef, FC } from 'react';
import { DistrictOrg, SchoolOrg } from 'shared/lib/types/Org';
import { RemoveConfirm } from '../RemoveConfirm/RemoveConfirm';

interface RemoveSchoolConfirmProps extends ComponentPropsWithRef<'form'> {
  school: Pick<SchoolOrg, 'id' | 'name'>;
  district: Pick<DistrictOrg, 'name'>;
  onCancelled(): void;
}

export const RemoveSchoolConfirm: FC<RemoveSchoolConfirmProps> = ({
  school,
  district,
  ...rest
}) => {
  const { id, name } = school;
  return (
    <RemoveConfirm
      recordId={id}
      removalType="School"
      recordName={name}
      warningText={`You are about to Remove School - ${name} - from ${district.name}. Once removed, this action cannot be undone.`}
      confirmButtonText="Remove School"
      {...rest}
    />
  );
};
