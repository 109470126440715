import { EditableDistrict } from '../../types/district/EditableDistrict';
import { DistrictOrg } from 'shared/lib/types/Org';
import { apiAxios } from '../apiAxios';

export async function createDistrict(
  body: EditableDistrict,
): Promise<DistrictOrg> {
  const response = await apiAxios.put('/district', body);
  return response.data;
}
