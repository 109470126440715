import React, { FC } from 'react';
import { AlertModal, AlertModalProps } from '../AlertModal/AlertModal';

interface InputAlertProps
  extends Omit<AlertModalProps, 'showConfirm' | 'reverseButtonOrder'> {
  value: string;
  onChange(value: string): void;
  placeholder?: string;
}

export const InputAlert: FC<InputAlertProps> = ({
  value,
  onChange,
  placeholder,
  children,
  ...rest
}) => (
  <AlertModal showConfirm reverseButtonOrder {...rest}>
    {children}
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="border border-trueGray-425 rounded h-21 w-82 font-bold text-trueGray-750 p-4 placeholder-trueGray-750 resize-none"
    />
  </AlertModal>
);
