import React, { FC, useMemo, useState } from 'react';
import { BAPWithFullData } from 'shared/lib/types/BAP';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getBAP } from '../../api/baps/getBAP';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import {
  assignedSchool,
  districts,
  formatSchoolDetailsRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import { students } from 'shared/lib/constants/routes/assessorRoutes';
import { getPrimaryStudentGroup } from '../../api/student/getPrimaryStudentGroup';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { useLoggedInUserContext } from '../../contexts/userContext';
import { showOkAlert } from '../../components/Alert/Alert';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { BAPAssessmentResults } from '../../components/BAPAssessmentResults/BAPAssessmentResults';

interface AssessmentResultPageProps {
  bapId: number;
}

export const AssessmentResultsPage: FC<AssessmentResultPageProps> = ({
  bapId,
  ...rest
}) => {
  const [bap, setBap] = useState<BAPWithFullData | null>(null);
  const [
    primaryStudentGroup,
    setPrimaryStudentGroup,
  ] = useState<StudentGroup | null>(null);
  const [loading, setLoading] = useState(true);
  const { user } = useLoggedInUserContext();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setBap(null);
      }
      try {
        const bapData = await getBAP(bapId);
        const { student } = bapData;
        const studentGroup = await getPrimaryStudentGroup(student.id);

        if (!isCancelled()) {
          setBap(bapData);
          setPrimaryStudentGroup(studentGroup);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare assessment result details: ${e.message}. Student's results are saved.`,
          theme: 'error',
        });
      }
    },
    [bapId],
  );

  const classListRoute = useMemo(() => {
    if (isAdmin(user)) {
      const { role } = user;
      if (role.adminLevel === AdminLevel.SCHOOL) {
        return assignedSchool;
      }
      if (primaryStudentGroup) {
        return formatSchoolDetailsRoute({ id: primaryStudentGroup.schoolId });
      }
      return role.adminLevel === AdminLevel.DISTRICT ? home : districts;
    }
    return students;
  }, [user, primaryStudentGroup]);

  if (loading) {
    return <SpinnerOverlay />;
  }

  return (
    <>
      <PageTitle pageTitle="Assessment Results" />
      <BAPAssessmentResults
        bap={bap}
        classListRoute={classListRoute}
        showScaledScore={isAdmin(user)}
        includeLanguage
      />
    </>
  );
};
