import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { StudentGroupEditor } from '../../pages/StudentGroupEditor/StudentGroupEditor';
import { RemoveClass } from '../../pages/RemoveClass/RemoveClass';
import { AssessorEditor } from '../../pages/AssessorEditor/AssessorEditor';
import {
  editClass,
  enrollAssessorInGroupRoute,
  formatEditClassRoute,
  removeClass,
} from 'shared/lib/constants/routes/administratorRoutes';
import { StudentEditor } from '../../pages/StudentEditor/StudentEditor';
import {
  addStudentToGroupRoute,
  enrollStudentInGroupRoute,
  removeStudentFromGroup,
} from 'shared/lib/constants/routes/commonRoutes';
import { RemoveStudent } from '../../pages/RemoveStudent/RemoveStudent';
import { useLoggedInUserContext } from '../../contexts/userContext';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import { GroupEnrollmentEditor } from '../../pages/GroupEnrollmentEditor/GroupEnrollmentEditor';

interface ClassRoutesProps {
  studentGroupId: number;
}

export const ClassRoutes: FC<ClassRoutesProps> = ({ studentGroupId }) => {
  const { user } = useLoggedInUserContext();
  return (
    <Switch>
      <Route path={editClass}>
        <StudentGroupEditor studentGroupId={studentGroupId} school={null} />;
      </Route>
      <Route path={removeClass}>
        <RemoveClass studentGroupId={studentGroupId} />;
      </Route>
      {isAdmin(user) && (
        <Route path={enrollAssessorInGroupRoute}>
          <AssessorEditor studentGroupId={studentGroupId} assessorId={null} />
        </Route>
      )}
      <Route path={enrollStudentInGroupRoute}>
        <StudentEditor studentId={null} studentGroupId={studentGroupId} />
      </Route>
      <Route path={addStudentToGroupRoute}>
        <GroupEnrollmentEditor studentGroupId={studentGroupId} mode="Student" />
      </Route>
      <Route path={removeStudentFromGroup}>
        {({ match }) => {
          if (!match) {
            return null;
          }
          const studentId = +match.params.studentId;
          if (Number.isNaN(studentId)) {
            return <Redirect to={formatEditClassRoute(studentGroupId)} />;
          }
          return (
            <RemoveStudent
              studentGroupId={studentGroupId}
              studentId={studentId}
            />
          );
        }}
      </Route>
    </Switch>
  );
};
