import { DashboardData } from 'shared/lib/types/dashboard/DashboardData';
import { apiAxios } from '../apiAxios';

interface Query {
  filteredSchoolId: number | null;
  filteredClassId: number | null;
  filteredDistrictId: number | null;
}

export async function getDashboardData(params: Query): Promise<DashboardData> {
  const response = await apiAxios.get('/dashboard', { params });
  return response.data;
}

interface CSVQuery extends Query {
  timezone: string;
}

export async function getDashboardDataCSV(params: CSVQuery) {
  return apiAxios.get('/dashboard/csv', { params });
}
