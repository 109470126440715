import React, {
  ComponentPropsWithoutRef,
  FC,
  FormEvent,
  useCallback,
} from 'react';
import { Org } from 'shared/lib/types/Org';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { TextInput } from '../TextInput/TextInput';
import { PillButton } from '../PillButton/PillButton';
import { OrgType } from 'shared/lib/constants/org/OrgType';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { FormLabel } from '../FormLabel/FormLabel';

interface OrgFormProps extends ComponentPropsWithoutRef<'form'> {
  editMode: boolean;
  orgName: string;
  org: Pick<Org, 'name' | 'location' | 'researchOrg'>;
  orgType: OrgType;
  onCancelled(): void;
  onNameChanged(name: string): void;
  onLocationChanged(location: string): void;
  onResearchOrgChanged(researchOrg: boolean): void;
  error?: string;
}

export const OrgForm: FC<OrgFormProps> = ({
  error,
  orgName,
  editMode,
  className = '',
  org,
  onCancelled,
  onNameChanged,
  onLocationChanged,
  orgType,
  onResearchOrgChanged,
  ...rest
}) => {
  const { name, location, researchOrg } = org;

  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );

  return (
    <form className={`flex flex-col ${className}`} {...rest}>
      <Column className="w-full max-w-2.75xl">
        <Row className="w-full flex-grow flex-shrink-0 justify-between flex-wrap pr-4">
          <Column>
            <FormLabel htmlFor="org-name">
              {capitalizeFirst(orgType)} Name
            </FormLabel>
            <TextInput
              type="text"
              onChange={(e) => onNameChanged(e.target.value)}
              id="org-name"
              value={name}
              placeholder={`${capitalizeFirst(orgType)} Name`}
              className="mt-2 mb-2"
              required
            />
          </Column>
          <Column>
            <FormLabel htmlFor="location" className="ml-2">
              Location
            </FormLabel>
            <TextInput
              type="text"
              id="location"
              placeholder="Location"
              value={location}
              onChange={(e) => onLocationChanged(e.target.value)}
              className="mt-2"
              required
            />
          </Column>
        </Row>
        {orgType === OrgType.DISTRICT && (
          <Row className="w-full flex-grow flex-shrink-0 justify-between flex-wrap pr-4  mt-4">
            <Row className="items-center">
              <FormLabel htmlFor="researchOrg" className="ml-2">
                Research District:
              </FormLabel>
              <input
                type="checkbox"
                id="researchOrg"
                className="relative ml-2 -top-1"
                onChange={() => onResearchOrgChanged(!researchOrg)}
                checked={researchOrg}
              />
            </Row>
          </Row>
        )}
        <Row className="items-center flex-wrap pt-6">
          <PillButton
            type="submit"
            buttonVariation="primary"
            className="mt-9 mb-9"
          >
            {editMode ? 'Save Changes' : `Add New ${capitalizeFirst(orgType)}`}
          </PillButton>
          <PillButton
            onClick={handleCancel}
            buttonVariation="cancel"
            className="m-9"
          >
            Cancel
          </PillButton>
        </Row>
        <Row className="pt-3 text-red-600">{error}</Row>
      </Column>
    </form>
  );
};
