import React, { FC, FormEvent, useCallback, useState } from 'react';
import { UnauthenticatedPage } from '../../components/UnauthenticatedPage/UnauthenticatedPage';
import { useSearchParams } from '../../hooks/useSearchParams';
import { Redirect, useHistory } from 'react-router-dom';
import { login } from 'shared/lib/constants/routes/unauthenticatedRoutes';
import { resetPassword } from '../../api/auth/resetPassword';
import { Column } from '../../components/Column/Column';
import { TextInput } from '../../components/TextInput/TextInput';
import { Row } from '../../components/Row/Row';
import { showOkAlert } from '../../components/Alert/Alert';
import { PillButton } from '../../components/PillButton/PillButton';

export const ResetPassword: FC = (props) => {
  const params = useSearchParams();
  const history = useHistory();
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (loading) {
        return;
      }
      if (password.length < 6) {
        setError('Password must be 6 or more characters.');
        return;
      }
      if (password !== passwordConfirm) {
        setError('Password and Password Confirm do not match.');
        return;
      }
      const code = params.get('code');
      const email = params.get('email');
      if (!code || !email) {
        history.replace(login);
        return;
      }
      setLoading(true);
      try {
        await resetPassword({ password, code, email });
        await showOkAlert({
          title: 'Success',
          text:
            'Your password has been reset. For security reasons, you will need to login.',
        });
        history.replace(login);
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    },
    [password, passwordConfirm, loading, history, params],
  );

  if (!params.has('code') || !params.has('email')) {
    return <Redirect to={login} />;
  }
  return (
    <UnauthenticatedPage {...props}>
      <div className="flex lg:flex-row flex-col justify-center self-center flex-grow">
        <Column className="justify-center w-60 pb-36">
          <h1 className="font-bold text-4xl">
            Reset
            <br /> Password
          </h1>
        </Column>
        <form
          className="flex flex-col justify-center sm:pl-0 lg:pl-5"
          onSubmit={handleSubmit}
        >
          <TextInput
            type="password"
            value={password}
            placeholder="New Password"
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <TextInput
            type="password"
            value={passwordConfirm}
            placeholder="Confirm Password"
            onChange={(e) => setPasswordConfirm(e.target.value)}
            required
            className="mt-8"
          />

          <Row className="mt-9">
            <PillButton
              type="submit"
              buttonVariation="primary"
              disabled={loading}
            >
              Reset Password
            </PillButton>
          </Row>
          <Row className="h-8 text-red-600">{error}</Row>
        </form>
      </div>
    </UnauthenticatedPage>
  );
};
