import { apiAxios } from '../apiAxios';
import { AssessorDetail } from 'shared/lib/types/AssessorDetail';
import { EditableUser } from '../../types/user/EditableUser';

export async function updateAssessor(
  assessorId: number,
  body: EditableUser,
): Promise<AssessorDetail> {
  const response = await apiAxios.patch(`/assessor/${assessorId}`, body);
  return response.data;
}
