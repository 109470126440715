import React, { FC } from 'react';
import { Column } from '../Column/Column';
import { logo } from '../../images';
import { Row } from '../Row/Row';
import { BackButton } from '../BackButton/BackButton';

interface UnauthenticatedPageProps {
  backButton?: {
    onClick(): void;
    backButtonText: string;
  };
}

export const UnauthenticatedPage: FC<UnauthenticatedPageProps> = ({
  backButton,
  children,
  ...rest
}) => (
  <Row className="h-screen min-w-screen" {...rest}>
    <Column className="w-full min-h-supported overflow-hidden overflow-y-auto pt-12 mt-2">
      <Column className="pl-2 flex-grow items-center">
        <Row className="w-full max-w-4xl justify-center items-center flex-wrap">
          {backButton && (
            <div className="relative md:absolute md:left-10">
              <BackButton variation="circled" onClick={backButton.onClick}>
                {backButton.backButtonText}
              </BackButton>
            </div>
          )}
          <img src={logo} alt="BAPS" className="h-52 w-74" />
        </Row>
        {children}
      </Column>
    </Column>
  </Row>
);
