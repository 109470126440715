import React, { ComponentPropsWithRef, FC } from 'react';
import { RemoveConfirm } from '../RemoveConfirm/RemoveConfirm';
import { DistrictOrg } from 'shared/lib/types/Org';

interface RemoveDistrictConfirmProps extends ComponentPropsWithRef<'form'> {
  district: DistrictOrg;
  onCancelled(): void;
}
export const RemoveDistrictConfirm: FC<RemoveDistrictConfirmProps> = ({
  district,
  ...rest
}) => {
  const { id, name } = district;
  return (
    <RemoveConfirm
      recordId={id}
      removalType="District"
      recordName={name}
      warningText={`You are about to remove ${name} from the BAPS system. Once Removed, this action cannot be undone.`}
      confirmButtonText="Remove District"
      {...rest}
    />
  );
};
