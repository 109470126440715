import { User } from 'shared/lib/types/User';
import { apiAxios } from '../apiAxios';
import { EditableUser } from '../../types/user/EditableUser';

export async function createAndEnrollAssessorInGroup(
  studentGroupId: number,
  body: EditableUser,
): Promise<User> {
  const response = await apiAxios.put(
    `/student-group/${studentGroupId}/enroll/teacher`,
    body,
  );
  return response.data;
}
