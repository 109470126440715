import { ComponentPropsWithoutRef, FC } from 'react';
import { LanguagePercentile } from 'shared/lib/types/assessment/PercentileReport';
import { formatMonthDayYear } from 'shared/lib/utils/formatDate';
import { Column } from '../Column/Column';
import { Button } from '../Button/Button';
import { largeClockBlue } from '../../images';
import { Row } from '../Row/Row';
import { twMerge } from 'tailwind-merge';

function formatLabel(percentile: LanguagePercentile): string {
  const { inProgress, date } = percentile;
  if (inProgress) {
    return 'Continue';
  }
  return formatMonthDayYear(date, {
    outputTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
}

interface PercentileButtonProps extends ComponentPropsWithoutRef<'div'> {
  percentile: LanguagePercentile | null;
  onResumeClicked(): void;
  onScoreClicked?(): void;
  disabled?: boolean;
  fontSize?: 'text-2xl' | 'text-base';
}

export const PercentileButton: FC<PercentileButtonProps> = ({
  percentile,
  onResumeClicked,
  className,
  onScoreClicked,
  disabled,
  fontSize = 'text-2xl',
  ...rest
}) => (
  <Column
    className={twMerge('justify-center items-center', className)}
    {...rest}
  >
    <Button
      className={twMerge(
        'rounded-full text-light-blue-500 justify-center items-center w-12 h-12 font-mplus',
        fontSize,
        !percentile?.inProgress && 'border border-solid border-light-blue-500',
      )}
      onClick={percentile?.inProgress ? onResumeClicked : onScoreClicked}
      disabled={disabled}
    >
      {percentile === null && '-'}
      {percentile !== null &&
        (percentile.inProgress ? (
          <img src={largeClockBlue} alt="Resume" className="w-12 h-12" />
        ) : (
          percentile.score
        ))}
    </Button>
    <Row className="font-roboto font-normal justify-center items-end h-5 text-xss w-20">
      {percentile ? formatLabel(percentile) : <>&nbsp;</>}
    </Row>
  </Column>
);
