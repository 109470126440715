import React, { ComponentPropsWithoutRef, FC } from 'react';
import { Row } from '../../Row/Row';
import { Column } from '../../Column/Column';

interface AssessmentOrderedListItemProps
  extends ComponentPropsWithoutRef<'div'> {
  order: number;
}

export const AssessmentOrderedListItem: FC<AssessmentOrderedListItemProps> = ({
  order,
  children,
  ...rest
}) => (
  <Row {...rest}>
    <Column className="font-mplus text-2xl text-light-blue-500 bg-white border border-light-blue-500 h-12 w-12 rounded-full mr-6 flex-shrink-0 justify-center items-center">
      {order}
    </Column>
    {children}
  </Row>
);
