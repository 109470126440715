import React, { FC, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { DistrictOrg } from 'shared/lib/types/Org';
import { SchoolWithEnrollmentInfo } from 'shared/lib/types/school/SchoolWithEnrollmentInfo';
import { Admin } from 'shared/lib/types/Admin';
import { useHistory, useLocation } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getDistrict } from '../../api/district/getDistrict';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { getDistrictSchools } from '../../api/district/getDistrictSchools';
import { useDistrictDashboard } from '../../hooks/district/useDistrictDashboard';
import { DistrictDashboard } from '../../components/DistrictDashboard/DistrictDashboard';
import { useLoggedInAdminContext } from '../../contexts/userContext';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { useAssignedDistrict } from '../../contexts/assignedOrganizationContext';
import { getDistrictAdmins } from '../../api/district/getDistrictAdmins';
import { showOkAlert } from '../../components/Alert/Alert';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import {
  districts,
  formatDistrictDetailsRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { useResendInvite } from '../../hooks/user/useResendInvite';

interface DistrictDetailsProps {
  /**
   * The ID of the district (if the user is a super admin), or the
   * assigned district (if the user is a district admin)
   */
  district: number | DistrictOrg;
}

export const DistrictDetails: FC<DistrictDetailsProps> = ({
  district,
  ...rest
}) => {
  const [districtOrg, setDistrictOrg] = useState<DistrictOrg | null>(null);
  const [schools, setSchools] = useState<SchoolWithEnrollmentInfo[]>([]);
  const [districtAdmins, setDistrictAdmins] = useState<Admin[]>([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const { adminLevel } = useLoggedInAdminContext();
  const { pathname } = useLocation();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setDistrictOrg(null);
        setSchools([]);
        setDistrictAdmins([]);
      }
      try {
        let districtOrg: DistrictOrg;
        if (typeof district === 'number') {
          districtOrg = await getDistrict(district);
        } else {
          // Skip the request and use the passed district if provided.
          districtOrg = district;
        }
        const [schoolList, admins] = await Promise.all([
          getDistrictSchools(districtOrg.id),
          getDistrictAdmins(districtOrg.id),
        ]);

        if (!isCancelled()) {
          setDistrictOrg(districtOrg);
          setSchools(schoolList);
          setDistrictAdmins(admins);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to load district details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [district, adminLevel],
  );

  const {
    schoolList,
    districtAdminList,
    ...restOfDashboardProps
  } = useDistrictDashboard({ schools, districtAdmins });

  const { sendPending, reinviteUser } = useResendInvite();

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || !districtOrg ? (
        <SpinnerOverlay />
      ) : (
        <Column className="flex-grow flex-shrink-0 h-full ">
          <PageTitle pageTitle={districtOrg.name} />
          <Row className="justify-between pl-17 mb-6">
            <BackButton
              onClick={() =>
                history.replace(
                  adminLevel === AdminLevel.DISTRICT ? home : districts,
                )
              }
            />
            <Row className="font-bold text-trueGray-900 font-roboto w-full justify-center">
              {districtOrg.name}
            </Row>
            <div>&nbsp;</div>
          </Row>
          <DistrictDashboard
            district={districtOrg}
            schools={schoolList}
            districtAdmins={districtAdminList}
            canCreateDistrictAdmins
            showDistrictAdminList
            canEditDistrict={adminLevel === AdminLevel.SUPER}
            mainHeading={
              adminLevel === AdminLevel.SUPER
                ? 'All Schools'
                : `${districtOrg.name} Schools`
            }
            basePath={formatDistrictDetailsRoute(districtOrg.id)}
            activePath={pathname}
            onReinviteClicked={reinviteUser}
            {...restOfDashboardProps}
          />
        </Column>
      )}
      {sendPending && <SpinnerOverlay opaque />}
    </Column>
  );
};

/**
 * A thin wrapper around DistrictDetails that load the details of the assigned district.
 */
export const AssignedDistrictDetails: FC = () => {
  const { assignedDistrict } = useAssignedDistrict();
  return <DistrictDetails district={assignedDistrict} />;
};
