import React, { FC } from 'react';
import { BaseActivityStepProps } from '../../types/assessment/ActivityStepProps';
import { Row } from '../Row/Row';
import { Column } from '../Column/Column';
import { ActivityProgressBar } from '../Assessment/ActivityProgressBar/ActivityProgressBar';
import { AudioPlayer } from '../AudioPlayer/AudioPlayer';
import { ASSESSMENT_GRACE_PERIOD_COMPLETION_MILLISECONDS } from '../../constants/assessment/assessmentGracePeriodCompletionMilliseconds';

export const AssessmentStepHeading: FC<Pick<
  BaseActivityStepProps<any>,
  | 'title'
  | 'activityProgress'
  | 'audio'
  | 'onAudioPlayed'
  | 'showAudioIncomplete'
  | 'onShowAudioCompleteDismissed'
>> = ({
  title,
  activityProgress,
  audio,
  onAudioPlayed,
  showAudioIncomplete,
  onShowAudioCompleteDismissed,
}) => {
  return (
    <Row className="w-full flex-wrap">
      <Column className={`${audio ? 'w-1/2' : 'w-full'}`}>
        <Row className="mb-2">
          <Row className="font-bold text-light-blue-500 mr-2">{title[0]}</Row>
          <Row className="font-light">{title[1]}</Row>
        </Row>
        <ActivityProgressBar activityProgress={activityProgress} />
      </Column>
      {audio && (
        <Column className="w-1/2 max-w-xs min-w-60 justify-center pt-2 md:pt-0">
          <AudioPlayer
            source={audio}
            onPlayedInFull={onAudioPlayed}
            preload="auto"
            showAudioIncomplete={showAudioIncomplete}
            onShowAudioCompleteDismissed={onShowAudioCompleteDismissed}
            graceCompletionMilliseconds={
              ASSESSMENT_GRACE_PERIOD_COMPLETION_MILLISECONDS
            }
          />
        </Column>
      )}
    </Row>
  );
};
