import { useHistory } from 'react-router-dom';
import { useCallback } from 'react';
import { Student } from 'shared/lib/types/Student';
import {
  beginAssessment,
  takeAssessment,
} from 'shared/lib/constants/routes/assessmentRoutes';
import { Language } from 'shared/lib/constants/Language';
import { beginBAPAssessment } from '../../api/baps/beginBAPAssessment';
import { showResumeBapAlert } from '../../components/ResumeBapAlert/ResumeBapAlert';
import { showOkAlert } from '../../components/Alert/Alert';

export function useBeginAssessmentLauncher() {
  const history = useHistory();

  /**
   * Launch the user into the 'Choose Language' page
   */
  const launchFromSelectAssessment = useCallback(
    ({ id }: Pick<Student, 'id'>) => {
      history.push(`${beginAssessment}?studentId=${id}`);
    },
    [history],
  );

  /**
   * Launch the user directly into the BAP Assessment for the language.
   * Should not be called for a completed language.
   */
  const launchForResume = useCallback(
    async (
      { id, ...nameInfo }: Pick<Student, 'id' | 'firstName' | 'lastName'>,
      language: Language,
    ) => {
      if (await showResumeBapAlert({ student: nameInfo, language })) {
        try {
          await beginBAPAssessment({
            studentId: id,
            language,
            unsafeLaunch: false,
          });
          history.push(takeAssessment);
        } catch (e) {
          await showOkAlert({
            title: 'Error',
            text: `Failed to begin BAP assessment: ${e.message}`,
            theme: 'error',
          });
        }
      }
    },
    [history],
  );

  return {
    launchForResume,
    launchFromSelectAssessment,
  };
}
