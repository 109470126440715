import { ComponentPropsWithRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';

export const Button: FC<ComponentPropsWithRef<'button'>> = ({
  className,
  disabled,
  ...rest
}) => (
  <button
    className={twMerge(
      'relative flex flex-row',
      disabled && 'pointer-events-none cursor-default',
      className,
    )}
    {...rest}
  />
);
