import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';
import {
  editAccount,
  home,
  info,
  tutorial,
} from 'shared/lib/constants/routes/commonRoutes';
import {
  AdministratorPanelRoutes,
  AssessorPanelRoutes,
  CommonPanelRoutes,
} from '../constants/SidePanelRoutes';
import { students } from 'shared/lib/constants/routes/assessorRoutes';
import { useLoggedInAdminContext } from '../contexts/userContext';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';

function matchCommonRoute(pathname: string): CommonPanelRoutes | null {
  switch (pathname) {
    case home:
      return 'home';
    case editAccount:
      return 'account';
    case tutorial:
      return 'tutorial';
    case info:
      return 'info';
    default:
      return null;
  }
}

function primaryRouteName(adminLevel: AdminLevel): AdministratorPanelRoutes {
  switch (adminLevel) {
    case AdminLevel.SUPER:
      return 'districts';
    case AdminLevel.DISTRICT:
      return 'schools';
    case AdminLevel.SCHOOL:
    default:
      return 'school';
  }
}

const PRIMARY_ACTIVE_ROUTES = [
  '/district',
  '/school',
  '/class',
  '/assessor',
  '/student',
  '/admin',
];

function matchAdministratorRoutes(
  pathName: string,
  adminLevel: AdminLevel,
): AdministratorPanelRoutes | undefined {
  const primaryRoute = primaryRouteName(adminLevel);

  if (PRIMARY_ACTIVE_ROUTES.some((route) => pathName.startsWith(route))) {
    return primaryRoute;
  }
  return undefined;
}

function matchAssessorRoutes(
  pathname: string,
): AssessorPanelRoutes | undefined {
  if (
    pathname === students ||
    PRIMARY_ACTIVE_ROUTES.some((route) => pathname.startsWith(route))
  ) {
    return 'students';
  }
  return undefined;
}

export function useAdministratorActiveRoutes():
  | AdministratorPanelRoutes
  | undefined {
  const { pathname } = useLocation();
  const { user } = useLoggedInAdminContext();
  const { role } = user;
  const { adminLevel } = role;
  return useMemo(() => {
    return (
      matchCommonRoute(pathname) ??
      matchAdministratorRoutes(pathname, adminLevel)
    );
  }, [pathname, adminLevel]);
}

export function useAssessorActiveRoutes(): AssessorPanelRoutes | undefined {
  const { pathname } = useLocation();

  return useMemo(() => {
    return matchCommonRoute(pathname) ?? matchAssessorRoutes(pathname);
  }, [pathname]);
}
