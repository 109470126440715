export function downloadFile(url: string, name: string, crossOrigin = false) {
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  if (crossOrigin) {
    link.target = '_blank';
  }
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
