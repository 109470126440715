import { useHistory } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { Student } from 'shared/lib/types/Student';
import {
  formatAssessmentInstructionsRoute,
  takeAssessment,
} from 'shared/lib/constants/routes/assessmentRoutes';
import { useSearchParams } from '../useSearchParams';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudent } from '../../api/student/getStudent';
import { Language } from 'shared/lib/constants/Language';
import { showLanguageSelectAlert } from '../../components/Assessment/LanguageSelectedAlert/LanguageSelectedAlert';
import { beginBAPAssessment } from '../../api/baps/beginBAPAssessment';
import { showOkAlert, showYesNoAlert } from '../../components/Alert/Alert';
import { BAPAssessments } from 'shared/lib/types/assessment/BAPAssessments';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { getIncompleteBapsForStudent } from '../../api/baps/getIncompleteBapsForStudent';

export function useBeginAssessment() {
  const params = useSearchParams();
  const [student, setStudent] = useState<Student | null>(null);
  const [loading, setLoading] = useState(true);
  const [
    bapAssessmentResults,
    setBAPAssessmentResults,
  ] = useState<BAPAssessments | null>(null);
  const [language, setLanguage] = useState<Language | null>(null);
  const history = useHistory();

  const studentId = useMemo(() => {
    if (params.has('studentId')) {
      const studentIdString = params.get('studentId');
      if (studentIdString) {
        const parsedId = parseInt(studentIdString, 10);
        return !Number.isNaN(parsedId) ? parsedId : null;
      }
    }
    return null;
  }, [params]);

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudent(null);
      }
      if (!studentId) {
        await showOkAlert({
          title: 'Select Student',
          text: 'Please select a student before continuing.',
        });
        history.goBack();
        return;
      }
      try {
        const [studentData, bapResults] = await Promise.all([
          getStudent(studentId),
          getIncompleteBapsForStudent(studentId),
        ]);
        if (!isCancelled()) {
          setStudent(studentData);
          setBAPAssessmentResults(bapResults);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          theme: 'error',
          text: `Failed to set up for beginning this assessment: ${e.message}`,
        });
      }
    },
    [studentId],
  );

  const onLanguageSelected = useCallback(
    async (selectedLanguage: Language) => {
      if (selectedLanguage === language || !bapAssessmentResults) {
        return;
      }
      const languageResult =
        selectedLanguage === Language.ENGLISH
          ? bapAssessmentResults.english
          : bapAssessmentResults.spanish;
      if (
        languageResult?.completedAt &&
        !(await showYesNoAlert({
          title: 'Abandon BAPS?',
          text: `The ${capitalizeFirst(
            selectedLanguage,
          )} BAPS is already completed. Starting a new take will abandon the old results. Do you wish to continue?`,
        }))
      ) {
        return;
      }
      const previousValue = language;
      setLanguage(null);
      if (
        await showLanguageSelectAlert({
          language: selectedLanguage,
          resume: languageResult?.completedAt === null,
        })
      ) {
        setLanguage(selectedLanguage);
      } else {
        setLanguage(previousValue);
      }
    },
    [bapAssessmentResults, language],
  );

  const onBeginClicked = useCallback(async () => {
    if (!language) {
      await showOkAlert({
        title: 'Select Language',
        text: 'Please select a language before starting.',
        theme: 'error',
      });
      return;
    }

    if (!student || !bapAssessmentResults) {
      // Should be safe by now.
      return;
    }

    const languageResult =
      language === Language.ENGLISH
        ? bapAssessmentResults.english
        : bapAssessmentResults.spanish;
    // If there are no in-progress baps for this language, or if they are abandoning the take, direct them to the instructions
    if (languageResult === null || languageResult.completedAt !== null) {
      history.push(formatAssessmentInstructionsRoute(language), {
        studentId: student.id,
      });
      return;
    }

    try {
      await beginBAPAssessment({
        studentId: student.id,
        language,
        unsafeLaunch: true,
      });
      history.push(takeAssessment);
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        theme: 'error',
        text: `Failed to launch BAPS: ${e.message}`,
      });
    }
  }, [language, student, history, bapAssessmentResults]);

  return {
    student,
    loading,
    onLanguageSelected,
    language,
    onBeginClicked,
  };
}
