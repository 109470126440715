import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

interface FormLabelProps extends ComponentPropsWithoutRef<'label'> {
  children: ReactNode;
}

export const FormLabel: FC<FormLabelProps> = ({ className = '', ...rest }) => (
  <label
    className={`font-roboto font-bold text-trueGray-900 mb-2 ${className}`}
    {...rest}
  />
);
