import React, { ComponentPropsWithoutRef, FC, useMemo } from 'react';
import { DistrictOrg } from 'shared/lib/types/Org';
import { SchoolWithEnrollmentInfo } from 'shared/lib/types/school/SchoolWithEnrollmentInfo';
import { Admin } from 'shared/lib/types/Admin';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import {
  formatAddDistrictAdminRoute,
  formatAddDistrictSchoolRoute,
  formatEditDistrictRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { ListToggleButton } from '../ListToggleButton/ListToggleButton';
import { SearchField } from '../SearchField/SearchField';
import { AdminList } from '../AdminList/AdminList';
import { SchoolList } from '../SchoolList/SchoolList';
import { MainPanel } from '../MainPanel/MainPanel';
import { ActionLink } from '../ActionLink/ActionLink';
import { MenuOption, RouteMenu } from '../RouteMenu/RouteMenu';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ListSwitchable } from '../../types/ListSwitchable';

const listSwitchRoutes = (basePath: string) => ({
  schoolListRoute: `${basePath}/schools`,
  adminListRoute: `${basePath}/admin`,
});

interface DistrictDashboardProps
  extends ComponentPropsWithoutRef<'div'>,
    ListSwitchable {
  district: DistrictOrg;
  schools: ReadonlyArray<SchoolWithEnrollmentInfo>;
  districtAdmins: ReadonlyArray<Admin>;
  search: string;
  onSearchChanged(search: string): void;
  canCreateDistrictAdmins: boolean;
  showDistrictAdminList: boolean;
  canEditDistrict: boolean;
  mainHeading?: string;
  onReinviteClicked(admin: Admin): void;
}

export const DistrictDashboard: FC<DistrictDashboardProps> = ({
  district,
  schools,
  districtAdmins,
  search,
  onSearchChanged,
  className = '',
  canCreateDistrictAdmins,
  showDistrictAdminList,
  canEditDistrict,
  mainHeading = 'All Schools',
  basePath,
  activePath,
  onReinviteClicked,
  ...rest
}) => {
  const addOptions = useMemo((): MenuOption[] => {
    const options: MenuOption[] = [
      {
        to: formatAddDistrictSchoolRoute(district.id),
        label: 'Add School',
      },
    ];
    if (canCreateDistrictAdmins) {
      options.push({
        to: formatAddDistrictAdminRoute(district.id),
        label: 'Add District Admin',
      });
    }
    return options;
  }, [canCreateDistrictAdmins, district]);

  const { adminListRoute, schoolListRoute } = listSwitchRoutes(basePath);
  const isSchoolRoute =
    activePath === schoolListRoute || activePath === basePath;

  return (
    <Column className={`flex-grow flex-shrink-0 h-full ${className}`} {...rest}>
      <Row className="w-full justify-between items-center pl-17 pr-5 pb-8">
        <h1 className="font-roboto text-trueGray-750 text-2xl">All Schools</h1>
        <Row className="items-center">
          {canEditDistrict && (
            <ActionLink
              variant="edit"
              className="mr-6"
              to={formatEditDistrictRoute(district.id)}
            >
              Edit District
            </ActionLink>
          )}
          {canCreateDistrictAdmins ? (
            <RouteMenu routes={addOptions} />
          ) : (
            <ActionLink
              variant="add"
              className="ml-6 mr-6"
              to={formatAddDistrictSchoolRoute(district.id)}
            >
              Add School
            </ActionLink>
          )}
        </Row>
      </Row>
      <MainPanel className="mt-2.5">
        <Row className="justify-between pb-7 pl-8 lg:pl-15 pr-5 lg:pr-10">
          <div>&nbsp;{/* for layout */}</div>
          <Row className="flex-wrap">
            <ListToggleButton
              active={isSchoolRoute}
              buttonText="Schools"
              to={schoolListRoute}
            />
            {showDistrictAdminList && (
              <ListToggleButton
                active={activePath === adminListRoute}
                to={adminListRoute}
                buttonText="District Admins"
              />
            )}
          </Row>
          <SearchField
            search={search}
            onSearchChanged={onSearchChanged}
            placeholder={`Search For ${
              isSchoolRoute ? 'School' : 'District Admin'
            }`}
          />
        </Row>
        <Switch>
          <Route exact path={[basePath, schoolListRoute]}>
            <SchoolList schools={schools} />
          </Route>
          <Route exact path={adminListRoute}>
            <AdminList
              admins={districtAdmins}
              onReinviteClicked={onReinviteClicked}
            />
          </Route>
          <Redirect to={basePath} />
        </Switch>
      </MainPanel>
    </Column>
  );
};
