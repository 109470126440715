import React, { FC, useEffect, useState } from 'react';
import { InputAlert } from '../../InputAlert/InputAlert';
import showModal from '@emberex/react-utils/lib/showModal';

interface StudentResponseAlertProps {
  studentResponse: string | null;
  onStudentResponseChanged(value: string): void;
  onConfirmClicked(): void;
  onCancelClicked(): void;
}

export const StudentResponseAlert: FC<StudentResponseAlertProps> = ({
  studentResponse,
  onStudentResponseChanged,
  ...rest
}) => {
  const [value, setValue] = useState(studentResponse ?? '');
  useEffect(() => {
    onStudentResponseChanged(value);
  }, [onStudentResponseChanged, value]);
  return (
    <InputAlert
      showCancel
      value={value}
      onChange={setValue}
      placeholder="enter response here"
      cancelText="Cancel"
      confirmText="Next Item"
      {...rest}
    >
      <h2 className="font-mplus text-light-blue-500 text-2xl font-extrabold">
        You Selected INCORRECT
      </h2>
      <p className="font-roboto font-bold mt-9 mb-3">
        Please record the child's answer:
      </p>
    </InputAlert>
  );
};

interface StudentResponseAlertValue {
  value: string | null;
  cancel: boolean;
}

export function showStudentResponseAlert() {
  return showModal<StudentResponseAlertValue>((resolve) => {
    let currentValue = '';
    return (
      <StudentResponseAlert
        studentResponse={currentValue}
        onStudentResponseChanged={(newValue) => (currentValue = newValue)}
        onConfirmClicked={() => resolve({ value: currentValue, cancel: false })}
        onCancelClicked={() => resolve({ value: null, cancel: true })}
      />
    );
  });
}
