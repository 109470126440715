import React, { FC } from 'react';
import { ActivityIntroStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { ActivityTextDisplay } from '../ActivityTextDisplay/ActivityTextDisplay';
import { PillButton } from '../../PillButton/PillButton';
import { Row } from '../../Row/Row';
import { Column } from '../../Column/Column';
import { AssessmentListItem } from '../AssessmentListItem/AssessmentListItem';

export const ActivityIntroStep: FC<ActivityIntroStepProps> = ({
  activityStep,
  onNext,
  ...rest
}) => {
  const { intro, items, outro, stepId } = activityStep;
  const [itemHeader, ...itemsInActivity] = items;
  return (
    <AssessmentStepPage {...rest} audio={null}>
      <Column className="w-full h-full overflow-y-auto max-h-assessment-page">
        <Column className="pb-3">
          {intro.map((introItem, idx) => (
            <ActivityTextDisplay
              activityText={introItem}
              key={`${stepId}_intro_${idx}`}
            />
          ))}
        </Column>
        <Column className="mb-3">
          <ActivityTextDisplay
            activityText={itemHeader}
            className="pb-9 short:pb-4"
          />
          {itemsInActivity.map((item, idx) => (
            <AssessmentListItem key={`item_${idx}`} className="pb-6">
              <ActivityTextDisplay activityText={item} />
            </AssessmentListItem>
          ))}
        </Column>
        {outro &&
          outro.map((outroItem, idx) => (
            <ActivityTextDisplay
              activityText={outroItem}
              key={`${stepId}_outro_${idx}`}
              className="pb-6"
            />
          ))}
        <Row className="w-full items-center justify-center short:mt-0 mt-20 pt-4 pb-4">
          <PillButton buttonVariation="primary" onClick={onNext}>
            Continue
          </PillButton>
        </Row>
      </Column>
    </AssessmentStepPage>
  );
};
