import { useCallback, useMemo, useState } from 'react';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import {
  englishInstructionLevelAscending,
  englishInstructionLevelDescending,
  englishPercentileAscending,
  englishPercentileDescending,
  nameAscending,
  nameDescending,
  riskStatusAscending,
  riskStatusDescending,
  spanishInstructionLevelAscending,
  spanishInstructionLevelDescending,
  spanishPercentileAscending,
  spanishPercentileDescending,
} from 'shared/lib/utils/dashboard/dashboardStudentComparators';
import { sort } from 'shared/lib/utils/sort';
import {
  SortOrder,
  StudentSort,
  StudentSortField,
} from '../../types/student/StudentSort';

const SORT_COMPARATORS: Record<
  StudentSortField,
  Record<SortOrder, (a: DashboardStudent, b: DashboardStudent) => number>
> = {
  [StudentSortField.NAME]: {
    [SortOrder.ASCENDING]: nameAscending,
    [SortOrder.DESCENDING]: nameDescending,
  },
  [StudentSortField.RISK_STATUS]: {
    [SortOrder.ASCENDING]: riskStatusAscending,
    [SortOrder.DESCENDING]: riskStatusDescending,
  },
  [StudentSortField.INSTRUCTION_LEVEL_ENGLISH]: {
    [SortOrder.ASCENDING]: englishInstructionLevelAscending,
    [SortOrder.DESCENDING]: englishInstructionLevelDescending,
  },
  [StudentSortField.INSTRUCTION_LEVEL_SPANISH]: {
    [SortOrder.ASCENDING]: spanishInstructionLevelAscending,
    [SortOrder.DESCENDING]: spanishInstructionLevelDescending,
  },
  [StudentSortField.PERCENTILE_ENGLISH]: {
    [SortOrder.ASCENDING]: englishPercentileAscending,
    [SortOrder.DESCENDING]: englishPercentileDescending,
  },
  [StudentSortField.PERCENTILE_SPANISH]: {
    [SortOrder.ASCENDING]: spanishPercentileAscending,
    [SortOrder.DESCENDING]: spanishPercentileDescending,
  },
};

export function useStudentListSort(students: readonly DashboardStudent[]) {
  const [sortField, setSortField] = useState(StudentSortField.NAME);
  const [sortOrder, setSortOrder] = useState(SortOrder.ASCENDING);

  const onSortChanged = useCallback(
    (sort: StudentSort) => {
      setSortField(sort.field);
      setSortOrder(sort.order);
    },
    [setSortField, setSortOrder],
  );

  const sortedStudents = useMemo(() => {
    const comparator = SORT_COMPARATORS[sortField][sortOrder];
    return sort(students, comparator);
  }, [students, sortField, sortOrder]);

  return {
    sortedStudents,
    sortField,
    sortOrder,
    onSortChanged,
  };
}
