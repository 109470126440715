import { useUserContext } from '../contexts/userContext';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import {
  acceptInvite,
  forgotPassword,
  login,
  resetPassword,
} from 'shared/lib/constants/routes/unauthenticatedRoutes';
import { Login } from '../pages/Login/Login';
import { AcceptInvite } from '../pages/AcceptInvite/AcceptInvite';
import { ForgotPassword } from '../pages/ForgotPassword/ForgotPassword';
import { ResetPassword } from '../pages/ResetPassword/ResetPassword';
import { PageTitle } from '../components/PageTitle/PageTitle';

export function UnauthenticatedRoutes() {
  const { login: performLogin } = useUserContext();

  return (
    <BrowserRouter>
      <Switch>
        <Route exact path={login}>
          <PageTitle pageTitle="Login" />
          <Login login={performLogin} />
        </Route>
        <Route path={acceptInvite}>
          <PageTitle pageTitle="Accept Invite" />
          <AcceptInvite />
        </Route>
        <Route exact path={forgotPassword}>
          <PageTitle pageTitle="Forgot Password" />
          <ForgotPassword />
        </Route>
        <Route path={resetPassword}>
          <PageTitle pageTitle="Reset Password" />
          <ResetPassword />
        </Route>
        <Redirect to={login} />
      </Switch>
    </BrowserRouter>
  );
}
