import React, { ComponentPropsWithoutRef, FC } from 'react';
import { logout } from 'shared/lib/constants/routes/userRoutes';
import { Link } from 'react-router-dom';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import './AuthenticatedSidePanel.css';
import {
  editAccount,
  home,
  info,
  tutorial,
} from 'shared/lib/constants/routes/commonRoutes';
import {
  headCircleBlack,
  headCircleBlue,
  headsBlack,
  headsBlueFilled,
  homeBlack,
  homeBlue,
  infoCircleBlack,
  infoCircleBlue,
  logo,
  playCircleBlack,
} from '../../images';
import { SidePanelButton } from '../SidePanelButton/SidePanelButton';
import {
  AdministratorPanelRoutes,
  AssessorPanelRoutes,
} from '../../constants/SidePanelRoutes';
import { playCircleBlueFilled } from '../../images';

export interface PrimaryRoute {
  name: string;
  route: string;
  activeRouteName: AdministratorPanelRoutes | AssessorPanelRoutes;
}

interface AuthenticatedSidePanelProps extends ComponentPropsWithoutRef<'div'> {
  primaryRoute: PrimaryRoute;
  activeRoute?: AdministratorPanelRoutes | AssessorPanelRoutes;
}

export const AuthenticatedSidePanel: FC<AuthenticatedSidePanelProps> = ({
  children,
  className = '',
  activeRoute,
  primaryRoute,
  ...rest
}) => (
  <Column
    className={`flex-grow w-full justify-between auth-side-panel ${className}`}
    {...rest}
  >
    <Row className="w-full justify-center">
      <Link to={home}>
        <img
          src={logo}
          alt="BAPS - Bilingual Assessment of Phonological Sensitivity"
          className="logo"
        />{' '}
      </Link>
    </Row>
    <Column className="flex-grow">
      <Column className="pl-6 pb-6">
        <ul className="mt-24 side-panel-options">
          <li>
            <SidePanelButton
              route={home}
              name="Home"
              images={{ active: homeBlue, inactive: homeBlack }}
              isActive={activeRoute === 'home'}
            />
          </li>
          <li>
            <SidePanelButton
              route={primaryRoute.route}
              name={primaryRoute.name}
              images={{
                inactive: headsBlack,
                active: headsBlueFilled,
              }}
              isActive={activeRoute === primaryRoute.activeRouteName}
            />
          </li>
        </ul>
      </Column>
      <Row className="w-full pl-3 pr-9">
        <div className="w-full border-b border-gray-450" />
      </Row>
      <Column className="pl-6 mt-6">
        <ul>
          <li>
            <SidePanelButton
              route={editAccount}
              name="Edit Account"
              images={{
                inactive: headCircleBlack,
                active: headCircleBlue,
              }}
              isActive={activeRoute === 'account'}
            />
          </li>
          <li>
            <SidePanelButton
              route={tutorial}
              name="Tutorial Video"
              images={{
                inactive: playCircleBlack,
                active: playCircleBlueFilled,
              }}
              isActive={activeRoute === 'tutorial'}
              hide
            />
          </li>
          <li>
            <SidePanelButton
              route={info}
              name="Info Pages"
              images={{
                inactive: infoCircleBlack,
                active: infoCircleBlue,
              }}
              isActive={activeRoute === 'info'}
              hide
            />
          </li>
        </ul>
      </Column>
    </Column>
    <Link
      className="self-center font-roboto text-light-blue-500 font-medium text-sm"
      to={logout}
    >
      Sign Out
    </Link>
  </Column>
);
