import React, { FC, useState } from 'react';
import { Column } from '../../components/Column/Column';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { AssessorDetail } from 'shared/lib/types/AssessorDetail';
import { getAssessor } from '../../api/assessor/getAssessor';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import { TeacherDetail } from '../../components/TeacherDetail/TeacherDetail';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { useHistory } from 'react-router-dom';
import { showOkAlert } from '../../components/Alert/Alert';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { formatEditAssessorRoute } from 'shared/lib/constants/routes/administratorRoutes';

interface AssessorDetailsProps {
  assessorId: number;
}

export const AssessorDetails: FC<AssessorDetailsProps> = ({
  assessorId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [assessor, setAssessor] = useState<AssessorDetail | null>(null);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setAssessor(null);
      }
      try {
        const teacher = await getAssessor(assessorId);
        if (!isCancelled()) {
          setAssessor(teacher);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to get teacher details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [assessorId],
  );

  return (
    <Column className="flex-grow pt-14 overflow-hidden" {...rest}>
      <PageTitle
        pageTitle={assessor ? formatFirstLast(assessor) : 'Teacher Details'}
      />
      {loading || !assessor ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle pageTitle={`${formatFirstLast(assessor)}`} />
          <Column className="pl-8 lg:pl-15 pr-10 pb-8">
            <Column className="pl-9 pb-2.5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between pr-10">
                <Row className="font-roboto text-2xl text-trueGray-750 items-center">
                  {formatFirstLast(assessor)}
                </Row>
                <ActionLink
                  variant="edit"
                  to={{
                    pathname: formatEditAssessorRoute(assessor),
                  }}
                >
                  Edit Teacher
                </ActionLink>
              </Row>
            </Column>
          </Column>
          <MainPanel>
            <Column className="pl-9 pr-10 pt-6">
              <TeacherDetail teacher={assessor} />
            </Column>
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
