import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';

export interface CheckIconProps extends ComponentPropsWithoutRef<'svg'> {
  polyLineClassName?: string;
}

export const CheckIcon: FC<CheckIconProps> = ({
  strokeWidth = 2,
  polyLineClassName,
  ...rest
}) => (
  <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" {...rest}>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Styled_SA_Home" transform="translate(-531.000000, -264.000000)">
        <g id="Group-20" transform="translate(519.000000, 252.000000)">
          <g id="Group-3" transform="translate(12.000000, 12.000000)">
            <polygon id="Fill-2" points="0 24 24 24 24 0 0 0"></polygon>
            <polyline
              className={twMerge(
                'stroke-current trueGray-750',
                polyLineClassName,
              )}
              stroke-width={strokeWidth}
              stroke-linecap="round"
              stroke-linejoin="round"
              points="6 12 10 16 18 8"
            ></polyline>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
