import React, { FC } from 'react';
import { Row } from '../../Row/Row';
import { AssessmentImageAnswerButton } from '../AssessmentImageAnswerButton/AssessmentImageAnswerButton';
import { ImageAnswerChoice } from 'shared/lib/types/assessment/ActivityAnswer';

interface AssessmentImageAnswerButtonsProps {
  choices: ReadonlyArray<ImageAnswerChoice>;
  selected: ImageAnswerChoice | null;
  onClick(choice: ImageAnswerChoice): void;
  className?: string;
}

export const AssessmentImageAnswerButtons: FC<AssessmentImageAnswerButtonsProps> = ({
  className,
  choices,
  onClick,
  selected,
  ...rest
}) => (
  <Row
    className={`w-full overflow-x-auto justify-center pr-2 pl-2 ${className}`}
    {...rest}
  >
    {choices.map((choice) => (
      <AssessmentImageAnswerButton
        key={choice.id}
        image={choice.image}
        selected={choice.id === selected?.id}
        onClick={() => onClick(choice)}
      />
    ))}
  </Row>
);
