import React, { FC } from 'react';
import { ActivityTestStepProps } from '../../../types/assessment/ActivityStepProps';
import { Row } from '../../Row/Row';
import { ActivityAnswerButtons } from '../ActivityAnswerButtons/ActivityAnswerButtons';
import { AssessmentNavigationButtons } from '../AssessmentNavigationButtons/AssessmentNavigationButtons';
import { AssessmentStepHeading } from '../../AssessmentStepHeading/AssessmentStepHeading';
import { Column } from '../../Column/Column';
import { Button } from '../../Button/Button';
import { arrowLeft } from '../../../images';

export const ActivityTestStep: FC<ActivityTestStepProps> = ({
  answers,
  selectedAnswer,
  activityStep,
  canProgress,
  onAnswerSelected,
  onBackClicked,
  onNext,
  title,
  activityProgress,
  audio,
  onAudioPlayed,
  showAudioIncomplete,
  onShowAudioCompleteDismissed,
  onPauseClicked,
  ...rest
}) => {
  return (
    <Column
      className="w-full h-full flex-grow flex-shrink-0 font-roboto bg-white overflow-y-hidden"
      {...rest}
    >
      <Row className="w-full items-center justify-between">
        <div className="w-full h-full min-h-21 bg-white pl-10 pr-10 pt-4 pb-4 items-center rounded-br-100px shadow-assessmentHeadShadow">
          <AssessmentStepHeading
            title={title}
            activityProgress={activityProgress}
            showAudioIncomplete={showAudioIncomplete}
            onShowAudioCompleteDismissed={onShowAudioCompleteDismissed}
            audio={audio}
            onAudioPlayed={onAudioPlayed}
          />
        </div>
        <Row className="w-58 flex-grow flex-shrink-0 h-full justify-center items-center">
          <Button
            className="h-full w-full text-light-blue-500 font-medium justify-center items-center"
            onClick={onPauseClicked}
          >
            Pause Test
          </Button>
        </Row>
      </Row>
      <div className="flex flex-col pt-15 flex-grow flex-shrink-0 short:pt-2">
        <div className="flex flex-row pl-10">
          <Button
            onClick={onBackClicked}
            className="items-center h-10 font-bold"
          >
            <img src={arrowLeft} alt="<-" className="mr-2 h-6 2-6" />
            Back
          </Button>
        </div>
        <div className="flex flex-row w-full items-center pt-6 short:pt-0 mt-2.5">
          <ActivityAnswerButtons
            answers={answers}
            selectedAnswer={selectedAnswer}
            onAnswerSelected={onAnswerSelected}
          />
        </div>
        <Row className="pt-15 short:pt-4 flex-grow flex-shrink-0 h-full">
          <AssessmentNavigationButtons
            onNext={onNext}
            onBack={onBackClicked}
            canProgress={canProgress}
          />
        </Row>
      </div>
    </Column>
  );
};
