import { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import {
  adminDetails,
  editAdmin,
  removeAdmin,
} from 'shared/lib/constants/routes/administratorRoutes';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import { adminLevels } from 'shared/lib/constants/AdminLevel';
import { useLoggedInAdminContext } from '../../contexts/userContext';
import { compareAdminLevel } from 'shared/lib/utils/admin/compareAdminLevel';
import { AdminEditor } from '../../pages/AdminEditor/AdminEditor';
import { AdminDetailsPage } from '../../pages/AdminDetailsPage/AdminDetailsPage';
import { RemoveAdminConfirm } from '../../pages/RemoveAdminConfirm/RemoveAdminConfirm';

interface AdminUserRoutesProps {
  adminId: number;
}

export const AdminUserRoutes: FC<AdminUserRoutesProps> = ({ adminId }) => {
  const { adminLevel } = useLoggedInAdminContext();
  return (
    <Switch>
      <Route exact path={editAdmin}>
        {({ match }) => {
          if (!match) {
            return <Redirect to={home} />;
          }
          const level = match.params.level;
          const destinationAdminLevel = adminLevels.find((lv) => lv === level);
          if (
            !destinationAdminLevel ||
            compareAdminLevel(adminLevel, destinationAdminLevel) < 0
          ) {
            return <Redirect to={home} />;
          }
          return (
            <AdminEditor
              adminLevel={destinationAdminLevel}
              userId={adminId}
              orgId={null}
            />
          );
        }}
      </Route>
      <Route exact path={adminDetails}>
        <AdminDetailsPage adminId={adminId} />
      </Route>
      <Route exact path={removeAdmin}>
        <RemoveAdminConfirm adminId={adminId} />
      </Route>
    </Switch>
  );
};
