import React, { ComponentPropsWithoutRef, FC } from 'react';

export const Column: FC<ComponentPropsWithoutRef<'div'>> = ({
  className= '',
  children,
  ...rest
}) => (
  <div className={`relative flex flex-col box-border ${className}`} {...rest}>
    {children}
  </div>
);
