import { FC } from 'react';
import { Route, Switch } from 'react-router-dom';
import { SchoolDetails } from '../../pages/SchoolDetails/SchoolDetails';
import { SchoolEditor } from '../../pages/SchoolEditor/SchoolEditor';
import { RemoveSchool } from '../../pages/RemoveSchool/RemoveSchool';
import { StudentGroupEditor } from '../../pages/StudentGroupEditor/StudentGroupEditor';
import {
  addSchoolAdmin,
  createClass,
  editSchool,
  removeSchool,
  schoolDetails,
} from 'shared/lib/constants/routes/administratorRoutes';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { AdminEditor } from '../../pages/AdminEditor/AdminEditor';

interface SchoolRoutesProps {
  schoolId: number;
}

export const SchoolRoutes: FC<SchoolRoutesProps> = ({ schoolId }) => {
  return (
    <Switch>
      <Route path={schoolDetails}>
        <SchoolDetails school={schoolId} />
      </Route>
      <Route path={editSchool}>
        <SchoolEditor schoolId={schoolId} districtId={null} />
      </Route>
      <Route path={removeSchool}>
        <RemoveSchool schoolId={schoolId} />
      </Route>
      <Route path={createClass}>
        <StudentGroupEditor school={schoolId} studentGroupId={null} />
      </Route>
      <Route path={addSchoolAdmin}>
        <AdminEditor
          orgId={schoolId}
          userId={null}
          adminLevel={AdminLevel.SCHOOL}
        />
      </Route>
    </Switch>
  );
};
