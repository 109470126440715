import React, { FC } from 'react';
import {
  DemonstrationStep,
  IntroductionStep,
  PracticeStep,
  PreDemonstrationStep,
  PrePracticeStep,
  PreTestStep,
  TestStep,
} from 'shared/lib/types/activity/ActivityStep';
import { ActivityStepType } from 'shared/lib/constants/activity/ActivityStepType';
import { ActivityStepProps } from '../../../types/assessment/ActivityStepProps';
import { ActivityIntroStep } from '../ActivityIntroStep/ActivityIntroStep';
import { ActivityPreDemoStep } from '../ActivityPreDemoStep/ActivityPreDemoStep';
import { ActivityDemoStep } from '../ActivityDemoStep/ActivityDemoStep';
import { ActivityPrePracticeStep } from '../ActivityPrePracticeStep/ActivityPrePracticeStep';
import { ActivityPracticeStep } from '../ActivityPracticeStep/ActivityPracticeStep';
import { ActivityTestStep } from '../ActivityTestStep/ActivityTestStep';
import { ActivityErrorStep } from '../ActivityErrorStep/ActivityErrorStep';
import { Column } from '../../Column/Column';
import { ActivityPreTestStep } from '../ActivityPreTestStep/ActivityPreTestStep';

/**
 * The goal of this component is to route to the correct sub-type of an ActivityStep
 */
export const ActivityStep: FC<ActivityStepProps> = ({
  activityStep,
  onError,
  ...rest
}) => {
  const { stepType } = activityStep;
  // The hope is that these are the object they say they are.
  switch (stepType) {
    case ActivityStepType.INTRODUCTION:
      return (
        <ActivityIntroStep
          activityStep={activityStep as IntroductionStep}
          {...rest}
        />
      );
    case ActivityStepType.PRE_DEMO:
      return (
        <ActivityPreDemoStep
          activityStep={activityStep as PreDemonstrationStep}
          {...rest}
        />
      );
    case ActivityStepType.DEMO:
      return (
        <ActivityDemoStep
          activityStep={activityStep as DemonstrationStep}
          {...rest}
        />
      );
    case ActivityStepType.PRE_PRACTICE:
      return (
        <ActivityPrePracticeStep
          activityStep={activityStep as PrePracticeStep}
          {...rest}
        />
      );
    case ActivityStepType.PRACTICE:
      return (
        <ActivityPracticeStep
          activityStep={activityStep as PracticeStep}
          {...rest}
        />
      );
    case ActivityStepType.PRE_TEST:
      return (
        <ActivityPreTestStep
          activityStep={activityStep as PreTestStep}
          {...rest}
        />
      );
    case ActivityStepType.TEST:
      return (
        <ActivityTestStep activityStep={activityStep as TestStep} {...rest} />
      );
    default:
      return (
        <ActivityErrorStep
          errorTitle="Activity Type Not Found"
          onNext={onError}
        >
          <Column className="text-center">
            <p className="pb-24">
              Failed to determine the type of activity to display. The item is{' '}
              {stepType}
            </p>
            <p>
              Press next to continue. No answer will be submitted and it wont
              count for the student's score.
            </p>
          </Column>
        </ActivityErrorStep>
      );
  }
};
