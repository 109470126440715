import React, { FC, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { Student } from 'shared/lib/types/Student';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { StudentPercentileHistoryItem } from 'shared/lib/types/assessment/StudentPercentileHistoryItem';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudent } from '../../api/student/getStudent';
import { getStudentGroupsForStudent } from '../../api/student/getStudentGroupsForStudent';
import { getStudentPercentileHistory } from '../../api/student/getStudentPercentileHistory';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { StudentDetails } from '../../components/StudentDetails/StudentDetails';
import { useLoggedInUserContext } from '../../contexts/userContext';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import { BackButton } from '../../components/BackButton/BackButton';
import { Row } from '../../components/Row/Row';
import { useHistory, useLocation } from 'react-router-dom';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { showAgeAdjustedPercentileModal } from '../../components/AgeAdjustedPercentileModal/AgeAdjustedPercentileModal';
import { PillButton } from '../../components/PillButton/PillButton';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { showOkAlert } from '../../components/Alert/Alert';
import { useBeginAssessmentLauncher } from '../../hooks/assessment/useBeginAssessmentLaunch';
import { noop } from 'shared/lib/utils/noop';
import { TeachingRecommendation } from 'shared/lib/types/assessment/TeachingRecommendation';
import { showTeachingRecommendationsModal } from '../../components/TeachingRecommendationsModal/TeachingRecommendationsModal';

interface StudentDetailProps {
  studentId: number;
}

export const StudentDetailPage: FC<StudentDetailProps> = ({
  studentId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [student, setStudent] = useState<Student | null>(null);
  const [studentGroups, setStudentGroups] = useState<StudentGroup[]>([]);
  const [percentileHistory, setPercentileHistory] = useState<
    StudentPercentileHistoryItem[]
  >([]);
  const history = useHistory();
  const { pathname, state: locationState } = useLocation<{
    sourceClassId?: number;
  }>();
  const { user } = useLoggedInUserContext();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudent(null);
        setStudentGroups([]);
        setPercentileHistory([]);
      }
      try {
        const [studentInfo, groups, percentiles] = await Promise.all([
          getStudent(studentId),
          getStudentGroupsForStudent(studentId),
          getStudentPercentileHistory(studentId),
        ]);
        if (!isCancelled()) {
          setStudent(studentInfo);
          setStudentGroups(groups);
          setPercentileHistory(percentiles);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to load student data: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [studentId],
  );

  const {
    launchFromSelectAssessment,
    launchForResume,
  } = useBeginAssessmentLauncher();
  const beginAssessmentForStudent = useCallback(() => {
    if (!student) {
      return;
    }
    launchFromSelectAssessment(student);
  }, [student, launchFromSelectAssessment]);

  const onScoreClicked = useCallback(async () => {
    if (!student) {
      return;
    }
    const mostRecentPercentile = percentileHistory[0] ?? null;
    await showAgeAdjustedPercentileModal({
      onResumeClicked: beginAssessmentForStudent,
      student: {
        ...student,
        ageAdjustedPercentileReport:
          mostRecentPercentile?.ageAdjustedPercentile ?? null,
      },
    });
  }, [student, beginAssessmentForStudent, percentileHistory]);

  const onTeachingRecommendationClicked = useCallback(
    async ({ strugglingSkills }: TeachingRecommendation) => {
      if (!student || strugglingSkills.length < 1) {
        return;
      }
      await showTeachingRecommendationsModal({
        student,
        struggledSkills: strugglingSkills,
      });
    },
    [student],
  );

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading || !student ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle pageTitle={`${formatFirstLast(student)}`} />
          <Column className="pl-10 pr-5 pb-8">
            <Column className="pb-2.5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between">
                <Row className="font-roboto text-2xl text-trueGray-750 items-center">
                  {formatFirstLast(student)}
                </Row>
                <PillButton
                  className="bg-lime-350"
                  onClick={() => launchFromSelectAssessment({ id: studentId })}
                >
                  Start New BAPS
                </PillButton>
              </Row>
            </Column>
          </Column>
          <MainPanel leftPad={false}>
            <Column className="overflow-hidden pl-10 pr-5 pt-6">
              <StudentDetails
                student={student}
                studentGroups={studentGroups}
                percentileHistory={percentileHistory}
                showScaledScore={isAdmin(user)}
                onResumeClicked={async (language) =>
                  student ? await launchForResume(student, language) : noop()
                }
                onStartNewClicked={() =>
                  launchFromSelectAssessment({ id: studentId })
                }
                onScoreClicked={onScoreClicked}
                from={pathname}
                className="overflow-hidden"
                onTeachingRecommendationClicked={
                  onTeachingRecommendationClicked
                }
                sourceClassId={locationState?.sourceClassId}
              />
            </Column>
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
