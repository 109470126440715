import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { AuthenticatedPage } from '../components/AuthenticatedPage/AuthenticatedPage';
import { useLoggedInAdminContext } from '../contexts/userContext';
import {
  assignedSchool,
  createDistrict,
  createSuperAdmin,
  districts,
  schools,
} from 'shared/lib/constants/routes/administratorRoutes';
import { SchoolRoutes } from './sub-routes/SchoolRoutes';
import { ClassRoutes } from './sub-routes/ClassRoutes';
import { AssessorRoutes } from './sub-routes/AssessorRoutes';
import { Districts } from '../pages/Districts/Districts';
import { DistrictEditor } from '../pages/DistrictEditor/DistrictEditor';
import { DistrictRoutes } from './sub-routes/DistrictRoutes';
import { StudentRoutes } from './sub-routes/StudentRoutes';
import { editAccount, home } from 'shared/lib/constants/routes/commonRoutes';
import { AdminUserRoutes } from './sub-routes/AdminUserRoutes';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { AssignedDistrictDetails } from '../pages/DistrictDetails/DistrictDetails';
import { AssignedSchoolDetails } from '../pages/SchoolDetails/SchoolDetails';
import { AdminEditor } from '../pages/AdminEditor/AdminEditor';
import {
  assessmentResults,
  assessmentInstructions,
  beginAssessment,
  takeAssessment,
} from 'shared/lib/constants/routes/assessmentRoutes';
import { BeginAssessmentPage } from '../pages/BeginAssessmentPage/BeginAssessmentPage';
import { TakeAssessmentPage } from '../pages/TakeAssessmentPage/TakeAssessmentPage';
import { languages } from 'shared/lib/constants/Language';
import { AssessmentTakeInstructionsPage } from '../pages/AssessmentTakeInstructionsPage/AssessmentTakeInstructionsPage';
import React from 'react';
import { DashboardPage } from '../pages/Dashboard/DashboardPage';
import { Account } from '../pages/Account/Account';
import { logout } from 'shared/lib/constants/routes/userRoutes';
import { Logout } from '../pages/Logout/Logout';
import { AssessmentResultsPage } from '../pages/AssessmentResultsPage/AssessmentResultsPage';

export const AdminRoutes = () => {
  const { user, adminLevel } = useLoggedInAdminContext();
  return (
    <BrowserRouter>
      <AuthenticatedPage user={user}>
        <Switch>
          <Route path={districts}>
            <Districts />
          </Route>
          <Route exact path={createDistrict}>
            <DistrictEditor districtId={null} />
          </Route>
          <Route path="/district/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const districtId = +match.params.id;
              if (Number.isNaN(districtId)) {
                return <Redirect to={districts} />;
              }
              return <DistrictRoutes districtId={districtId} />;
            }}
          </Route>
          <Route path="/school/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const schoolId = +match.params.id;
              if (Number.isNaN(schoolId)) {
                return <Redirect to={schools} />;
              }
              return <SchoolRoutes schoolId={schoolId} />;
            }}
          </Route>
          <Route path="/class/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const classId = +match.params.id;
              if (Number.isNaN(classId)) {
                return <Redirect to={schools} />;
              }
              return <ClassRoutes studentGroupId={classId} />;
            }}
          </Route>
          <Route path="/assessor/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const assessorId = +match.params.id;
              if (Number.isNaN(assessorId)) {
                return <Redirect to={schools} />;
              }
              return <AssessorRoutes assessorId={assessorId} />;
            }}
          </Route>
          <Route path="/student/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const studentId = +match.params.id;
              if (Number.isNaN(studentId)) {
                return <Redirect to={schools} />;
              }
              return <StudentRoutes studentId={studentId} />;
            }}
          </Route>
          <Route path="/admin/:id">
            {({ match }) => {
              if (!match) {
                return null;
              }

              const adminId = +match.params.id;
              if (Number.isNaN(adminId)) {
                return <Redirect to={home} />;
              }
              return <AdminUserRoutes adminId={adminId} />;
            }}
          </Route>
          {adminLevel === AdminLevel.DISTRICT && (
            <Route exact path={schools}>
              <AssignedDistrictDetails />
            </Route>
          )}
          {adminLevel === AdminLevel.SCHOOL && (
            <Route exact path={assignedSchool}>
              <AssignedSchoolDetails />
            </Route>
          )}
          {adminLevel === AdminLevel.SUPER && (
            <Route exact path={createSuperAdmin}>
              <AdminEditor
                orgId={null}
                userId={null}
                adminLevel={AdminLevel.SUPER}
              />
            </Route>
          )}
          <Route exact path={beginAssessment}>
            <BeginAssessmentPage />
          </Route>
          <Route exact path={takeAssessment}>
            <TakeAssessmentPage />
          </Route>
          <Route exact path={assessmentInstructions}>
            {({ match }) => {
              if (!match) {
                return null;
              }
              const language = match.params.language;
              if (!language || !languages.includes(language)) {
                return <Redirect to={home} />;
              }
              return <AssessmentTakeInstructionsPage language={language} />;
            }}
          </Route>
          <Route exact path={assessmentResults}>
            {({ match }) => {
              if (!match) {
                return null;
              }
              const bapId = +match.params.bapId;
              if (Number.isNaN(bapId)) {
                return <Redirect to={home} />;
              }
              return <AssessmentResultsPage bapId={bapId} />;
            }}
          </Route>
          <Route exact path={home}>
            <DashboardPage />
          </Route>
          <Route exact path={editAccount}>
            <Account />
          </Route>
          <Route exact path={logout}>
            <Logout />
          </Route>
          <Redirect to={home} />
        </Switch>
      </AuthenticatedPage>
    </BrowserRouter>
  );
};
