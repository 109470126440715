import React, { ComponentPropsWithRef, FC } from 'react';
import { StudentGroupAndSchool } from 'shared/lib/types/StudentGroup';
import { RemoveConfirm } from '../RemoveConfirm/RemoveConfirm';

interface RemoveClassConfirmProps extends ComponentPropsWithRef<'form'> {
  studentGroup: StudentGroupAndSchool;
  onCancelled(): void;
}

export const RemoveClassConfirm: FC<RemoveClassConfirmProps> = ({
  studentGroup,
  ...rest
}) => {
  const { id, name, school } = studentGroup;
  return (
    <RemoveConfirm
      recordId={id}
      removalType="Class"
      recordName={name}
      warningText={`You are about to Remove Class - ${name} - from ${school.name}. Once removed, this action cannot be undone.`}
      confirmButtonText="Remove Class"
      {...rest}
    />
  );
};
