import React, { FC, FormEvent, useCallback, useMemo } from 'react';
import { EnrollableUser } from 'shared/lib/types/student-group/EnrollableUser';
import { StringOption } from '../../types/Option';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { SearchSelector } from '../SearchSelector/SearchSelector';
import { PillButton } from '../PillButton/PillButton';
import { Row } from '../Row/Row';

interface EnrollUserFormProps {
  className?: string;
  users: readonly EnrollableUser[];
  search: string;
  onSearchChanged(search: string): void;
  onUserSelected(user: EnrollableUser): void;
  onSubmit(): void;
  enrollmentType: 'Student' | 'Teacher';
  onCancelled(): void;
  error?: string;
  submitDisabled?: boolean;
}

export const EnrollUserForm: FC<EnrollUserFormProps> = ({
  className = '',
  users,
  onUserSelected,
  onSearchChanged,
  search,
  onSubmit,
  enrollmentType,
  onCancelled,
  error,
  submitDisabled,
  ...rest
}) => {
  const userOptions: StringOption[] = useMemo(() => {
    if (!users.length) {
      return [{ id: -1, value: `No ${enrollmentType}s Available` }];
    }
    return users.map((user) => ({ id: user.id, value: formatFirstLast(user) }));
  }, [users, enrollmentType]);
  const handleUserSelected = useCallback(
    ({ id }: StringOption) => {
      const user = users.find((u) => u.id === id);
      if (user) {
        onUserSelected(user);
      }
    },
    [users, onUserSelected],
  );
  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );
  return (
    <form
      className={`relative flex flex-col ${className}`}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
      {...rest}
    >
      <SearchSelector
        label="Name"
        search={search}
        onSearchChanged={onSearchChanged}
        options={userOptions}
        onOptionSelected={handleUserSelected}
        className="z-10"
      />
      <Row className="items-center flex-wrap pt-6">
        <PillButton
          type="submit"
          buttonVariation="primary"
          disabled={submitDisabled}
          className={`transition transition-opacity ${
            submitDisabled ? 'opacity-20' : 'opacity-100'
          }`}
        >
          Add {enrollmentType}
        </PillButton>
        <PillButton
          onClick={handleCancel}
          buttonVariation="cancel"
          className="m-9"
        >
          Cancel
        </PillButton>
      </Row>
      <Row className="pt-3 text-red-600">{error}</Row>
    </form>
  );
};
