import { FC } from 'react';
import { useLoggedInUserContext } from '../contexts/userContext';
import { isAdmin } from 'shared/lib/utils/isAdmin';
import { AdminApp } from './AdminApp.';
import { AssessorApp } from './AssessorApp';
import { useSettings } from '../hooks/useSettings';
import { SettingsContext } from '../contexts/settingsContext';

export const LoggedInApp: FC = () => {
  const { user } = useLoggedInUserContext();
  const { researchMode, refreshResearchMode } = useSettings(user);

  return (
    <SettingsContext.Provider value={{ researchMode, refreshResearchMode }}>
      {isAdmin(user) ? <AdminApp /> : <AssessorApp />}
    </SettingsContext.Provider>
  );
};
