import React, { FC } from 'react';
import { Language } from 'shared/lib/constants/Language';
import { AssessmentPage } from '../AssessmentPage/AssessmentPage';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { Column } from '../../Column/Column';
import { Row } from '../../Row/Row';
import { PillButton } from '../../PillButton/PillButton';
import { AssessmentListItem } from '../AssessmentListItem/AssessmentListItem';

interface BeginAssessmentNotesProps {
  language: Language;
  onStartClicked(): void;
  onBackClicked(): void;
}

export const BeginAssessmentNotes: FC<BeginAssessmentNotesProps> = ({
  language,
  onStartClicked,
  ...rest
}) => (
  <AssessmentPage
    headingContents={<HeadingContents language={language} />}
    showExit={false}
    showBack
    {...rest}
  >
    <Column className="w-full h-full overflow-y-auto max-h-assessment-page">
      <p className="pb-3">
        {language === Language.ENGLISH
          ? `The BAPS begins with the Blending: Matching Subtest. After the child completes this subtest, the BAPS App will determine which of the other BAPS subtests will be given to the child. Just follow the instructions as they appear on the screen.`
          : `El BAPS empieza con Sonidos Mezclados: Identificación. Despues que el niño termine con esta subprueba, la app del BAPS determinará cual de las otras subpruebas del BAPS seráño. Siga las instrucciones como las ve en la pantalla. `}
      </p>
      <p className="font-bold pb-9">
        {language === Language.ENGLISH
          ? 'Each subtest, with the exception of Blending: Production, has one Demonstration Item and two or three Practice Items.'
          : 'Cada subprueba, con la excepción de Sonidos Mezclados: Producción, tiene un ítem de demonstración y dos o tres ítems de practica. '}
      </p>
      <Column>
        <Column className="pb-6">
          <AssessmentListItem className="pb-3">
            {language === Language.ENGLISH
              ? 'When administering the Demonstration Item, the examiner demonstrates how to complete an item for the child.'
              : 'Al administrar el Ítem de Demostración, el examinador muestra cómo completar un ítem para el niño.'}
          </AssessmentListItem>
          <Row className="pl-9">
            {language === Language.ENGLISH
              ? 'Instructions are provided on the screen prior to the Demonstration Item, and in the Information area on the bottom of the Demonstration Item screen.'
              : 'Instrucciones están proveidos en la pantalla antes del Ítem de Demonstración, y en el botón de información en la esquina inferior de la derecha de la pantalla del Ítem de Demonstración.'}
          </Row>
        </Column>
        <Column className="pb-9">
          <AssessmentListItem className="pb-3">
            {language === Language.ENGLISH
              ? 'When administering the Practice Items, the child completes the item and the examiner gives the child feedback about whether their answer was correct or incorrect.'
              : 'Al administrar el Ítem de Demostración, el niño completa cada ítem y el examinador da comentarios sobre si la respuesta fue correcta o incorrecta. '}
          </AssessmentListItem>
          <Row className="pl-9">
            {language === Language.ENGLISH
              ? 'The feedback to be read to the child can be found in the information area on the bottom of the Practice Items screen.'
              : 'El comentario que debe ser leído al niño se puede encontrar cuando pulses el Botón de Información en la esquina inferior de la derecha de la pantalla. '}
          </Row>
        </Column>
      </Column>
      <p className="pb-9">
        {language === Language.ENGLISH
          ? 'All subtests have Test Items.'
          : 'Todas las subpruebas tienen Ítems del Examen'}
      </p>
      <Column className="pb-9">
        <AssessmentListItem className="pb-6">
          {language === Language.ENGLISH
            ? 'To administer these items, follow the instructions on the screen.'
            : 'Para administrar estos ítems, siga las instrucciones en la pantalla.'}
        </AssessmentListItem>
        <AssessmentListItem className="items-center">
          {language === Language.ENGLISH
            ? 'To administer these items, follow the instructions on the screen.'
            : 'No se le da comentarios al niño sobre si la respuesta fue correcta o incorrecta.'}
        </AssessmentListItem>
      </Column>
      <Row className="pb-15">
        <span className="font-bold mr-2">
          {language === Language.ENGLISH ? 'Note: ' : 'Nota: '}
        </span>
        {language === Language.ENGLISH
          ? 'Periodically, reinforce the child for working hard (“You’re working hard”) or for trying (“I like how hard you’re trying”).'
          : 'Periódicamente, refuerce al niño por trabajar bien (“Estás trabajando muy bien”) o por intentar (“Me gusta que estás intentando”). '}
      </Row>
      <Row className="w-full justify-center">
        <PillButton buttonVariation="primary" onClick={onStartClicked}>
          Start
        </PillButton>
      </Row>
    </Column>
    <div className="w-58 flex-grow flex-shrink-0">{/* For Layout */}</div>
  </AssessmentPage>
);

const HeadingContents: FC<{ language: Language }> = ({ language }) => (
  <h1 className="font-mplus text-light-blue-500 text-2xl font-extrabold">
    {language === Language.SPANISH ? 'Inicie el BAPS' : 'Begin BAPS'}:{' '}
    {capitalizeFirst(language)}
  </h1>
);
