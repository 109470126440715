import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { Admin } from 'shared/lib/types/Admin';
import { Org } from 'shared/lib/types/Org';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getAdmin } from '../../api/admin/getAdmin';
import { getAssignedOrganization } from '../../api/admin/getAssignedOrganization';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { formatFirstLast } from 'shared/lib/utils/formatName';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import {
  districts,
  formatAdminDetailsRoute,
  formatDistrictDetailsRoute,
  formatSchoolDetailsRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { RemoveConfirm } from '../../components/RemoveConfirm/RemoveConfirm';
import { home } from 'shared/lib/constants/routes/commonRoutes';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { deleteAdmin } from '../../api/admin/deleteAdmin';
import { clearAdminAssignments } from '../../api/admin/clearAdminAssignments';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { showOkAlert } from '../../components/Alert/Alert';

interface RemoveAdminConfirmProps {
  adminId: number;
}

export const RemoveAdminConfirm: FC<RemoveAdminConfirmProps> = ({
  adminId,
  ...rest
}) => {
  const [admin, setAdmin] = useState<Admin | null>(null);
  const [org, setOrg] = useState<Org | null>(null);
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setOrg(null);
        setAdmin(null);
      }

      try {
        const [user, assignedOrg] = await Promise.all([
          getAdmin(adminId),
          getAssignedOrganization(adminId),
        ]);
        if (!isCancelled()) {
          setAdmin(user);
          setOrg(assignedOrg);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed load admin details: ${e.message}`,
          theme: 'error',
        });
        history.goBack();
      }
    },
    [adminId],
  );
  const backRoute = admin ? formatAdminDetailsRoute(admin.id) : home;

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (!admin || loading) {
        return;
      }
      const { role } = admin;
      const { adminLevel } = role;
      try {
        if (adminLevel === AdminLevel.SUPER) {
          await deleteAdmin(admin.id);
        } else {
          await clearAdminAssignments(admin.id);
        }
        if (org) {
          history.replace(
            adminLevel === AdminLevel.DISTRICT
              ? formatDistrictDetailsRoute(org.id)
              : formatSchoolDetailsRoute(org),
          );
        } else {
          history.replace(districts);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to remove admin: ${e.message}`,
          theme: 'error',
        });
        setLoading(false);
      }
    },
    [admin, loading, org, history],
  );

  const onCancelled = useCallback(() => {
    history.replace(backRoute);
  }, [history, backRoute]);

  const adminLevel = admin?.role.adminLevel ?? '';
  const adminLabel = `${capitalizeFirst(adminLevel)} Admin`;

  return (
    <Column className="flex-grow pt-14 overflow-hidden" {...rest}>
      {loading || !admin ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle pageTitle={`Remove ${formatFirstLast(admin)}`} />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {formatFirstLast(admin)}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    Remove {capitalizeFirst(adminLevel)} Admin
                  </Row>
                </Column>
              </Row>
            </Column>
          </Column>
          <MainPanel>
            <RemoveConfirm
              recordId={adminId}
              confirmButtonText={`Remove User`}
              recordName={formatFirstLast(admin)}
              warningText={`You are about to remove ${adminLabel} - ${formatFirstLast(
                admin,
              )}${
                adminLevel !== AdminLevel.SUPER
                  ? ` - from ${org?.name ?? 'This Organization'}`
                  : ' - from the BAPS system'
              }.  Once removed, this action cannot be undone.`}
              onCancelled={onCancelled}
              removalType={adminLabel}
              onSubmit={onSubmit}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
