import { useLoggedInUserContext } from '../../contexts/userContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DistrictOrg, SchoolOrg } from 'shared/lib/types/Org';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { useSearchParamPatch, useSearchParams } from '../useSearchParams';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudentGroups } from '../../api/student-group/getStudentGroups';
import { isAdmin, isSuperAdmin } from 'shared/lib/utils/isAdmin';
import { SchoolWithEnrollmentInfo } from 'shared/lib/types/school/SchoolWithEnrollmentInfo';
import { getSchoolsWithEnrollmentInfo } from '../../api/school/getSchoolsWithEnrollmentInfo';
import { DashboardDropdownOption } from '../../components/DashboardDropdown/DashboardDropdown';
import { StudentGroupEnrollmentCounts } from 'shared/lib/types/student-group/StudentGroupEnrollmentCounts';
import { getStudentGroupEnrollmentCounts } from '../../api/student-group/getStudentGroupEnrollmentCounts';
import { useStudentGroupSelect } from '../student-group/useStudentGroupSelect';
import { DashboardData } from 'shared/lib/types/dashboard/DashboardData';
import {
  getDashboardData,
  getDashboardDataCSV,
} from '../../api/dashboard/getDashboardData';
import { useDebounce } from '../useDebouce';
import { showOkAlert } from '../../components/Alert/Alert';
import { getDistricts } from '../../api/district/getDistricts';
import { downloadCSVFile } from '../../utils/file-download/downloadCSVFile';

interface DashboardValues {
  onClassSelect(option: DashboardDropdownOption): void;
  onSchoolSelect(option: DashboardDropdownOption): void;
  onDistrictSelect(option: DashboardDropdownOption): void;
  schoolOptions: ReadonlyArray<DashboardDropdownOption>;
  classOptions: ReadonlyArray<DashboardDropdownOption>;
  districtOptions: ReadonlyArray<DashboardDropdownOption>;
  selectedClassOption: DashboardDropdownOption | null;
  selectedSchoolOption: DashboardDropdownOption | null;
  selectedDistrictOption: DashboardDropdownOption | null;
  loading: boolean;
  dashboardData: DashboardData | null;
  dashboardRefreshing: boolean;
  onExportCSVClicked(): void;
}

export function useDashboard(): DashboardValues {
  const { user } = useLoggedInUserContext();
  const [districts, setDistricts] = useState<DistrictOrg[]>([]);
  const [schoolsAndEnrollmentInfo, setSchoolsAndEnrollmentInfo] = useState<
    SchoolWithEnrollmentInfo[]
  >([]);
  const [classes, setClasses] = useState<StudentGroup[]>([]);
  const [
    studentGroupEnrollmentCounts,
    setStudentGroupEnrollmentCounts,
  ] = useState<StudentGroupEnrollmentCounts[]>([]);
  const [selectedDistrict, setSelectedDistrict] = useState<DistrictOrg | null>(
    null,
  );
  const [selectedSchool, setSelectedSchool] = useState<SchoolOrg | null>(null);
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(
    null,
  );
  const [loading, setLoading] = useState(true);
  const [dashboardRefreshing, setDashboardRefreshing] = useState(true);
  const params = useSearchParams();
  const patchParams = useSearchParamPatch();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setSchoolsAndEnrollmentInfo([]);
        setClasses([]);
        setDistricts([]);
        setLoading(true);
      }

      const [
        classList,
        schoolList,
        enrollmentCounts,
        districtList,
      ] = await Promise.all([
        getStudentGroups(),
        isAdmin(user) ? getSchoolsWithEnrollmentInfo() : [],
        getStudentGroupEnrollmentCounts(),
        isAdmin(user) && isSuperAdmin(user) ? getDistricts() : [],
      ]);

      if (!isCancelled()) {
        setClasses(classList);
        setSchoolsAndEnrollmentInfo(schoolList);
        setStudentGroupEnrollmentCounts(enrollmentCounts);
        setDistricts(districtList);
        setLoading(false);
      }
    },
    [user],
  );

  const filteredClasses = useMemo(() => {
    if (selectedSchool) {
      return classes.filter((cls) => cls.schoolId === selectedSchool.id);
    }
    return classes;
  }, [selectedSchool, classes]);

  const handleSchoolSelect = useCallback(
    ({ id }: DashboardDropdownOption) => {
      const info =
        schoolsAndEnrollmentInfo.find(
          ({ school: schoolOrg }) => schoolOrg.id === id,
        ) ?? null;
      setSelectedSchool(info?.school ?? null);
      patchParams({ schoolId: info?.school.id });
    },
    [schoolsAndEnrollmentInfo, patchParams],
  );

  const handleDistrictSelect = useCallback(
    ({ id }: DashboardDropdownOption) => {
      const district = districts.find((dist) => dist.id === id);
      setSelectedDistrict(district ?? null);
      patchParams({ districtId: district?.id });
    },
    [districts, patchParams],
  );

  const districtOptions = useMemo((): DashboardDropdownOption[] => {
    return [
      { id: -1, name: 'All Districts', studentCount: null },
      ...districts.map(({ name, id }) => ({
        id,
        name,
        studentCount: schoolsAndEnrollmentInfo
          .filter(({ school }) => school.parent === id)
          .reduce((totalStudents, { students }) => totalStudents + students, 0),
      })),
    ];
  }, [districts, schoolsAndEnrollmentInfo]);

  const selectedDistrictOption = useMemo((): DashboardDropdownOption | null => {
    return (
      districtOptions.find((option) => option.id === selectedDistrict?.id) ??
      null
    );
  }, [districtOptions, selectedDistrict]);

  const filteredSchoolsWithEnrollmentInfo = useMemo(() => {
    if (!selectedDistrictOption) {
      return schoolsAndEnrollmentInfo;
    }
    return schoolsAndEnrollmentInfo.filter(
      ({ school }) => school.parent === selectedDistrictOption.id,
    );
  }, [schoolsAndEnrollmentInfo, selectedDistrictOption]);

  const schoolOptions = useMemo((): DashboardDropdownOption[] => {
    return [
      { id: -1, name: 'All Schools', studentCount: null, categoryName: '' },
      ...filteredSchoolsWithEnrollmentInfo.map(({ school, students }) => ({
        id: school.id,
        name: school.name,
        studentCount: students,
        categoryName:
          districts.find((district) => district.id === school.parent)?.name ??
          '',
      })),
    ];
  }, [filteredSchoolsWithEnrollmentInfo, districts]);

  const selectedSchoolOption = useMemo((): DashboardDropdownOption | null => {
    return (
      schoolOptions.find((option) => option.id === selectedSchool?.id) ?? null
    );
  }, [schoolOptions, selectedSchool]);

  useEffect(() => {
    if (!loading) {
      const districtId = +(params.get('districtId') ?? '');
      if (!Number.isNaN(districtId)) {
        setSelectedDistrict(
          districts.find(({ id }) => id === districtId) ?? null,
        );
      }
      const schoolId = +(params.get('schoolId') ?? '');
      if (!Number.isNaN(schoolId)) {
        setSelectedSchool(
          schoolsAndEnrollmentInfo.find(({ school }) => school.id === schoolId)
            ?.school ?? null,
        );
      }
    }
  }, [params, loading, schoolsAndEnrollmentInfo, districts]);

  const {
    onClassSelect,
    classOptions,
    selectedClassOption,
  } = useStudentGroupSelect({
    classes: filteredClasses,
    studentGroupEnrollmentCounts,
    loading,
  });

  const { filteredClassId, filteredSchoolId, filteredDistrictId } = useDebounce(
    {
      filteredClassId: selectedClassOption?.id ?? null,
      filteredSchoolId: selectedSchoolOption?.id ?? null,
      filteredDistrictId: selectedDistrict?.id ?? null,
      // Change to a 400ms delay once the initial load is complete
    },
    dashboardData === null ? 0 : 400,
  );

  // Refresh the dashboard data whenever a school or class is filtered.
  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setDashboardRefreshing(true);
      }
      try {
        const data = await getDashboardData({
          filteredClassId,
          filteredSchoolId,
          filteredDistrictId,
        });
        if (!isCancelled()) {
          setDashboardData(data);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to load dashboard data: ${e.message}`,
          theme: 'error',
        });
      } finally {
        if (!isCancelled()) {
          setDashboardRefreshing(false);
        }
      }
    },
    [filteredClassId, filteredSchoolId, filteredDistrictId],
  );

  const onExportCSVClicked = useCallback(async () => {
    if (dashboardRefreshing) {
      return;
    }
    setDashboardRefreshing(true);
    try {
      const response = await getDashboardDataCSV({
        filteredClassId,
        filteredSchoolId,
        filteredDistrictId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
      await downloadCSVFile(response);
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        text: `Failed to get CSV export: ${e.message}`,
        theme: 'error',
      });
    }
    setDashboardRefreshing(false);
  }, [
    filteredClassId,
    filteredSchoolId,
    filteredDistrictId,
    dashboardRefreshing,
  ]);

  return {
    onClassSelect,
    onSchoolSelect: handleSchoolSelect,
    selectedClassOption,
    selectedSchoolOption,
    loading,
    classOptions,
    schoolOptions,
    dashboardData,
    dashboardRefreshing,
    districtOptions,
    selectedDistrictOption,
    onDistrictSelect: handleDistrictSelect,
    onExportCSVClicked,
  };
}
