import { User } from 'shared/lib/types/User';
import { apiAxios } from '../apiAxios';

export async function getUser(): Promise<User | null> {
  const response = await apiAxios.get('/auth/user').catch(() => {
    // not logged in
    return null;
  });

  return response?.data ?? null;
}
