import React, {
  ComponentPropsWithoutRef,
  FC,
  FormEvent,
  useCallback,
} from 'react';
import { EditableUser } from '../../types/user/EditableUser';
import { AdminLevel } from 'shared/lib/constants/AdminLevel';
import { Row } from '../Row/Row';
import { Column } from '../Column/Column';
import { TextInput } from '../TextInput/TextInput';
import { capitalizeFirst } from 'shared/lib/utils/capitalizeFirst';
import { PillButton } from '../PillButton/PillButton';
import { FormLabel } from '../FormLabel/FormLabel';

interface EditAdminFormProps extends ComponentPropsWithoutRef<'form'> {
  userName: string;
  orgName: string;
  admin: EditableUser;
  adminLevel: AdminLevel;
  onNameChanged(name: string): void;
  onEmailChanged(email: string): void;
  editMode: boolean;
  onCancelled(): void;
  error?: string;
}

export const EditAdminForm: FC<EditAdminFormProps> = ({
  onCancelled,
  className = '',
  onEmailChanged,
  onNameChanged,
  editMode,
  error,
  admin,
  adminLevel,
  orgName,
  userName,
  ...rest
}) => {
  const { email, name } = admin;
  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );

  const formattedAdminLevel = `${capitalizeFirst(adminLevel)} Admin`;

  return (
    <form className={`flex flex-col ${className}`} {...rest}>
      <Column className="w-full max-w-2.75xl">
        <Row className="w-full flex-grow flex-shrink-0 justify-between flex-wrap pr-4">
          <Column>
            <FormLabel htmlFor="name">Name</FormLabel>
            <TextInput
              id="name"
              value={name}
              onChange={(e) => onNameChanged(e.target.value)}
              placeholder="Name"
              required
            />
          </Column>
          <Column>
            <FormLabel htmlFor="email">Email</FormLabel>
            <TextInput
              id="email"
              value={email}
              onChange={(e) => onEmailChanged(e.target.value)}
              placeholder="Email"
              required
            />
          </Column>
        </Row>
      </Column>
      <Row className="items-center flex-wrap pt-6">
        <PillButton type="submit" buttonVariation="primary">
          {editMode ? 'Save Changes' : `Add ${formattedAdminLevel}`}
        </PillButton>
        <PillButton
          onClick={handleCancel}
          buttonVariation="cancel"
          className="m-9"
        >
          Cancel
        </PillButton>
      </Row>
      <Row className="pt-3 text-red-600">{error}</Row>
    </form>
  );
};
