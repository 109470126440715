import React, {
  ComponentPropsWithRef,
  FC,
  FormEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import './RemoveConfirm.css';
import { Row } from '../Row/Row';
import { Column } from '../Column/Column';
import { useTimeout } from '../../hooks/useTimeout';
import { SECOND } from 'shared/lib/constants/time';
import { PillButton } from '../PillButton/PillButton';

interface RemoveConfirmProps extends ComponentPropsWithRef<'form'> {
  recordId: number;
  removalType: string;
  recordName: string;
  onCancelled(): void;
  warningText: string;
  confirmButtonText: string;
  cancelButtonText?: string;
}

export const RemoveConfirm: FC<RemoveConfirmProps> = ({
  recordId,
  recordName,
  onCancelled,
  className = '',
  warningText,
  confirmButtonText,
  cancelButtonText = 'Cancel',
  removalType,
  ...rest
}) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    setDisabled(true);
  }, [recordId]);

  const handleTimeout = useCallback(() => {
    setDisabled(false);
  }, []);

  useTimeout(handleTimeout, 3 * SECOND);

  const handleCancel = useCallback(
    (event: FormEvent) => {
      event.preventDefault();
      onCancelled();
    },
    [onCancelled],
  );

  return (
    <form className={`flex flex-col h-full ${className}`} {...rest}>
      <Column className="justify-center items-center self-center text-center font-mplus text-xl text-trueGray-750 mb-12 remove-confirm-text">
        {warningText}
      </Column>
      <Column className="w-full justify-center items-center self-center text-center font-roboto font-bold text-trueGray-750">
        Do you wish to continue?
      </Column>
      <Row className="justify-center items-center flex-wrap">
        <PillButton
          type="submit"
          buttonVariation="primary"
          className={`mt-9 mb-9 transition-opacity duration-1000 ${
            disabled ? 'bg-opacity-50' : 'bg-opacity-100'
          }`}
          disabled={disabled}
        >
          {confirmButtonText}
        </PillButton>
        <PillButton
          onClick={handleCancel}
          buttonVariation="secondary"
          className="m-9 border-none"
        >
          {cancelButtonText}
        </PillButton>
      </Row>
    </form>
  );
};
