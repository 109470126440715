import React, { FC, ReactNode } from 'react';
import { AlertModal, AlertModalProps } from '../AlertModal/AlertModal';
import showModal from '@emberex/react-utils/lib/showModal';

type Theme = 'normal' | 'error';

interface AlertProps extends AlertModalProps {
  title?: ReactNode;
  text?: ReactNode;
  theme?: Theme;
}

function getTitleStyleForTheme(theme: Theme) {
  switch (theme) {
    case 'error':
      return 'text-red-highlight text-3xl';
    case 'normal':
    default:
      return 'text-light-blue-500 text-2xl';
  }
}

export const Alert: FC<AlertProps> = ({
  title,
  text,
  theme = 'normal',
  ...rest
}) => (
  <AlertModal {...rest}>
    <h2 className={`font-mplus ${getTitleStyleForTheme(theme)} font-extrabold`}>
      {title}
    </h2>
    <p className="font-roboto font-bold mt-9">{text}</p>
  </AlertModal>
);

interface ShowAlertParams
  extends Omit<
    AlertProps,
    'onConfirmClicked' | 'onCancelClicked' | 'onExitClicked'
  > {
  /**
   * Treat the exit button as a cancel.
   * Defaults to true.
   */
  exitAsCancel?: boolean;
}

export function showAlert({ exitAsCancel = true, ...props }: ShowAlertParams) {
  return showModal<boolean>((resolve) => {
    return (
      <Alert
        onConfirmClicked={() => resolve(true)}
        onCancelClicked={() => resolve(false)}
        onExitClicked={() => {
          if (exitAsCancel) {
            resolve(false);
          } else {
            resolve(true);
          }
        }}
        {...props}
      />
    );
  });
}

export function showOkAlert(
  props: Omit<ShowAlertParams, 'confirmText' | 'showConfirm'>,
) {
  return showModal<boolean>((resolve) => {
    return (
      <Alert
        onConfirmClicked={() => resolve(true)}
        onCancelClicked={() => resolve(false)}
        showConfirm
        confirmText="OK"
        {...props}
      />
    );
  });
}

export function showYesNoAlert(
  props: Omit<
    ShowAlertParams,
    'confirmText' | 'showConfirm' | 'showCancel' | 'cancelText' | 'showExit'
  >,
) {
  return showModal<boolean>((resolve) => {
    return (
      <Alert
        onConfirmClicked={() => resolve(true)}
        onCancelClicked={() => resolve(false)}
        showConfirm
        confirmText="Yes"
        showCancel
        cancelText="No"
        {...props}
      />
    );
  });
}
