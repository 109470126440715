import { SchoolOrg } from 'shared/lib/types/Org';
import { useState, useMemo } from 'react';
import { DashboardStudent } from 'shared/lib/types/dashboard/DashboardStudent';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { UserWithGroupEnrollments } from 'shared/lib/types/User';
import { Admin } from 'shared/lib/types/Admin';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import groupBy from 'lodash/groupBy';
import { getSchool } from '../../api/school/getSchool';
import { getStudentGroupsForSchool } from '../../api/school/getStudentGroupsForSchool';
import { getSchoolAssessors } from '../../api/school/getSchoolAssessors';
import { getSchoolStudents } from '../../api/school/getSchoolStudents';
import { getSchoolAdmins } from '../../api/school/getSchoolAdmins';
import { showOkAlert } from '../../components/Alert/Alert';
import { schools } from 'shared/lib/constants/routes/administratorRoutes';
import { useHistory } from 'react-router-dom';
import { BAPWithAssessments, BAPWithFullData } from 'shared/lib/types/BAP';
import { getInProgressBAPsForSchool } from '../../api/school/getInProgressBAPsForSchool';
import { getCompletedBAPSForSchool } from '../../api/school/getCompletedBAPSForSchool';

interface UseSchoolDataParams {
  school: number | SchoolOrg;
}

export function useSchoolData({ school }: UseSchoolDataParams) {
  const [schoolOrg, setSchoolOrg] = useState<SchoolOrg | null>(null);
  const [students, setStudents] = useState<DashboardStudent[]>([]);
  const [classes, setClasses] = useState<StudentGroup[]>([]);
  const [assessors, setAssessors] = useState<UserWithGroupEnrollments[]>([]);
  const [schoolAdmins, setSchoolAdmins] = useState<Admin[]>([]);
  const [loading, setLoading] = useState(true);
  const [inProgressBAPs, setInProgressBAPs] = useState<BAPWithFullData[]>([]);
  const [completedBAPs, setCompletedBAPs] = useState<BAPWithAssessments[]>([]);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setSchoolOrg(null);
        setStudents([]);
        setAssessors([]);
        setClasses([]);
        setLoading(true);
      }
      try {
        let currentSchool: SchoolOrg;
        if (typeof school === 'number') {
          currentSchool = await getSchool(school);
        } else {
          currentSchool = school;
        }
        const schoolId = currentSchool.id;
        const [
          groups,
          teachers,
          studentList,
          bapsInProgress,
          bapsCompleted,
          admins,
        ] = await Promise.all([
          getStudentGroupsForSchool(schoolId),
          getSchoolAssessors(schoolId),
          getSchoolStudents(schoolId),
          getInProgressBAPsForSchool(schoolId),
          getCompletedBAPSForSchool(schoolId),
          getSchoolAdmins(schoolId),
        ]);
        if (!isCancelled()) {
          setSchoolOrg(currentSchool);
          setClasses(groups);
          setAssessors(teachers);
          setStudents(studentList);
          setSchoolAdmins(admins);
          setInProgressBAPs(bapsInProgress);
          setCompletedBAPs(bapsCompleted);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to get school details: ${e.message}`,
          theme: 'error',
        });
        history.replace(schools);
      }
    },
    [school, history],
  );

  const studentsGroupedByPrimaryClass = useMemo(() => {
    return Object.values(
      groupBy(students, (student) => student.studentGroups[0]?.id),
    ).flat();
  }, [students]);

  return {
    loading,
    schoolOrg,
    schoolAdmins,
    students: studentsGroupedByPrimaryClass,
    assessors,
    inProgressBAPs,
    completedBAPs,
    classes,
  };
}
