import React, { FC } from 'react';
import { ActivityPracticeStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { Row } from '../../Row/Row';
import { ActivityAnswerButtons } from '../ActivityAnswerButtons/ActivityAnswerButtons';
import { AssessmentNavigationButtons } from '../AssessmentNavigationButtons/AssessmentNavigationButtons';
import { AssessmentInfoSection } from '../AssessmentInfoSection/AssessmentInfoSection';
import { Column } from '../../Column/Column';
import { ActivityTextDisplay } from '../ActivityTextDisplay/ActivityTextDisplay';
import { PracticeStep } from 'shared/lib/types/activity/ActivityStep';

export const ActivityPracticeStep: FC<ActivityPracticeStepProps> = ({
  answers,
  selectedAnswer,
  activityStep,
  canProgress,
  onAnswerSelected,
  onBackClicked,
  onNext,
  ...rest
}) => {
  return (
    <AssessmentStepPage
      maintainHiddenElementLayout={false}
      {...rest}
      showBack={false}
    >
      <Row className="w-full items-center pt-6 short:pt-0 mt-2.5">
        <ActivityAnswerButtons
          answers={answers}
          selectedAnswer={selectedAnswer}
          onAnswerSelected={onAnswerSelected}
        />
      </Row>
      <Row className="pt-15 short:pt-4 flex-grow flex-shrink-0 h-full">
        <AssessmentNavigationButtons
          onNext={onNext}
          onBack={onBackClicked}
          canProgress={canProgress}
        />
      </Row>
      <PracticeInfoSection activityStep={activityStep} />
    </AssessmentStepPage>
  );
};

const PracticeInfoSection: FC<{ activityStep: PracticeStep }> = ({
  activityStep,
}) => {
  const {
    feedbackHeading,
    feedbackIncorrect,
    feedbackCorrect,
    correctResponse,
    notes,
    stepId,
  } = activityStep;
  return (
    <AssessmentInfoSection key={stepId}>
      <Column className="w-full max-w-lg text-sm">
        <div className="text-sm font-bold pb-3">{feedbackHeading}</div>
        <Column className="pb-3">
          {correctResponse && (
            <Row className="items-center">
              <div className="flex absolute -left-9 bg-green-highlight text-white font-mplus font-extrabold text-xs h-6 w-6 rounded-sm justify-center items-center text-center">
                C
              </div>
              <div className="text-green-highlight font-medium mr-2">
                Correct Response:
              </div>
              {correctResponse}
            </Row>
          )}
          {feedbackCorrect.map((feedbackItem, idx) => (
            <ActivityTextDisplay
              activityText={feedbackItem}
              key={`correct_${idx}`}
              highlightColor="green"
              className={
                feedbackCorrect.length > 1 && idx !== feedbackCorrect.length - 1
                  ? 'pb-3'
                  : ''
              }
            />
          ))}
        </Column>
        <Column className="pb-3">
          <Row className="items-center">
            <div className="flex absolute -left-9 bg-light-blue-500 text-white font-mplus font-extrabold text-xs h-6 w-6 rounded-sm justify-center items-center text-center">
              I
            </div>
            <div className="text-red-highlight font-medium">
              Incorrect Response:
            </div>
          </Row>
          {feedbackIncorrect.map((feedbackItem, idx) => (
            <ActivityTextDisplay
              activityText={feedbackItem}
              key={`incorrect_${idx}`}
              highlightColor="red"
              className={
                feedbackIncorrect.length > 1 &&
                idx !== feedbackIncorrect.length - 1
                  ? 'pb-3'
                  : ''
              }
            />
          ))}
        </Column>
        {notes && (
          <Column className="pb-3">
            {notes.map((note, idx) => (
              <ActivityTextDisplay activityText={note} key={`note_${idx}`} />
            ))}
          </Column>
        )}
      </Column>
    </AssessmentInfoSection>
  );
};
