import { useEffect, useState } from 'react';

/**
 * Debounce a value
 * @param value the value to debounce.
 * @param debounceTime the timeout between updates in milliseconds
 * @return the updated value after the debounce.
 */
export function useDebounce<T>(value: T, debounceTime = 200): T {
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => {
    const timeout = window.setTimeout(() => {
      setCurrentValue(value);
    }, debounceTime);
    return () => {
      clearTimeout(timeout);
    };
  }, [value, debounceTime]);

  return currentValue;
}
