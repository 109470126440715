import { EditableStudentGroup } from '../../types/student-group/EditableStudentGroup';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { apiAxios } from '../apiAxios';

export async function createStudentGroup(
  schoolId: number,
  body: EditableStudentGroup,
): Promise<StudentGroup> {
  const response = await apiAxios.put(
    `/school/${schoolId}/student-group`,
    body,
  );
  return response.data;
}
