import React, { FC } from 'react';
import { ActivityPrePracticeStepProps } from '../../../types/assessment/ActivityStepProps';
import { AssessmentStepPage } from '../AssessmentStepPage/AssessmentStepPage';
import { AssessmentOrderedListItem } from '../AssessmentOrderedListItem/AssessmentOrderedListItem';
import { ActivityTextDisplay } from '../ActivityTextDisplay/ActivityTextDisplay';
import { Row } from '../../Row/Row';
import { PillButton } from '../../PillButton/PillButton';
import { Column } from '../../Column/Column';

export const ActivityPrePracticeStep: FC<ActivityPrePracticeStepProps> = ({
  activityStep,
  onNext,
  ...rest
}) => {
  const { intro, items, outro } = activityStep;
  return (
    <AssessmentStepPage {...rest} showBack audio={null}>
      <Column className="w-full overflow-y-auto pr-4 max-h-assessment-page">
        {intro && (
          <Column className="pb-3">
            {intro.map((introItem, idx) => (
              <ActivityTextDisplay
                activityText={introItem}
                key={`intro_${idx}`}
              />
            ))}
          </Column>
        )}
        {items.map((item, idx) => (
          <AssessmentOrderedListItem
            className="items-baseline pb-9 short:pb-4"
            order={idx + 1}
          >
            <ActivityTextDisplay activityText={item} />
          </AssessmentOrderedListItem>
        ))}
        {outro &&
          outro.map((outroItem, idx) => (
            <ActivityTextDisplay
              activityText={outroItem}
              key={`outro_${idx}`}
              className="pb-6"
            />
          ))}
        <Row className="w-full justify-center short:mt-0 mt-20 pt-4 pb-4">
          <PillButton buttonVariation="primary" onClick={onNext}>
            Continue
          </PillButton>
        </Row>
      </Column>
    </AssessmentStepPage>
  );
};
