import React, { FC, FormEvent, useCallback, useState } from 'react';
import { UnauthenticatedPage } from '../../components/UnauthenticatedPage/UnauthenticatedPage';
import { LoginForm } from '../../components/LoginForm/LoginForm';
import { Column } from '../../components/Column/Column';

interface LoginProps {
  login: (email: string, password: string) => Promise<void>;
}

export const Login: FC<LoginProps> = ({ login, ...rest }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      setError('');
      try {
        await login(email, password);
      } catch (e) {
        setError(e.message);
      }
    },
    [login, email, password],
  );
  return (
    <UnauthenticatedPage>
      <div className="flex flex-col md:flex-row w-full max-w-3xl flex-grow justify-center md:justify-between pr-2 pl-2 pt-17 flex-wrap">
        <Column className="w-72 sm:pr-2">
          <h2 className="mb-5 text-trueGray-900 font-roboto font-medium">
            Welcome Back!
          </h2>
          <h1 className="text-4xl font-extrabold">
            Login To
            <br /> Your Account
          </h1>
        </Column>
        <LoginForm
          username={email}
          password={password}
          onUsernameChanged={(value) => setEmail(value)}
          onPasswordChanged={(value) => setPassword(value)}
          onSubmit={handleSubmit}
          error={error}
        />
      </div>
    </UnauthenticatedPage>
  );
};
