import { DashboardDistrict } from 'shared/lib/types/district/DashboardDistrict';
import { Admin } from 'shared/lib/types/Admin';
import { useMemo } from 'react';
import { useSearch } from '../useSearch';
import { startsWithIgnoreCase } from 'shared/lib/utils/startsWithIgnoreCase';
import { useNameSearch } from '../useNameSearch';

interface UseDistrictsDashboardParams {
  districts: ReadonlyArray<DashboardDistrict>;
  superAdmins: ReadonlyArray<Admin>;
}

export function useDistrictsDashboard({
  superAdmins,
  districts,
}: UseDistrictsDashboardParams) {
  const { search, onSearchChanged } = useSearch();

  const filteredDistricts = useMemo(() => {
    if (!search) {
      return districts;
    }
    return districts.filter(({ name }) => startsWithIgnoreCase(name, search));
  }, [search, districts]);

  return {
    districts: filteredDistricts,
    superAdmins: useNameSearch({ list: superAdmins, search }),
    search,
    onSearchChanged,
  };
}
