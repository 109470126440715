import React, { FC, useCallback } from 'react';
import { Gender, genders } from 'shared/lib/constants/user/Gender';
import { Option } from '../../types/Option';
import { Dropdown } from '../Dropdown/Dropdown';

const GENDER_OPTIONS: Option[] = genders.map((gender, idx) => ({
  id: idx,
  value: gender,
}));

interface GenderSelectorProps {
  gender: Gender | null;
  onGenderChanged(gender: Gender): void;
  placeholder?: string;
  className?: string;
}

export const GenderSelector: FC<GenderSelectorProps> = ({
  gender,
  onGenderChanged,
  ...rest
}) => {
  const onSelect = useCallback(
    ({ value }: Option) => {
      const gender = genders.find((g) => g === value);
      if (gender) {
        onGenderChanged(gender);
      }
    },
    [onGenderChanged],
  );

  return (
    <Dropdown
      selected={GENDER_OPTIONS.find((g) => g.value === gender)}
      onSelected={onSelect}
      options={GENDER_OPTIONS}
      {...rest}
    />
  );
};
