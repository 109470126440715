import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface UseAudioPlayerParams {
  /**
   * The audio source
   */
  source: string;

  pause?: boolean;
}

interface AudioPlayerControls {
  /**
   * Whether or no the audio is currently playing.
   */
  playing: boolean;

  /**
   * The progress, between 0 and 1.
   */
  progress: number;

  /**
   * The percent progress, between 0 and 100
   */
  percentProgress: number;

  /**
   * Whether or not the audio has been completely played at least once.
   */
  playedInFull: boolean;

  /**
   * Toggle play/pause
   */
  togglePlay: () => void;

  /**
   * Restart the audio from the beginning.
   */
  replay: () => void;

  /**
   * Reset the audio from to beginning.
   */
  reset: () => void;

  /**
   * A ref to an HTML Audio Player
   */
  playerRef: MutableRefObject<HTMLAudioElement | null>;

  /**
   * Whether or not the player has been muted.
   */
  muted: boolean;

  /**
   * Turn mute on/off
   */
  toggleMute: () => void;

  /**
   * Whether the audio has started but has not been completed.
   */
  currentPlayIncomplete: boolean;

  /**
   * The amount of time the audio has played.
   * Note: this is in seconds
   */
  elapsedTime: number;
  /**
   * The total duration of the audio
   * Note: this is in seconds
   */
  totalTime: number;
}

export function useAudioPlayer({
  source,
  pause,
}: UseAudioPlayerParams): AudioPlayerControls {
  const playerRef = useRef<HTMLAudioElement | null>(null);
  const [playing, setPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [fullPlayCount, setFullPlayCount] = useState(0);
  const [currentPlayIncomplete, setCurrentPlayIncomplete] = useState(true);

  useEffect(() => {
    setPlaying(false);
    setProgress(0);
    setFullPlayCount(0);
    setCurrentPlayIncomplete(true);

    if (playerRef.current) {
      // Ensure the player is paused
      playerRef.current.pause();
      playerRef.current.src = source;
      let duration = 0;
      playerRef.current.ontimeupdate = () => {
        const elapsed = playerRef.current?.currentTime ?? 0;
        const totalProgress = duration ? elapsed / duration : 0;
        const playedInFull = playerRef.current?.ended;
        // Safari, in particular, likes to report 0.999 when 100% played, so when playedInFull is true, set it to 1 (100%)
        setProgress(playedInFull ? 1 : totalProgress);
        if (playedInFull) {
          setFullPlayCount((prev) => prev + 1);
          setCurrentPlayIncomplete(false);
        } else {
          setCurrentPlayIncomplete(true);
        }
      };

      playerRef.current.ondurationchange = () => {
        duration = playerRef.current?.duration ?? 0;
      };

      playerRef.current.onended = () => {
        setProgress(1);
      };
    }
  }, [playerRef, source]);

  const togglePlay = useCallback(() => {
    if (playerRef.current) {
      if (playing) {
        playerRef.current.pause();
        setPlaying(false);
      } else {
        playerRef.current.play();
        setPlaying(true);
      }
    }
  }, [playerRef, playing]);

  const reset = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.currentTime = 0;
    }
  }, [playerRef]);

  const replay = useCallback(() => {
    reset();
    if (playerRef.current) {
      playerRef.current.play();
      setPlaying(true);
    }
  }, [reset, playerRef]);

  useEffect(() => {
    if (playerRef.current && progress >= 1) {
      setPlaying(false);
    }
  }, [playerRef, progress]);

  const percentProgress = useMemo(() => {
    return Math.min(100, progress * 100);
  }, [progress]);

  const toggleMute = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.muted = !playerRef.current.muted;
    }
  }, [playerRef]);

  useEffect(() => {
    if (playerRef.current && pause) {
      playerRef.current.pause();
      setPlaying(false);
    }
  }, [playerRef, pause]);

  return {
    playing,
    togglePlay,
    progress,
    replay,
    reset,
    playedInFull: fullPlayCount > 0,
    playerRef,
    percentProgress,
    toggleMute,
    muted: playerRef.current?.muted ?? false,
    currentPlayIncomplete,
    elapsedTime: playerRef.current?.currentTime ?? 0,
    totalTime: playerRef.current?.duration ?? 0,
  };
}
