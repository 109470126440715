import React, { FC, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { Student } from 'shared/lib/types/Student';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getStudentsNotInGroup } from '../../api/student-group/getStudentsNotInGroup';
import { getStudentGroup } from '../../api/student-group/getStudentGroup';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { useHistory } from 'react-router-dom';
import { Row } from '../../components/Row/Row';
import { BackButton } from '../../components/BackButton/BackButton';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { EnrollUserForm } from '../../components/EnrollUserForm/EnrollUserForm';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { useGroupEnrollmentEditor } from '../../hooks/student-group/useGroupEnrollmentEditor';
import { showOkAlert } from '../../components/Alert/Alert';
import { addStudentToGroup } from '../../api/student-group/addStudentToGroup';
import { formatSchoolDetailsRoute } from 'shared/lib/constants/routes/administratorRoutes';

interface GroupEnrollmentEditorProps {
  studentGroupId: number;
  mode: 'Student'; // only student, for now
}

export const GroupEnrollmentEditor: FC<GroupEnrollmentEditorProps> = ({
  studentGroupId,
  mode,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [studentsNotInGroup, setStudentsNotInGroup] = useState<Student[]>([]);
  const [studentGroup, setStudentGroup] = useState<StudentGroup | null>(null);
  const history = useHistory();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudentsNotInGroup([]);
        setStudentGroup(null);
      }
      const [unenrolledStudents, group] = await Promise.all([
        getStudentsNotInGroup(studentGroupId),
        getStudentGroup(studentGroupId),
      ]);

      if (!isCancelled()) {
        setStudentGroup(group);
        setStudentsNotInGroup(unenrolledStudents);
        setLoading(false);
      }
    },
    [studentGroupId, mode],
  );

  const { selectedUser, ...formProps } = useGroupEnrollmentEditor(
    studentsNotInGroup,
  );

  const onSubmit = useCallback(async () => {
    if (!studentGroup || !selectedUser) {
      return;
    }
    try {
      await addStudentToGroup(studentGroup.id, selectedUser.id);
      history.replace(
        `${formatSchoolDetailsRoute({
          id: studentGroup.schoolId,
        })}?selectedClass=${studentGroup.id}`,
      );
    } catch (e) {
      await showOkAlert({
        title: 'Error',
        text: `Failed to add ${mode} to class: ${e.message}`,
        theme: 'error',
      });
    } finally {
      setLoading(false);
    }
  }, [studentGroup, selectedUser, history, mode]);

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle
            pageTitle={`Add Existing ${mode} to ${studentGroup?.name ?? ''}`}
          />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <Row className="justify-between mb-6">
                <BackButton onClick={() => history.goBack()} />
              </Row>
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {studentGroup?.name}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    Add Existing {mode}
                  </Row>
                </Column>
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <EnrollUserForm
              onCancelled={() => history.goBack()}
              onSubmit={onSubmit}
              className="pt-6 pl-9"
              enrollmentType={mode}
              submitDisabled={!selectedUser}
              {...formProps}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
