import { FormEvent, useCallback, useState } from 'react';
import { StudentGroupAndSchool } from 'shared/lib/types/StudentGroup';
import { useHistory } from 'react-router-dom';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { formatAssessorDetailRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { getStudentGroup } from '../../api/student-group/getStudentGroup';
import isEmail from 'validator/lib/isEmail';
import { createAndEnrollAssessorInGroup } from '../../api/student-group/createAndEnrollAssessorInGroup';
import { updateAssessor } from '../../api/assessor/updateAssessor';
import { getAssessor } from '../../api/assessor/getAssessor';
import { getPrimaryStudentGroupForAssessor } from '../../api/assessor/getPrimaryStudentGroupForAssessor';
import { AssessorDetail } from 'shared/lib/types/AssessorDetail';
import { useUserForm } from './useUserForm';
import { showOkAlert } from '../../components/Alert/Alert';

interface UseAssessorEditorParams {
  studentGroupId: number | null;
  assessorId: number | null;
}

async function loadForCreate(
  studentGroupId: number,
): Promise<[StudentGroupAndSchool, null]> {
  return Promise.all([getStudentGroup(studentGroupId), null]);
}

async function loadForEdit(
  assessorId: number,
): Promise<[StudentGroupAndSchool | null, AssessorDetail]> {
  return Promise.all([
    getPrimaryStudentGroupForAssessor(assessorId),
    getAssessor(assessorId),
  ]);
}

export function useAssessorEditor({
  studentGroupId,
  assessorId,
}: UseAssessorEditorParams) {
  const [loading, setLoading] = useState(true);
  const [
    studentGroup,
    setStudentGroup,
  ] = useState<StudentGroupAndSchool | null>(null);
  const [assessor, setAssessor] = useState<AssessorDetail | null>(null);
  const history = useHistory();
  const [error, setError] = useState('');

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setStudentGroup(null);
        setAssessor(null);
      }

      if (!studentGroupId && !assessorId) {
        await showOkAlert({
          title: 'Error',
          text: 'Cannot create assessor',
          theme: 'error',
        });
        history.goBack();
        return;
      }

      try {
        let user: AssessorDetail | null;
        let primaryGroup: StudentGroupAndSchool | null;
        if (assessorId) {
          const [group, userInfo] = await loadForEdit(assessorId);
          user = userInfo;
          primaryGroup = group;
        } else {
          const [group, userInfo] = await loadForCreate(studentGroupId!);
          user = userInfo;
          primaryGroup = group;
        }
        if (!isCancelled()) {
          setStudentGroup(primaryGroup);
          setAssessor(user);
          setLoading(false);
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to prepare for ${
            assessorId ? 'updating' : 'creating'
          } teacher: ${e.message} `,
        });
        history.goBack();
      }
    },
    [studentGroupId, assessorId, history],
  );

  const {
    editableUser: editableAssessor,
    userName,
    ...formProps
  } = useUserForm(assessor);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading || (assessor === null && studentGroup === null)) {
        return;
      }
      setError('');
      const { email, name } = editableAssessor;
      if (!email || !isEmail(email)) {
        setError('Email is invalid.');
        return;
      }

      if (!name) {
        setError('Name is required.');
        return;
      }

      try {
        setLoading(true);
        if (assessor) {
          setAssessor(
            await updateAssessor(assessor.id, {
              email,
              name,
            }),
          );
          return;
        }
        if (studentGroup) {
          const created = await createAndEnrollAssessorInGroup(
            studentGroup.id,
            {
              name,
              email,
            },
          );
          history.replace(formatAssessorDetailRoute(created));
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to ${assessor ? 'update' : 'create'} teacher: ${
            e.message
          }`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [assessor, editableAssessor, studentGroup, loading, history],
  );

  return {
    ...formProps,
    loading,
    studentGroup,
    assessor,
    editableAssessor,
    error,
    onSubmit,
    teacherName: userName,
  };
}
