import { Language } from 'shared/lib/constants/Language';
import { BAPAssessment } from 'shared/lib/types/BAPAssessment';
import { apiAxios } from '../apiAxios';

interface BeginBAPAssessmentParams {
  studentId: number;
  language: Language;
  /**
   * Pass true to allow an existing assessment to be abandoned.
   * Pass false to forbid the launch
   */
  unsafeLaunch: boolean;
}

export async function beginBAPAssessment({
  studentId,
  unsafeLaunch,
  language,
}: BeginBAPAssessmentParams): Promise<BAPAssessment> {
  const response = await apiAxios.post(`/baps/begin/${studentId}/${language}`, {
    unsafeLaunch,
  });
  return response.data.baps;
}
