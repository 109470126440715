import { ValidatedEditableStudent } from '../../types/student/EditableStudent';
import { Student } from 'shared/lib/types/Student';
import { apiAxios } from '../apiAxios';

export async function createAndEnrollStudentInGroup(
  studentGroupId: number,
  body: ValidatedEditableStudent,
): Promise<Student> {
  const response = await apiAxios.put(
    `/student-group/${studentGroupId}/enroll/student`,
    body,
  );
  return response.data;
}
