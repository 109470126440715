import React, { ComponentPropsWithoutRef, FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import useToggleState from '@emberex/react-utils/lib/useToggleState';
import useOnClickOutside from '@emberex/react-utils/lib/useOnClickOutside';
import { Button } from '../Button/Button';
import { add, blueTriangle } from '../../images';

export interface MenuOption {
  to: string;
  label: string;
}

interface RouteMenuProps extends ComponentPropsWithoutRef<'div'> {
  routes: ReadonlyArray<MenuOption>;
  buttonText?: string;
}

export const RouteMenu: FC<RouteMenuProps> = ({
  routes,
  buttonText = 'Add',
  className = '',
  ...rest
}) => {
  const [
    showAddOption,
    toggleShowAddOptions,
    setShowAddOptions,
  ] = useToggleState(false);
  const onClickOutside = useCallback(() => {
    setShowAddOptions(false);
  }, [setShowAddOptions]);
  const addOptionsRoot = useOnClickOutside<HTMLDivElement>(onClickOutside);
  return (
    <div
      className={`flex flex-col relative w-36 ${className}`}
      ref={addOptionsRoot}
      {...rest}
    >
      <Button
        className="w-full items-center justify-center text-light-blue-500 ml-3"
        onClick={toggleShowAddOptions}
      >
        <img src={add} alt="Add" className="mr-2 w-6 h-6" />
        {buttonText}
        <img
          src={blueTriangle}
          alt={showAddOption ? 'Close' : 'Open'}
          className={`ml-2 w-6 h-6 transition-transform transform duration-500 ${
            showAddOption ? 'rotate-180' : 'rotate-0'
          }`}
        />
      </Button>
      {showAddOption && (
        <ul className="absolute z-10 top-12 right-2 bg-white shadow-dashboardShadow text-light-blue-500 font-roboto rounded-xl pr-6 pl-6 w-50">
          {routes.map(({ to, label }, idx) => (
            <li
              key={to}
              className={
                idx < routes.length - 1 ? 'border-b border-trueGray-150' : ''
              }
            >
              <Link
                to={to}
                className="flex flex-row justify-center pt-6 pb-6 w-full hover:underline"
              >
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
