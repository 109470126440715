import { ComponentPropsWithoutRef, FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button } from '../Button/Button';
import { ButtonVariation } from '../../types/ButtonVariation';
import { getStylesForButtonVariation } from '../../utils/getStylesForButtonVariation';

interface PillButtonProps extends ComponentPropsWithoutRef<'button'> {
  /**
   * Optional, utility to style a button to appear like a primary (blue background, white text)
   * or secondary (transparent background, blue text) button.
   * Defaults to no standard style other than making it look like a Pill button
   */
  buttonVariation?: ButtonVariation;
}

export const PillButton: FC<PillButtonProps> = ({
  className,
  buttonVariation,
  ...rest
}) => (
  <Button
    className={twMerge(
      'font-roboto text-sm font-medium rounded-full justify-center items-center text-center transition-colors w-39 h-12',
      getStylesForButtonVariation(buttonVariation),
      className,
    )}
    {...rest}
  />
);
