import React, { ComponentPropsWithoutRef, FC } from 'react';

interface TextInputProps extends ComponentPropsWithoutRef<'input'> {
  type?: 'text' | 'password' | 'tel';
  width?: 'w-80' | 'w-82';
}

export const TextInput: FC<TextInputProps> = ({
  type = 'text',
  className = '',
  width = 'w-80',
  ...rest
}) => (
  <input
    type={type}
    className={`h-15 ${width} rounded border border-light-blue-500 bg-white items-center pl-6 pr-6 placeholder-trueGray-900 font-roboto font-medium text-sm text-trueGray-900 ${className}`}
    {...rest}
  />
);
