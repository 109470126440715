import React, { FC, FormEvent, useCallback, useState } from 'react';
import { Column } from '../../components/Column/Column';
import { DistrictOrg } from 'shared/lib/types/Org';
import useAsyncEffect from '@emberex/react-utils/lib/useAsyncEffect';
import { getDistrict } from '../../api/district/getDistrict';
import { SpinnerOverlay } from '../../components/SpinnerOverlay/SpinnerOverlay';
import { BackButton } from '../../components/BackButton/BackButton';
import {
  formatDistrictDetailsRoute,
  formatRemoveDistrictRoute,
} from 'shared/lib/constants/routes/administratorRoutes';
import { useDistrictForm } from '../../hooks/district/useDistrictForm';
import { useHistory } from 'react-router-dom';
import { updateDistrict } from '../../api/district/updateDistrict';
import { createDistrict } from '../../api/district/createDistrict';
import { PageTitle } from '../../components/PageTitle/PageTitle';
import { MainPanel } from '../../components/MainPanel/MainPanel';
import { Row } from '../../components/Row/Row';
import { ActionLink } from '../../components/ActionLink/ActionLink';
import { OrgForm } from '../../components/OrgForm/OrgForm';
import { OrgType } from 'shared/lib/constants/org/OrgType';
import { showOkAlert } from '../../components/Alert/Alert';
import { useSettingsContext } from '../../contexts/settingsContext';

interface DistrictEditorProps {
  districtId: number | null;
}

export const DistrictEditor: FC<DistrictEditorProps> = ({
  districtId,
  ...rest
}) => {
  const [loading, setLoading] = useState(true);
  const [district, setDistrict] = useState<DistrictOrg | null>(null);
  const [error, setError] = useState('');
  const history = useHistory();
  const { refreshResearchMode } = useSettingsContext();

  useAsyncEffect(
    async (isCancelled) => {
      if (!isCancelled()) {
        setLoading(true);
        setDistrict(null);
      }
      const districtOrg = districtId ? await getDistrict(districtId) : null;
      if (!isCancelled()) {
        setDistrict(districtOrg);
        setLoading(false);
      }
    },
    [districtId],
  );

  const {
    editableDistrict,
    districtName,
    ...districtFormProps
  } = useDistrictForm(district);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading) {
        return;
      }
      setError('');
      const { name, location, researchOrg } = editableDistrict;
      if (!name.trim()) {
        setError('District Name is required.');
        return;
      }

      if (!location.trim()) {
        setError('District Location is required.');
        return;
      }
      setLoading(true);
      try {
        if (district) {
          setDistrict(
            await updateDistrict(district.id, {
              name,
              location,
              researchOrg,
            }),
          );
          // Refresh the "researchMode" setting in case its changed.
          await refreshResearchMode();
          await showOkAlert({
            title: 'Updated',
            text: 'District was updated successfully.',
          });
        } else {
          const created = await createDistrict({
            name,
            location,
            researchOrg,
          });
          await showOkAlert({
            title: 'Created',
            text: 'District was created successfully.',
          });
          history.replace(formatDistrictDetailsRoute(created.id));
        }
      } catch (e) {
        await showOkAlert({
          title: 'Error',
          text: `Failed to ${district ? 'update' : 'create'} district: ${
            e.message
          }`,
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [district, editableDistrict, loading, history, refreshResearchMode],
  );

  return (
    <Column className="flex-grow pt-3.5 overflow-hidden" {...rest}>
      {loading ? (
        <SpinnerOverlay />
      ) : (
        <Column className="h-full">
          <PageTitle
            pageTitle={district ? `Edit ${district.name}` : 'Create District'}
          />
          <Column className="pl-8 lg:pl-15 pr-10">
            <Column className="pl-9 pb-5">
              <BackButton onClick={() => history.goBack()} className="mb-6" />
              <Row className="justify-between">
                <Column className="font-roboto">
                  <Row className="text-2xl text-trueGray-750">
                    {district?.name ?? 'All Districts'}
                  </Row>
                  <Row className="font-bold text-trueGray-900">
                    {district ? 'Edit District' : 'Add District'}
                  </Row>
                </Column>
                {district && (
                  <ActionLink
                    variant="remove"
                    replace
                    to={formatRemoveDistrictRoute(district.id)}
                  >
                    Remove District
                  </ActionLink>
                )}
              </Row>
            </Column>
          </Column>
          <MainPanel className="overflow-y-auto">
            <OrgForm
              editMode={district !== null}
              org={editableDistrict}
              onCancelled={() => history.goBack()}
              onSubmit={onSubmit}
              error={error}
              orgType={OrgType.DISTRICT}
              orgName={districtName}
              className="pl-9"
              {...districtFormProps}
            />
          </MainPanel>
        </Column>
      )}
    </Column>
  );
};
