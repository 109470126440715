import { EditableStudentGroup } from '../../types/student-group/EditableStudentGroup';
import { StudentGroup } from 'shared/lib/types/StudentGroup';
import { apiAxios } from '../apiAxios';

export async function updateStudentGroup(
  studentGroupId: number,
  body: EditableStudentGroup,
): Promise<StudentGroup> {
  const response = await apiAxios.patch(
    `/student-group/${studentGroupId}`,
    body,
  );
  return response.data;
}
