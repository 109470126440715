import React, { ComponentPropsWithoutRef, FC } from 'react';
import { DashboardDistrict } from 'shared/lib/types/district/DashboardDistrict';
import { Column } from '../Column/Column';
import { Row } from '../Row/Row';
import { Link } from 'react-router-dom';
import { formatDistrictDetailsRoute } from 'shared/lib/constants/routes/administratorRoutes';
import { headBlue } from '../../images';

interface DistrictListProps extends ComponentPropsWithoutRef<'div'> {
  districts: ReadonlyArray<DashboardDistrict>;
}

export const DistrictList: FC<DistrictListProps> = ({
  districts,
  className = '',
  ...rest
}) => (
  <Column className={`overflow-y-hidden h-full ${className}`} {...rest}>
    <div className="grid grid-cols-2 2xl:grid-cols-3 font-roboto text-trueGray-750 font-light w-full items-center pr-12 pl-12 pb-1 min-w-640px">
      <Row>District Name</Row>
      <div className="grid grid-cols-4 pr-6">
        <Row className="justify-center">Schools</Row>
        <Row className="justify-center">Classes</Row>
        <Row className="justify-center">Teachers</Row>
        <Row className="justify-center">Students</Row>
      </div>
      <div className="hidden 2xl:block">{/* for layout */}</div>
    </div>
    <Column className="overflow-y-auto dashboard-list pt-0 pl-7.5 pr-10 pb-20 min-w-640px">
      {districts.map(
        ({ id, name, studentCount, classCount, teacherCount, schoolCount }) => (
          <div
            className="grid grid-cols-2 2xl:grid-cols-3 min-h-25 max-h-25 bg-white font-bold font-roboto text-sm shadow-dashboardShadow rounded-xl items-center mb-3 pr-3 pl-6"
            key={id}
          >
            <Link
              to={formatDistrictDetailsRoute(id)}
              className="flex flex-row items-center"
            >
              <img src={headBlue} alt="District" className="w-6 h-6 mr-2" />
              {name}
            </Link>
            <div className="grid grid-cols-4 pr-6">
              <Row className="justify-center">{schoolCount}</Row>
              <Row className="justify-center">{classCount}</Row>
              <Row className="justify-center">{teacherCount}</Row>
              <Row className="justify-center">{studentCount}</Row>
            </div>
            <div className="hidden 2xl:block">{/* for layout */}</div>
          </div>
        ),
      )}
    </Column>
  </Column>
);
