import { UpdatableStudent } from '../../types/student/EditableStudent';
import { Student } from 'shared/lib/types/Student';
import { apiAxios } from '../apiAxios';

export async function updateStudent(
  studentId: number,
  body: UpdatableStudent,
): Promise<Student> {
  const response = await apiAxios.patch(`/student/${studentId}`, body);
  return response.data;
}
