import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';
import { Spinner } from '../Spinner/Spinner';
import { Column } from '../Column/Column';

interface SpinnerOverlayProps extends ComponentPropsWithoutRef<'div'> {
  message?: ReactNode;
  fadeIn?: boolean;
  opaque?: boolean;
}

export const SpinnerOverlay: FC<SpinnerOverlayProps> = ({
  fadeIn,
  message,
  opaque = false,
  className = '',
  ...rest
}) => (
  <div
    className={`flex flex-column absolute top-0 w-full h-screen justify-center items-center bg-white z-50 ${
      fadeIn ? 'fade-in animation-delay-1500' : ''
    } ${opaque ? 'bg-opacity-50' : 'bg-opacity-100'} ${className}`}
    {...rest}
  >
    <Column className="justify-center items-center h-full">
      <Spinner />
      {message && (
        <p className="mt-4 text-sm text-black-800 font-light opacity-80">
          {message}
        </p>
      )}
    </Column>
  </div>
);
