import { DistrictOrg, SchoolOrg } from 'shared/lib/types/Org';
import { EditableSchool } from '../../types/school/EditableSchool';
import { FormEvent, useCallback, useEffect, useState } from 'react';
import { State } from 'shared/lib/constants/state';
import { updateSchool } from '../../api/school/updateSchool';
import { createSchoolInDistrict } from '../../api/district/createSchoolInDistrict';
import { showOkAlert } from '../../components/Alert/Alert';

interface UseSchoolFormParams {
  school: SchoolOrg | null;
  onSubmitted(school: SchoolOrg): void;
  district: DistrictOrg | null;
}

interface UseSchoolFormValues {
  editableSchool: EditableSchool;
  onNameChanged(name: string): void;
  onLocationChanged(location: string): void;
  onSubmit(event: FormEvent): void;
  loading: boolean;
  error: string;
}

const defaultSchoolValues: EditableSchool = {
  name: '',
  location: '',
};

export function useSchoolForm({
  school,
  onSubmitted,
  district,
}: UseSchoolFormParams): UseSchoolFormValues {
  const [loading, setLoading] = useState(false);
  const [editableSchool, setEditableSchool] = useState<EditableSchool>({
    ...(school ?? defaultSchoolValues),
  });

  useEffect(() => {
    if (school) {
      setEditableSchool({ ...school });
    }
    setEditableSchool({
      ...defaultSchoolValues,
      location: district?.location ?? '',
    });
  }, [school, district]);

  const [error, setError] = useState('');

  const onNameChanged = useCallback((name: string) => {
    setEditableSchool((prev) => ({ ...prev, name }));
  }, []);

  const onLocationChanged = useCallback((location: State) => {
    setEditableSchool((prev) => ({ ...prev, location }));
  }, []);

  const onSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      if (loading) {
        return;
      }
      setError('');
      const { name, location } = editableSchool;

      if (!name.trim()) {
        setError('Name is required');
        return;
      }

      if (!location) {
        setError('Location is required');
        return;
      }

      setLoading(true);
      try {
        let modified: SchoolOrg;
        if (school) {
          modified = await updateSchool({ id: school.id, ...editableSchool });
        } else {
          if (!district) {
            await showOkAlert({
              title: 'Error',
              text:
                'School cannot be created because the district does not exist.',
              theme: 'error',
            });
            return;
          }
          modified = await createSchoolInDistrict(district.id, editableSchool);
        }
        await showOkAlert({
          title: 'Success',
          text: `School was successfully ${school ? 'updated' : 'created'}.`,
        });
        onSubmitted(modified);
      } catch (e) {
        await showOkAlert({
          text: `Failed to ${school ? 'Update' : 'Create'} School: ${
            e.message
          }`,
          title: 'Error',
          theme: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [school, editableSchool, loading, onSubmitted, district],
  );

  useEffect(() => {
    setEditableSchool({ ...(school ?? defaultSchoolValues) });
  }, [school]);
  return {
    editableSchool,
    onNameChanged,
    onLocationChanged,
    onSubmit,
    loading,
    error,
  };
}
